<template>
	<v-treeview
		id="tree-view"
		ref="tree"
		dense
		:open.sync="openItems"
		:active="active"
		:disabled="disabled"
		hoverable
		:open-on-click="openOnClick"
		activatable
		:items="items"
		:load-children="loadChildren"
		:expand-icon="expandIcon"
		selection-type="independent"
		:class="[{ 'bee-treeview--hide-expand-icon': hideExpandIcon }]"
		:style="{
			width: treeWidth ? treeWidth + 'px' : '100%',
			minWidth: minTreeWidth + 'px'
		}"
		@update:active="$emit('update:active', $event);"
		@update:open="$emit('update:open', $event); findTreeWidth()"
	>
		<template v-slot:label="data">
			<div
				@click="!disabled ? $emit('update:clicked-item', data.item) : null"
				class="d-flex align-center flex-grow-1"
				:class="[
					!disabled
						? typeof itemClass === 'function'
							? itemClass(data.item, { leaf: data.leaf, active: data.active })
							: itemClass
						: 'grey--text',
					!data.leaf && !disabled ? rootClass : null,
					data.active ? activeClass : null,
				]"
			>
				<slot
					v-if="$slots['prepend-label'] || $scopedSlots['prepend-label']"
					name="prepend-label"
					v-bind="{...data}"
				></slot>
				{{data.item.name}}
			</div>
		</template>

		<template
			v-if="$slots['append'] || $scopedSlots['append']"
			v-slot:append="data"
		>
			<slot name="append" v-bind="{...data}"></slot>
		</template>
		<template
			v-if="$slots['prepend'] || $scopedSlots['prepend']"
			v-slot:prepend="data"
		>
			<slot name="prepend" v-bind="{...data}"></slot>
		</template>
	</v-treeview>
</template>

<script>
export default {
	name: 'BeeTreeview',
	props: {
		items: { type: Array, default: () => [] },
		active: { type: Array, default: () => [] },
		open: { type: Array, default: () => [] },
		itemOpen: { type: [String, Number] },
		itemOpenProp: { type: String, default: 'id' },
		itemClass: { type: [Function, String] },
		clickedItem: { type: Number },
		activeClass: { type: String },
		openOnClick: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		hideExpandIcon: { type: Boolean, default: false },
		rootClass: { type: String },
		loadChildren: { type: Function },
		expandIcon: { type: String, default: '$subgroup' },
	},

	data() {
		return {
			openItems: [],
			minTreeWidth: 0,
			treeWidth: 305,
		}
	},

	watch: {
		itemOpen(val) {
			this.$emit('update:item-open', val);
			this.openItemsFunction(val);
		},
		openItems(val) {
			this.$emit('update:open', val);
		}
	},

	methods: {
		findTreeWidth() {
			this.$nextTick(() => {
				const getWidth = (el) => {
					const offsetCount = 
						el.parentElement.parentElement.parentElement.querySelectorAll('.v-treeview-node__level').length;
					return el.clientWidth + (offsetCount + 2) * 24
				}

				if (this.$refs.tree) {
					let maxWidth = 0;

					const parentElement = this.$refs.tree.$el.parentElement;
					const parentWidth = parentElement.clientWidth;

					// find the most width tree element
					const nodes = this.$refs.tree.$el.querySelectorAll('.v-treeview-node__label div');
					nodes.forEach(el => {
						const itemWidth = getWidth(el);
						if (itemWidth > parentWidth) {
							maxWidth = itemWidth;
						}
					});
					// set Tree width
					this.treeWidth = maxWidth > parentWidth ? maxWidth : parentWidth;

					if (this.treeWidth > parentWidth) {
						parentElement.style.overflowX = 'auto';
						parentElement.scrollTo({ left: this.treeWidth * 2 })
					} else {
						this.treeWidth = null;
						parentElement.style.overflowX = 'hidden';
						parentElement.scrollTo({ left: 0 });
					}
				}
			})
		},

		openItemsFunction(val) {
			if (val) {
				const getPathByItemName = (items, path, searchedValue) => {
					items?.some(item => {
						if (item[this.itemOpenProp] === searchedValue) { 
							this.openItems = path;
							return true
						} else {
							getPathByItemName(item.children, [...path, item.id], searchedValue); 
							return false
						}
					});
				}

				getPathByItemName(this.items, [], val);
			}
		}
	},

	mounted() {
		this.findTreeWidth();
		/*
			const id = 133;
			let finalPath;
			const func = (items, path, id) => {
				for (let i = 0; i < items.length; i++) {
					const item = items[i];
					if (item.id === id) {
						finalPath = path;
						return;
					} else {
						if (item.children) {
							func(item.children, [...path, item.id], id)	
						}
					}	
				}
			}
			func(this.items, [], id)
			this.openItems = (finalPath);

			openItemsFunctionOld(val) {
				if (val) {
					const queue = [];
					const path = [];
					const visited = [];
					const items = [...this.items];
					items.forEach((c) => { queue.push(c[this.itemOpenProp]) });
					while (queue.length !== 0) {
						const topOfQueue = queue.shift();
						if (visited.includes(topOfQueue)) {
							path.pop();
							continue;
						}
						
						path.push(topOfQueue)
						const item = items.find(c => {
							return c[this.itemOpenProp] === topOfQueue;
						});
						visited.push(item[this.itemOpenProp])

						if (!item) break;
						if (item[this.itemOpenProp] === this.itemOpen) break;
						if (!item.children || (item.children && item.children.length === 0)) continue;

						item.children.forEach(child => {
							items.push(child)
						});
						queue.unshift(...item.children.map(c => c[this.itemOpenProp]))
					}
					this.openItems = queue;
				}
			},
		*/
	}
}
</script>

<style lang="scss">
#tree-view {
	&.bee-treeview--hide-expand-icon {
		.v-treeview-node__toggle {
			display: none;
		}
	}

	.v-treeview-node__content {
		cursor: pointer;
		align-self: stretch;

		.v-treeview-node__prepend {
			min-width: 0px;
		}
		
		.v-treeview-node__label {
			align-self: stretch;
			display: flex;
		}
	}
}
</style>