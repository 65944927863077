var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"tree-col rounded-lg bgColor",attrs:{"lg":"3","sm":"4","cols":"12"}},[_c('v-row',{staticClass:"flex-wrap align-center mt-0 mb-2"},[_c('v-col',{staticClass:"px-2 pt-0 pb-md-0 pb-1",attrs:{"md":"8","cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.accounts,"label":_vm.$t('account-id-or-name'),"item-value":"id","item-text":item => `${item.accountingNo} - ${item.name}`,"outlined":"","dense":"","hide-no-data":"","hide-details":"","auto-select-first":"","disabled":_vm.loading},on:{"change":_vm.selectedAccountIsUpdated},model:{value:(_vm.searchInTree),callback:function ($$v) {_vm.searchInTree=$$v},expression:"searchInTree"}})],1),_c('v-col',{staticClass:"px-2 pt-0 pb-md-0 pb-1",attrs:{"md":"4","cols":"12"}},[_c('v-btn',{staticClass:"px-2",attrs:{"to":_vm.getAccountById(_vm.activeNodes[0])?.type === _vm.type.main
								? { name: 'account', params: { id: _vm.activeNodes[0] }, query: { addSub: 1 } }
								: { name: 'account' },"exact":"","elevation":"1","color":"primary","disabled":_vm.loading || _vm.disableAddBtn || _vm.currentItemIsSub || _vm.permissionsForUser.userType === _vm.userType.dataReader}},[_c('v-icon',{staticClass:"me-1"},[_vm._v("mdi-account-plus")]),_vm._v(" "+_vm._s(_vm.$t('add'))+" ")],1)],1)],1),(_vm.loading)?_c('v-progress-linear',{staticClass:"rounded",attrs:{"indeterminate":""}}):_vm._e(),_c('bee-treeview',{attrs:{"items":_vm.accountsTree,"itemOpen":_vm.itemOpen,"active":_vm.activeNodes,"open":_vm.openNodes,"hide-expand-icon":"","active-class":"deep-orange--text text--darken-1","disabled":_vm.disableAddBtn,"itemClass":(item) =>
					item.type === _vm.type.main
						? 'font-weight-medium primary--text'
						: ''},on:{"update:itemOpen":function($event){_vm.itemOpen=$event},"update:item-open":function($event){_vm.itemOpen=$event},"update:active":function($event){_vm.activeNodes=$event},"update:open":function($event){_vm.openNodes=$event},"update:clicked-item":function($event){_vm.previewItem($event.id); _vm.openNodes.push($event.id)}},scopedSlots:_vm._u([{key:"prepend-label",fn:function({ item }){return [_vm._v(" "+_vm._s(item.accountingNo + '-')+" ")]}},{key:"prepend",fn:function({ item, open, leaf }){return [(!leaf)?_c('v-icon',{staticClass:"me-1",on:{"click":function($event){open && !_vm.disableAddBtn
							? _vm.openNodes.splice(_vm.openNodes.indexOf(item.id), 1)
							: _vm.openNodes.push(item.id)}}},[_vm._v(" "+_vm._s(open ? 'mdi-minus' : 'mdi-plus')+" ")]):_vm._e()]}}])})],1),_c('v-col',[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$route.name === 'accounts-tree')?_c('div',{staticClass:"d-flex flex-column justify-center align-center",staticStyle:{"height":"85vh"}},[_c('div',[_c('v-img',{attrs:{"src":require("@/assets/empty-space-imgs/no-data.png"),"contain":"","width":"350","height":"250","transition":"scale-transition","lazy-src":require("@/assets/placeholder-image.png")}}),_c('p',{staticClass:"primary--text text-h6 text-center mb-0"},[_vm._v(" "+_vm._s(_vm.$t('Account.select-account'))+" ")])],1)]):_c('router-view',{on:{"update-tree-accounts":function($event){return _vm.updateTreeAccounts(true)},"update-add-state":(isActive) => _vm.disableAddBtn = !isActive}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }