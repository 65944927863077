<template>
	<div class="bgColor rounded-t-xl" style="height: 90vh">
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="itemJournals"
			:ids="$route.params.id"
			@on-delete="afterDelete"
		>
			{{$t('do-you-want-to')}} 
			{{$t('JournalItem.delete-journal')}} 
			؟
		</bee-delete-dialog>
		<v-row align="center" class="px-3">
			<!-- no -->
			<v-col cols="3" sm="3" md="2" class="">
				<items-navigator
					v-model="no"
					:label="$t('invoice-no')"
					:disabled="loading"
					:items="journals"
				></items-navigator>
			</v-col>

			<!-- speacer -->
			<v-col v-if="$vuetify.breakpoint.mdAndUp" cols="3" sm="2">
				<v-spacer />
			</v-col>

			<!-- add btn -->
			<v-col class="d-flex justify-end justify-end px-0">
				<v-btn
					@click="addNewJournal()"
					color="primary"
					:disabled="loading || permissionsForUser.userType === userType.dataReader"
				>
					<v-icon class="me-1">mdi-file-document-plus-outline</v-icon>
					{{$t('JournalItem.new-journal')}}
				</v-btn>
			</v-col>

			<v-col cols="auto" class="d-flex justify-end justify-end px-1">
				<v-btn
					v-if="itemJournals.isPosted && itemJournals.invoiceId"
					:to="{
						name: this.getInvoiceById(itemJournals.invoiceId)?.type === 2 ? 'sale-invoice' : 'pruchase-invoice',
						params: {
							id: itemJournals.invoiceId
						}
					}"
					color="blue-dark white--text"
					:disabled="loading || permissionsForUser.userType === userType.dataReader"
				>
					<v-icon class="me-1">mdi-file-document-outline</v-icon>
					{{$t('JournalItem.view-the-associated-invoice')}}
				</v-btn>
			</v-col>

			<!-- save btn -->
			<v-col cols="auto" class="d-flex justify-end">
				<v-btn
					@click="itemJournals.isPosted ? editOrUnSave() : saveJournal()"
					:color="$route.query.preview ? 'edit-color white--text' : 'blue-dark white--text'"
					:disabled="loading || (itemJournals.invoiceId !== null) || permissionsForUser.userType === userType.dataReader"
				>
					<v-icon class="me-1">mdi-content-save</v-icon>
					{{ $route.query.preview  ? $t('edit')  : $t('save')}}
				</v-btn>
			</v-col>

			<v-divider vertical class="my-2" />

			<!-- delete btn -->
			<v-col cols="auto" class="d-flex justify-end">
				<v-btn
					@click="deleteDialog = true"
					color="alert-color white--text"
					:disabled="loading || Boolean(itemJournals.invoiceId) || Boolean($route.query.preview) || permissionsForUser.userType === userType.dataReader"
				>
					<v-icon class="me-1">mdi-delete</v-icon>
					{{$t('delete')}}
				</v-btn>
				
			</v-col>
		</v-row>
		<!-- table -->
        <bee-handy-smart-table
			v-model="tableInputs"
			:headers="headers"
			:items="entries"
			ref="table"
			hide-default-footer
			dense
			class="my-2 px-2 mx-2"
			@keypress.enter="!isEditItem ? addItem() : editItem()"
			:show-add-row="(itemJournals.invoiceId === null && !Boolean($route.query.preview) && permissionsForUser.userType !== userType.dataReader) || 
			!Boolean($route.query.preview) && permissionsForUser.userType !== userType.dataReader && itemJournals.invoiceId === null"
			:loading="loading"
			hide-inputs-details
			:data-table-props="{
				singleExpand: singleExpand,
				expandedSync: expanded,
				showExpand: itemJournals.invoiceId !== null
			}"
			hot-inputs
			:items-per-page="-1"
		>
			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" class="tab">
					<bee-handy-table
						v-if="item.implicitEntries?.length !== 0" 
						:headers="head"
						:items="!loading && itemJournals.entries !== null ? item.implicitEntries : []"
						hide-default-footer
						class="tab"
						table-class="green lighten-5 rounded-0 elevation-0"
					>
						<template v-slot:input.itemId="{ on, attr }">
							<v-autocomplete
								:items="items?.filter(c => c.type === type.sub)"
								item-value="id"
								:item-text="(item) => `${item.no} - ${item.name}`"
								auto-select-first
								v-on="on"
								v-bind="attr"
							></v-autocomplete>
						</template>
					</bee-handy-table>

					<p class="text-center" v-else>
						{{ $t('JournalItem.there-are-no-details') }}
					</p>
				</td>
			</template>

			<!-- itemId -->
			<template v-slot:input.itemId="{ on, attr }">
				<v-autocomplete
					:items="items?.filter(c => c.type === type.sub)"
					item-value="id"
					:item-text="(item) => `${item.no} - ${item.name}`"
					auto-select-first
					v-on="on"
					v-bind="attr"
				></v-autocomplete>
			</template>

			<!-- account -->
			<template v-slot:input.accountId="{ on, attr }">
				<v-autocomplete
					:items="accounts?.filter(c => c.type === type.sub)"
					item-value="id"
					item-text="name"
					auto-select-first
					v-on="on"
					v-bind="attr"
				></v-autocomplete>
			</template>

			<!-- itemId -->
			<template v-slot:input.date="{ on, attr }">
				<!-- date -->
				<bee-date-input
					label=" "
					dense
					v-on="on"
					v-bind="attr"
					dir="ltr"
				/>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ index, item }">
				<!-- edit -->
				<v-btn
					outlined
					width="25"
					min-width="30"
					height="25"
					color="edit-color"
					class="me-2 pa-0"
					:disabled="loading || Boolean($route.query.preview) || permissionsForUser.userType === userType.dataReader"
					@click="$refs.table.activeEditForRow(index); setRowIndex({ index }); isEditItem = true; rowId = item.id"
				>
					<v-icon size="18">mdi-pencil</v-icon>
				</v-btn>

				<!-- delete -->
				<v-btn
					width="25"
					outlined
					min-width="30"
					height="25"
					color="alert-color"
					class="pa-0"
					:disabled="loading || Boolean($route.query.preview) || permissionsForUser.userType === userType.dataReader"
					@click="deleteId = item.id; deleteItem()"
				>
					<v-icon size="18">mdi-delete</v-icon>
				</v-btn>
			</template>
		</bee-handy-smart-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { type, userType } from '@/helpers/enums'
import ItemsNavigator from '@/components/ItemsNavigator.vue';
import rules from '@/helpers/validation rules'
import moment from 'moment';

export default {
	name: 'JournalItem',

	data() {
		return {
			tableInputs: {
				date: this.moment().format('YYYY-MM-DD')
			},
			type,
			loading: false,
			// itemId: null,
			entries: [],
			expanded: [],
			singleExpand: false,
			no: null,
			isAdd: false,
			isEditItem: false,
			rowId: null,
			deleteId: null,
			deleteDialog: false,
			rules,
			itemJournals: [],
			userType
		}
	},
	components: {
		ItemsNavigator
	},
	watch: {
		'$route.params.id'(val) {
			if (val !== null) {
				this.fetchById(this.$route.params.id)
				// } else {
				this.$refs.table.resetInputs()
				this.tableInputs.date = this.moment().format('YYYY-MM-DD')
				this.isAdd = true
				// data.entries !== null ? data.entries : []
			}
		}
	},
	computed: {
		...mapState({
			items: state => state.items.items,
			journals: state => state.itemJournals.journals,
			accounts: state => state.accounts.accounts,
			invoices: state => state.invoices.invoices,
			permissionsForUser: state => state.auth.permissionsForUser
		}),

		...mapGetters({
			getJournalById: 'itemJournals/getJournalById',
			getItemById: 'items/getItemById',
			getInvoiceById: 'invoices/getInvoiceById'
		}),

		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'type',
					value: 'type',
					setValueExpr: (val, index) => index + 1,
					align: 'start',
					noInput: true
				},
				{
					text: this.$t('headers.entered-quantity'),
					name: 'incomingQuantity',
					value: 'incomingQuantity',
					setValueExpr: val => (val === null) ? null : val.quantity,
					setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'outcomingQuantity' && inputs.outcomingQuantity) {
							return null
						}
						return inputs.incomingQuantity
					},
					inputRules: this.tableInputs.outcomingQuantity === null && this.tableInputs.incomingQuantity === null ? rules.required : [],
					type: 'number',
					align: 'start'
				},
				{
					text: this.$t('headers.outgoing-quantity'),
					name: 'outcomingQuantity',
					value: 'outcomingQuantity',
					setValueExpr: val => (val === null) ? null : val.quantity,
					setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'incomingQuantity' && inputs.incomingQuantity) {
							return null
						}
						return inputs.outcomingQuantity
					},
					inputRules: this.tableInputs.outcomingQuantity === null && this.tableInputs.incomingQuantity === null ? rules.required : [],
					type: 'number',
					align: 'start'
				},
				{
					text: this.$t('headers.item'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.items.find(c => c.id === val)?.name ?? val,
					width: 180,
					disableInput: this.isEditItem,					
					noInput: false,
					align: 'start',
					inputRules: this.itemJournals.invoiceId === null ? rules.required : []
				},
				{
					text: this.$t('headers.the-number'),
					name: 'count',
					value: 'count',
					noInput: false,
					align: 'start'
				},
				{
					text: this.$t('headers.price'),
					name: 'price',
					value: 'price.amount',
					noInput: false,
					align: 'start',
					type: 'number'
				}, 
				{
					text: this.$t('headers.explanation'),
					name: 'notes',
					value: 'notes',
					noInput: false,
					width: 160,
					align: 'start'
				},
				{
					text: this.$t('headers.account'),
					name: 'accountId',
					value: 'accountId',
					setValueExpr: val => this.accounts.find(c => c.id === val)?.name ?? val,
					width: 140,
					align: 'start'
				},
				// {
				// 	text: this.$t('headers.the-document'),
				// 	name: 'the-document',
				// 	value: 'theDocument',
				// 	noInput: false,
				// },
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					setValueExpr: val => this.moment(val).format('YYYY-MM-DD'),
					noInput: false,
					width: 190,
					align: 'start'
				},
			]
			if (this.itemJournals.invoiceId === null) {
				headers.push({
					text: '',
					name: 'actions',
					value: 'actions',
					noInput: true,
					width: '100px',
					align: 'start'
				})
			}
			return headers
		},

		head() {
			const head = 
				[
					{
						text: this.$t('headers.entered-quantity'),
						name: 'incomingQuantity',
						value: 'incomingQuantity',
						setValueExpr: val => val !== null ? val.quantity : '-',
						noInput: false,
						columnClass: 'text-h6',
						cellClass: 'text-body-2',
						align: 'start'
					},
					{
						text: this.$t('headers.outgoing-quantity'),
						name: 'outgoingQuantity',
						value: 'outcomingQuantity',
						setValueExpr: val => val !== null ? val.quantity : '-',
						noInput: false,
						columnClass: 'text-h6',
						cellClass: 'text-body-2',
						align: 'start'
					},
					{
						text: this.$t('headers.item'),
						name: 'itemId',
						value: 'itemId',
						setValueExpr: val => this.items.find(c => c.id === val)?.name ?? val,
						width: 140,
						disableInput: this.isEditItem,					
						noInput: false,
						columnClass: 'text-h6',
						cellClass: 'text-body-2',
						align: 'start'
					},
					{
						text: this.$t('headers.the-number'),
						name: 'count',
						value: 'count',
						noInput: false,
						columnClass: 'text-h6',
						cellClass: 'text-body-2',
						align: 'start'
					},
					{
						text: this.$t('headers.price'),
						name: 'price',
						value: 'price',
						setValueExpr: val => val.amount,
						noInput: false,
						columnClass: 'text-h6',
						cellClass: 'text-body-2',
						align: 'start'
					}
				]
			return head
		}
	},

	methods: {
		moment,
		addNewJournal() {
			this.$store.dispatch('itemJournals/create', { 
				date: this.moment().format('YYYY-MM-DD')
			}).then((data) => {
				this.$router.replace({
					params: {
						id: data.data
					}
				})
				this.isAdd = true
			})
		},

		addItem () {
			if (this.$refs.table.validate()) {
				this.$store.dispatch('itemJournals/createEntries', {
					quantity: {
						quantity: this.tableInputs.incomingQuantity !== null ? Number(this.tableInputs.incomingQuantity) : Number(this.tableInputs.outcomingQuantity),
						unitSizeId: this.getItemById(this.tableInputs.itemId)?.unitId
					},
					journalId: this.$route.params.id,
					id: this.$route.params.id,
					accountId: this.tableInputs.accountId,
					itemId: this.tableInputs.itemId,
					count: Number(this.tableInputs.count),
					price: {
						amount:	Number(this.tableInputs.price),
						CurrencyId: this.$localStorage.currencyId
					},
					type: this.tableInputs.incomingQuantity !== null ? 1 : 2,
					notes: this.tableInputs.notes,
					date: this.tableInputs.date
				}).then(() => {
					this.$refs.table.resetInputs()
					this.tableInputs.date = this.moment().format('YYYY-MM-DD')
					this.$refs.table.resetInputsFocus()
					this.fetchById(this.$route.params.id)
				})
			}
		},

		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus()
			})
		},

		editItem () {
			this.loading = true
			this.$store.dispatch('itemJournals/updateEntries', { 
				journalId: this.$route.params.id, 
				id: this.rowId,
				quantity: {
					quantity: this.tableInputs.enteredQuantity !== null ? Number(this.tableInputs.enteredQuantity) : Number(this.tableInputs.outgoingQuantity),
					unitSizeId: this.getItemById(this.tableInputs.itemId)?.unitId
				},

				accountId: this.tableInputs.accountId,
				itemId: this.tableInputs.itemId,
				count: Number(this.tableInputs.count),
				price: {
					amount:	Number(this.tableInputs.price),
					CurrencyId: this.$localStorage.currencyId
				},
				type: this.tableInputs.enteredQuantity !== null ? 1 : 2,
				notes: this.tableInputs.notes,
				date: this.tableInputs.date

			}).then(() => {
				this.$refs.table.resetEdit()
				// this.isEditItem = false;
				this.fetchById(this.$route.params.id)
				this.resetTableInputs();
				this.$refs.table.resetInputsFocus()
			}).finally(() => {
				this.isEditItem = false
				this.loading = false
				this.$refs.table.resetEdit()
			})
			// } 
		},

		deleteItem() {
			this.loading = true
			this.$store.dispatch('itemJournals/deleteEntries', { 
				journalId: this.$route.params.id, 
				id: this.deleteId 
			}).then(() => {
				this.fetchById(this.$route.params.id)
				this.$refs.table.resetEdit()
				this.resetTableInputs();
				this.$refs.table.resetInputsFocus()
			}).finally(() => {
				this.loading = false
			})
		},

		afterDelete() {
			this.$store.dispatch('itemJournals/fetchAll', {}).then((data) => {
				this.$router.replace({
					params: {
						id: data[data.length - 1].id
					}
				})
			})
		},

		fetchById(id) {
			this.loading = true
			return this.$store.dispatch('itemJournals/fetchById', { id: id }).then((data) => {
				this.itemJournals = data
				if (data.isPosted) {
					this.$router.replace({
						params: {
							id: id
						},
						query: {
							preview: true
						}
					})
				}
				if (data.invoiceId !== null) {
					this.entries = data.entries !== null ? data.entries : []
					this.no = data.no
				} else {
					this.no = data.no
					this.isAdd = true
					this.entries = data.entries ? data.entries : []
				}

				if (data.invoiceId !== null) {
					this.entries = data.entries ? data.entries : []
				} else {
					this.isAdd = true
					this.entries = data?.entries === null ? [] : data.entries
				}
			}).finally(() => {
				this.loading = false
			})
		},

		saveJournal() {
			this.loading = true
			this.$refs.table.resetInputs()
			this.$store.dispatch('itemJournals/save', { 
				id: this.$route.params.id 
			}).then(() => {
				this.$router.replace({
					params: {
						id: this.$route.params.id
					},
					query: {
						preview: true
					}
				})
				this.fetchById(this.$route.params.id)
			}).finally(() => {
				this.loading = false
			})
		},

		editOrUnSave() {
			this.loading = true
			this.$store.dispatch('itemJournals/unSave', { id: this.$route.params.id }).then(() => {
				this.$router.replace({
					params: { id: this.$route.params.id },
					query: {}
				})
				this.fetchById(this.$route.params.id)
			}).finally(() => {
				this.loading = false
			})
		},

		resetTableInputs() {
			this.$refs.table.resetInputs({
				no: null,
				enteredQuantity: '1',
				outgoingQuantity: '1',
				itemId: null,
				price: null,
				explanation: null,
				theNumber: null,
				theDocument: null,
				date: null
			})
		},
	},

	created () {
		this.loading = true
		Promise.all([
			!this.accounts.length ? this.$store.dispatch('accounts/fetchAll') : null,
			!this.invoices.length ? this.$store.dispatch('invoices/fetchAll', {}) : null,
			this.$store.dispatch('itemJournals/fetchAll', {}).then((data) => {
				this.$store.dispatch('itemJournals/fetchById', { 
					id: this.$route.params.id ? this.$route.params.id : data[data.length - 1]?.id 
				}).then((data) => {
					this.itemJournals = data
					this.no = data.no
					if (!this.$route.params.id) {
						this.$router.push({
							name: 'journal-item',
							params: {
								id: data.id
							}
						})
					}
					if (this.itemJournals.invoiceId !== null) {
						this.entries = data.entries ? data.entries : []
					} else {
						this.isAdd = true
						this.entries = data?.entries === null ? [] : data.entries
					}
					console.log(data.entries)
				}).finally(() => {
					this.loading = false
				})
			})
		])
		this.tableInputs.date = this.moment().format('YYYY-MM-DD')
	},

	mounted() {
		this.tableInputs.date = this.moment().format('YYYY-MM-DD')
	},
	metaInfo() {
		return {
			title: this.$t('routes.journal-item'),
			titleTemplate: '%s | POS'	
		}
	}
}
</script>

<style scoped>
</style>
