import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import { $localStorage } from '@/plugins/pos'
import { userType } from '@/helpers/enums'
import auth from '@/store/modules/auth'

Vue.use(VueRouter)

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router

router.beforeEach((to, from, next) => {
	if (to.name === 'login' && auth.state.authenticated) {
		next({ name: 'choose-store', replace: true })
	} else if (to.name !== 'login' && !auth.state.authenticated) {
		next({ name: 'login', replace: true })
	} else {
		if (to.name === 'login') next();
		else if (to.name === 'home' && $localStorage.storeId) next()
		/* in store context */
		else if ($localStorage.storeId) {
			/* if user is manger or admin */
			if (auth.state.permissionsForUser.userType !== userType.casher) {
				if (!auth.state.self.isAdmin && ['stores', 'users'].includes(to.name)) {
					next({ name: 'home', replace: true });
				} else {
					next();
				}
			} 
			else {
				/* if user is casher */
				if (!['sale-invoice', 'sales-invoice-returned', 'cashbox-journal'].includes(to.name)) {
					next({ name: 'home', replace: true });
				} else {
					next();
				}
			}
		} else {
			/* out of store context */
			if (auth.state.authenticated) {
				if (!['choose-store', 'stores', 'store-info', 'users'].includes(to.name)) {
					next({ name: 'choose-store', replace: true });
				} else {
					next();
				}
			}
		}
	}
})
