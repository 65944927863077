import * as types from '../mutation-types'
export default {
	namespaced: true,

	state: {
		// itemJournals: [
		// 	{
		// 		entries: [
		// 			{
		// 				quantity: {
		// 					quantity: null
		// 				},
		// 				implicitEntries: []
		// 			}
		// 		]
		// 	}
		// ],
		journals: []
	},

	actions: {
		fetchAll ({ commit }, { fromDate, toDate, status, dateSortMode, journalId, JournalNo, context }) {
			return window.axios.get('/ItemJournals', { 
				params: {
					fromDate,
					toDate,
					status,
					dateSortMode,
					journalId,
					JournalNo,
					context,
				}
			}).then(({ data }) => {
				commit(types.STORE_ALL_JOURNALS, data)
				return data
			})
		},

		fetchById ({ commit }, { id }) {
			return window.axios.get(`/ItemJournals/${id}`).then(({ data }) => {
				// commit(types.STORE_ITEM_JOURNALS, data)
				return data
			})
		},

		create({ commit }, { date }) {
			return window.axios.post('/ItemJournals', null, { params: { date } }).then((data) => {
				return data
			})
		},

		delete({ commit }, { id }) {
			return window.axios.delete(`/ItemJournals/${id}`)
		},

		createEntries({ commit }, { journalId, id, itemId, accountId, quantity, count, price, notes, type, date, refDoc }) {
			return window.axios.post(`/ItemJournals/${journalId}/Entries`, { journalId, id, itemId, accountId, quantity, count, price, notes, type, date, refDoc }).then(({ data }) => {
				return data
			})
		},

		updateEntries({ commit }, { journalId, id, itemId, accountId, quantity, count, price, notes, type, date, refDoc }) {
			return window.axios.put(`itemJournals/${journalId}/Entries/${id}`, { itemId, accountId, quantity, count, price, notes, type, date, refDoc }).then(({ data }) => {
				return data
			})
		},

		deleteEntries({ commit }, { journalId, id }) {
			return window.axios.delete(`/ItemJournals/${journalId}/Entries/${id}`).then((data) => {
				return data
			})
		},

		save({ commit }, { id }) {
			return window.axios.put(`/ItemJournals/${id}/Post`).then(({ data }) => {
				return data
			})
		},

		unSave({ commit }, { id }) {
			return window.axios.put(`/ItemJournals/${id}/UnPost`).then(({ data }) => {
				return data
			})
		}
	},

	mutations: {
		// [types.STORE_ITEM_JOURNALS] (state, itemJournals) {
		// 	state.itemJournals = itemJournals
		// },

		[types.STORE_ALL_JOURNALS] (state, journals) {
			state.journals = journals
		}
	},

	getters: {
		getJournalById: state => id => state.journals.find(c => c.id === id),
	}
}