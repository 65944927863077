export default {
	'add-journal-cashbox': 'Add journal cashbox',
	'adding-journal-cashbox': 'Adding journal cashbox',
	'delete-journal': 'delete journal',
	restriction: 'Restriction',
	'total-received': 'Total received',
	'paid-total': 'Paid total',
	'new-journal': 'New journal',
	'the-difference': 'The difference',
	'the-journal-has-been-migrated-successfully': 'The journal has been migrated successfully',
	'journal-saved-successfully': 'Journal saved successfully',
	journal: 'Journal',
	'alert-message-for-date-matching': 'Warning: The journal date does not match today is date'
}