var render = function render(){var _vm=this,_c=_vm._self._c;return _c('bee-label',{attrs:{"color":_vm.value
			? _vm.value.money_type >= 0 && _vm.withColor
				? _vm.perspective && _vm.perspective == 'statement'
					? _vm.value.money_type == '1'
						? 'green'
						: 'red'
					: _vm.localValue.money_type == '1'
					? 'red'
					: 'green'
				: 'black'
			: 'black',"bold":_vm.bold,"content":_vm.formatedValue,"empty-state":_vm.emptyState}})
}
var staticRenderFns = []

export { render, staticRenderFns }