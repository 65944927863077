export default {
	info: 'Info',
	'select-store': 'Select store',
	menu: {
		logout: 'logout'
	},
	pages: {
		stores: 'Stores',
		'store-info': 'Store information',
		'store-cashboxes': 'Store cashboxes',
		units: 'Units',
		users: 'Users',
	}
}