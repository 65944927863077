export default {
	'select-item': 'اختر مادة رجاءً',

	'the-item': 'المادة؟',
	
	'main-info': 'المعلومات الرئيسية',
	'item-name': 'اسم المادة',
	'main-item': 'المادة الرئيسية',
	'item-no-automatic-or-manual': 'رقم المادة (آلي أو يدوي)',

	'item-type': 'نوع المادة',

	'item-classification': 'التصنيف',
	unit: 'الواحدة',

	'trading-info': 'معلومات المتاجرة',
	'selling-price': 'سعر المبيع',
	min: 'الحد الأدنى',
	max: 'الحد الأعلى',
	manufacturer: 'المصنّع',
	notes: 'ملاحظات',
	'favorite-item': 'مادة مفضلة',
	unsaleable: 'غير قابلة للبيع',
	'the-cost-of-the-input-items': 'كلفة المواد المدخلة:',
	ingredients: 'المكونات',
	'the-difference': 'الربح',
	'for-one-of-$-from-$': 'من أجل 1 {unit} من {item}',
	'the-component-has-been-modified-successfully': 'تم تعديل المكون بنجاح',
	close: 'إغلاق',
	'mixture-ingredient': ' مكونات الخلطة',
	'add-ingredient-after-save-item': 'يرجى إضافة مكونات بعد حفظ المادة',
	'this-item-has-been-added-before-you-can-edit-the-item-instead': 'هذه المادة مضافة من قبل .. يمكنك تعديل المادة بدلاً من ذلك',
	'purchasing-price': 'آخر سعر شراء'
}