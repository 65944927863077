export default {
	'select-account': 'اختر حساب رجاءً',

	'the-account': 'الحساب؟',

	'main-info': 'المعلومات الرئيسية',
	'account-name': 'اسم الحساب',
	'main-account': 'الحساب الرئيسي',
	'account-no-automatic-or-manual': 'رقم الحساب (آلي أو يدوي)',

	'account-type': 'نوع الحساب',

	'final-account': 'الحساب الختامي',
	'is-hidden': 'إخفاء الحساب',
	notes: 'ملاحظات',
	'info-account': {
		'phone-number': 'رقم الهاتف',
		'tax-number': 'الرقم الضريبي',
		address: 'العنوان',
		email: 'البريد الإلكتروني',
		'mobile-number': 'رقم الموبايل'
	},
	tabs: {
		info: 'المعلومات',
		'custom-prices': 'الأسعار المخصصة'
	}
}