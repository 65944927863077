export default {
	'select-account': 'Select account please',

	'the-account': 'the account?',

	'main-info': 'Main Info.',
	'account-name': 'Account name',
	'main-account': 'Main account',
	'account-no-automatic-or-manual': 'Account no (auto or manual)',

	'account-type': 'Account type',

	'final-account': 'Final account',
	'is-hidden': 'Hide account',
	notes: 'Notes',

	'info-account': {
		'phone-number': 'Phone number',
		'tax-number': 'Tax number',
		address: 'Address',
		email: 'Email',
		'mobile-number': 'Mobile number'
	},

	tabs: {
		info: 'Information',
		'custom-prices': 'Custom prices'
	}
}