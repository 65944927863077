export const STORE_CONFIGS = 'STORE_CONFIGS';

export const STORE_CONFIG_BYID = 'STORE_CONFIG_BYID';

export const STORE_UNITS = 'STORE_UNITS';

export const STORE_STORES = 'STORE_STORES';

export const STORE_CASHBOXES = 'STORE_CASHBOXES';

export const STORE_ACCOUNTS = 'STORE_ACCOUNTS';
export const STORE_ACCOUNTS_TREE = 'STORE_ACCOUNTS_TREE';

export const STORE_ITEMS = 'STORE_ITEMS';
export const STORE_ITEMS_TREE = 'STORE_ITEMS_TREE';
export const STORE_ONE_LEVEL_ITEMS_TREE = 'STORE_ONE_LEVEL_ITEMS_TREE';
export const STORE_SALEABLE_ITEMS_TREE = 'STORE_SALEABLE_ITEMS_TREE';
export const STORE_SELECTED_ITEM = 'STORE_SELECTED_ITEM';

export const STORE_INVOICES = 'STORE_INVOICES';

export const STORE_ITEMS_INVENTORY = 'STORE ITEMS INVENTORY';

export const STORE_JOURNALS = 'STORE_JOURNALS';

export const STORE_CASHBOX_JOURNAL = 'STORE_CASHBOX_JOURNAL';

export const STORE_ALL_RESTRICTIONS = 'STORE_ALL_RESTRICTIONS';

export const STORE_JOURNAL = 'STORE_JOURNAL'

// users part 
export const STORE_USERS = 'STORE_USERS'

export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'

export const STORE_PERMISSIONS_FOR_USER = 'STORE_PERMISSIONS_FOR_USER'

export const CASHBOX_SYSTEM = 'CASHBOX_SYSTEM'

export const STORE_PERMISSIONS_FOR_STORES = 'STORE_PERMISSIONS_FOR_STORES'

export const RESET = 'RESET'

export const STORE_NOTIFICATIONS = 'STORE_NOTIFICATIONS'

export const STORE_ACCOUNT_STATEMENT_SUMMARY = 'STORE_ACCOUNT_STATEMENT_SUMMARY'

export const STORE_CURRENCIES = 'STORE_CURRENCIES'

export const STORE_DAY_SUMMARY = 'STORE_DAY_SUMMARY'

export const STORE_ACCOUNT_STATEMENT = 'STORE_ACCOUNT_STATEMENT'

export const STORE_ITEM_JOURNALS = 'STORE_ITEM_JOURNALS'

export const STORE_ALL_JOURNALS = 'STORE_ALL_JOURNALS'

export const STORE_STATICS = 'STORE_STATICS'