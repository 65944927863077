<template>
    <div class="rounded-t-xl">
		<!-- tabs -->
		<v-tabs
			v-model="tab"
			grow
			height="36"
			background-color="bgColor"
			active-class="primary white--text"
			slider-color="success"
			class="rounded-lg mb-2"
			style="overflow: hidden"
			show-arrows
			@change="$router.replace({ query: { journalType: tab } })"
			
		>
			<v-tab
				v-for="(tab) in tabs"
				:key="tab.value"
				:disabled="loading"
				@click="
					journalType !== tab.value
						? (() => { journalType = tab.value; fetchData(journalType) })()
						: null
				"
			>
				<!-- :class="{ 'primary': tab === tabs[index]}" -->
				<!-- :class="{ 'primary white--text': tab }" -->
				{{tab.name}}
			</v-tab> 
			<!--  fetchData()  -->
		</v-tabs>
		<v-form @submit.prevent="fetchData">
			<v-row align="center" class="mx-2">
				<v-col class="d-flex" cols="6" lg="2" md="3" sm="3">
					<v-autocomplete 
						v-model="status"
						:items="statuses"
						item-text="name"
						item-value="id"
						outlined
						dense
						:label="$t('forms.status')"
						hide-details
						:disabled="loading"
					/>
				</v-col>

				<v-col class="d-flex" cols="6" lg="2" md="3" sm="3">
					<v-autocomplete 
						v-model="cashbox"
						:items="cashboxes"
						item-text="name"
						item-value="id"
						outlined
						dense
						:label="$t('forms.cashbox')"
						hide-details
						:disabled="loading"
					/>
				</v-col>
				<!-- from date  -->
				<v-col cols="6" lg="2" md="3" sm="4" class="py-md-0">
					<bee-date-input
						v-model="fromDate"
						:label="$t('forms.from-date')"
						input-format-order="DD-MM-YYYY"
						prepend-icon="mdi-calendar"
						dense
						hide-details="auto"
						:disabled="loading"
					></bee-date-input>
				</v-col>
				<!-- toDate -->
				<v-col cols="6" lg="2" md="3" sm="3" class="py-md-0">
					<bee-date-input
						v-model="toDate"
						:label="$t('forms.to-date')"
						input-format-order="DD-MM-YYYY"
						prepend-icon="mdi-calendar"
						dense
						hide-details="auto"
						:disabled="loading"
					></bee-date-input>
				</v-col>
				<!-- search btn -->
				<v-col cols="auto" class="px-0 ">
					<v-btn 
						class="mx-2"
						fab
						x-small
						color="of-white"
						:disabled="loading"
						type="submit"	
						@click="journalType = $route.query.type; fetchData(journalType); $router.replace({
							query: {
								journalType: tab,
								status: status,
								cashbox: cashbox,
								fromDate: fromDate,
								toDate: toDate
							}
						})"
					>
						<v-icon color="blue-dark">mdi-magnify</v-icon>
					</v-btn>
				</v-col>

				<!-- close btn -->
				<v-col cols="auto" class="px-0 ">
					<v-btn 
						class="mx-2"
						fab
						x-small
						color="of-white"
						:disabled="loading"
						@click="reset"
					>
						<v-icon color="alert-color">mdi-close</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- table -->
		<v-row>
			<v-col class="py-0 ">
				<bee-handy-table 
					:headers="headers"
					:items="journals"
					:height="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.height - 260 : $vuetify.breakpoint.height - (45 + 88 + 55 + 130) "
					zebra
					fixed-header
					class="mx-3 mt-2"
					:loading="loading"
					pagination-on-scroll
					:shown-rows-text="$t('shown-rows')"
				>
					<!-- statues -->
					<template v-slot:item.status="{ item }">
						<v-chip :color="item.isPosted ? 'success' :' alert-color'" class="white--text" small>
							{{ item.isPosted ? $t('Journals.posted') : $t('Journals.non-posted')}}
						</v-chip>
					</template>
					<!-- actions -->
					<template v-slot:item.actions="{ item }">
						<div class="d-flex">
							<!-- priview -->
							<tooltip :left="false" :top="true" :text="$t('preview')" >
								<v-btn
									@click="journalId = item.id"
									color="edit-color"
									small
									text
									:to="{
										name: 'cashbox-journal',
										params: {
											id: item.id
										}
									}"
								>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</tooltip>

							<!-- delete -->
							<tooltip :left="false" :top="true" :text="$t('delete')" >
								<v-btn
									@click="journalId = item.id; deleteDialog = true"
									color="alert-color"
									small
									text
									:disabled="item.isPosted || permissionsForUser.userType === userType.dataReader"
								>
									<v-icon>mdi-delete</v-icon>
									
								</v-btn>
							</tooltip>

							<template v-if="notifications.map(c => c.contextId).includes(item.id)">
								<tooltip
									:left="false"
									:top="true"
									v-if="!notifications.find(c => c.contextId === item.id).isSeen"
								>
									<template v-slot:text>
										<span
											class="text-body-2"
											v-html="notifications.find(c => c.contextId === item.id).content">
										</span>
									</template>
									<v-icon color="red">
										mdi-alert-circle-outline
									</v-icon>		
								</tooltip>
								<v-icon v-else color="primary"> mdi-check-circle-outline </v-icon>
							</template>
						</div>
					</template>
				</bee-handy-table>
			</v-col>
		</v-row>
		<!-- footer -->
		<v-footer app inset color="white" class="py-4 elevation-0 ">
			<v-row>
				<v-col>
					{{ $t('Journals.the-total-number') + ' ' + Number(totalJournals)}}
				</v-col>
				<v-col>
					{{ $t('Journals.non-posted-bonds-count') + '  ' + Number(journalsNotPosted)}}
				</v-col>
			</v-row>
		</v-footer >

		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="cashJournals"
			:ids="journalId"
			@on-delete="fetchData"
		>
			{{$t('do-you-want-to')}} 
			{{$t('CashboxJournal.delete-journal')}}
			؟
		</bee-delete-dialog>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { postingState, dateSortMode, context, userType } from '@/helpers/enums'
import moment from 'moment'
// define journalType in enum when linked
export default {
	name: 'Journals',

	data() {
		return {
			status: null,
			cashbox: null,
			toDate: null,
			fromDate: null,
			itemId: null,
			tab: 0,
			loading: false,
			journalId: null,
			journalType: null,
			deleteDialog: false,
			user: {
				initials: 'JD',
				fullName: 'John Doe',
				email: 'john.doe@doe.com',
			},
			context,
			userType
		}
	},
	computed: {
		...mapState({
			cashboxes: state => state.cashboxes.cashboxes,
			journals: state => state.cashJournals.journals,
			notifications: state => state.users.notifications,
			permissionsForUser: state => state.auth.permissionsForUser
		}),
		...mapGetters({
			getCashboxById: 'cashboxes/getCashboxById'
		}),
		headers() {
			const header = [
				{
					text: this.$t('headers.serial'),
					name: 'no',
					value: 'id',
					setValueExpr: val => this.journals.map(c => c.id).indexOf(val) + 1,
				},
				{
					text: this.$t('headers.no'),
					name: 'no',
					value: 'cashJournalNo',
				},
				{
					text: this.$t('headers.cashbox'),
					name: 'cashbox',
					value: 'cashboxId',
					setValueExpr: val => this.getCashboxById(val).name
				},
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					setValueExpr: val => moment(val).format('DD-MM-YYYY'),
				},
				{
					text: this.$t('headers.status'),
					name: 'status',
					value: 'isPosted'
				},
				{
					text: '',
					name: 'actions',
					value: 'actions'
				}
			]
			return header
		},
		tabs() {
			return [
				{ value: context.all, name: this.$t('Journals.tabs.all') },
				{ value: context.cashbox, name: this.$t('Journals.tabs.cashboxes-journals') },
				{ value: context.invoice, name: this.$t('Journals.tabs.journals-linking-invoices') },
				{ value: context.postingCashboxBalance, name: this.$t('Journals.tabs.journals-associated-with-the-closing-of-funds') },
			]
		},
		statuses() {
			return [
				{ id: postingState.posted, name: this.$t('Journals.posted') },
				{ id: postingState.notPosted, name: this.$t('Journals.non-posted') },
			]
		},
		totalJournals() {
			return this.journals.length
		},
		journalsNotPosted() {
			return this.journals.filter(c => !c.isPosted).length
		}
	},
	methods: {
		moment,
		fetchData(journalType) {
			this.loading = true
			this.$store.dispatch('cashJournals/fetchAll', {
				status: this.status,
				cashboxId: this.cashbox,
				fromDate: this.fromDate,
				toDate: this.toDate,
				context: journalType,
				dateSortMode: dateSortMode.descending
			}).then(() => {
				// this.$router.push({
				// 	query: {
				// 		type: journalType
				// 	}
				// })
			}).finally(() => {
				this.loading = false
			})
		},
		reset() {
			this.status = null
			this.cashbox = null
			this.toDate = null
			this.fromDate = null
			this.fetchData()
		}
	},
	created() {
		this.loading = true
		this.$store.dispatch('users/fetchNotifications', {
			storeId: this.$localStorage.storeId,
		})
		this.fetchData()
		this.status = this.$route.query.status ? this.$router.query.status : null
		this.tab = this.$route.query.journalType ? Number(this.$route.query.journalType) : 0
		this.cashbox = this.$route.query.cashbox ? this.$route.query.cashbox : null
		this.fromDate = this.$route.query.fromDate ? this.$route.query.fromDate : null
		this.toDate = this.$route.query.toDate ? this.$route.query.toDate : null
	},
	metaInfo() {
		return {
			title: this.$t('routes.journals'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>
<style scoped>
 .m-minus {
	margin-top: -0.5rem;
 }
</style>