export default {
	casher: 'Casher',
	manager: 'Manager',
	name: 'Name',
	balance: 'Balance',
	specify: 'Specify',
	notDefined: 'NotDefined',
	operation: 'Operation',
	trade: 'Trade',
	gainsAndLosses: 'GainsAndLosses',
	budget: 'Budget',
	'types-of-final-accounts': {
		notDefined: 'notDefined',
		operation: 'Operation',
		trade: 'Trade',
		'gains-and-losses': 'Gains and losses',
		budget: 'Budget',
		account: 'account'
	},
	'default-account-names': {
		'sales-account': 'Sales account ',
		'purchases-account': 'Purchases-account',
		'sales-return-account': 'Sales return account ',
		'purchases-return-account': 'Purchases return account  ',
		'the-discount-account-granted': 'The discount account granted',
		'earned-discount-account': 'Earned-discount-account',
		'tax-account': 'Tax-account ',
		'cash-customer': 'Cash customer ',
		'default-resource': 'Default resource ',
		'bank-account': 'Bank account',
		'bank-fee-account': 'Bank fee account',
		'vat-number': 'Vat number'
	},
	'main-cashBoxId': 'Main cashBoxId',
	'vat-percent': 'Vat percent',
	'bank-fee-percent': 'Bank fee percent',
	'account-cashboxes-reconciliation': 'Account cashboxes reconciliation',
	'store-archive': 'Store archive',
	'store-default-settings': 'Store defaultm settings ',
	reappointment: 'Reappointment',
	reset: 'Reset',
	titles: {
		cashboxes: 'Cashboxes',
		tax: 'Tax',
		'intermediary-accountsId': 'Intermediary accountsId ',
		sales: 'Sales',
		purchase: 'Purchase',
		bank: 'Bank'
	},
	currency: 'Currency',
	'attach-logo': 'Attach logo',
	'import-store-materials': 'Import store materials',
	address: 'Address',
	phone: 'Phone',
	'please-log-in-to-the-store-context-to-add-settings': '(Please log in to the store context to add settings)',
	'import-item-tree': 'Import item tree',
	'export-item-tree': 'Export item tree',
	'confirm-import-of-material-tree': 'Confirm import of material tree',
	'are-you-sure-you-want-to-import-the-material-tree-for-the-current-store?': 'Are you sure you want to import the material tree for the current store?',
	'the-material-tree-import-process-is-irreversible-and-may-take-some-time': 'The material tree import process is irreversible and may take some time',
	tracking: 'Tracking',
	'default-language': 'Default language',
	importing: 'Importing',
	exporting: 'Exporting',
	'export-import-tree-item': 'Export/import tree item:',
	language: {
		arabic: 'Arabic',
		english: 'English',
		spanish: 'Spanish'
	},
	'the-import-process-was-successful': 'The import process was successful',
	'the-store-is-materials-file-has-been-successfully-exported-to-the-Downloads-folder': 'The store is materials file has been successfully exported to the Downloads folder',
	'initial-number-of-sales-invoices': 'Initial number of sales invoices',
	'your-tax-report-results-will-be-affected': 'Your tax report results will be affected',
	alert: 'alert',
	'store-name-in-reports-(English)': 'Store name in reports (English)',
	'store-name-in-reports-(Arabic)': 'Store name in reports (Arabic)',

	timezone: 'Timezone',
	'printed-invoice-title': 'Printed invoice title',
}