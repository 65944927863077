export default {
	home: 'الصفحة الرئيسية',
	'items-tree': 'شجرة المواد',
	'accounts-tree': 'شجرة الحسابات',
	'all-invoices': 'كل الفواتير',
	'sale-invoice': 'فاتورة بيع',
	'sales-invoice-returned': 'فاتورة مرتجع البيع',
	'pruchase-invoice': 'فاتورة شراء',
	reports: 'التقارير',
	statment: 'كشف مادة',
	'item-inventory': 'جرد مواد ',
	user: 'المستخدم',
	'choose-store': 'تبديل متجر',
	'account-statement': 'كشف حساب',
	'all-journals': ' كل اليوميات النقدية ',
	'cashbox-journal': 'يومية الصندوق',

	'journals-items': 'يوميات المواد',
	'journal-item': 'يومية مواد',
	'all-journals-items': 'كل اليوميات',
	'items-journal-table': 'جدول يومية مواد',
	'setting-store': 'الإعدادات الافتراضية',
	'switch-store': 'تبديل المتجر',
	'store-settings': 'إعدادات المتجر',
	'daily-journal': 'ملخص اليوم',
	'funds-journal-table': 'جدول اليومية'
}