import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		units: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/units').then(({ data }) => {
				commit(types.STORE_UNITS, data);
				return data;
			})
		},
		fetchById({ commit }, { id }) {
			return axios.get(`/units/${id}`).then(({ data }) => {
				return data
			})
		},
		create({ commit }, { name, notes }) {
			return axios.post('/units', { name, notes }).then(({ data }) => {
				return data
			})
		},
		update({ commit }, { id, name, notes }) {
			return axios.put(`/units/${id}`, { id, name, notes }).then(({ data }) => {
				return data
			})
		},
		delete({ commit }, { id }) {
			return axios.delete(`/units/${id}`)
		},

		reset({ commit }) {
			return commit(types.RESET)
		}
	},

	mutations: {
		[types.STORE_UNITS](state, units) {
			state.units = units;
		},
		[types.RESET](state) {
			state.units = []
		}
	},

	getters: {
		getUnitById: state => id => state.units.find(c => c.id === id)
	}
}