<template>
	<div class="bgColor rounded-t-xl pt-3" style="height: 90vh">
		<!-- dialog add edit -->
		<v-dialog width="300" v-model="dialog">
			<v-form ref="form" @submit.prevent="save">
				<v-card>
					<v-card-title class="py-1">
						<v-spacer/>
						<span class="primary--text"> 
							{{ (unitId ? $t('edit') : $t('add')) + ' ' + $t('Units.unit') }}
						</span>
						<v-spacer/>
						<v-btn icon dark @click="dialog = false">
							<v-icon small color="primary">
								mdi-close
							</v-icon>
						</v-btn>
					</v-card-title>
					<v-divider class="mx-4" />
					<v-card-text class="pt-4 pb-2">
						<v-row class="align-center mt-0">							
							<!-- name -->
							<v-col cols="12" class="pt-0 pb-1">
								<v-text-field
									v-model="dialogData.unitName"
									:label="$t('forms.name')"
									outlined
									dense
									:loading="submitLoading"
									:rules="rules.required"
								></v-text-field>
							</v-col>
							
							<!-- notes -->
							<v-col cols="12" class="pt-0 pb-1">
								<v-textarea
									v-model="dialogData.notes"
									:label="$t('forms.notes')"
									outlined
									dense
									rows="2"
									auto-grow
									no-resize
									:loading="submitLoading"
								></v-textarea>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>

						<v-btn
							type="submit"
							color="primary"
							large
							height="34"
							:loading="submitLoading"
							:disabled="submitLoading"
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							large
							height="34"
							@click="dialog = false"
							:disabled="submitLoading"
							text
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>

		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="units"
			:ids="unitId"
			@on-delete="fetchAll"
		>
			{{$t('do-you-want-to')}} 
			{{$t('Units.delete-unit')}} 
			{{ getUnitById(unitId)?.name }}
			؟
		</bee-delete-dialog>

		<div class="d-flex justify-end px-2">
			<!-- Add btn -->
			<v-btn
				@click="dialog = true"
				color="primary"
				class="mb-2"
				:disabled="permissionsForUser.userType === userType.dataReader"
			>
				<v-icon class="me-1">mdi-file-document-plus-outline</v-icon>
				{{$t('add')}}
			</v-btn>
		</div>

		<!-- table -->
		<bee-handy-table
			:headers="headers"
			:items="units"
			:items-per-page="-1"
			:loading="loading"
			:data-table-props="{
				disableSort: true,
				noDataText: ''
			}"
			fixed-header
			hide-default-footer
			dense
			show-add-row
			:editable-rows="!submitLoading && !loading"
			zebra
			elevation="2"
			table-class="py-2 px-3"
			class="px-3"
		>
			<template v-slot:item.actions="{ item }">
				<v-btn
					color="edit-color"
					text
					small
					class="px-0"
					@click="unitId = item.id, dialog = true"
					:disabled="permissionsForUser.userType === userType.dataReader"
				>
					<v-icon> mdi-pencil </v-icon>
				</v-btn>

				<v-btn
					color="alert-color"
					text
					small
					class="px-0"
					@click="unitId = item.id, deleteDialog = true"
					:disabled="permissionsForUser.userType === userType.dataReader"
				>
					<v-icon> mdi-delete </v-icon>
				</v-btn>
			</template>
		</bee-handy-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import rules from '@/helpers/validation rules'
import { userType } from '@/helpers/enums'
export default {
	name: 'Units',
	data() {
		return {
			dialog: false,
			name: null,
			notes: null,

			// data
			dialogData: {
				id: null,
				unitName: null,
				notes: null,
			},
			unitId: null,

			// helpers
			submitLoading: false,
			loading: false,

			deleteDialog: false,
			rules,
			userType
		}
	},
	watch: {
		dialog(val) {
			if (val) {
				if (this.unitId) {
					this.dialogData.unitName = this.getUnitById(this.unitId).name;
					this.dialogData.notes = this.getUnitById(this.unitId).notes
				}
			} else {
				this.unitId = null;
				this.$refs.form.reset();
			}
		}
	},
	computed: {
		...mapState({
			units: state => state.units.units,
			permissionsForUser: state => state.auth.permissionsForUser
		}),

		...mapGetters({
			getUnitById: 'units/getUnitById'
		}),
		
		headers() {
			const headers = [
				{
					text: this.$t('headers.unit'),
					name: 'name',
					value: 'name',
					noInput: true,
					width: '30%',
				},
				{
					text: this.$t('headers.notes'),
					name: 'notes',
					value: 'notes',
					noInput: true,
					width: '50%',
				},
				{
					text: '',
					name: 'actions',
					value: 'actions',
					noInput: true,
					width: '20%',
					aling: 'center'
				}
			];
			return headers
		}
	},

	methods: {
		save() {
			if (this.$refs.form.validate()) {
				this.submitLoading = true;
				const actionName = this.unitId ? 'update' : 'create';
				this.$store.dispatch(`units/${actionName}`, { 
					id: this.unitId,
					name: this.dialogData.unitName,
					notes: this.dialogData.notes
				}).then(() => { 
					this.fetchAll()
				}).finally(() => {
					this.dialog = false
				})
			}
		},
		deleteUnit() {
			this.submitLoading = true;
			this.$store.dispatch('units/delete', { id: this.unitId }).finally(() => {
				this.submitLoading = false;
				this.deleteDialog = false
			})
		},
		fetchAll() {
			this.$store.dispatch('units/fetchAll').finally(() => {
				this.submitLoading = false;
				this.loading = false;
				this.dialog = false
			})
		}
		
	},
	metaInfo() {
		return {
			title: this.$t('routes.units'),
			titleTemplate: '%s | POS'	
		}
	}
}
</script>
