<template>
	<div>
		<v-form ref="form" @submit.prevent="saveItem">
			<!-- delete dialog -->
			<bee-delete-dialog
				v-model="deleteDialog"
				moduleType="items"
				:ids="$route.params.id"
				@on-delete="$emit('update-tree-items'); $router.push({ name: 'items-tree' });"
			>
				{{$t('do-you-want-to')}} 
				<span class="text-uppercase error--text">{{$t('delete')}}</span> 
				{{$t('Item.the-item')}}
			</bee-delete-dialog>
			<!-- actions -->
			<div class="mb-2">
				<!-- edit -->
				<v-btn
					v-if="$route.query.preview"
					@click="
						$router.replace({
							name: 'item',
							params: {
								id: $route.params.id 
							}
						})
					"
					elevation="1"
					color="edit-color white--text"
					height="35px"
					class="px-2 px-4"
					:loading="submitLoading"
					:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
					key="edit-button"
				>
					<v-icon size="22" class="me-1">mdi-pencil</v-icon>
					{{$t('edit')}}
				</v-btn>

				<!-- delete -->
				<v-btn
					v-if="$route.query.preview"
					elevation="1"
					color="alert-color white--text"
					height="35px"
					class="ms-2 px-4"
					:disabled="submitLoading || permissionsForUser.userType === userType.dataReader"
					@click="deleteDialog = true"
				>
					<v-icon size="22" class="me-1">mdi-delete</v-icon>
					{{$t('delete')}} 
				</v-btn>

				<!-- save -->
				<v-btn
					v-if="!$route.query.preview"
					elevation="1"
					color="primary"
					height="35px"
					class="px-4"
					:loading="submitLoading"
					:disabled="submitLoading"
					key="save-button"
					type="submit"
					@click="disableTable = !disableTable"
				>
					<v-icon size="22" class="me-1">mdi-content-save</v-icon>
					{{$t('save')}}
				</v-btn>

				<!-- cancel -->
				<v-btn
					v-if="!$route.query.preview"
					:to="{ name: 'items-tree' }"
					elevation="1"
					color="alert-color white--text"
					height="35px"
					:disabled="submitLoading"
					class="ms-2 px-4"
				>
					{{$t('cancel')}}
				</v-btn>
			</div>
			
			<v-card elevation="2">
				<v-card-text class="pt-0">
					<!-- main info -->
					<v-card-title class="pt-2 pb-4 px-0 main--text">
						{{$t('Item.main-info')}}
					</v-card-title>
					<v-row class="flex-wrap align-center">
						<!-- item name -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="name"
								:label="$t('Item.item-name')"
								outlined
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								:rules="rules.required"
							></v-text-field>
						</v-col>
						
						<!-- main item -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-autocomplete
								v-model="mainItem"
								:items="items.filter(c => c.type === type.main)"
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								item-text="name"
								item-value="id"
								outlined
								dense
								clearable
								:label="$t('Item.main-item')"
								color="primary"
								:rules="itemType === type.sub ? rules.required : []"
								@change="
									fetchSuggestedChildNo($event);
									noPrefix = getItemById($event)?.no ?? null;
								"
							></v-autocomplete>
						</v-col>

						<!-- item no -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model.number="itemNo"
								type="number"
								min="1"
								:label="$t('Item.item-no-automatic-or-manual')"
								hide-spin-buttons
								outlined
								dense
								:prefix="!$vuetify.rtl ? noPrefix : null"
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								:rules="rules.requiredAsNumber"
								requiredAsNumber
								dir="ltr"
							>
								<template v-if="$vuetify.rtl" v-slot:append>
								<div
									class="d-flex align-center mt-1"
									:class="{ 'primary--text': !disableFieldsToPreview }"
								>
									{{noPrefix}}
								</div>
								</template>
							</v-text-field>
						</v-col>

						<!-- item type -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-autocomplete
								v-model="itemType"
								:items="types"
								item-value="id"
								item-text="name"
								:label="$t('Item.item-type')"
								outlined
								clearable
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								:rules="rules.requiredAsNumber"
							></v-autocomplete>
						</v-col>

						<!-- item classification -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-autocomplete
								v-model="itemClassification"
								:items="itemCategories.filter(c => itemType === type.main ? c.id !== itemCategoryType.manufactured : true)"
								item-value="id"
								item-text="name"
								:label="$t('Item.item-classification')"
								outlined
								clearable
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								:rules="rules.requiredAsNumber"
								@change="showMessage; resetTable()"
							></v-autocomplete>
						</v-col>
					</v-row>

					<!-- trading info -->
					<v-card-title class="pt-2 pb-4 px-0 main--text">
						{{$t('Item.trading-info')}}
					</v-card-title>
					<v-row class="flex-wrap align-center">
						<!-- unit -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-1">
							<v-autocomplete
								v-model="itemUnit"
								:items="units"
								item-value="id"
								item-text="name"
								:label="$t('Item.unit')"
								outlined
								clearable
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								:rules="itemType === type.sub ? rules.required : []"
							></v-autocomplete>
						</v-col>

						<!-- Item sale price -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="priceFormatted"
								v-number-format.number="[priceFormatted, price.amount]"
								:label="$t('Item.selling-price')"
								outlined
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								:rules="itemType === type.sub ? rules.numberFormat : []"
							></v-text-field>
						</v-col>

						<!-- Item purchase price -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="latestPurchasePrice"
								:label="$t('Item.purchasing-price')"
								outlined
								dense
								readonly
								:loading="loading"
							></v-text-field>
						</v-col>

						<!-- barcode -->
						<v-col lg="3" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="barcode"
								:label="$t('forms.barcode')"
								outlined
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
							></v-text-field>
						</v-col>

						<!-- Item min -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="minFormatted"
								v-number-format.number="[minFormatted, min]"
								:label="$t('Item.min')"
								outlined
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
							></v-text-field>
						</v-col>

						<!-- Item max -->
						<v-col lg="2" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="maxFormatted"
								v-number-format.number="[maxFormatted, max]"
								:label="$t('Item.max')"
								outlined
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
							></v-text-field>
						</v-col>

						<!-- Item manufacturer -->
						<v-col lg="2" md="4" sm="6" class="py-1">
							<v-text-field
								v-model="manufacturer"
								:label="$t('Item.manufacturer')"
								outlined
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
							></v-text-field>
						</v-col>

						<!-- notes -->
						<v-col lg="4" md="8" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="notes"
								:label="$t('Item.notes')"
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								outlined
								dense
							></v-text-field>
						</v-col>

						<!-- fav & saleable -->
						<v-col lg="4" cols="12" class="align-self-start d-flex flex-wrap pt-lg-2 pt-1 pb-1">
							<!-- favorite item -->
							<v-switch
								v-model="isFavoriteItem"
								:label="$t('Item.favorite-item')"
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								hide-details
								class="ma-0 me-4"
							></v-switch>

							<!-- sellable item -->
							<v-switch
								v-model="isUnsellable"
								:label="$t('Item.unsaleable')"
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								hide-details
								class="ma-0"
							></v-switch>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-form>

		<p
			v-if="(itemClassification === itemCategoryType.manufactured)"
			class="primary--text my-2 text-h6"
		>
			{{$t('Item.mixture-ingredient')}} 
		</p>
		<bee-handy-smart-table
			v-show="(itemClassification === itemCategoryType.manufactured)"
			v-model="tableInputs"
			ref="table"
			:headers="headers"
			:items="entries"
			:items-per-page="-1"
			:loading="loading || loadingTable"
			:data-table-props="{
				disableSort: true,
				noDataText: ''
			}"
			fixed-header
			hide-default-footer
			dense
			show-add-row
			:editable-rows="!submitLoading && !loading && !loadingTable"
			height="auto"
			stay-in-input-fields
			:validation-messages.sync="validationMessages"
			disable-mobile-context-menu
			hide-inputs-details
			@edit-cancelled="isEditItem = false"
			@context-menu-edit="setRowIndex"
			@context-menu-delete="deleteItem"
			@change-subItemId="reset()"
			@input-price="isPriceChangedByUser = true"
			@keypress.enter="!isEditItem ? addItem() : editItem()"
			@reached-last-input="!isEditItem ? addItem() : editItem()"
			zebra
			hot-inputs
			table-class="py-2 px-3 rounded"
			:disable-context-menu="submitLoading || loading"
			:disable-input-fields="submitLoading || loading || disableFieldsToPreview || loadingTable"
		>
			<!-- actions -->
			<template v-slot:item.actions="{ index }">
				<div class="d-flex">
					<!-- edit -->
					<v-btn
						text
						small
						color="edit-color"
						:disabled="submitLoading || loading"
						@click="$refs.table.activeEditForRow(index); setRowIndex({ index })"
					>
						<v-icon>mdi-pencil</v-icon>
					</v-btn>

					<!-- delete -->
					<v-btn
						text
						small
						color="alert-color"
						:disabled="submitLoading || loading"
						@click="deleteItem({ index }) "
					>
						<v-icon>mdi-delete</v-icon>
					</v-btn>
				</div>
			</template>

			<template v-slot:no-input.actions>
				<v-btn
					small
					text
					:color="!isEditItem ? 'success': null"
					@click="!isEditItem ? addItem() : editItem()"
				>
					<v-icon>mdi-{{!isEditItem ? 'playlist-plus' : 'check'}}</v-icon>
				</v-btn>
			</template>

			<!-- itemId -->
			<template v-slot:input.subItemId="{ on, attr }">
				<v-autocomplete
					:items="items.filter(c => c.type === type.sub)"
					item-value="id"
					:item-text="(item) => `${item.no} - ${item.name}`"
					v-on="on"
					v-bind="attr"
				></v-autocomplete>
			</template>

			<!-- quantity -->
			<template v-slot:input.quantity="{ on, attr }">
				<quantity-input
					v-on="on"
					v-bind="attr"
				></quantity-input>
			</template>

			<!-- price -->
			<template v-slot:input.price="{ on, attr }">
				<v-text-field
					@focus="fetchSuggestedPurchasePrice"
					:loading="loadingRequestPrice"
					v-on="on"
					v-bind="attr"
					readonly
				></v-text-field>
			</template>

		</bee-handy-smart-table>
		
		<div
			v-if="(itemClassification === itemCategoryType.manufactured)"
			class="mt-3"
		>
			<span class="mx-2">
				{{
					$t(
						'Item.for-one-of-$-from-$', {
							unit: getUnitById(itemUnit)?.name,
							item: getItemById($route.params.id)?.name
						}
					)
				}}
			</span>
			<div class="d-flex">
				<!-- cost input item  -->
				<p class="mx-2">
					{{$t('Item.the-cost-of-the-input-items')}}
					{{ totalNet ? numberFormat(totalNet) : Number(totalNet.toFixed(2)) }}
				</p>
				-
				<!-- price sell-->
				<p class="mx-2"> 
					{{$t('Item.selling-price') + ':'}} {{(priceFormatted ? priceFormatted : '0.00' ) }}
				</p>
				-
				<!-- difference -->
				<p class="mx-2"> 
					{{ $t('Item.the-difference') + ': '}}
					<span dir="auto">{{numberFormat(price.amount - totalNet)}} </span>
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import QuantityInput from '@/components/QuantityInput.vue';
import { types, type, itemCategories, itemCategoryType, userType } from '@/helpers/enums'
import { fixNumber } from '@/helpers/functions'
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'Item',

	components: {
		QuantityInput
	},
	data() {
		return {
			// helpers
			showTable: false,
			isManufactured: false,
			disableTable: true,
			itemId: null,
			submitLoading: false,
			loading: false,
			loadingTable: false,

			deleteDialog: false,
			isInSameItem: false,

			noPrefix: null,
			type,
			itemCategoryType,
			rules,

			// formate helpers
			priceFormatted: null,
			minFormatted: null,
			maxFormatted: null,

			name: null,
			mainItem: null,
			itemNo: null,
			itemType: null,
			itemClassification: null,		
			itemUnit: null,
			barcode: null,
			min: null,
			max: null,
			manufacturer: null,
			price: {
				amount: null,
				currencyId: null
			},
			notes: null,
			isFavoriteItem: false,
			isUnsellable: false,

			// table
			tableInputs: {
				quantity: '1'
			},
			entries: [],
			validationMessages: [],
			isEditItem: false,
			latestPurchasePrice: null,
			loadingRequestPrice: false,
			userType
		}
	},

	watch: {
		watchRoute() {
			this.$refs.form?.resetValidation();
			
			if (!this.isInSameItem) {
				this.fetchItemData();
			} 
		},
		'tableInputs.itemId'(val) {
			this.tableInputs.price = this.getItemById(val)?.salePrice?.amount;
		}
	},

	computed: {
		watchRoute() {
			return `${this.$route.name} | ${this.$route.params.id} | ${this.$route.query.preview}`
		},
		...mapState({
			items: state => state.items.items,
			units: state => state.units.units,
			selectedItem: state => state.items.selectedItem,
			permissionsForUser: state => state.auth.permissionsForUser
		}),

		...mapGetters({
			getItemById: 'items/getItemById',
			getUnitById: 'units/getUnitById',
		}),

		types() { return types() },
		itemCategories() { return itemCategories() },

		disableFieldsToPreview() {
			return Boolean(this.$route.query.preview);
		},

		headers() {
			const headers = [
				{
					text: this.$t('headers.item'),
					name: 'subItemId',
					value: 'subItemId',
					setValueExpr: val => this.getItemById(val)?.name,
					inputRules: rules.required,
					width: 170,
				},
				{
					text: this.$t('headers.unit'),
					name: 'unit',
					value: 'quantity.unitSizeId',
					setValueExpr: val => this.getUnitById(val)?.name,
					width: 90,
					disableInput: true,
					setInputExpr: inputs => {
						return this.getUnitById(this.getItemById(inputs.subItemId)?.unitId)?.name
					}
				},
				{
					text: this.$t('headers.quantity'),
					name: 'quantity',
					value: 'quantity.quantity',
					width: 100,
					inputRules: [val => !isNaN(val) && Number(val) > 0],
					onWriteChange: (item, activeInput, index) => {
						this.entries[index].amount = item.quantity * item.price;
						this.entries[index].discount = fixNumber(item.amount * (item.percentDiscount / 100))
						this.entries[index].totalNet = item.amount - this.entries[index].discount;
					}
				},
				// {
				// 	text: this.$t('headers.price'),
				// 	name: 'price',
				// 	value: 'price',
				// 	width: 90,
				// 	inputRules: [val => !isNaN(val) && Number(val) > 0],
				// 	setValueExpr: val => this.numberFormat(val),
				// 	setInputExpr: inputs => {
				// 		if (this.isPriceChangedByUser) {
				// 			return inputs.price
				// 		}
				// 		return this.getItemById(inputs.subItemId)?.salePrice?.amount || 0
				// 	}
				// },
				{
					text: this.$t('headers.price'),
					name: 'price',
					value: 'price',
					// setValueExpr: val => this.getItemById(val)?.salePrice?.amount,
					inputRules: [val => !isNaN(val) && Number(val) >= 0 && val !== null],
				},
				{
					text: this.$t('headers.amount'),
					name: 'amount',
					value: 'amount',
					disableInput: true,
					setInputExpr: inputs => inputs.price * inputs.quantity,
				}
			];
			if (!this.disableFieldsToPreview) {
				headers.splice(
					headers.length, 0,
					{
						text: '',
						name: 'actions',
						value: 'actions',
						align: 'start',
						noInput: true,
						sortable: false
					}
				)
			}
			for (let i = 0; i < headers.length; i++) {
				headers[i].columnClass = headers[i].columnClass ? headers[i].columnClass + ' px-2' : 'px-2'
			}
			return headers
		},
		totalNet() {
			if (this.entries.map(c => c.amount).length !== 0) {
				return this.entries?.map(c => c.amount).reduce((a, b) => a + b)
			} else {
				return 0
			}
		}
	},
	methods: {
		fetchSuggestedChildNo(itemId) {
			if (itemId) {
				this.$store.dispatch('items/suggestNewChildNo', { id: itemId })
					.then((data) => { this.itemNo = data })
			} else {
				this.itemNo = null;
			}
		},

		fetchItemData() {
			if (this.$route.params.id) {
				this.loading = true;
				this.entries = [];
				this.$emit('disable-add');
				Promise.all([
					this.$store.dispatch('items/fetchById', { id: this.$route.params.id })
						.then((data) => {
							this.name = data.name;
							this.mainItem = data.parentId;
							this.noPrefix = data.parentNo;
							this.itemNo = data.innerNo;
							this.itemType = data.type;
							this.itemClassification = data.category;
							this.itemUnit = data.unitId;
							this.barcode = data.barcode;
							this.min = data.minQuantity;
							this.max = data.maxQuantity;
							this.manufacturer = data.manufacturer;
							this.price.amount = data.salePrice?.amount;
							this.price.currencyId = data.salePrice?.currencyId;
							this.notes = data.notes;
							this.isFavoriteItem = data.isFavourite;
							this.isUnsellable = data.isUnsellable;

							this.priceFormatted = this.numberFormat(this.price.amount);
							this.latestPurchasePrice = data.latestPurchasePrice ? this.numberFormat(data.latestPurchasePrice) : 0;
							this.minFormatted = this.numberFormat(this.min);
							this.maxFormatted = this.numberFormat(this.max);
							data.ingredients?.forEach((ingredient) => {
								this.entries.push({
									subItemId: ingredient.subItemId,
									quantity: {
										quantity: ingredient.itemQuantity.quantity,
										unitSizeId: this.getUnitById(ingredient.itemQuantity.unitSizeId).id,
									},
									//  FIXME should to use price but price return 0 always from the API
									// price: ingredient.price.amount,
									price: this.getItemById(ingredient.subItemId).salePrice.amount,
									amount: Number(ingredient.itemQuantity.quantity) * Number(this.getItemById(ingredient.subItemId).salePrice.amount),
								})
							})
							if (this.selectedItem.category === this.itemCategoryType.manufactured) {
								this.isManufactured = true
							} else {
								this.isManufactured = false
							}
						}),
					this.$route.query.addSub
						? this.fetchSuggestedChildNo(this.$route.params.id)
						: null
				]).finally(() => {
					this.loading = false;
					this.$emit('enable-add');
				})
			}
		},

		saveItem() {
			if (this.$refs.form.validate()) {
				this.submitLoading = true;
				const actionName = this.$route.params.id && !this.$route.query.addSub ? 'update' : 'create';
				this.$store.dispatch(`items/${actionName}`, {
					id: this.$route.params.id,
					parentId: this.mainItem,
					unitId: this.itemUnit,
					barcode: this.barcode,
					name: this.name,
					innerNo: this.itemNo,
					type: this.itemType,
					category: this.itemClassification,
					salePrice: 
						this.price.amount > 0 
							? {
								amount: this.price.amount,
								currencyId: this.price.currencyId || this.$localStorage.currencyId
							}
							: null,
					maxQuantity: this.max,
					minQuantity: this.min,
					manufacturer: this.manufacturer,
					notes: this.notes,
					isFavourite: this.isFavoriteItem,
					isUnsellable: this.isUnsellable,
					ingredients: this.entries
				}).then((itemId) => {
					// if (this.itemClassification === itemCategoryType.manufactured) {
					// 	this.$store.dispatch('items/ingredient', { ingredients: this.entries, id: this.$route.params.id ? this.$route.params.id : itemId }).then(() => {
					// 	})
					// }
					this.$emit('update-tree-items')
					this.$router.replace({
						name: 'item',
						params: { id: actionName === 'update' ? this.$route.params.id : itemId },
						query: { preview: 1 }
					})
				}).finally(() => {
					this.isManufactured = false
					this.submitLoading = false;
				})
			}
		},
		showMessage(val) {
			if (val) {
				const item = this.getItemById(this.$route.params.id);
				if (
					val !== itemCategoryType.manufactured &&
					item.category === itemCategoryType.manufactured &&
					this.$route.params.id &&
					!this.$route.query.addSub
				) {
					this.$eventBus.$emit(
						'show-snackbar',
						true,
						this.$t('messages.ingredients-will-be-deleted')
					);
				}
				else if (
					val === itemCategoryType.manufactured &&
					(
						(item && item.category !== itemCategoryType.manufactured && this.$route.params.id) ||
						!this.$route.params.id ||
						this.$route.query.addSub
					)
				) {
					this.$eventBus.$emit(
						'show-snackbar',
						this.$t('messages.save-item-to-add-ingredients')
					);
				}
			}
		},

		// helpers
		numberFormat(val) {
			return this.$options.filters.numberFormat(val)
		},
		// for table
		deleteItem({ index }) {
			this.entries.splice(index, 1)
		},

		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.idItemRow = this.entries[this.rowEditIndex].itemId
			// this.ingredientIdRow = JSON.parse(JSON.stringify(this.entries[rowData.index])).ingredientId;
			this.ingredientIdRow = this.entries[rowData.index].ingredientId
			this.itemId = this.idItemRow
		},

		resetTableInputs() {
			this.$refs.table.resetInputs({
				itemId: null,
				quantity: '1',
				price: null,
				amount: null,
				totalNet: null,
			})
		},
		reset() {
			this.tableInputs.price = null
			this.tableInputs.amount = null
			this.tableInputs.totalNet = null
		},
		addItem() {
			if (this.$refs.table.validate()) {
				const itemInTable = this.entries.find(c => c.subItemId === this.tableInputs.subItemId);
				if (!itemInTable) {
					this.entries.push({
						...this.tableInputs,
						subItemId: this.tableInputs.subItemId,
						quantity: {
							quantity: Number(this.tableInputs.quantity),
							unitSizeId: this.getUnitById(this.getItemById(this.tableInputs.subItemId).unitId).id
						},
						amount: this.tableInputs.quantity * this.tableInputs.price,
					})
				} else {
					itemInTable.quantity.quantity = Number(itemInTable.quantity.quantity) + Number(this.tableInputs.quantity)
					itemInTable.amount = itemInTable.quantity.quantity * this.tableInputs.price
				}
				this.$nextTick(() => { 
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus()
				})
			}
		},
		editItem() {	
			this.entries[this.rowEditIndex].subItemId = this.tableInputs.subItemId
			this.entries[this.rowEditIndex].quantity.quantity = this.tableInputs.quantity
			this.entries[this.rowEditIndex].quantity.unitSizeId = this.getItemById(this.tableInputs.subItemId).unitId
			this.entries[this.rowEditIndex].price = this.tableInputs.price;
			this.entries[this.rowEditIndex].amount = this.tableInputs.amount
			this.$refs.table.resetEdit();
			this.isEditItem = false;
				
			this.$nextTick(() => {
				this.resetTableInputs();
			})	
		},
		resetTable() {
			if (this.itemClassification === itemCategoryType.manufactured && !this.$route.params.id) {
				this.entries = [];
				// this.tableInputs.quantity = '1'
			}
		},

		fetchSuggestedPurchasePrice() {
			if (this.tableInputs.subItemId) {
				this.loadingRequestPrice = true
				this.$store.dispatch('invoices/fetchSuggestedPurchasePrice', {
					itemId: this.tableInputs.subItemId,
					accountId: null
				}).then((data) => {
					this.tableInputs.price = data.price;
					// this.priceEntryDialog = data;
				}).finally(() => {
					this.loadingRequestPrice = false
				})
			}
		},
	},
	created() {
		this.$refs.form?.resetValidation();
		if (!this.$route.params.id || !this.$route.query.addSub) {
			this.fetchItemData();
		}
		if (this.$route.query.addSub) {
			this.mainItem = this.$route.params.id;
			this.noPrefix = String(this.getItemById(this.$route.params.id).no);
			this.fetchSuggestedChildNo(this.$route.params.id);
		};
	},

	mounted() {
		this.tableInputs.quantity = '1';
	},

	// route helper
	beforeRouteUpdate(to, from, next) {
		if (to.params.id === from.params.id) {
			this.isInSameItem = true;
			if (to.query.preview) {
				this.isInSameItem = false;
			}
		} else {
			this.isInSameItem = false;
			// this.resetTableInputs()
		}
		if (!to.params.id || to.query.addSub) {
			this.$refs.form.reset();
			this.isFavoriteItem = false;
			this.isUnsellable = false;
			this.noPrefix = null;
		}
		if (to.query.addSub) {
			this.mainItem = to.params.id;
			this.noPrefix = String(this.getItemById(to.params.id).no);
			this.fetchSuggestedChildNo(to.params.id);
		}
		next();
	},

	// meta info
	metaInfo() {
		let title;
		if (this.$route.query.preview) {
			title = this.$t('Navbar.info') + ' ';
		} else if (this.$route.query.addSub) {
			title = this.$t('add-sub') + (this.$i18n.locale === 'ar' ? '' : ' ');
		} else if (this.$route.params.id) {
			title = this.$t('edit') + ' ';
		} else {
			title = this.$t('add') + ' ';
		}
		return {
			title: title + this.$t('routes.item'),
			titleTemplate: `%s | ${this.$t('routes.items-tree')} | POS`
		}
	},
}
</script>
