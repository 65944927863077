import Vue from 'vue'
import Vuex from 'vuex'
import accounts from './modules/accounts'
import accounting from './modules/accounting'
import cashboxes from './modules/cashboxes'
import configs from './modules/configs'
import items from './modules/items'
import invoices from './modules/invoices'
import stores from './modules/stores'
import units from './modules/units'
import reports from './modules/reports'
import statisticsReports from './modules/statisticsReports'
import cashJournals from './modules/cashJournals'
import cashboxJournal from './modules/cashboxJournal'
import users from './modules/users'
import auth from './modules/auth'
import currencies from './modules/currencies'
import itemJournals from './modules/itemJournals'
Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		configs,
		units,
		stores,
		cashboxes,
		accounts,
		accounting,
		items,
		invoices,
		reports,
		statisticsReports,
		cashJournals,
		cashboxJournal,
		auth,
		users,
		currencies,
		itemJournals,
	},

	actions: {
		reset({ dispatch }) {
			dispatch('accounts/reset');
			dispatch('cashboxes/reset');
			dispatch('cashboxJournal/reset');
			dispatch('cashJournals/reset');
			dispatch('configs/reset');
			dispatch('currencies/reset');
			dispatch('invoices/reset');
			dispatch('items/reset');
			dispatch('statisticsReports/reset');
			dispatch('stores/reset');
			dispatch('units/reset');
			dispatch('users/reset')
		}
	}
})
