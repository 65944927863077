import { downloadBlob } from '@/helpers/functions';
import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		items: [],
		itemsTree: [],
		oneLevelItemsTree: [],
		saleableItemsTree: [],
		selectedItem: {}
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/items').then(({ data }) => {
				commit(types.STORE_ITEMS, data);
				return data;
			})
		},

		fetchById({ commit }, { id }) {
			return axios.get(`/items/${id}`).then(({ data }) => {
				commit(types.STORE_SELECTED_ITEM, data);
				return data
			})
		},

		fetchAllAsTree({ commit }, { sellableOnly, oneNestedLevel }) {
			return axios.get('/items/tree', { params: { sellableOnly, oneNestedLevel } }).then(({ data }) => {
				if (sellableOnly) {
					commit(types.STORE_SALEABLE_ITEMS_TREE, data.filter(c => c.children && c.children.length));
				} else if (oneNestedLevel) {
					commit(types.STORE_ONE_LEVEL_ITEMS_TREE, data.filter(c => c.children && c.children.length));
				} else {
					commit(types.STORE_ITEMS_TREE, data);
				}
				return data;
			})
		},

		suggestNewChildNo({ commit }, { id }) {
			return axios.get(`/items/${id}/suggestNewChildNo`).then(({ data }) => data)
		},

		create({ commit }, { parentId, unitId, barcode, name, innerNo, type, category, salePrice, maxQuantity, minQuantity, manufacturer, notes, isFavourite, isUnsellable, ingredients }) {
			return axios.post('/items', {
				parentId, unitId, barcode, name, innerNo, type, category, salePrice, maxQuantity, minQuantity, manufacturer, notes, isFavourite, isUnsellable, ingredients
				
				/* salePrice = { amount: 0, currencyId: "" } */
			}).then(({ data }) => data)
		},

		update({ commit }, { id, parentId, unitId, barcode, name, innerNo, type, category, salePrice, maxQuantity, minQuantity, manufacturer, notes, isFavourite, isUnsellable, ingredients }) {
			return axios.put(`/items/${id}`, {
				parentId, unitId, barcode, name, innerNo, type, category, salePrice, maxQuantity, minQuantity, manufacturer, notes, isFavourite, isUnsellable, ingredients
			})
		},

		delete({ commit }, { id }) {
			return axios.delete(`/items/${id}`)
		},

		// Ingredients
		createIngredient({ commit }, { id, subItemId, quantity, unitSizeId }) {
			return axios.post(`/Items/${id}/Ingredients`, {
				subItemId, quantity: { quantity, unitSizeId }
			}).then(({ data }) => data)
		},
		updateIngredient({ commit }, { id, ingredientId, subItemId, quantity, unitSizeId }) {
			return axios.put(`/Items/${id}/ingredients/${ingredientId}`, {
				subItemId, quantity: { quantity, unitSizeId }
			}).then((data) => data)
		},
		deleteIngredient({ commit }, { id, ingredientId }) {
			return axios.delete(`Items/${id}/ingredients/${ingredientId}`)
		}, 
		ingredient({ commit }, { id, ingredients }) {
			return axios.put(`/Items/${id}/Ingredients`, ingredients)
		},
		reset({ commit }) {
			return commit(types.RESET)
		},

		// export and import tree
		exportItemTree({ commit }) {
			return axios.get('/Items/ExportAsJsonFile', { responseType: 'blob' }).then(({ data }) => {
				downloadBlob(data, `items ${new Date().toISOString().slice(0, 10)}.json`);
			})
		},

		// import 
		importItemTree({ commit }, { file }) {
			const formData = new FormData();
			formData.append('file', file);
			return axios.post('/Items/import', formData)
		}
	},	

	mutations: {
		[types.STORE_ITEMS](state, items) {
			state.items = items;
		},
		[types.STORE_ITEMS_TREE](state, itemsTree) {
			state.itemsTree = itemsTree;
		},
		[types.STORE_ONE_LEVEL_ITEMS_TREE](state, oneLevelItemsTree) {
			state.oneLevelItemsTree = oneLevelItemsTree;
		},
		[types.STORE_SALEABLE_ITEMS_TREE](state, saleableItemsTree) {
			state.saleableItemsTree = saleableItemsTree;
		},
		[types.STORE_SELECTED_ITEM](state, item) {
			state.selectedItem = item;
		},
		[types.RESET](state) {
			state.items = [];
			state.itemsTree = [];
			state.oneLevelItemsTree = [];
			state.saleableItemsTree = [];
			state.selectedItem = {}
		},
	},

	getters: {
		getItemById: state => id => state.items.find(c => c.id === id),
		getItemByBarcode: state => barcode => state.items.find(c => c.barcode === barcode),
	}
}