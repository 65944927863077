export default {
	all: 'All',
	favorite: 'Favorite',

	'edit-fav-list': 'Edit fav list',

	'total-without-vat': 'Total without vat',
	'total-net': 'Total net',
	paid: 'Paid',
	alert: 'Alert',
	'alert-message': 'Prices will be changed to the prices allocated for this account, if any'
}