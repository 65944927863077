import i18n from '@/i18n/i18n'

export function finalAccounts() {
	return [
		{ id: 1, name: i18n.t('final-accounts.operating') },
		{ id: 2, name: i18n.t('final-accounts.trading') },
		{ id: 3, name: i18n.t('final-accounts.profit-and-losses') },
		{ id: 4, name: i18n.t('final-accounts.balance-sheet') },
	]
}

export const finalAccount = {
	trading: 2,
	profitAndLosses: 3,
	budget: 4
}

export function types() {
	return [
		{ id: 1, name: i18n.t('type.main') },
		{ id: 2, name: i18n.t('type.sub') }
	]
}

export const type = {
	main: 1,
	sub: 2,
}
export function itemCategories() {
	return [
		{ id: 1, name: i18n.t('item-category.commodity') },
		{ id: 2, name: i18n.t('item-category.raw') },
		// { id: 3, name: i18n.t('item-category.indirect-raw') },
		// { id: 4, name: i18n.t('item-category.in-process') },
		// { id: 5, name: i18n.t('item-category.finished') },
		{ id: 6, name: i18n.t('item-category.manufactured') },
	]
}

export const itemCategoryType = {
	commodity: 1,
	raw: 2,
	manufactured: 6
}
export const accountType = {
	notDefined: 0,
	primary: 1,
	secondary: 2
}

export const accountConfigType = {
	sales: 1,
	purchases: 2,
	grantedDiscount: 3,
	vestedDiscount: 4,
	salesRefund: 8,
	purchasesRefund: 9,
	defaultCashCustomer: 11,
	defaultImporter: 10,
	vatAccountId: 12,
	BankAccountId: 13,
	BankFeeAccountId: 14,
	CashboxesReconciliation: 15
}

export const postingState = {
	posted: 1,
	notPosted: 2,
	all: 3,
}

export const invoiceTypes = {
	purchases: 1,
	sales: 2,
	purchasesRefund: 3,
	salesRefund: 4
}

export const paymentMethod = {
	cash: 1,
	debt: 2,
	creditCard: 3,
	internal: 4
}
export function paymentMethodArray() {
	return [
		{ id: 1, name: i18n.t('payment-method.cash') },
		{ id: 2, name: i18n.t('payment-method.debt') },
		{ id: 3, name: i18n.t('payment-method.creditCard') },
		{ id: 4, name: i18n.t('payment-method.internal') },
	]
}

export const dateSortMode = {
	ascending: 1,
	descending: 2,
}

export const statusStore = {
	NotDefined: 0,
	activated: 1,
	suspended: 2
}
export const statusStoreWithName = {
	notDefined: {
		id: 5,
		name: i18n.t('status-store.notDefined')
	},
	activated: {
		id: 0,
		name: i18n.t('status-store.Unarchived')
	},
	suspended: {
		id: 1,
		name: i18n.t('status-store.archived')
	}
}

export const journalType = {
	invoice: 1,
	cashbox: 2,
	postingCashboxBalance: 3,
	allWithoutInvoices: 4,
	all: 5
}

export const context = {
	notDefined: 0,
	invoice: 1,
	cashbox: 2,
	postingCashboxBalance: 3,
	allWithoutInvoices: 4,
	all: 5
}

export const compoundType = {
	compounded: 1,
	notCompounded: 2,
	all: 3,
}

export const contextItems = {
	notDefined: 0,
	journal: 1,
	invoice: 2,
	manufacturing: 3,
	reconciliation: 4,
	all: 5
}

export const status = {
	notDefined: 0,
	posted: 1,
	notPosted: 2,
	all: 3
}

export const userType = {
	casher: 3,
	manager: 2,
	dataReader: 5
}

export const defaultLanguage = [
	{
		id: 1,
		name: i18n.t('StoreInfo.language.arabic')
	},
	{
		id: 2,
		name: i18n.t('StoreInfo.language.english')
	},
	{
		id: 3,
		name: i18n.t('StoreInfo.language.spanish')
	}
];

export const moneyType = {
	credit: 0,
	Debit: 1
}
