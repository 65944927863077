import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		invoices: []
	},

	actions: {
		fetchAll({ commit }, { type, fromDate, toDate, status, dateSortMode, invoiceId, invoiceNo, page, perPage, postingStatusSortMode, creationDateSortMode, cashboxId }) {
			return axios.get('/invoices', {
				params: { type, fromDate, toDate, status, dateSortMode, invoiceId, invoiceNo, page, perPage, postingStatusSortMode, creationDateSortMode, cashboxId }
			}).then(({ data }) => {
				commit(types.STORE_INVOICES, data);
				return data;
			})
		},
		fetchById({ commit }, { id }) {
			return axios.get(`/invoices/${id}`).then(({ data }) => {
				return data;
			})
		},

		create({ commit }, { type, cashboxId, intermediaryAccountId, date, paymentWay, notes, refDoc, refundedFromInvoices }) {
			return axios.post('/invoices', {
				type, cashboxId, intermediaryAccountId, date, paymentWay, notes, refDoc, refundedFromInvoices
			}).then(({ data }) => {
				return data;
			})
		},	

		update({ commit }, { id, type, cashboxId, intermediaryAccountId, date, paymentWay, notes, refDoc, paidAmount, discountTotal, totalNet, refundedFromInvoices, printingNotes, entries }) {
			return axios.put(`/invoices/${id}`, {
				type, cashboxId, intermediaryAccountId, date, paymentWay, notes, refDoc, paidAmount, discountTotal, totalNet, refundedFromInvoices, printingNotes, entries
			}).then(({ data }) => {
				return data;
			})
			/*
				each of paidAmount, discountTotal, totalNet should has: 
				amount: 0,
				currencyId: "3fa85f64-5717-4562-b3fc-2c963f66afa6"
			*/
		},

		updateEntries({ commit }, { invoiceType, invoiceId, entries }) {
			return axios.post(`/invoices/${invoiceType}/${invoiceId}/entries`, entries);
			/*
				each entry should has:
				id : "3fa85f64-5717-4562-b3fc-2c963f66afa6",
				invoiceId : "3fa85f64-5717-4562-b3fc-2c963f66afa6",
				itemId : "3fa85f64-5717-4562-b3fc-2c963f66afa6",
				quantity : {
					quantity : 0,
					unitSizeId : "3fa85f64-5717-4562-b3fc-2c963f66afa6"
				},
				price : {
				amount : 0,
					currencyId : "3fa85f64-5717-4562-b3fc-2c963f66afa6"
				},
				totalNet : {
					amount : 0,
					currencyId : "3fa85f64-5717-4562-b3fc-2c963f66afa6"
				},
				notes : "string",
				discountPercentage : 0,
				discountAmount : {
					amount : 0,
					currencyId : "3fa85f64-5717-4562-b3fc-2c963f66afa6"
				}
			*/
		},

		checkout({ commit }, { id }) {
			return axios.put(`/invoices/${id}/checkout`)
		},

		delete({ commit }, { id }) {
			return axios.delete(`/invoices/${id}`)
		},

		fetchSuggestedPurchasePrice({ commit }, { itemId, accountId }) {
			return axios.get(`/Invoices/SuggestedPurchasePrice/${itemId}`, { params: { accountId } }).then(({ data }) => {
				return data
			})
		},

		reset({ commit }) {
			commit(types.RESET);
		},

		// for sales invoice and sale return
		updateInvoiceWithoutEntries({ commit }, { id, type, cashboxId, intermediaryAccountId, date, notes, printingNotes, refDoc, paidAmount, discountTotal, totalNet, paymentWay, refundedFromInvoices, entries }) {
			return axios.put(`/Invoices/${id}/FiscalAmounts`, { id, type, cashboxId, intermediaryAccountId, date, notes, printingNotes, refDoc, paidAmount, discountTotal, totalNet, paymentWay, refundedFromInvoices, entries: [] }).then(({ data }) => {
				return data
			})
		}
	},

	mutations: {
		[types.STORE_INVOICES](state, invoices) {
			state.invoices = invoices
		},
		[types.RESET](state) {
			state.invoices = [];
		}
	},

	getters: {
		getInvoiceById: state => id => state.invoices.find(c => c.id === id),
		getInvoiceByNo: state => no => state.invoices.find(c => c.no === no),
	}
}