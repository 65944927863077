<template>
	<div id="cashbox-journal">
		<!-- add dialog -->
		<v-dialog v-model="dialog" width="500px">
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span class="primary--text"> 
						{{$t('CashboxJournal.add-journal-cashbox')}}
					</span>
					<v-spacer/>
					<v-btn icon dark @click="dialog = false">
						<v-icon small color="primary">
							mdi-close
						</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider class="mx-4 mt-1 mb-2" />

				<v-form ref="form" @submit.prevent="addNewCashboxJournal">
					<v-card-text class="pb-0 pt-2">
						<v-row>
							<v-col cols="6">
								<bee-date-input
									v-model="dateDialog"
									:label="$t('forms.date')"
									dense
									hide-details
									clearable
									prepend-icon="mdi-calendar"
									user-menu-picker
									fit-inputs
									input-format-order="DD-MM-YYYY"
								></bee-date-input>
							</v-col>
							
							<v-col cols="6">
								<v-autocomplete
									v-model="cashbox"
									:items="cashboxes"
									item-value="id"
									item-text="name"
									:label="$t('forms.cashbox')"
									dense
									:rules="rules.required"
								/>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions class="justify-end">
						<v-btn type="submit" color="primary" :loading="loading" :disabled="loading">
							{{$t('add')}}
						</v-btn>
						<v-btn color="alert-color white--text" @click="dialog = false" :disabled="loading">
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>

			</v-card>
		</v-dialog>
		<!-- actions -->
		<v-row class="ma-0 mb-2">
			<!-- no -->
			<v-col v-if="$route.params.id || cashboxes?.length !== 0" lg="1" md="2" sm="3" cols="6" class="pa-1">
				<items-navigator
					v-model="submitData.no"
					:label="$t('forms.no')"
					:items="journals"
					item-no="cashJournalNo"
					:disabled="Boolean($route.query.notByNo) || loading"
				></items-navigator>
			</v-col>

			<!-- date -->
			<v-col v-if="$route.params.id || cashboxes?.length !== 0" sm="auto" cols="6" class="pa-1">
				<v-text-field 
					v-model="submitData.date"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					:label="$t('forms.date')"
					dense
					hide-details
					disabled
				/>
			</v-col>

			<!-- cashbox -->
			<v-col v-if="$route.params.id || cashboxes?.length !== 0" lg="2" sm="auto" cols="12" class="pa-1">
				<v-autocomplete
					v-model="submitData.cashboxId"
					:label="$t('forms.cashbox')"
					:items="cashboxes"
					item-text="name"
					item-value="id"
					dense
					outlined
					hide-details
					disabled
				></v-autocomplete>
			</v-col>

			<v-spacer v-if="$vuetify.breakpoint.smAndUp"/>

			<!-- add journal -->
			<v-col cols="auto" class="pa-1" >
				<v-btn
					color="primary"
					@click="dialog = true"
					class="mx-2"
					:disabled="permissionsForUser.userType === userType.dataReader"
				>
					<v-icon class="me-1">mdi-file-document-plus-outline</v-icon>
					{{$t('CashboxJournal.new-journal')}}
				</v-btn>
			</v-col>

			<v-divider vertical v-if="$route.params.id || cashboxes?.length !== 0" />
		
			<v-col
				v-if="(journal.context !== context.postingCashboxBalance)"
				cols="auto"
				class="pa-1"
			>
				<v-btn
					color="blue-dark white--text"
					class="mx-2"
					@click="transferBalances = true"
					:disabled="Boolean($route.query.preview) && journal.context !== context.postingCashboxBalance || permissionsForUser.userType === userType.dataReader"
				>
					<v-icon class="me-1">mdi-content-save-outline </v-icon>
					{{ $t('relay.close-today')}}
				</v-btn>
			</v-col>

			<v-col
				v-if="(!$route.query.preview == 1 && journal.context === context.postingCashboxBalance)"
				cols="auto"
				class="pa-1"
			>
				<v-btn
					color="blue-dark white--text"
					class="mx-2"
					@click="post()"
					:disabled="Boolean($route.query.preview) && journal.context !== context.postingCashboxBalance || permissionsForUser.userType === userType.dataReader"
					:loading="loading"
				>
					<v-icon class="me-1">mdi-content-save-outline </v-icon>
					{{ $t('save')}}
				</v-btn>
			</v-col>

			<!-- $route.params.id -->
			<v-col
				v-if="$route.query.preview == 1 && journal.context === context.postingCashboxBalance"
				cols="auto"
				class="pa-1"
			>
				<v-btn
					color="blue-dark white--text"
					class="mx-2"
					@click="unPosted()"
					:disabled="Boolean($route.query.preview) && journal.context !== context.postingCashboxBalance || permissionsForUser.userType === userType.dataReader"
				>
					<v-icon class="me-1">mdi-content-save-outline </v-icon>
					<!-- {{ journal.context === 3 ? $t('edit') : $t('relay.close-today')}} -->
					{{ $t('edit') }}
				</v-btn>
			</v-col>

			<!-- delete -->
			<v-col v-if="$route.params.id" cols="auto" class="pa-1 px-0">
				<v-btn
					color="alert-color white--text"
					@click="deleteDialogJournal = true"
					:disabled="!(journal.context === context.cashbox) && !(!journal.isPosted)"
				>
					<v-icon class="me-1">mdi-delete </v-icon>
					{{$t('delete')}}
				</v-btn>
			</v-col>
		</v-row>
		<transition name="fade" mode="out-in">
			<div
				class="d-flex flex-column justify-center align-center"
				style="height: 85vh;"
				v-if="!$route.params.id && journals?.length === 0"
			>
				<div>
					<v-img
						src="@/assets/empty-space-imgs/no-data.png"
						contain
						width="350"
						height="250"
						transition="scale-transition"
						lazy-src="@/assets/placeholder-image.png"
					/>
					<p class="primary--text text-h6 text-center mb-0">
						{{$t('CashboxJournal.adding-journal-cashbox')}}
					</p>
				</div>
			</div>

		</transition>
		<!-- table -->
		<bee-handy-smart-table
			v-if="$route.params.id || journals?.length !== 0"
			v-model="tableInputs"
			ref="table"
			:items="entryTable"
			:headers="headers"					
			:height="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.height - (45 + 58 + 110) :  $vuetify.breakpoint.height - (45 + 88 + 55 + 64) "
			zebra
			dense
			hide-inputs-details
			:data-table-props="{
				disableSort: true,
				noDataText: $t('entery-value-and-prass-enter')
			}"
			stay-in-input-fields
			hot-inputs
			:show-add-row="!Boolean($route.query.preview) && permissionsForUser.userType !== userType.dataReader"
			@edit-cancelled="isEditItem = false"
			@keypress.enter="!isEditItem ? addItem() : editItem()"
			@reached-last-input="!isEditItem ? addItem() : editItem()"
			:loading="loading"
			pagination-on-scroll
			:shown-rows-text="$t('shown-rows')"
			:disable-input-fields="Boolean($route.query.preview)"
			:editable-rows="!Boolean($route.query.preview)"
		>
			<!-- account -->
			<template v-slot:input.accountId="{ attr, on }">
				<v-autocomplete
					:items="accounts.filter(c => c.type === type.sub)"
					auto-select-first
					item-text="name"
					item-value="id"
					v-bind="attr"
					v-on="on"
				/>
			</template>

			<!-- date -->
			<bee-date-input
				label=" "
				dense
				hide-details
			/>

			<!-- actions -->
			<template v-slot:item.actions="{ index, item }">
				<!-- edit -->
				<v-btn
					text
					small
					color="edit-color"
					:disabled="submitLoading || loading || Boolean($route.query.preview) || permissionsForUser.userType === userType.dataReader"
					@click="$refs.table.activeEditForRow(index); entry = item.id; editItem; isEditItem = true"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>

				<!-- delete -->
				<v-btn
					text
					small
					color="alert-color"
					:disabled="submitLoading || loading || Boolean($route.query.preview) || permissionsForUser.userType === userType.dataReader"
					@click="deleteDialog = true; id = item.id; cashJournalId = $route.params.id "
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</bee-handy-smart-table>
		<!-- footer -->
		<v-footer v-if="$route.params.id || cashboxes?.length !== 0" app inset color="white" class="py-4 elevation-0 ">
			<v-row>
				<v-col>
					{{$t('CashboxJournal.total-received') + ': ' + Number(receivedTotal) }}
				</v-col>
				<v-col>
					{{$t('CashboxJournal.paid-total') + ': ' + Number(paidTotal) }}
				</v-col>
				<v-col v-if="journal.context === context.postingCashboxBalance">
					{{$t('CashboxJournal.the-difference')+ ': ' + Number(difference) }}
				</v-col>
				<v-col v-if="moment(submitData.date).isBefore(moment()) ">
					<v-icon color="orange">
						mdi-alert-circle-outline
					</v-icon>
					<span class="orange--text">
						{{ $t('CashboxJournal.alert-message-for-date-matching') }}
					</span>
				</v-col>
			</v-row>
		</v-footer >

		<!-- dialog post balance -->
		<v-dialog v-model="transferBalances" width="500">
			<v-card>
				<v-card-title>
					<v-row>
						<v-col class="pb-1">
							<span class="primary--text"> 
								{{$t('relay.close-today')}}
							</span>
						</v-col>

						<v-spacer />

						<v-col class="d-flex justify-end pb-1">
							<v-btn icon dark @click="transferBalances = false">
								<v-icon small color="primary">
									mdi-close
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>

				<v-divider />

				<v-form ref="form" @submit.prevent="postBalance">
					<v-card-text>
						<v-row>
							<v-col cols="12" class="py-0">
								<span>
									{{ $t('relay.from-cashbox') }} {{ transferBalances ? getCashboxById(submitData.cashboxId.id)?.name : null }}
								</span>
							</v-col>
							<v-col
								v-if="permissionsForUser.userType === userType.manager || auth.isAdmin === true"
								cols="4"
								class=" px-1 pb-0"
							>
								<v-text-field
									:label="$t('forms.balance')"
									hide-details="auto"
									outlined
									dense
									disabled
								/>
							</v-col>
							<v-col
								:cols="((permissionsForUser.userType === userType.manager )|| (auth.isAdmin === true) )? 4 : 6"
								class="px-1"
							>
								<v-text-field
									v-model="amount"
									:label="$t('forms.amount')"
									hide-details
									outlined
									dense
									:rules="rules.required"
								/>
							</v-col>
							<v-col
								:cols="((permissionsForUser.userType === userType.manager )|| (auth.isAdmin === true) )? 4 : 6"
								class="px-1"
							>
								<v-text-field
									v-model="remainingAmount"
									:label="$t('forms.remaining-amount')"
									hide-details
									outlined
									dense
								/>
							</v-col>
							<v-col cols="6" class="px-1">
								<p>
									{{$t('relay.to-an-account')}}
								</p>
								<v-autocomplete
									v-model="account"
									:items="accounts.filter(c => c.type === type.sub)"
									item-text="name"
									item-value="id"
									:label="$t('account-id-or-name')"
									outlined
									dense
								/>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions class="justify-end">
						<v-btn
							color="edit-color white--text px-5"
							type="submit"
							:loading="loading"
							:disabled="loading"
						>
							{{$t('save')}}
						</v-btn>

						<v-btn
							color="alert-color white--text px-5"
							:disabled="loading"
							@click="transferBalances = false"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="cashboxJournal"
			:ids="{cashJournalId, id}"
			@on-delete="fetchJournal"
		>
			{{$t('do-you-want-to') + ' ' + $t('delete')}} 
			{{$t('CashboxJournal.restriction')}}
			؟
		</bee-delete-dialog>
		
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialogJournal"
			moduleType="cashboxJournal"
			actionName="deleteJournal"
			:ids="$route.params.id"
			@on-delete="fetchAllJournals"
		>
			{{$t('do-you-want-to') + ' ' + $t('delete')}} 
			{{$t('CashboxJournal.journal')}}
			؟
		</bee-delete-dialog>
	</div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapState } from 'vuex'
import { type, status, context, dateSortMode, userType } from '@/helpers/enums'
import rules from '@/helpers/validation rules'
import ItemsNavigator from '@/components/ItemsNavigator.vue';
// window.addEventListener('hashchange', function() {
// 	console.log('User clicked the browser buttons. Detected using hashchange event.');
// });
// import router from './router';

export default {
	name: 'CashboxJournal',

	components: {
		ItemsNavigator
	},
	data() {
		return {
			dateDialog: moment().format('YYYY-MM-DD'),
			deleteDialog: false,
			deleteDialogJournal: false,
			cashbox: null,
			id: null,
			// helpers
			tableInputs: {},
			isEditItem: false,
			submitLoading: false,
			loading: false,
			
			type,
			transferBalances: false,
			// entries: [],

			// data
			submitData: {
				no: 0,
				date: null,
				cashboxId: null,
			},
			rules,
			dialog: false,
			journalId: null,
			entry: null,
			cashJournalId: null,
			status, 
			context, 
			dateSortMode,
			userType,
			remainingAmount: null,
			amount: 0,
			account: null,
			cashboxesPostBalance: null,
		}
	},

	watch: {
		'$route.params.id'(val) {
			if (val) {
				this.fetchJournal(this.$route.params.id)
			} else {
				this.fetchAllJournals().then((data) => {
					this.fetchJournal(data[data?.length - 1].id)
				})
			}
		},
		isEditItem(val) {
			if (!val) {
				this.$nextTick(() => {
					this.tableInputs.date = this.moment().format('YYYY-MM-DD')
				})
			}
		},
		transferBalances (val) {
			if (val === false) {
				this.$refs.form.reset()
				this.amount = null
				this.remainingAmount = null
				this.account = null
			}
		}
	},

	computed: {
		...mapState({
			accounts: state => state.accounts.accounts,
			cashboxes: state => state.cashboxes.cashboxes,
			journal: state => state.cashJournals.journal,
			journals: state => state.cashJournals.journals,
			permissionsForUser: state => state.auth.permissionsForUser,
			auth: state => state.auth.self,
			configs: state => state.configs.configs
		}),

		entryTable() { 
			if (this.journal?.length !== 0) {
				return this.journal.entries.map(c => ({
					...c,
					credit: c.credit ? c.credit : {},
					debit: c.debit ? c.debit : {},
					date: moment(c.date).format('YYYY-MM-DD')
				}))
			} else {
				return []
			}
		},
		...mapGetters({
			getAccountById: 'accounts/getAccountById',
			getCashboxById: 'cashboxes/getCashboxById'
		}),

		// Received Total (footer) 
		receivedTotal() {
			return this.entryTable.map(c => c.debit.amount).filter(c => c).reduce((x, y) => {
				return x + y;
			}, 0)
		},

		paidTotal() {
			return this.entryTable.map(c => c.credit.amount).filter(c => c).reduce((x, y) => {
				return x + y;
			}, 0)
		},

		difference() {
			return Number(this.receivedTotal) - Number(this.paidTotal)
		},

		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'no',
					value: 'no',
					noInput: true,
					setValueExpr: (val, index) => index + 1
				},
				{
					text: this.$t('headers.account'),
					name: 'accountId',
					value: 'accountId',
					setValueExpr: val => this.getAccountById(val).name,
					inputRules: rules.required,
				},
				{
					text: this.$t('headers.receipt'),
					name: 'revenue',
					value: 'debit.amount',
					setValueExpr: val => (val === null) ? null : val,
					setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'expense' && inputs.expense) {
							return null
						}
						return inputs.revenue
					},
					inputRules: this.tableInputs.expense === null && this.tableInputs.revenue === null ? rules.required : [],
					type: 'number'
				},
				{
					text: this.$t('headers.payment'),
					name: 'expense',
					value: 'credit.amount',
					setValueExpr: val => (val === null) ? null : val,
					setInputExpr: (inputs, activeInput) => {
						if (activeInput === 'revenue' && inputs.revenue) {
							return null
						}
						return inputs.expense
					},
					inputRules: this.tableInputs.revenue === null && this.tableInputs.expense === null ? rules.required : [],
					type: 'number'
				},
				{
					text: this.$t('headers.description'),
					name: 'note',
					value: 'note',
				},
				{
					text: this.$t('headers.ref-doc'),
					name: 'refDoc',
					value: 'refDoc',
				},
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					width: 160,
					type: 'date',
					dateFormat: 'DD-MM-YYYY'
				},
				{
					text: '',
					name: 'actions',
					value: 'action',
					noInput: true,
				},
			];

			if (this.isCurrentJournalPosted) {
				headers.splice(headers.length - 1);
			}

			for (let i = 0; i < headers.length; i++) {
				headers[i].columnClass = headers[i].columnClass ? headers[i].columnClass + ' px-2' : 'px-2';
			}

			return headers;
		},

		isCurrentJournalPosted() {
			return false
		}
	},

	methods: {
		// add new cashboxJournal
		addNewCashboxJournal() {
			console.log(this.submitData.cashboxId)
			if (this.$refs.form.validate) {
				this.loading = true
				this.$store.dispatch('cashJournals/create', {
					date: this.dateDialog,
					cashboxId: this.cashbox ? this.cashbox : this.submitData.cashboxId.id
				}).then((data) => {
					this.$router.replace({
						params: {
							id: data
						}
					})
					this.fetchJournal(this.$route.params.id)
				}).finally(() => {
					this.dialog = false
					this.loading = false
				})
			}
		},

		resetTableInputs() {
			this.$refs.table.resetInputs({
				revenue: null,
				expense: null,
				accountId: null,
				refDoc: null,
				date: null,
				note: null
			})
		},
		// add new entry to table 
		addItem() {
			if (this.$refs.table.validate()) {
				this.loading = true
				this.$store.dispatch('cashboxJournal/create', {
					cashJournalId: this.$route.params.id,
					accountId: this.tableInputs.accountId, 
					debitAmount: this.tableInputs.revenue, 
					creditAmount: this.tableInputs.expense,
					note: this.tableInputs.note,
					date: this.tableInputs.date,
					refDoc: this.tableInputs.refDoc
				}).then(() => {
					this.fetchJournal(this.$route.params.id)
				}).finally(() => {
					this.loading = false
				})
				
				this.$nextTick(() => {
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus()
				})
			}
		},
		// edit entry from table
		editItem() {
			this.loading = true
			this.$store.dispatch('cashboxJournal/update', {	
				id: this.entry,
				cashJournalId: this.$route.params.id,
				accountId: this.tableInputs.accountId,
				bondId: this.tableInputs.refDoc,
				debitAmount: this.tableInputs.revenue, 
				creditAmount: this.tableInputs.expense,
				note: this.tableInputs.note,
				date: this.tableInputs.date,
				refDoc: this.tableInputs.refDoc
			}).then(() => {
				this.fetchJournal(this.$route.params.id)
			}).catch(() => {
				this.loading = false
			})
			this.$refs.table.resetEdit()
			this.resetTableInputs();
			this.isEditItem = false;
			this.$refs.table.resetInputsFocus()
		},
		// delete entry from table
		deleteItem(id) {
			this.loading = true
			this.$refs.table.resetEdit()
			this.isEditItem = false;
			this.$store.dispatch('cashboxJournal/delete', { id: id, cashJournalId: this.$route.params.id }).then(() => {
				this.fetchJournal(this.$route.params.id)
			}).catch(() => {
				this.loading = false
			})
		},

		moment,
		// get all cashJournals
		fetchAllJournals () {
			this.loading = true
			return this.$store.dispatch('cashJournals/fetchAll', {
				context: context.cashbox,
				status: status.notPosted,
				dateSortMode: dateSortMode.ascending,
				cashboxId: JSON.parse(localStorage.userType === userType.casher) ? this.$localStorage.cashboxId : null
			}).then((data) => {
				if (data.length === 0) {
					this.$router.replace({
						name: 'cashbox-journal'
					})
					// this.$router.removeRoute('cashbox-journal')
					this.submitData.no = null
					this.submitData.date = null
					this.submitData.cashboxId = null
				} else {
					this.$router.replace({
						name: 'cashbox-journal',
						params: {
							id: data[data.length - 1].id
						}
					})
				}
			}).finally(() => {
				this.loading = false
			})
		},
		// fetch Journal by id
		fetchJournal(id) {
			this.loading = true
			this.$store.dispatch('cashJournals/fetchById', {
				id: id == null ? this.$route.params.id : id 
			}).then((data) => {
				// if (!this.$route.params.id) {
				this.$router.replace({
					name: 'cashbox-journal',
					params: {
						id: data.id,
					},
					query: {
						preview: data.isPosted
					}
				})
				// }
				this.submitData.date = moment(data.date).locale('ar').format('dddd') + '  ' + moment(data.date).format('DD-MM-YYYY');   
				this.submitData.no = data.cashJournalNo
				this.submitData.cashboxId = this.getCashboxById(data.cashboxId)
				this.submitData.no = data.cashJournalNo
				this.tableInputs.date = this.moment().format('YYYY-MM-DD')
			}).finally(() => {
				this.loading = false
			})
		},

		fetchAccountStatement(configs) {
			this.$store.dispatch('statisticsReports/fetchAccountStatement', { accountId: configs }).then((data) => {
				if (data.sumDifference.amount === 0) {
					this.$store.dispatch('users/seenNotifications', {
						storeId: this.$localStorage.storeId
					})
				}
			})
		},

		post() {
			this.loading = true
			this.$store.dispatch('cashboxJournal/post', {
				id: this.$route.params.id,
			}).then(() => {
				this.$router.replace({
					query: {
						preview: 1
					}
				})
				this.$eventBus.$emit('show-snackbar', false, this.$t('CashboxJournal.journal-saved-successfully'))
				this.$store.dispatch('users/fetchNotifications', {
					storeId: this.$localStorage.storeId,
					isSeen: false
				})
				if (this.configs) {
					this.fetchAccountStatement(this.configs.cashboxesReconciliationId)
				} else {
					this.$store.dispatch('configs/fetchAll').then((configs) => {
						this.fetchAccountStatement(configs.cashboxesReconciliationId)
					})
				}
			}).finally(() => {
				this.loading = false
			})
		},
		postBalance() {
			// if (this.$refs.form.validate()) {
			this.loading = true
			this.$store.dispatch('cashboxJournal/closeDay', {
				id: this.$route.params.id,
				cashboxId: this.submitData.cashboxId.id, 
				accountId: this.account, 
				amount: {
					amount: this.amount,
					currencyId: this.$localStorage.currencyId
				},
				remainingAmount: {
					amount:	this.remainingAmount,
					currencyId: this.$localStorage.currencyId
				} 
			}).then(() => {
				this.fetchAllJournals().then((data) => {
					if (data.length) {
						this.$router.replace({
							params: {
								id: data[data.length - 1].id
							}
						})
					} else {
						this.addNewCashboxJournal()
					}
				})
				this.$eventBus.$emit('show-snackbar', false, this.$t('CashboxJournal.the-journal-has-been-migrated-successfully'))
			}).finally(() => {
				this.loading = false
				this.transferBalances = false
			})
			// }
		},

		unPosted() {
			this.loading = true
			this.$store.dispatch('cashboxJournal/unPosted', { id: this.$route.params.id }).then(() => {
				this.$router.replace({
					query: {
						preview: null
					}
				})
			}).finally(() => {
				this.loading = false
			})
		},
		me () {
			
			// const url = new URL(location);
			// console.log
			// url.searchParams.set('foo', 'bar');
			// const searchParams = new URLSearchParams({ foo: 'bar', baz: 'bar' });
			// url.query = searchParams
			// history.pushState({}, '', url);
		}
	},

	created() {
		// this.cashbox = this.$localStorage.cashboxId;
		this.journalId = this.$route.params.id
		if (this.$route.params.id) {
			this.$store.dispatch('cashJournals/fetchAll', {
				context: !this.$route.params.id ? context.cashbox : null,
				status: status.notPosted,
				dateSortMode: dateSortMode.ascending,
				cashboxId: JSON.parse(localStorage.userType === userType.casher) ? this.$localStorage.cashboxId : null
			}).then(() => {
				this.fetchJournal(this.$route.params.id)
			})
		} else {	
			this.fetchAllJournals().then((data) => {
				if (data.length) {
					this.$router.replace({
						params: {
							id: data[data.length - 1].id
						}
					})
				}
			})
		}
		this.tableInputs.date = moment().format('YYYY-MM-DD')
	},

	metaInfo() {
		return {
			title: this.$t('routes.cashbox-journal'),
			titleTemplate: '%s | POS'
		}
	},
	mounted () {
		this.$el.addEventListener('popstate', this.me());
	}
};
// window.addEventListener('popstate', function(route) {
// 	// import route from ''
	
// });
</script>

<style lang="scss">
#cashbox-journal {
	.bee-handy-smart-table__input-cell {	
		padding: 0 8px;
	}
}
</style>