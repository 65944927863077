export default {
	info: 'معلومات',
	'select-store': 'اختر متجر',
	'are-you-sure-you-want-to-logout': 'هل أنت متأكد من تسجيل الخروج ؟',
	menu: {
		logout: 'تسجيل الخروج'
	},
	pages: {
		stores: 'المتاجر',
		'store-info': 'بيانات المتجر',
		'store-cashboxes': 'صناديق البيع',
		units: 'الواحدات',
		users: 'المستخدمون',
	}
}