<template>
	<v-app>
		<transition name="fade" mode="out-in">
			<template v-if="!loading">
				<transition-group>
					<navbar
						v-if="$route.name !== 'login'"
						key="navbar"
						:event-hub="eventHub"
						@update-main-data="fetchAll"
					>
					</navbar>

					<navigation-drawer
						v-if="$route.name !== 'login' && $route.name !== 'choose-store'"
						v-model="drawer"
						key="navigation-drawer"
					>
					</navigation-drawer>
					
					<v-main
						key="main"
						:class="$route.name === 'statment-item' || $route.name === 'item -inventory' ? 'style-bg' : '' "
					>
						<v-container fluid>
							<router-view
								:event-hub="eventHub"
								@update-main-data="fetchAll"
							/>
						</v-container>
					</v-main>
				</transition-group>
			</template>

			<v-layout v-else id="loader" justify-center align-center>
				<v-flex class="text-center">
					<v-img
						v-if="0"
						src="@/assets/logo.png"
						max-width="160px"
						max-height="160px"
						class="image-loading mx-auto mb-5"
					/>
					<svg
						v-else
						version="1.0"
						xmlns="http://www.w3.org/2000/svg"
						width="160px"
						height="160px"
						viewBox="0 0 430 393"
						preserveAspectRatio="xMidYMid meet"
					>
						<g transform="translate(0,393) scale(0.100000,-0.100000)" stroke="none">
							<path class="part-1" d="M1263 3893 c2 -5 113 -198 247 -430 l243 -423 401 0 401 0 220 382 c121 210 232 403 248 430 l27 48 -895 0 c-493 0 -894 -3 -892 -7z"/>
							<path class="part-2" d="M3354 3683 c-15 -26 -34 -59 -42 -73 -9 -14 -30 -50 -47 -80 -148
							-258 -310 -539 -349 -604 l-47 -79 79 -136 c43 -75 134 -234 203 -353 l124
							-218 513 0 c281 0 512 3 512 6 0 5 -158 282 -190 334 -21 34 -370 638 -398
							690 -14 25 -35 61 -47 80 -24 38 -149 256 -175 305 -9 17 -30 53 -48 80 -17
							28 -32 55 -32 61 0 6 -6 17 -14 23 -12 10 -19 3 -42 -36z"/>
							<path class="part-3" d="M3675 1772 l-390 -2 -192 -333 c-106 -183 -195 -340 -198 -349 -4 -9 85 -174 235 -434 133 -231 245 -422 248 -426 4 -4 163 264 354 595 191 331
							392 679 447 774 l99 172 -106 3 c-59 2 -282 2 -497 0z"/>
							<path class="part-4" d="M1754 876 c-8 -6 -123 -198 -254 -426 l-240 -415 451 -3 c248 -1 650
							-1 893 0 l443 3 -246 426 -245 426 -394 0 c-270 0 -397 -4 -408 -11z"/>
							<path class="part-5" d="M116 1623 c127 -219 223 -384 352 -608 63 -110 140 -243 170 -295 30 -52 71 -122 90 -155 19 -33 54 -94 77 -135 23 -41 60 -105 81 -142 l39 -67 16
							27 c8 15 76 133 151 262 163 283 278 482 307 530 l21 36 -136 234 c-131 227
							-161 278 -234 403 l-34 57 -493 0 -492 0 85 -147z"/>
							<path class="part-6" d="M478 2937 c-245 -425 -445 -773 -443 -775 4 -4 985 -5 988 -1 2 2 92
							158 201 347 l198 342 -238 413 c-131 226 -243 420 -249 429 -9 14 -83 -107
							-457 -755z"/>
							<path class="part-7" d="M1554 2318 c-108 -189 -197 -347 -197 -353 1 -8 63 -117 361 -632
							l36 -63 401 0 401 0 194 337 c107 185 196 342 198 349 2 7 -68 136 -154 286
							-87 150 -176 306 -198 345 l-41 73 -401 0 -402 0 -198 -342z"/>
						</g>
					</svg>
					<p>{{$t('messages.loading-data-please-wait')}}</p>
					<template v-if="0">
						<v-progress-linear
							height="4px"
							indeterminate
							class="rounded-t mx-auto"
							style="max-width: 200px"
						/>
						<v-progress-linear
							height="4px"
							reverse
							indeterminate
							color="success"
							class="rounded-b mx-auto"
							style="max-width: 200px"
						/>
					</template>
				</v-flex>
			</v-layout>
		</transition>

		<v-snackbar
			app
			id="app-snackbar"
            v-model="snackbar"
            :color="
				isAlert
				? 'warning'
				: (isError ? 'red lighten-1' : 'primary lighten-1')
			"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
				<v-btn
					color="white"
					icon
					v-bind="attrs"
					@click="snackbar = false"
				>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
		
	</v-app>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import NavigationDrawer from '@/components/NavigationDrawer.vue'
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';
import { userType } from './helpers/enums';
export default {
	name: 'App',

	components: {
		Navbar,
		NavigationDrawer,
	},

	data() {
		return {
			eventHub: new Vue(),
			loading: false,
			drawer: false,
			snackbar: false,
			isError: false,
			isAlert: false,
			message: '',
			userType
		}
	},

	watch: {
		'$route.name'() {
			this.eventHub.$off();
		}
	},

	computed: {
		...mapGetters({
			getStoreById: 'stores/getStoreById'
		}),
		...mapState({
			stores: state => state.stores.stores,
			permissionsForUser: state => state.auth.permissionsForUser,
			isAdmin: state => state.auth.self.isAdmin,
			authenticated: state => state.auth.authenticated,
			configs: state => state.configs.configs
		})
	},
	methods: {
		fetchMainData() {
			if (this.authenticated && this.$inStoreContext) {
				return Promise.all([
					!this.stores.length ? this.$store.dispatch('stores/fetchAll', {}) : null,
					this.$store.dispatch('configs/fetchAll').then(() => {
						if (this.configs.cashboxesReconciliationId !== null) {
							this.$store.dispatch('statisticsReports/fetchAccountStatement', { 
								accountId: this.configs.cashboxesReconciliationId
							})
						}
					}),
					this.$store.dispatch('units/fetchAll'),
					this.$store.dispatch('cashboxes/fetchAll'),
					this.$store.dispatch('items/fetchAll'),
					this.$store.dispatch('accounts/fetchAll'),
				]).then(() => {
					this.$localStorage.currencyId = this.getStoreById(this.$localStorage.storeId).defaultCurrencyId
				})
			}
		},

		fetchPermissions() {
			return this.$store.dispatch('auth/fetchPermissionsForUserLogin', { storeId: this.$localStorage.storeId }).then((data) => {
				this.$localStorage.cashboxId = data.cashboxId;
				localStorage.setItem('userType', data.userType)
			})
		},

		fetchAll() {
			this.loading = true;
			Promise.all([
				this.fetchMainData(),
				this.authenticated && this.$inStoreContext && !this.isAdmin ? this.fetchPermissions() : null 
			]).finally(() => { this.loading = false });
		}
	},

	created() {
		this.$eventBus.$on('show-snackbar', (state, message) => {
			this.snackbar = true;
			if (typeof state === 'string') {
				this.isError = false;
				this.isAlert = true;
				this.message = state;
			} else {
				this.isError = state;
				this.isAlert = false;
				this.message = message;
			}
		});
		if (this.authenticated && this.$inStoreContext) {
			this.fetchAll();
		}
	},

	// meta info
	metaInfo: {
		title: 'POS'
	},
};
</script>

<style lang="scss">
@import "~@mdi/font/css/materialdesignicons.css";
@import "./scss/main.scss";

.rounded {
	&-large {
		border-radius: 15px !important;
	}
	&-medium {
		border-radius: 11px !important;
	}
	&-small {
		border-radius: 9px !important;
	}
}

.shaped {
	&-all {
		border-radius: 24px 0 !important;
	}
	&-top {
		border-top-left-radius: 24px !important;
	}
	&-bottom {
		border-bottom-right-radius: 24px !important;
	}
}

// emerald logo
$list: 1 5, 2 6, 3 1, 4 2, 5 3, 6 4;
@each $item, $order in $list {
	.part-#{$item} {
		opacity: 0;
		animation: fadeIn .8s cubic-bezier(.46,.45,.39,.95) #{$order * 150}ms infinite forwards;
	}
	@keyframes fadeIn {
		0% {
			opacity: .4;
		}
		100% {
			opacity: 1;
		}
	}
}

$greenList: 1, 3, 4, 5, 6, 7;
@each $item in $greenList {
	.part-#{$item} {
		fill: #009a66;
	}
}
.part-2 {
	fill: #001e66
}

// transition
#loader {
	.image-loading {
		// animation: plus-rotate 2s cubic-bezier(.74,.15,.15,.74) infinite forwards;
		// animation: plus-rotate 2s cubic-bezier(.59,.28,.28,.59) infinite forwards;
		animation: plus-rotate 2.5s cubic-bezier(.95,-0.34,0,1.39) infinite forwards;
		@keyframes plus-rotate {
			0% {
				opacity: 1;
				transform: rotate(0);
			}
			50% {
				opacity: 0.5;
				transform: rotate(180deg);
			}
			100% {
				opacity: 1;
				transform: rotate(360deg);
			}
		}
	}
	.v-progress-linear__indeterminate {
		// animation-timing-function: cubic-bezier(.95,0,0,.95);
	}
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

// border radius overrides
#app {
	* {
		font-family: 'Cairo', sans-serif;
	}
	.v-snack__wrapper {
		border-radius: 11px;
	}
	.v-btn:not(.v-btn--fab, .v-btn--icon), .v-list {
		border-radius: 10px;	
	}
	.v-item-group {
		border-radius: 12px;	
	}
	.v-menu__content {
		border-radius: 10px;	
	}
	.v-data-table, .v-data-table__wrapper, .v-data-table-header {
		border-radius: 15px;
	}
	.v-treeview-node {
		border-radius: 5px;
		overflow-y: hidden;
	}
	.v-input {
		border-radius: 12px;
	}

	// v-input overrides
	.v-input {
		input {
			// padding: 0;
		}
		[dir=rtl] & .v-messages__message {
			font-size: 12px;
		}
	}
	.v-label:not(.v-label--active) {
		transform: translateY(-4px);
	}
	.v-text-field .v-input__control .v-input__slot {
		min-height: auto !important;
	}
	.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo).v-text-field--outlined .v-input__append-inner {
		margin-top: 4px;
	}
	.v-text-field.v-text-field--enclosed .v-text-field__details {
		margin-bottom: 0;
	}
	.v-input--dense > .v-input__control > .v-input__slot {
		margin-bottom: 2px;
	}

	// FIXME remove margin from bottom of inputs in bee-handy-table
	.bee-handy-smart-table .mb-4 {
		margin-bottom: 1px !important;
	}
}

*::-webkit-scrollbar{
    height: 5px;
    width: 5px;
	background-color: #f8f8f8;
}
*::-webkit-scrollbar-thumb{
    background-color: #888;
    border-radius: 10px;
}
.style-bg {
	height: 100vh;
	background: #f5f5f7 ;
}
.text-small {
	font-size: 0.80rem;
	line-height: 0.95rem;
}
</style>