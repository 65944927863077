export default {
	home: 'Home',
	'items-tree': 'Items tree',
	'accounts-tree': 'Accounts tree',
	stores: 'Stores',
	'all-invoices': 'All invoice',
	'sale-invoice': 'Sale invoice',
	'sales-invoice-returned': 'Sales invoice returned',
	'pruchase-invoice': 'Purchase invoice',
	'store-cashboxes': 'Cashboxes',
	reports: 'Reports',
	statment: 'Statment',
	'item-inventory': 'Item Inventory',
	units: 'Units',
	user: 'User',
	'choose-store': 'Choose store',
	'account-statement': 'Account statement',
	'all-journals': 'All cash journals',
	'cashbox-journal': 'Cashbox journal',

	'journals-items': 'Journals items',
	'journal-item': 'Journal item',
	'all-journals-items': 'All journal',
	'items-journal-table': 'Items journal table',
	users: 'Users',
	'setting-store': 'Setting store',
	'store-info': 'Store info',
	'switch-store': 'Switch store',
	'store-settings': 'Store settings',
	'are-you-sure-you-want-to-logout': 'Are you sure you want to log out?',
	'daily-journal': 'Daily journal',
	'funds-journal-table': 'Funds journal table'
}