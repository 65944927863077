import HomeView from '../pages/Home.vue'

// item
import ItemsTree from '../pages/items/ItemsTree.vue'
import Item from '../pages/items/Item.vue'

// account
import AccountsTree from '../pages/accounts/AccountsTree.vue'
import Account from '../pages/accounts/Account.vue'

// stores
import Stores from '../pages/stores/Stores.vue'
import StoreCashboxes from '../pages/stores/StoreCashboxes.vue'
import StoreInfo from '../pages/stores/StoreInfo.vue'

// invoices
import AllInvoices from '../pages/invoices/AllInvoices.vue'
import SaleInvoice from '../pages/invoices/SaleInvoice.vue'
import PruchaseInvocies from '../pages/invoices/PruchaseInvocies.vue'
import SalesReturns from '../pages/invoices/SalesReturns.vue'

import CashboxJournal from '../pages/journals/CashboxJournal.vue'

// statistics
import ItemInventory from '../pages/statistics/ItemsInventory.vue'
import StatementItem from '../pages/statistics/ItemStatement.vue'
import InventoryMatching from '../pages/statistics/InventoryMatching.vue'

// Accounting
import AccountStatement from '../pages/journals/AccountStatement.vue'
import Journals from '../pages/journals/Journals.vue'
import DetailedAccountStatement from '../pages/journals/DetailedAccountStatement.vue'
import DailyJournal from '../pages/journals/DailyJournal'
import FundsJournalTable from '../pages/journals/FundsJournalTable'

// journal items
import JournalItem from '../pages/journals-items/JournalItem.vue'
import AllJournals from '../pages/journals-items/AllJournals.vue'
import ItemsJournalTable from '../pages/journals-items/ItemsJournalTable.vue'

import Login from '../pages/Login.vue'

// users
import Users from '../pages/users/Users.vue'
import Units from '../pages/Units.vue'

import ChooseStore from '../pages/ChooseStore.vue'

// reports
import salesReport from '../pages/reports/SalesReport.vue'
import Reports from '../pages/reports/Reports.vue'

export default [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		i18nTitleKey: 'home',
		isAddEditPage: false
	},
	{
		path: '/items-tree',
		name: 'items-tree',
		component: ItemsTree,
		i18nTitleKey: 'items-tree',
		isAddEditPage: false,
		children: [
			{
				path: '/items-tree/item/:id?',
				name: 'item',
				component: Item,
				i18nTitleKey: 'item',
				isAddEditPage: true
			},
		]
	},
	{
		path: '/accounts-tree',
		name: 'accounts-tree',
		component: AccountsTree,
		i18nTitleKey: 'accounts-tree',
		isAddEditPage: false,
		children: [
			{
				path: '/accounts-tree/account/:id?',
				name: 'account',
				component: Account,
				i18nTitleKey: 'account',
				isAddEditPage: true
			},
		]
	},
	{
		path: '/stores',
		name: 'stores',
		component: Stores,
		i18nTitleKey: 'stores',
		isAddEditPage: false,
	},
	// {
	// 	path: '/store-information',
	// 	name: 'store-info-add',
	// 	component: StoreInfo,
	// 	i18nTitleKey: 'store-info',
	// 	isAddEditPage: false,
	// },
	{
		path: '/store-info/:id?',
		name: 'store-info',
		component: StoreInfo,
		i18nTitleKey: 'store-info',
		isAddEditPage: false,
	},
	{
		path: '/store-cashboxes',
		name: 'store-cashboxes',
		component: StoreCashboxes,
		i18nTitleKey: 'store-cashboxes',
		isAddEditPage: false,
	},
	{
		path: '/all-invoices',
		name: 'all-invoices',
		component: AllInvoices,
		i18nTitleKey: 'all-invoices',
		isAddEditPage: false,
	},
	{
		path: '/sale-invoice/:id?',
		name: 'sale-invoice',
		component: SaleInvoice,
		i18nTitleKey: 'sale-invoice',
		isAddEditPage: false,
	},
	{
		path: '/pruchase-invoice/:id?',
		name: 'pruchase-invoice',
		component: PruchaseInvocies,
		i18nTitleKey: 'pruchase-invoice',
		isAddEditPage: false
	},
	{
		path: '/sale-refund-invoice/:id?',
		name: 'sales-invoice-returned',
		component: SalesReturns,
		i18nTitleKey: 'sales-invoice-returned',
		isAddEditPage: false
	},
	{
		path: '/cashbox-journal/:id?',
		name: 'cashbox-journal',
		component: CashboxJournal,
		i18nTitleKey: 'cashbox-journal',
	},
	{
		path: '/item-inventory',
		name: 'item-inventory',
		component: ItemInventory,
		i18nTitleKey: 'item-inventory',
		isAddEditPage: false
	},
	{
		path: '/inventory-matching',
		name: 'inventory-matching',
		component: InventoryMatching,
		i18nTitleKey: 'inventory-matching',
		isAddEditPage: false
	},
	{
		path: '/statement-item',
		name: 'statement-item',
		component: StatementItem,
		i18nTitleKey: 'statement-item',
		isAddEditPage: false
	},
	// Accounting
	{	
		path: '/account-statement',
		name: 'account-statement',
		component: AccountStatement,
		i18nTitleKey: 'account-statement',
		isAddEditPage: false
	},
	{	
		path: '/detailed-account-statement',
		name: 'detailed-account-statement',
		component: DetailedAccountStatement,
		i18nTitleKey: 'detailed-account-statement',
		isAddEditPage: false
	},
	{
		path: '/journals',
		name: 'journals',
		component: Journals,
		i18nTitleKey: 'journals',
		isAddEditPage: false
	},
	
	{
		path: '/funds-journal-table',
		name: 'funds-journal-table',
		component: FundsJournalTable,
		i18nTitleKey: 'funds-journal-table',
		isAddEditPage: false
	},
	{
		path: '/daily-journal',
		name: 'daily-journal',
		component: DailyJournal,
		i18nTitleKey: 'daily-journal',
		isAddEditPage: false
	},

	{
		path: '/journal-item/:id?',
		name: 'journal-item',
		component: JournalItem,
		i18nTitleKey: 'journal-item',
		isAddEditPage: false
	},
	{
		path: '/all-journals',
		name: 'all-journals',
		component: AllJournals,
		i18nTitleKey: 'all-journals',
		isAddEditPage: false
	},
	{
		path: '/items-journal-table/:id?',
		name: 'items-journal-table',
		component: ItemsJournalTable,
		i18nTitleKey: 'items-journal-table',
		isAddEditPage: false
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		i18nTitleKey: 'login',
		isAddEditPage: false

	},
	{
		path: '/users',
		name: 'users',
		component: Users,
		i18nTitleKey: 'users',
		isAddEditPage: false
	},
	{
		path: '/units',
		name: 'units',
		component: Units,
		i18nTitleKey: 'units',
		isAddEditPage: false
	},
	{
		path: '/choose-store',
		name: 'choose-store',
		component: ChooseStore,
		i18nTitleKey: 'choose-store',
		isAddEditPage: false,
	},
	{
		path: '*',
		redirect: '/'
	},
	// report 
	{
		path: '/sales-report',
		name: 'sales-report',
		component: salesReport,
		i18nTitleKey: 'sales-report',
		isAddEditPage: false,
	},
	{
		path: '/reports',
		name: 'reports',
		component: Reports,
		i18nTitleKey: 'reports',
		isAddEditPage: false
	}
]