var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bgColor rounded-t-xl",staticStyle:{"height":"90vh"}},[_c('v-row',{staticClass:"px-3",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('bee-date-input',{attrs:{"label":_vm.$t('forms.the-date-of-the-last-match'),"input-format-order":"DD-MM-YYYY","prepend-icon":"mdi-calendar","dense":"","hide-details":"","disabled":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-spacer'),_c('v-col',{staticClass:"px-1",attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"edit-color white--text","disabled":_vm.permissionsForUser.userType === _vm.userType.dataReader},on:{"click":function($event){_vm.$route.query.preview == 1 ? _vm.$router.replace({
						query: {
							materialId: _vm.$route.query.material,
							withParents: _vm.$route.query.withParents,
							itemsWithBalancesOnly: _vm.$route.query.itemsWithBalancesOnly,
						}
					}) : _vm.match()}}},[_vm._v(" "+_vm._s(_vm.$route.query.preview === 1 ? _vm.$t('edit') : _vm.$t('InventoryMatching.matching'))+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":_vm.$route.query.preview ? 'blue-dark white--text' : 'alert-color white--text',"to":{
						name: 'item-inventory',
						query: {
							materialId: _vm.$route.query.material,
							withParents: _vm.$route.query.withParents,
							itemsWithBalancesOnly: _vm.$route.query.itemsWithBalancesOnly,
						}
					}}},[_vm._v(" "+_vm._s(_vm.$route.query.preview ? _vm.$t('back') : _vm.$t('cancel'))+" ")])],1)],1),_c('bee-handy-smart-table',{ref:"table",staticClass:"mt-2 px-3",attrs:{"headers":_vm.headers,"items":_vm.materials,"editable-rows":_vm.isEditItem,"hide-default-footer":"","zebra":"","loading":_vm.loading,"dense":"","row-highlight-expression":item => item.quantity.quantity !== item.actualQuantity,"row-highlight-expression-class":"edit-color lighten-5","pagination-on-scroll":""},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.editItem()}},model:{value:(_vm.tableInputs),callback:function ($$v) {_vm.tableInputs=$$v},expression:"tableInputs"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }