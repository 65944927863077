<template>
	<span :class="`${color}--text`">{{formattedValue || '-'}}</span>
</template>
<script>
import currency from 'currency.js';
export default {
	name: 'BeeMaterialAmountLabel',
	props: ['value', 'emptyState', 'showZerosValues'],
	computed: {
		localValue() {
			return this.value ? this.value : { quantity: null, unit_size_id: null };
		},
		color() {
			return this.localValue.quantity > 0 ? 'green' : 'red';
		},
		formattedValue() {
			if (this.localValue.quantity === 0 && this.showZerosValues) {
				return currency(this.localValue.quantity, {
					separator: ',',
					decimal: '.',
					symbol: '',
				}).format();
			}

			return this.localValue.quantity || this.emptyState;
		},
	},
};
</script>