import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		accounts: [],
		accountsTree: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/accounts').then(({ data }) => {
				commit(types.STORE_ACCOUNTS, data);
				return data;
			})
		},

		fetchById({ commit }, { id }) {
			return axios.get(`/accounts/${id}`).then(({ data }) => data)
		},

		fetchAllAsTree({ commit }) {
			return axios.get('/accounts/tree').then(({ data }) => {
				commit(types.STORE_ACCOUNTS_TREE, data);
				return data;
			})
		},

		suggestNewChildNo({ commit }, { id }) {
			return axios.get(`/accounts/${id}/suggestNewChildNo`).then(({ data }) => data)
		},

		create({ commit }, { name, innerAccountingNo, type, finalType, isHidden, notes, parentAccountId, contactInfo, customPrices }) {
			return axios.post('/accounts', {
				name, innerAccountingNo, type, finalType, isHidden, notes, parentAccountId, contactInfo, customPrices
			}).then(({ data }) => data)
		},

		update({ commit }, { id, name, innerAccountingNo, type, finalType, isHidden, notes, parentAccountId, contactInfo, customPrices }) {
			return axios.put(`/accounts/${id}`, {
				id, name, innerAccountingNo, type, finalType, isHidden, notes, parentAccountId, contactInfo, customPrices
			})
		},

		delete({ commit }, { id }) {
			return axios.delete(`/accounts/${id}`)
		},

		reset({ commit }) {
			return commit(types.RESET)
		}
	},

	mutations: {
		[types.STORE_ACCOUNTS](state, accounts) {
			state.accounts = accounts;
		},
		[types.STORE_ACCOUNTS_TREE](state, accountsTree) {
			state.accountsTree = accountsTree;
		},
		[types.RESET](state) {
			state.accountsTree = [];
			state.accounts = []
		}
	},

	getters: {
		getAccountById: state => id => state.accounts.find(c => c.id === id)
	}
}