<template>
	<v-app-bar
		app
		color="white"
		height="45"
		elevation="0"
	>
		<!-- title -->
		<div class="d-flex flex-grow-1">
			<h1 class="text-md-h5 text-h6 primary--text font-weight-medium text-capitalize mx-2">
				{{ $route.name !== 'login' ?  getRouteTitle() : '' }}
			</h1>

			<template v-if="$route.name === 'sale-invoice'">
				<v-spacer class="mx-lg-12"/>
				<invoices-tabs
					v-show="$vuetify.breakpoint.smAndUp"
					:event-hub="eventHub"
					:disable="permissionsForUser.userType === userType.dataReader"
				/>
			</template>
			<!-- Emerald POS UI-V 1.10.0 -->
		</div>
		
		<v-spacer/>

		<!-- settings -->
		<v-menu
			v-if="($inStoreContext && permissionsForUser.userType !== userType.casher) || auth.isAdmin"
			offset-y
		>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					icon
					small
					class="me-1"
				>
					<v-icon color="blue-dark" size="20"> mdi-cog </v-icon>
				</v-btn>
			</template>
			<v-list dense nav>
				<template v-for="(page, index) in pages.filter(page => page.show)">
					<v-list-item
						:key="'item' + index"
						:to="{ name: page.target }"
						:disabled="page.disabled"
						exact-path
						active-class="primary--text"
						@click="page.target === 'store-info' ? $router.replace({
							params: {
								id: $localStorage.storeId
							}
						}) : null"
					>		
						<v-list-item-icon>
							<v-icon>
								{{page.icon}}
							</v-icon>
						</v-list-item-icon>
						<v-list-item-title class="text-capitalize">
							{{page.title}}
						</v-list-item-title>
					</v-list-item>
					<v-divider v-if="page.divider && auth.isAdmin" :key="'divider-' + index"/>
				</template>
			</v-list>
		</v-menu>

		<!-- store -->
		<v-menu v-if="auth.isAdmin" offset-y max-height="60vh">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="blue-dark"
					v-bind="attrs"
					v-on="on"
					text
					height="30"
					class="px-2 me-1"
				>
					<v-icon v-if="$vuetify.breakpoint.lgAndUp" size="20"> 
						mdi-warehouse 
					</v-icon>
					<span class="mx-1"> 
						{{ $inStoreContext ? getStoreById($localStorage.storeId)?.name : $t('Navbar.select-store') }}
					</span>
					<v-icon small>mdi-chevron-down</v-icon>
				</v-btn>
			</template>
			<v-list dense nav>
				<v-list-item
					v-for="(store, index) in stores" :key="index"
					:class="{'primary white--text': $localStorage.storeId === store.id}"
					@click="updateStore(store.id)"
				>
					<v-list-item-icon v-if="store.logo === null" class="me-3">
						<v-icon :color="$localStorage.storeId === store.id ? 'primary' : null">
							mdi-warehouse 
						</v-icon>
					</v-list-item-icon>

					<v-list-item-avatar v-else>
						<v-img :src="store.logo" />
					</v-list-item-avatar>
					
					<v-list-item-title class="text-body-2">
						{{store.name}}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<v-btn 
			v-if="!auth.isAdmin"
			color="blue-dark"
			height="30"
			min-width="10"
			class="px-2 me-1"
			text
			@click="$localStorage.storeId = null; $router.push({ name: 'choose-store' })"
		>
			<v-icon size="20"> mdi-warehouse </v-icon>
			<span class="text-body-2">
				{{ $inStoreContext ? getStoreById($localStorage.storeId)?.name : $t('Navbar.select-store') }}
			</span>
		</v-btn>

		<!-- user -->
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					color="blue-dark"
					v-bind="attrs"
					v-on="on"
					text
					height="30"
					min-width="10"
					class="px-2"
				>
					<v-icon size="20"> mdi-account-circle </v-icon>
					<template v-if="$vuetify.breakpoint.lgAndUp">
						<span class="text-body-2 mx-1"> {{auth.name}} </span>
						<v-icon small>mdi-chevron-down</v-icon>
					</template>
				</v-btn>
			</template>
			<v-list dense nav>
				<!-- logout -->
				<v-list-item
					exact-path
					active-class="white--text"
					@click="dialogLogout = true"
				>
					<v-list-item-icon>
						<v-icon>
							mdi-logout
						</v-icon>
					</v-list-item-icon>
					<v-list-item-title class="text-capitalize">
						{{$t('Navbar.menu.logout')}}
					</v-list-item-title>
				</v-list-item>

				<!-- change password -->
				<v-list-item
					exact-path
					active-class="white--text"
					@click="dialog= true"
				>
					<v-list-item-icon>
						<v-icon>
							mdi-lock-reset
						</v-icon>
					</v-list-item-icon>
					<v-list-item-title class="text-capitalize">
						{{$t('edit-the-password')}}
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<!-- dialogLogout -->
		<v-dialog v-model="dialogLogout" width="400px">
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span class="primary--text"> 
						{{$t('Navbar.menu.logout')}}
					</span>
					<v-spacer/>
					<v-btn
						icon
						dark
						class="d-flex justify-end"
					>	
						<v-icon small color="primary" @click="dialogLogout = false">
							mdi-close
						</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider />

				<v-card-text class="pt-3">
					<p class="mb-0"> {{$t('Navbar.are-you-sure-you-want-to-logout')}} </p>
				</v-card-text>

				<v-card-actions>
					<v-btn color="primary" @click="logout()">
						{{$t('yes')}}
					</v-btn>
					<v-btn text @click="dialogLogout = false">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- dialog change password -->
		<v-dialog v-model="dialog" width="400px">
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span class="primary--text"> 
						{{$t('Users.change-password')}}
					</span>
					<v-spacer/>
					<v-btn
						icon
						dark
						@click="dialog = false; reset()"
						class="d-flex justify-end"
					>	
						<v-icon small color="primary">
							mdi-close
						</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider />
				
				<v-form @submit.prevent="selfChangePassword">
					<v-card-text>
						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model="oldPassword"
									:type="showPassword ? 'text' : 'password'"
									:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showPassword = !showPassword"
									:label="$t('forms.oldPassword')"
									outlined
									dense
									hide-details="auto"
								/>					
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="newPassword"
									:type="showConfirmNewPassword ? 'text' : 'password'"
									:append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showConfirmNewPassword = !showConfirmNewPassword"
									:label="$t('forms.newPassword')"
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="confirmNewPassword"
									:type="showConfirmPassword ? 'text' : 'password'"
									:append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showConfirmPassword = !showConfirmPassword"
									:label="$t('forms.confirmNewPassword')"
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>
							
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn text color="primary" type="submit" :loading="loading" :disabled="loading">
							{{$t('save')}}
						</v-btn>

						<v-btn text color="alert-color" @click="dialog = false, reset()" :disabled="loading">
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
	</v-app-bar>
</template>

<script>
import routes from '@/router/routes';
import { mapGetters, mapState } from 'vuex';
import InvoicesTabs from './InvoicesTabs.vue';
import { userType } from '@/helpers/enums'
export default {
	name: 'Navbar',
	
	props: {
		eventHub: { type: Object }
	},

	components: {
		InvoicesTabs	
	},

	data: () => ({
		userType,
		dialogLogout: false,
		dialog: false,
		showConfirmNewPassword: false,
		confirmNewPassword: null,
		showPassword: false,
		oldPassword: null,
		newPassword: null,
		showConfirmPassword: false,
		loading: false
	}),

	computed: {
		...mapState({
			isAdmin: state => state.auth.self.isAdmin,
			cashboxes: state => state.cashboxes.cashboxes,
			permissionsForUser: state => state.auth.permissionsForUser,
			auth: state => state.auth.self,
			stores: state => state.stores.stores.filter(c => c.isArchived === false)
		}),

		...mapGetters({
			getCashboxById: 'cashboxes/getCashboxById',
			getStoreById: 'stores/getStoreById'
		}),
		
		pages() {
			return [		
				{
					title: this.$t('Navbar.pages.stores'),
					target: 'stores',
					icon: 'mdi-store',
					disabled: false,
					show: !this.$inStoreContext && this.auth.isAdmin,
					group: 'settings-system',
					divider: false,
				},
				{
					title: this.$t('Navbar.pages.store-info'),
					target: 'store-info',
					icon: 'mdi-store-edit',
					disabled: false,
					show: this.$inStoreContext,
					group: 'settings-system',
					divider: false
				},
				{
					title: this.$t('Navbar.pages.store-cashboxes'),
					target: 'store-cashboxes',
					icon: 'mdi-cash-register',
					disabled: false,
					show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
					group: 'settings-system',
					divider: false
				},
				{
					title: this.$t('Navbar.pages.units'),
					target: 'units',
					icon: 'mdi-weight-gram',
					disabled: false,
					show: this.$inStoreContext && (this.auth.isAdmin),
					group: 'settings-system',
					divider: true
				},
				{
					title: this.$t('Navbar.pages.users'),
					target: 'users',
					icon: 'mdi-account-group',
					disabled: false,
					show: !this.$inStoreContext && this.auth.isAdmin,
					group: 'settings-system',
					divider: false
				},	
				{
					title: this.$t('Navbar.pages.stores'),
					target: 'stores',
					icon: 'mdi-store',
					disabled: false,
					show: this.$inStoreContext && this.auth.isAdmin,
					group: 'settings-system',
					divider: false
				},
				{
					title: this.$t('Navbar.pages.users'),
					target: 'users',
					icon: 'mdi-account-group',
					disabled: false,
					show: this.$inStoreContext && this.auth.isAdmin,
					group: 'settings-system',
					divider: false
				},
			]
		}
	},

	methods: {
		getRouteTitle() {
			let route = routes.find(c => c.name === this.$route.name);
			if (!route && this.$route.matched.length > 1) {
				const parentName = this.$router.getRoutes().find(c => c.name === this.$route.name).parent.name;
				const parent = routes.find(c => c.name === parentName);
				route = parent.children.find(c => c.name === this.$route.name);
			}
			
			if (route.isAddEditPage) {
				let action;
				if (this.$route.query.preview) {
					action = this.$t('Navbar.info');
					return (
						this.$i18n.locale === 'ar'
							? action + ' ' + this.$t(`routes.${route.i18nTitleKey}`)
							: this.$t(`routes.${route.i18nTitleKey}`) + ' ' + action
					)
				} else {
					action = (this.$route.params.id ? this.$t('edit') : this.$t('add')) + ' ';
					action =
						(this.$route.query.addSub ? this.$t('add-sub') : action) +
						(this.$i18n.locale === 'ar' ? '' : ' ');
					return action + this.$t(`routes.${route.i18nTitleKey}`);
				}
			}
			return this.$t(`routes.${route.i18nTitleKey}`);
		},
			
		logout() {
			this.$store.dispatch('auth/reset');
			this.$store.dispatch('auth/logout');
			this.$router.replace({ name: 'login' }).then(() => {
				this.$store.dispatch('stores/reset');
				this.$localStorage.storeId = null;
				this.$localStorage.currencyId = null;
			});
		},

		selfChangePassword() {
			this.loading = true
			this.$store.dispatch('users/resetPassword', {
				userId: this.auth.id,
				oldPassword: this.oldPassword,
				newPassword: this.newPassword,
				confirmNewPassword: this.confirmNewPassword
			}).then(() => {
				this.$eventBus.$emit('show-snackbar', false, this.$t('Users.password-has-been-modified-successfully'));
			}).finally(() => {
				this.loading = false
				this.dialog = false
				this.reset()
			})
		},

		reset() {
			this.oldPassword = null
			this.newPassword = null 
			this.confirmNewPassword = null
		},

		updateStore(storeId) {
			this.$localStorage.storeId = storeId;
			window.axios.defaults.headers.common['x-store'] = storeId;
			this.$store.dispatch('reset');

			this.$emit('update-main-data');

			if (this.$route.name !== 'home') {
				this.$router.replace({ name: 'home' });
			}
		}
	}
};
</script>

<style>
</style>