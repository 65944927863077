<template>
	<div class="bgColor rounded-t-xl" style="height: 87vh">
		<!-- add button -->
		<div class="d-flex mx-3">
			<v-spacer/>
			<v-btn
				height="35"
				color="primary"
				@click="open"
				class="px-4 my-2"
				:disabled="permissionsForUser.userType === userType.dataReader"
			>
				<v-icon size="23" class="me-1">mdi-store-plus</v-icon>
				{{$t('add')}}
			</v-btn>
			<v-btn
				v-if="0"
				height="35"
				small
				color="edit-color white--text"
				@click="transferBalances = true"
				class="px-4 my-2 ms-2"
			>
				<v-icon size="23" class="me-1"> mdi-account-convert </v-icon>
				{{$t('relay.transfer-of-fund-balances')}}
			</v-btn>
		</div>

		<!-- add/edit dialog -->
		<v-dialog width="600" v-model="dialog">
			<v-form v-model="form" ref="form" @submit.prevent="submit">
				<v-card>
					<v-card-title class="py-1">
						<v-spacer/>
						<span class="ms-8 text-uppercase primary--text">
							{{ (cashboxId ? $t('edit') : $t('add')) + ' ' + $t('StoreCashboxes.cashbox') }}
						</span>

						<v-spacer/>

						<v-btn @click="dialog = false" icon dark>
							<v-icon color="primary">mdi-close</v-icon>
						</v-btn>
					</v-card-title>

					<v-divider class="mx-4" />

					<v-card-text class="pt-4 pb-2">
						<v-row class="align-center mt-0">
							<!-- name -->
							<v-col md="6" sm="6" cols="12" class="pt-0 pb-1">
								<v-text-field
									v-model="dialogData.name"
									:label="$t('forms.name')"
									outlined
									dense
									:rules="rules.required"
									:loading="submitLoading"
								></v-text-field>
							</v-col>

							<!-- no -->
							<v-col md="6" sm="6" cols="12" class="pt-0 pb-1">
								<v-text-field
									v-model.number="dialogData.no"
									:label="$t('forms.no')"
									outlined
									dense
									:loading="submitLoading"
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>

							<!-- accountId -->
							<v-col md="6" sm="6" cols="12" class="pt-0 pb-1">
								<v-autocomplete
									v-model="dialogData.accountId"
									:label="$t('forms.account')"
									:items="accounts.filter(c => c.type === type.sub)"
									:item-text="(item) => `${item.name}`"
									item-value="id"
									outlined
									dense
									:rules="rules.required"
									:loading="submitLoading"
								></v-autocomplete>
							</v-col>

							<!-- users -->
							<v-col md="6" sm="6" cols="12" class="pt-0 pb-1">
								<v-text-field
									v-model="dialogData.users"
									:label="$t('forms.users')"
									outlined
									dense
									disabled
								></v-text-field>
							</v-col>
							
							<!-- status -->
							<v-col md="6" sm="6" cols="12" class="pt-0 pb-1">
								<v-switch
									v-model="dialogData.status"
									:label="dialogData.status ? $t('StoreCashboxes.active') : $t('StoreCashboxes.inactive')"
									dense
									flat
									jid
									class="ma-0"
								></v-switch>
							</v-col>
							
							<!-- notes -->
							<v-col cols="12" class="pt-0 pb-1">
								<v-textarea
									v-model="dialogData.notes"
									:label="$t('forms.notes')"
									outlined
									dense
									rows="2"
									auto-grow
									no-resize
									:loading="submitLoading"
								></v-textarea>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer/>
						<v-btn
							type="submit"
							color="primary"
							large
							height="34"
							:loading="submitLoading"
							:disabled="submitLoading || !form "
						>
							{{$t('save')}}
						</v-btn>
						<v-btn
							large
							height="34"
							@click="dialog = false"
							:disabled="submitLoading"
							color="alert-color white--text"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType= "cashboxes"
			:ids="cashboxIdDelete"
			@on-delete="fetchAll"
		>
			{{$t('do-you-want-to') + $t('StoreCashboxes.delete') + getCashboxById(cashboxIdDelete)?.name + '؟' }}
		</bee-delete-dialog>

		<!-- table -->
		<bee-handy-table
			:headers="headers"
			:items="cashboxes"
			:items-per-page="10"
			zebra
			fixed-header
			pagination-on-scroll
			height="auto"
			class="mx-3"
			:loading="loading"
		>
			<!-- status -->
			<template v-slot:item.status="{ value }">
				<v-chip :color="value ? 'success' : 'alert-color white--text'" small>
					{{value ? $t('StoreCashboxes.active') : $t('StoreCashboxes.inactive')}}
				</v-chip>
			</template>

			<template v-slot:item.archive="{ item }">
				<v-switch 
					v-model="item.isArchived"
					@click="cashboxId = item.id; item.isArchived === true ? archive() : retrieve() "
				>
				</v-switch>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex">

					<!-- post -->
					<tooltip :left="false" :top="true" :text="$t('posted')">
						<v-btn
							color="primary"
							small
							text
							@click="cashboxesPostBalance = item.id; transferBalances = true"
							:disabled="permissionsForUser.userType === userType.dataReader"
						>
							<v-icon>mdi-account-convert</v-icon>
						</v-btn>
					</tooltip>

					<!-- edit -->
					<tooltip :left="false" :top="true" :text="$t('edit')">
						<v-btn
							color="edit-color"
							small
							text
							@click="cashboxId = item.id; dialog = true"
							:disabled="permissionsForUser.userType === userType.dataReader"
						>
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</tooltip>

					<!-- delete -->
					<tooltip :left="false" :top="true" :text="$t('delete')">
						<v-btn
							color="alert-color"
							small
							text
							@click="cashboxIdDelete = item.id; deleteDialog = true"
							:disabled="permissionsForUser.userType === userType.dataReader"
						>
							<v-icon>mdi-delete</v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>

		</bee-handy-table>
		<!-- dialog post balance -->
		<v-dialog v-model="transferBalances" width="500">
			<v-card>
				<v-card-title>
					<v-row>
						<v-col class="pb-1">
							<span class="primary--text"> 
								{{$t('relay.transfer-of-fund-balances')}}
							</span>
						</v-col>

						<v-spacer />

						<v-col class="d-flex justify-end pb-1">
							<v-btn icon dark @click="transferBalances = false">
								<v-icon small color="primary">
									mdi-close
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>

				<v-divider />

				<v-form ref="form" @submit.prevent="postBalance">
					<v-card-text>
						<v-row>
							<v-col cols="12" class="py-0">
								<span>
									{{ $t('relay.from-cashbox') }}  {{ transferBalances ? getCashboxById(cashboxesPostBalance).name : null }}
								</span>
							</v-col>
							<v-col cols="4" class=" px-1 pb-0">
								<v-text-field
									:label="$t('forms.balance')"
									hide-details="auto"
									outlined
									dense
									disabled
								/>
							</v-col>
							<v-col cols="4" class="px-1">
								<v-text-field
									v-model="amount"
									:label="$t('forms.amount')"
									hide-details
									outlined
									dense
									:rules="rules.required"
								/>
							</v-col>
							<v-col cols="4" class="px-1">
								<v-text-field
									v-model="remainingAmount"
									:label="$t('forms.remaining-amount')"
									hide-details
									outlined
									dense
								/>
							</v-col>
							<v-col cols="6" class="px-1">
								<p>
									{{$t('relay.to-an-account')}}
								</p>
								<v-autocomplete
									v-model="account"
									:items="accounts.filter(c => c.type === type.sub)"
									item-text="name"
									item-value="id"
									:label="$t('account-id-or-name')"
									outlined
									dense
								/>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions class="justify-end">
						<v-btn color="edit-color white--text px-5" type="submit" :loading="loading">
							{{$t('save')}}
						</v-btn>

						<v-btn
							color="alert-color white--text px-5"
							:disabled="loading"
							@click="transferBalances = false"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<!-- <v-dialog v-model="transferBalances" width="600">
			<v-card class="w-100">
				<v-card-title class="w-100"> -->
					<!-- <v-spacer/> -->
					<!-- <v-row>
						<v-col cols="4">
							<span class="primary--text"> 
								{{$t('relay.transfer-of-fund-balances')}}
							</span>
						</v-col>

						<v-spacer />

						<v-col cols="1">
							<v-btn icon dark @click="transferBalances = false">
								<v-icon small color="primary">
									mdi-close
								</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title> -->
				<!-- <v-divider class="mx-3 pt-0" />
				<v-form ref="form" @submit.prevent="postBalance()">
					<v-card-text>
						<v-row class="">
							<v-col class="pt-4">
								<span>
									{{$t('StoreCashboxes.cashboxes')}}
								</span>
							</v-col>
							<v-col align-self="start" class="d-flex justify-end">
								<v-checkbox
									v-model="all"
									:label="$t('relay.everyone-chose')"
									hide-details
								/>
							</v-col>
						</v-row>
						<v-row v-for="(cashbox, index) in cashboxes " :key="index" class="my-2">
							<v-col align-self="end" class="d-flex justify-start px-0 pb-0" cols="12">
								<v-checkbox
									v-model="checkCashBox[index]"
									@change="row[index].cashboxIdP = cashbox.id; pushToArray(index)"
									hide-details
								/>
								{{cashbox.name}}
							</v-col>
							<v-col cols="4" class=" px-1 pb-0">
								<v-text-field
									:label="$t('forms.balance')"
									hide-details="auto"
									outlined
									dense
									disabled
								/>
							</v-col>
							<v-col cols="4" class=" px-1 pb-0">
								<v-text-field
									v-model="row[index].amount"
									:label="$t('forms.amount')"
									hide-details
									outlined
									dense
									@input="inputChange(index)" 
								/>
							</v-col>
							<v-col cols="4" class=" px-1 pb-0">
								<v-text-field
									v-model="row[index].remainingAmount"
									:label="$t('forms.remaining-amount')"
									hide-details
									outlined
									dense
								/>
							</v-col>
							<v-col class="px-0 py-0">
								<v-col cols="12" class="pt-0">
									<span> تحويل إلى حساب </span>
								</v-col>
								<v-col cols="6" class="py-0">
									<v-autocomplete
										v-model="row[index].account"
										:items="accounts.filter(c => c.type === type.sub)"
										item-text="name"
										item-value="id"
										:label="$t('account-id-or-name')"
										outlined
										dense
									/>
								</v-col>
							</v-col>
						</v-row>
					</v-card-text>

					<v-card-actions class="justify-end">
						<v-btn color="edit-color white--text px-5" type="submit" :loading="loading">
							{{$t('save')}}
						</v-btn>

						<v-btn
							color="alert-color white--text px-5"
							:disabled="loading"
							@click="transferBalances = false"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog> -->
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { type, userType } from '@/helpers/enums';
import rules from '@/helpers/validation rules'
export default {
	name: 'StoreCashboxes',
	
	data() {
		return {
			inputValue: null,
			store: null,
			// helpers
			loading: false,
			submitLoading: false,
			
			dialog: false,
			deleteDialog: false,
			cashboxId: null,
			cashboxIdDelete: null,
			transferBalances: false,
			all: false,
			checkCashBox: [false, false, false],

			// data
			dialogData: {
				no: null,
				name: null,
				accountId: null,
				users: null,
				notes: null,
				status: true
			},
			form: false,
			type,
			rules,
		
			postedArray: [],
			account: null,
			remainingAmount: null,
			amount: null,
			cashboxesPostBalance: null,
			userType
		}
	},

	watch: {
		dialog(val) {
			if (this.cashboxId) {
				if (val) {
					if (this.cashboxId) {
						this.dialogData.no = this.getCashboxById(this.cashboxId).no;
						this.dialogData.name = this.getCashboxById(this.cashboxId).name;
						this.dialogData.accountId = this.accounts.find(c => c.id === this.getCashboxById(this.cashboxId)?.accountId)?.id;
						this.dialogData.users = this.getCashboxById(this.cashboxId).users;
						this.dialogData.status = this.getCashboxById(this.cashboxId).status

						this.dialogData.notes = this.getCashboxById(this.cashboxId).notes;
					}
				} else {
					this.cashboxId = null;
					this.cashboxIdDelete = null
					this.$refs.form.reset();
					this.dialogData.status = true;
				}
			}
		},

		transferBalances(val) {
			if (val === false) {
				this.amount = null
				this.remainingAmount = null
				this.account = null
				this.$refs.form.reset()
			}
		}

		// all() {
		// 	if (this.all === true) {
		// 		this.checkCashBox[0] = true
		// 		this.checkCashBox[1] = true
		// 		this.checkCashBox[2] = true
		// 	};
		// 	this.postedArray.push({

		// 	})
		// },
	},

	computed: {
		...mapState({
			cashboxes: state => state.cashboxes.cashboxes,
			accounts: state => state.accounts.accounts,
			permissionsForUser: state => state.auth.permissionsForUser
		}),
		...mapGetters({
			getCashboxById: 'cashboxes/getCashboxById',
			getAccountById: 'accounts/getAccountById'
		}),

		headers() {
			return [
				{
					text: this.$t('headers.name'),
					name: 'name',
					value: 'name'
				},
				{
					text: this.$t('headers.financial-account'),
					name: 'financial-account',
					value: 'accountId',
					setValueExpr: val => this.accounts.find(c => c.id === val)?.name ?? val,
				},
				{
					text: this.$t('headers.users'),
					name: 'users',
					value: 'users'
				},
				// {
				// 	text: this.$t('headers.balance'),
				// 	name: 'balance',
				// 	value: 'balance'
				// },
				{
					text: this.$t('headers.notes'),
					name: 'notes',
					value: 'notes'
				},
				{
					text: this.$t('headers.status'),
					name: 'status',
					value: 'status'
				},
				{
					text: '',
					name: 'actions',
					value: 'actions',
					align: 'center',
					width: 120,
					sortable: false
				},
			]
		},

		// row() {
		// 	const row = []
		// 	for (let i = 0; i < this.cashboxes.length; i++) {
		// 		row.push({
		// 			cashboxIdP: null,
		// 			account: null,
		// 			amount: null,
		// 			remainingAmount: null
		// 		})
		// 	}
		// 	return row
		// }
	},
	methods: {
		// pushToArray() {
		// 	if (this.account && this.amount) {
		// 		this.postedArray.push({
		// 			accountId: this.account,
		// 			cashboxId: this.cashboxesPostBalance, 
		// 			amount: {
		// 				amount: this.amount
		// 			}, 
		// 			remainingAmount: {
		// 				amount: this.remainingAmount
		// 			}
		// 		})
		// 	}
		// },
	
		deleteFromArray(index) {
			if (this.postedArray.length) {
				this.postedArray.splice(index, 1)
			}
		},
		open() {
			this.form = true;
			this.dialog = true
			this.dialogData.name = null
			this.dialogData.no = null 
			this.dialogData.accountId = null 
		},
		submit() {
			if (this.$refs.form.validate()) {
				this.submitLoading = true;
				const actionName = this.cashboxId ? 'update' : 'create'
				this.$store.dispatch(`cashboxes/${actionName}`, {
					id: this.cashboxId,
					no: this.dialogData.no,
					name: this.dialogData.name, 
					accountId: this.dialogData.accountId,
					notes: this.dialogData.notes,
				}).then(() => {
					this.$store.dispatch('cashboxes/fetchAll').then((data) => {
						this.cashboxes = data
						actionName === 'update'
							? this.$eventBus.$emit('show-snackbar', false, this.$t('messages.the-store-has-been-modified-successfully'))
							: this.$eventBus.$emit('show-snackbar', false, this.$t('messages.the-store-has-been-created-successfully'))
					}).finally(() => {
						this.loading = false;
						this.submitLoading = false;
						this.dialog = false
					})
				}).finally(() => {
					this.loading = false;
					this.submitLoading = false;
					this.dialog = false
				})
			}
		},
		fetchAll() {
			this.loading = true;
			this.$store.dispatch('cashboxes/fetchAll').then((data) => {
				this.cashboxes = data
				this.loading = false;
				this.submitLoading = false
			})
		},
		delete() {
			this.$store.dispatch('cashboxes/delete', { id: this.cashboxId })
		},

		postBalance() {
			if (this.$refs.form.validate()) {
				if (this.account && this.amount) {
					this.postedArray.push({
						accountId: this.account,
						cashboxId: this.cashboxesPostBalance, 
						amount: {
							amount: this.amount,
							currencyId: this.$localStorage.currencyId
						}, 
						remainingAmount: {
							amount: this.remainingAmount,
							currencyId: this.$localStorage.currencyId
						}
					})
				}
				this.loading = true
				this.$store.dispatch('cashboxes/cashboxesPostBalance', {
					entries: this.postedArray
				}).then(() => {
					this.$eventBus.$emit(
						'show-snackbar',
						false,
						this.$t('messages.the-cashbox-balance-has-been-transferred-successfully')
					)
				}).finally(() => {
					this.loading = false
					this.transferBalances = false
				})
			}
		}
	},

	created() {
		console.log(Array.isArray(this.row))
		
		this.loading = true;
		this.$store.dispatch('cashboxes/fetchAll').then(() => {
			this.loading = false
		})
	},
	metaInfo() {
		return {
			title: this.$t('routes.store-cashboxes'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>
<style>
.v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
	margin-top: 0;
}
</style>