import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		authenticated: false,
		self: {
			id: null,
			name: null, 
			token: null,
			isAdmin: null
		},
		permissionsForUser: {}	
	},
	actions: {
		login({ commit }, { username, password }) {
			return axios.post('/Users/Login', {
				username,
				password
			}).then((response) => {
				const auth = {
					id: response.data.userId,
					name: response.data.username, 
					token: response.data.token,
					isAdmin: response.data.isAdmin
				};
				localStorage.setItem('auth', JSON.stringify(auth));
				window.axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.token;
				commit(types.LOGIN, auth);
				return response.data
			})
		},

		logout({ commit }) {
			localStorage.removeItem('auth');
			localStorage.removeItem('userType');
			localStorage.removeItem('isAdmin');
			delete window.axios.defaults.headers.common.authenticated;
			commit(types.LOGOUT)
		},

		fetchPermissionsForUserLogin({ commit }, { storeId }) {
			return axios.get(`Users/Self/Permissions/${storeId}`).then(({ data }) => {
				commit(types.STORE_PERMISSIONS_FOR_USER, data);
				return data
			})
		},
		reset({ commit }) {
			return commit(types.RESET)
		}
	},
	mutations: {
		[types.LOGIN](state, auth) {
			state.authenticated = true;
			state.self.name = auth.name;
			state.self.id = auth.id;
			state.self.isAdmin = auth.isAdmin
		},
		
		[types.LOGOUT](state) {
			state.authenticated = false;
			state.self.id = null;
			state.self.name = null
			state.self.token = null;
			state.self.isAdmin = false
		},

		[types.STORE_PERMISSIONS_FOR_USER](state, permissionsForUser) {
			state.permissionsForUser = permissionsForUser;
		},

		[types.RESET](state) {
			state.permissionsForUser = {}
		},
	}
}