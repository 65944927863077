<template>
    <div class="bgColor rounded-t-xl" style="height: 81vh">
        <v-row align="center" class="mx-2 mt-0">
			<!--item id or name  -->
            <v-col cols="5" lg="3" md="4" sm="4" class="py-md-0">
                <v-autocomplete
					v-model="itemId"
                    :label="$t('account-id-or-name')"
                    outlined
					dense
					hide-details="auto"
                />
            </v-col>
			<!-- from date  -->
            <v-col cols="5" lg="2" md="3" sm="4" class="py-md-0">
				<bee-date-input
					v-model="fromDate"
					:label="$t('forms.from-date')"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					dense
					hide-details="auto"
				></bee-date-input>
			</v-col>
			<!-- toDate -->
			<v-col cols="5" lg="2" md="3" sm="3" class="py-md-0">
				<bee-date-input
					v-model="toDate"
					:label="$t('forms.to-date')"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					dense
					hide-details="auto"
				></bee-date-input>
			</v-col>

			<!-- search btn -->
			<v-col cols="auto" class="px-0 ">
				<v-btn 
					class="mx-2"
					fab
					x-small
					color="of-white"
				>
					<v-icon color="blue-dark">mdi-magnify</v-icon>
				</v-btn>
			</v-col>

			<!-- close btn -->
			<v-col cols="auto" class="px-0 ">
				<v-btn 
					class="mx-2"
					fab
					x-small
					color="of-white"
				>
					<v-icon color="alert-color">mdi-close</v-icon>
				</v-btn>
			</v-col>

			<v-spacer />

			<v-divider vertical class="my-3" /> 

			<v-col cols="auto">
				<v-btn color="blue-dark white--text">
					<v-icon> mdi-printer-outline </v-icon>
					{{$t('check-out')}}
				</v-btn>
			</v-col>
        </v-row>
		<!-- table -->
		<v-row>
			<v-col class="py-0 mx-4">
				{{headers}}
				<v-data-table
					:headers="headers"
					:items="entries"
					:single-expand="singleExpand"
					:expanded.sync="expanded"
					item-key="date"
					show-expand
					class="elevation-1"
					hide-default-footer
				>
					<template v-slot:expanded-item="{ headers, item }">
						<td :colspan="headers.length" class="tab">
							<v-data-table
								v-if="item.head.length !== 0"
								:headers="item.head"
								:items="item.itemNested"
								hide-default-footer
								class="tab"
							>
							</v-data-table>
							<p v-else class="text-center mb-0"> لا يوجد تفاصيل </p>
						</td>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<!-- footer -->
		<v-footer app inset color="transparent" class="py-4 elevation-0 ">
			<v-row>
				<v-col>
					{{ $t('AccountStatement.total-arrested') }}
				</v-col>
				<v-col>
					{{ $t('AccountStatement.total-payments') }}
				</v-col>

				<v-spacer />

				<v-col>
					{{ $t('AccountStatement.the-difference') }}
				</v-col>
			</v-row>
		</v-footer >
    </div>
</template>

<script>
export default {
	name: 'accountStatement',

	data() {
		return {
			toDate: null,
			fromDate: null,
			itemId: null,
			checkboxOne: false,
			expanded: [],
			singleExpand: false,
		}
	},
	computed: {
		entries() {
			return [
				{
					date: '0-1-2023',
					balance: 10,
					debtor: 1129,
					creditor: 1200,
					explanation: 'string',
					head: []
				},
				{
					date: '20-1-2023',
					balance: 10,
					debtor: 1129,
					creditor: 1200,
					explanation: 'string',
					head: [
						{
							text: this.$t('headers.item'),
							name: 'item',
							value: 'item',
							class: 'text-center'
						},
						{
							text: this.$t('headers.quantity'),
							name: 'quantity',
							value: 'quantity',
							class: 'text-center'
						},
						{
							text: this.$t('headers.price'),
							name: 'price',
							value: 'price',
							class: 'text-center'
						},
						{
							text: this.$t('headers.total-amount'),
							name: 'totalAmount',
							value: 'totalAmount',
							class: 'text-center'
						}
					],
					itemNested: [
						{
							item: 'قهوة',
							quantity: '2',
							price: '1200',
							totalAmount: '2400' 
						}
					]
				},
				// {
				// 	date: '0-1-2023',
				// 	balance: 10,
				// 	debtor: 1129,
				// 	creditor: 1200,
				// 	explanation: 'string',
				// 	head: [
				// 		{
				// 			text: this.$t('headers.date'),
				// 			name: 'date',
				// 			value: 'date'
				// 		},
				// 		{
				// 			text: this.$t('headers.date'),
				// 			name: 'date',
				// 			value: 'date'
				// 		}
				// 	]
				// },
			]
		},

		headers() {
			return [
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date'
				},
				{
					text: this.$t('headers.amount-money'),
					name: 'balance',
					value: 'balance'
				},
				{
					text: this.$t('headers.arrested'),
					name: 'debtor',
					value: 'debtor'
				},
				{
					text: this.$t('headers.payments'),
					name: 'creditor',
					value: 'creditor'
				},
				{
					text: this.$t('headers.explanation'),
					name: 'explanation',
					value: 'explanation'
				},
				{ text: '', value: 'data-table-expand' },
			]
		}
	},

	metaInfo() {
		return {
			title: this.$t('routes.detailed-account-statement'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>
<style scoped>
.m-minus {
	margin-top: -0.5rem;
}
</style>

<style>
	tr.v-data-table__expanded.v-data-table__expanded__content {
		box-shadow: none !important;
		/* background-color: aliceblue !important; */
	}
	.v-data-table.tab.theme--light{
		/* background-color: aliceblue !important; */
	}

	th.text-start.text-center.sortable {
		text-align: center !important;
	}

	.v-application .text-start {
		text-align: center !important;
	}
</style>