import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		stores: [],
		permissionsForStores: [],
	},

	actions: {
		fetchAll({ commit }, { fromDate, toDate, country, name, storeNo, status, archiveStatus, page, perPage }) {
			return axios.get('/stores', {
				params: { fromDate, toDate, country, name, storeNo, status, archiveStatus, page, perPage }
			}).then(({ data }) => {
				commit(types.STORE_STORES, data);
				return data;
			})
		},
		fetchById({ commit }, { id }) {
			return axios.get(`/stores/${id}`).then(({ data }) => {
				return data;
			})
		},

		update ({ commit }, { id, name, storeNo, country, notes, defaultLanguage, defaultCurrencyId, phone, address, logo, originName, anotherName }) {
			const formData = new FormData();
			formData.append('id', id);
			formData.append('name', name);
			formData.append('storeNo', storeNo);
			formData.append('country', country);
			formData.append('notes', notes);
			formData.append('defaultLanguage', defaultLanguage);
			formData.append('defaultCurrencyId', defaultCurrencyId);
			formData.append('phone', phone);
			formData.append('address', address);
			formData.append('logo', logo);
			formData.append('originName', originName);
			formData.append('anotherName', anotherName);
			return axios.put(`/stores/${id}`, formData)	
		},

		create({ commit }, { name, storeNo, country, notes, defaultLanguage, defaultCurrencyId, initialSaleInvoiceNumber, originName, anotherName }) {
			return axios.post('/stores', {
				name, storeNo, country, notes, defaultLanguage, defaultCurrencyId, initialSaleInvoiceNumber, originName, anotherName
			}).then(({ data }) => { 
				return data
			})
		},
		archive({ commit }, { id }) {
			return axios.put(`/stores/${id}/Archive`, { id })
		},

		retrieve({ commit }, { id }) {
			return axios.put(`/stores/${id}/Retrieve`, { id })
		},

		StoreAccessPermissions({ commit }, { storeId }) {
			return axios.get(`Users/Permissions/${storeId}`).then(({ data }) => {
				commit(types.STORE_PERMISSIONS_FOR_STORES, data);
				return data
			})
		},

		reset({ commit }) {
			commit(types.RESET);
		}
	},

	mutations: {
		[types.STORE_STORES](state, stores) {
			state.stores = stores;
		},
		[types.RESET](state) {
			state.stores = [];
		},
		[types.STORE_PERMISSIONS_FOR_STORES](state, permissionsForStores) {
			state.permissionsForStores = permissionsForStores;
		},
	},

	getters: {
		getStoreById: state => id => state.stores.find(c => c.id === id)
	}
}