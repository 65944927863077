export default {
	all: 'الكل',
	favorite: 'المفضلة',

	'edit-fav-list': 'تعديل المواد المفضلة',

	'total-without-vat': 'المجموع بدون الضريبة',
	'total-net': 'الصافي',
	paid: 'مدفوع',
	unpaid: 'متبقي',
	alert: 'تنبيه',
	'alert-message': 'سيتم تغير أسعار المواد إلى الأسعار المخصصة لهذا الحساب إن وجدت'
}