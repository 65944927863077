var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-text-field',{attrs:{"label":_vm.label,"append-outer-icon":_vm.$i18n.locale === 'ar' ? 'mdi-chevron-left' : 'mdi-chevron-right',"prepend-icon":_vm.$i18n.locale === 'ar' ? 'mdi-chevron-right' : 'mdi-chevron-left',"dense":"","hide-details":"","disabled":_vm.disabled},on:{"click:prepend":function($event){_vm.index > 0
		? (() => {
			_vm.index--;
			_vm.$router.push({
				name: _vm.$route.name,
				params: { id: _vm.getItemByNo(_vm.itemsNumbers[_vm.index]).id }
			})
		})()
		: null;},"click:append-outer":function($event){_vm.index < _vm.itemsNumbers.length - 1
			? (() => {
				_vm.index++;
				_vm.$router.push({
					name: _vm.$route.name,
					params: { id: _vm.getItemByNo(_vm.itemsNumbers[_vm.index]).id }
				})
			})()
			: null},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.goToSelectedItem()}},model:{value:(_vm.localValue),callback:function ($$v) {_vm.localValue=$$v},expression:"localValue"}})
}
var staticRenderFns = []

export { render, staticRenderFns }