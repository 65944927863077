import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import pos, { $eventBus } from './plugins/pos'
import i18n from './i18n/i18n'
import axios from 'axios'
import { BASE_URL } from './helpers/constants'
import BeeComponents from '@beetronix/bee-components'
import printJS from 'print-js'
import * as types from '@/store/mutation-types'

window.axios = axios.create({ baseURL: BASE_URL + '/api' })
Vue.use(VueMeta);
Vue.use(BeeComponents);

Vue.config.productionTip = false;

window.printJs = printJS

window.axios = axios.create({
	baseURL: BASE_URL
})
window.axios.interceptors.response.use(
	function (response) {
		return response;
	},
	
	function (error) {
		if (!window.navigator.onLine) {
			$eventBus.$emit('show-snackbar', true, i18n.t('messages.your-are-offline'));
			return Promise.reject(error);
		}
		if (error.response.status === 401 && router.currentRoute.name !== 'login') {
			store.dispatch('auth/logout');
			router.replace({ name: 'login' })
		}
		$eventBus.$emit(
			'show-snackbar',
			true,
			error.response.data.message ||
			error.response.data.error ||
			error.response.statusText ||
			i18n.t('messages.an-error-occurred')
		)
		return Promise.reject(error);
	}
);
if ('auth' in localStorage) {
	try {
		const auth = JSON.parse(localStorage.getItem('auth'));
		store.state.auth.self.name = auth.name
		store.state.auth.self.id = auth.id
		store.state.auth.self.token = auth.token
		store.state.auth.self.isAdmin = auth.isAdmin
		store.state.auth.authenticated = true
		const authHeader = `Bearer ${auth.token}`;
		window.axios.defaults.headers.common.Authorization = authHeader;		
		// window.axios.defaults.headers.common['X-User-TimeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
	} catch (e) {
		store.dispatch(`users/${types.LOGOUT}`);
		store.dispatch('auth/reset');
		router.replace({ name: 'login' });
		store.dispatch('auth/logout')
	}
	if ('store-id' in localStorage) {
		window.axios.defaults.headers.common['x-store'] = JSON.parse(localStorage.getItem('store-id'))
		// window.axios.defaults.headers.common['X-User-TimeZone'] = Intl.DateTimeFormat().resolvedOptions().timeZone
	}
} else {
	router.replace({ name: 'login' });
}
// Vue.use(VueI18n)
new Vue({
	router,
	store,
	vuetify,
	pos,
	i18n,
	render: h => h(App)
}).$mount('#app')