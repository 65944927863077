export default {
	'tabs-title': {
		'detailed-sales-statement-for-an-item': 'كشف مبيعات تفصيلي لمادة',
		'total-sales-to-a-customer': 'إجمالي مبيعات لزبون',
		'total-sales': 'إجمالي مبيعات',
		'detailed-account-statement': 'كشف حساب تفصيلي',
		'vat-statement': 'كشف ضرائب'
	},
	item: 'المادة',
	account: 'حساب',
	'download-in-excel-format': 'تنزيل بصيغة اكسل'
}