<template>
	<div>
		<!-- tabs -->
		<v-tabs
			v-model="tab"
			grow
			height="36"
			background-color="bgColor"
			active-class="primary white--text"
			slider-color="success"
			class="rounded-lg mb-4"
			style="overflow: hidden"
			show-arrows
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:disabled="loading"
				@click="
					invoiceType !== tab.value
						? (() => { invoiceType = tab.value; filter(); reset(); $router.push({
							name: 'all-invoices',
							query: {
								type: tab.value,
								typeIndex: index,
								
							}
						}) })()
						: null
				"
			>
				{{tab.name}}
			</v-tab>
		</v-tabs>

		<!-- filters -->
		<v-form ref="form" @submit.prevent="filter(); isFiltered = true">
			<v-row class="mb-0">
				<!-- no -->
				<v-col lg="1" md="2" sm="3" cols="6">
					<v-text-field
						v-model="filterData.no"
						:label="$t('forms.no')"
						dense
						outlined
						hide-details
					></v-text-field>
				</v-col>

				<!-- status -->
				<v-col md="2" sm="3" cols="6">
					<v-autocomplete
						v-model="filterData.status"
						:label="$t('forms.status')"
						:items="statuses"
						item-value="id"
						item-text="name"
						dense
						outlined
						hide-details
						clearable
					></v-autocomplete>
				</v-col>
				
				<!-- cashbox -->
				<v-col md="2" sm="3" cols="6">
					<v-autocomplete
						v-model="filterData.cashbox"
						:label="$t('forms.cashbox')"
						:items="cashboxes"
						item-value="id"
						item-text="name"
						dense
						outlined
						hide-details
						clearable
					></v-autocomplete>
				</v-col>

				<!-- sort date -->
				<v-col md="2" sm="3" cols="6">
					<v-autocomplete
						v-model="filterData.dateSortMode"
						:label="$t('forms.date')"
						:items="arrayDateSortMode"
						item-value="id"
						item-text="name"
						dense
						outlined
						hide-details
						clearable
					></v-autocomplete>
				</v-col>

				<!-- creation Date -->
				<v-col md="2" sm="3" cols="6">
					<v-autocomplete
						v-model="filterData.creationDate"
						:label="$t('forms.date-created')"
						:items="arrayDateSortMode"
						item-value="id"
						item-text="name"
						dense
						outlined
						hide-details
						clearable
					></v-autocomplete>
				</v-col>

				<!-- creation Date -->
				<v-col md="2" sm="3" cols="6">
					<v-autocomplete
						v-model="filterData.postingStatusSortMode"
						:label="$t('AllInvoices.sort-by-status')"
						:items="postingStatusSortMode"
						item-value="id"
						item-text="name"
						dense
						outlined
						hide-details
						clearable
					></v-autocomplete>
				</v-col>
				
				<!-- from -->
				<v-col v-if="0" cols="auto">
					<bee-date-input
						v-model="filterData.from"
						:label="$t('forms.from')"
						dense
						hide-details
						clearable
						prepend-icon="mdi-calendar"
						user-menu-picker
						fit-inputs
						input-format-order="DD-MM-YYYY"
					></bee-date-input>
				</v-col>
				
				<!-- to -->
				<v-col v-if="0" cols="auto">
					<bee-date-input
						v-model="filterData.to"
						:label="$t('forms.to')"
						dense
						hide-details
						clearable
						prepend-icon="mdi-calendar"
						user-menu-picker
						fit-inputs
						input-format-order="DD-MM-YYYY"
					></bee-date-input>
				</v-col>

				<v-spacer/>
				
				<!-- search -->
				<v-col cols="auto">
					<v-btn 
						class="mx-2"
						fab
						x-small
						color="of-white"
						:loading="loading"
						:disabled="isAllFieldsEmpty"
						type="submit"
					>
						<v-icon color="blue-dark">mdi-magnify</v-icon>
					</v-btn>

					<v-btn
						v-if="isFiltered"
						icon
						color="blue-light"
						class="ms-2"
						@click="reset(); isFiltered = false; filter()"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- table -->
		<bee-handy-table
			:headers="headers"
			:items="invoices"
			:loading="loading"
			dense
			zebra
			fixed-header
			:items-per-page="20"
			pagination-on-scroll
			:height="$vuetify.breakpoint.height - 200"
			:context-menu-items="contextMenuItems"
			:data-table-props="{
				disableSort: false,
			}"
			:shown-rows-text="$t('shown-rows')"
			@context-menu-preview="previewInvoice"
		>
			<!-- actions -->
			<template v-slot:item.action="{ item }">
				<tooltip right :text="$t('AllInvoices.view-the-associated-invoice')">
					<v-btn
						small
						text
						@click="previewInvoice(item.type, item.id)"
						:disabled="loading"
						color="edit-color"
					>		
						<v-icon>mdi-eye</v-icon>
					</v-btn>
				</tooltip>

				<tooltip right :text="$t('AllInvoices.view-associated-journal')">
					<v-btn
						small
						text
						color="blue-dark"
						@click="$router.push({
							name: 'journal-item',
							params: {
								id: item.itemsJournalId
							}
						})"
						class="px-1"
						:disabled="!item.isPosted"
					>
						<!-- <v-img src="@/assets/invoice.png" contain width="20px" height="20px" /> -->
						<v-icon> mdi-file-document-outline  </v-icon>
					</v-btn>
				</tooltip>
				
				<tooltip right :text="$t('AllInvoices.print')">
					<v-menu v-if="item.isPosted" offset-y >
						<template v-slot:activator="{ attr, on }">
							<v-btn
								small
								depressed
								text
								v-on="on"
								v-bind="attr"
								@click="invoiceId = item.id"
								:loading="printLoading && invoiceId === item.id"
								:disabled="printLoading"
								color="blue-dark"
							>
								<v-icon>mdi-printer-outline</v-icon>
							</v-btn>
						</template>

						<v-list dense>
							<v-list-item @click="invoiceId = item.id, printReport(print.invoiceReceipt)">
								<v-list-item-title>
									{{$t('print.print-receipt-small')}}
								</v-list-item-title>
							</v-list-item>
							<v-list-item @click="invoiceId = item.id, printReport(print.saleInvoiceReport)">
								<v-list-item-title>
									{{$t('print.print-invoice')}}
								</v-list-item-title>
							</v-list-item>
							<v-list-item @click="invoiceId = item.id, printReport(print.deliveryReport)">
								<v-list-item-title>
									{{$t('print.print-delivery')}}
								</v-list-item-title>
							</v-list-item>
							<v-list-item @click="invoiceId = item.id, printReport(print.receiveReport)">
								<v-list-item-title>
									{{$t('print.print-receipt')}}
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</tooltip>

				<tooltip right :text="$t('delete')">
					<v-btn
						v-show="item.isPosted === false"
						small
						text
						@click="invoiceIdDelete = item.id ; deleteInvoice(invoiceIdDelete)"
						color="alert-color"
						:disabled="permissionsForUser.userType === userType.dataReader"
					>
						<v-icon > mdi-delete </v-icon>
					</v-btn>
				</tooltip>
			</template>

			<!-- status -->
			<template v-slot:item.status="{ value }">
				<v-chip :color="value ? 'success' : 'alert-color white--text'" small>
					{{value ? $t('status.ending') : $t('status.not-complete')}}
				</v-chip>
			</template> 	

			<!-- paymentMethods -->
			<template v-slot:item.paymentMethods="{ value }">
				{{
					value === paymentMethod.cash ?
					$t('payment-method.cash') :
					(value === paymentMethod.debt ?
					$t('payment-method.debt') :
					(value !== paymentMethod.debt && value !== paymentMethod.cash ? $t('payment-method.creditCard') : '')) 
				}}
			</template>
		</bee-handy-table>
	</div>
</template>

<script>
import { invoiceTypes, postingState, type, accountConfigType, paymentMethod, dateSortMode, userType } from '@/helpers/enums';
import { mapGetters, mapState } from 'vuex';	
import { printHtml } from '@/helpers/functions';

export default {
	name: 'AllInvoices',

	data() {
		return {
			// helpers
			loading: false,
			isFiltered: false,
			tab: 0,
			invoiceId: null,

			printLoading: false,

			// data
			invoiceType: null,
			accountConfigType,
			invoiceTypes,	
			paymentMethod,

			print: {
				saleInvoiceReport: 2,
				deliveryReport: 3,
				receiveReport: 4,
			},

			filterData: {
				no: null,
				status: null,
				dateSortMode: null,
				cashbox: null,
				from: null,
				to: null,
				creationDate: dateSortMode.descending,
				postingStatusSortMode: null
			},
			sortDesc: true,
			sortBy: 'status',
			invoiceIdDelete: null,

			// postingStatusSortMode: {
			// 	no: 0,
			// 	postedFirst: 1,
			// 	notPostedFirst: 2
			// },

			postingStatusSortMode: [
				// { 
				// 	name: this.$t('AllInvoices.posted-first'),
				// 	id: 0,
				// },
				{
					name: this.$t('AllInvoices.posted-first'),
					id: 1,
				},
				{
					name: this.$t('AllInvoices.not-posted-first'),
					id: 2
				}
			],
			userType
		}
	},
	watch: {
		// tab(val) {
		// 	// console.log(val)
		// 	if (val === 0) {
		// 		console.log(val)
		// 		this.filterData.dateSortMode = dateSortMode.descending
		// 		this.filterData.creationDate = dateSortMode.descending
		// 	} else {
		// 		console.log('ll')
		// 		this.filterData.dateSortMode = null
		// 		this.filterData.creationDate = dateSortMode.descending
		// 	}
		// }
	},
	computed: {
		...mapState({
			invoices: state => state.invoices.invoices,	
			subAccounts: state => state.accounts.accounts.filter(c => c.type === type.sub),	
			cashboxes: state => state.cashboxes.cashboxes,	
			permissionsForUser: state => state.auth.permissionsForUser
		}),

		...mapGetters({
			getAccountById: 'accounts/getAccountById',
			getCashboxById: 'cashboxes/getCashboxById',
			getInvoiceById: 'invoices/getInvoiceById'
		}),

		isAllFieldsEmpty() {
			return (
				!this.filterData.no &&
				!this.filterData.status &&
				!this.filterData.dateSortMode &&
				!this.filterData.cashbox &&
				!this.filterData.status &&
				!this.filterData.from &&
				!this.filterData.to && 
				!this.filterData.creationDate &&
				!this.filterData.postingStatusSortMode
			)
		},

		statuses() {
			return [
				{ id: postingState.posted, name: this.$t('status.ending') },
				{ id: postingState.notPosted, name: this.$t('status.not-complete') },
			]
		},

		tabs() {
			return [
				{ value: null, name: this.$t('AllInvoices.tabs.all') },
				{ value: invoiceTypes.sales, name: this.$t('AllInvoices.tabs.sales') },
				{ value: invoiceTypes.purchases, name: this.$t('AllInvoices.tabs.purchases') },
				{ value: invoiceTypes.salesRefund, name: this.$t('AllInvoices.tabs.sales-refund') },
			]
		},

		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'no',
					value: 'no',
					setValueExpr: val => this.invoices.map(c => c.no).indexOf(val) + 1,
				},
				{
					text: this.$t('headers.no'),
					name: 'no',
					value: 'no',
				},
				{
					text: this.$t('headers.type'),
					name: 'type',
					value: 'type',
					setValueExpr: val => (val === invoiceTypes.sales) ? this.$t('AllInvoices.sale') : val === invoiceTypes.salesRefund ? this.$t('AllInvoices.sales-returns') : this.$t('AllInvoices.purchases') 
				},
				{
					text: this.$t('headers.account'),
					name: 'account',
					value: 'intermediaryAccountId',
					setValueExpr: val => this.getAccountById(val)?.name,
				},
				{
					text: this.$t('headers.cashbox'),
					name: 'cashbox',
					value: 'cashboxId',
					setValueExpr: val => this.getCashboxById(val)?.name,
				},
				{
					text: this.$t('headers.status'),
					name: 'status',
					value: 'isPosted',
					// disableSort: false
					sortable: true
				},
				{
					text: this.$t('payment-method.title'),
					name: 'paymentMethods',
					value: 'paymentWay'
				},
				{
					text: this.$t('headers.total-net'),
					name: 'totalNet',
					value: 'id',
					setValueExpr: val => this.$options.filters.numberFormat(this.getInvoiceById(val)?.grandAmount.amount ?? 0)
				},
				{
					text: this.$t('headers.notes'),
					name: 'notes',
					value: 'notes',
				},
		
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					setValueExpr: val => val.slice(0, 10),
				},
				{
					text: '',
					name: 'action',
					value: 'action',
				}
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-2' : 'px-2'
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' pa-2' : 'pa-2'
			}

			return headers;
		},

		contextMenuItems() {
			return [
				{ title: this.$t('preview'), icon: 'mdi-eye', event: 'preview' }
			]
		},
		arrayDateSortMode() {
			return [
				{ id: dateSortMode.ascending, name: this.$t('AllInvoices.ascending') },
				{ id: dateSortMode.descending, name: this.$t('AllInvoices.descending') }
			]
		}
	},

	methods: { 
		filter() {
			this.loading = true;
			this.$router.push({
				name: 'all-invoices',
				query: {
					// invoiceType: this.invoiceType,
					type: this.$route.query.type,
					typeIndex: this.$route.query.typeIndex,
					from: this.filterData.from,
					to: this.filterData.to,
					status: this.filterData.status,
					no: this.filterData.no,
					dateSortMode: this.filterData.dateSortMode,
					creationDate: this.filterData.creationDate,
					postingStatusSortMode: this.filterData.postingStatusSortMode,
					cashbox: this.filterData.cashbox
				}
			})
			this.$store.dispatch('invoices/fetchAll', {
				type: this.invoiceType,
				fromDate: this.filterData.from,
				toDate: this.filterData.to,
				status: this.filterData.status,
				invoiceNo: this.filterData.no,
				dateSortMode: this.filterData.dateSortMode,
				postingStatusSortMode: this.filterData.postingStatusSortMode,
				creationDateSortMode: this.filterData.creationDate,
				cashboxId: this.filterData.cashbox
			}).finally(() => {
				this.loading = false
			})
		},

		previewInvoice(type, invoiceId) {
			let routeName;

			switch (type) {
			case invoiceTypes.sales: routeName = 'sale-invoice'; break;
			case invoiceTypes.purchases: routeName = 'pruchase-invoice'; break;
			case invoiceTypes.salesRefund: routeName = 'sales-invoice-returned'; break;
			case invoiceTypes.purchasesRefund: routeName = ''; break;
			}
			
			this.$router.push({ name: routeName, params: { id: invoiceId } })
		},

		printReport(printType) {
			let actionName;
			switch (printType) {
			case this.print.receipt: actionName = 'invoiceReceipt'; break;
			case this.print.saleInvoiceReport: actionName = 'saleInvoice'; break;
			case this.print.deliveryReport: actionName = 'delivery'; break;
			case this.print.receiveReport: actionName = 'receive'; break;
			}
			this.printLoading = true;

			return this.$store.dispatch(`reports/${actionName}`, {
				storeId: this.$localStorage.storeId,
				invoiceId: this.invoiceId,
			}).then((data) => {
				if (printType === this.print.receipt) {
					printHtml(data);
				}
			}).finally(() => {
				this.printLoading = false;
			})
		},

		deleteInvoice(invoiceIdDelete) {
			this.loading = true
			this.$store.dispatch('invoices/delete', { id: invoiceIdDelete }).then(() => {
				this.$store.dispatch('invoices/fetchAll', {}).then((data) => {
					this.invoices = data;
				}).finally(() => {
					this.loading = false
				})
			})
		},

		reset() {
			this.invoiceType = null
			this.filterData.from = null 
			this.filterData.to = null 
			this.filterData.status = null 
			this.filterData.no = null 
			this.filterData.dateSortMode = null 
			this.filterData.postingStatusSortMode = null 
			this.filterData.creationDate = dateSortMode.descending
		}
	},

	mounted() {
		if (this.$route.query.type) {
			console.log(Boolean(this.$route.query.type))
			this.invoiceType = this.$route.query.type;
			this.tab = Number(this.$route.query.typeIndex)
		} else {
			this.tab = this.$route.query.typeIndex ? this.$route.query.typeIndex : 0
		}
		this.filterData.from = this.$route.query.from ? this.$route.query.from : null
		this.filterData.status = this.$route.query.status ? Number(this.$route.query.status) : null
		this.filterData.no = this.$route.query.no
		this.filterData.dateSortMode = this.$route.query.dateSortMode ? Number(this.$route.query.dateSortMode) : null
		this.filterData.postingStatusSortMode = this.$route.query.postingStatusSortMode ? Number(this.$route.query.postingStatusSortMode) : null
		this.filterData.cashbox = this.$route.query.cashbox
		// this.filterData.creationDate = Number(this.$route.query.creationDate)
		this.filterData.creationDate = dateSortMode.descending
		this.$store.dispatch('invoices/reset');
		this.filter();
	},

	metaInfo() {
		return {
			title: this.$t('routes.all-invoices'),
			titleTemplate: '%s | POS'	
		}
	}
}
</script>
