<template>
	<bee-label
		:color="
			value
				? value.money_type >= 0 && withColor
					? perspective && perspective == 'statement'
						? value.money_type == '1'
							? 'green'
							: 'red'
						: localValue.money_type == '1'
						? 'red'
						: 'green'
					: 'black'
				: 'black'
		"
		:bold="bold"
		:content="formatedValue"
		:empty-state="emptyState"
	>
	</bee-label>
</template>
<script>
import currency from 'currency.js';

export default {
	name: 'BeeAdvancedMoneyLabel',
	props: [
		'value',
		'withColor',
		'emptyState',
		'currencies',
		'bold',
		'perspective',
		'showZerosValues',
	],
	computed: {
		symbol() {
			return '$';
		},
		localValue() {
			return this.value
				? this.value
				: { amount: null, currency_id: null, money_type: null };
		},
		formatedValue() {
			return this.localValue.amount ||
				(this.localValue.amount === 0 && this.showZerosValues)
				? currency(this.localValue.amount, {
					separator: ',',
					decimal: '.',
					symbol: this.symbol || '',
				}).format()
				: null;
		},
	},
};
</script>
