import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		users: [],
		notifications: []
	},

	actions: {
		// fetch all users
		fetchAll({ commit }) {
			return axios.get('/Users').then(({ data }) => {
				commit(types.STORE_USERS, data);
				return data
			})
		},

		// create new user 
		create({ commit }, { name, username, password, confirmPassword }) {
			return axios.post('/Users', { name, username, password, confirmPassword }).then(({ data }) => data)
		},

		// update user
		update({ commit }, { id, name, username, password, confirmPassword }) {
			return axios.put(`/users/${id}`, {
				name, username, password, confirmPassword
			})
		},
		
		// change password
		resetPassword({ commit }, { userId, oldPassword, newPassword, confirmNewPassword }) {
			return axios.put('/Users/self/ResetPassword', {
				userId, oldPassword, newPassword, confirmNewPassword
			})
		},

		// deActive previous account
		deactivate({ commit }, { id }) {
			return axios.put(`/Users/${id}/Deactivate`)
		},

		// activate
		activate({ commit }, { id }) {
			return axios.put(`/Users/${id}/Activate`)
		},

		// delete previous user
		delete({ commit }, { id }) {
			return axios.delete(`/users/${id}`)
		},

		// Permissions
		addPermissions({ commit }, { id, entryPermissions }) {
			return axios.put(`/Users/${id}/Permissions`, entryPermissions)
		},

		permissionsUser({ commit }, { id }) {
			return axios.get(`Users/${id}/Permissions`).then(({ data }) => data)
		},

		fetchNotifications({ commit }, { storeId, isSeen }) {
			return axios.get('/Users/Self/Notifications', { params: { storeId, isSeen } }).then(({ data }) => {
				commit(types.STORE_NOTIFICATIONS, data);
				return data
			})
		},

		seenNotifications({ commit }, { storeId }) {
			return axios.put(`/Users/Self/Notifications/${storeId}/MarkAsSeen`)
		},

		reset({ commit }) {
			return commit(types.RESET)
		},

		// permission for manager permissions
		createPermissionsByManager({ commit }, { id, storeId, cashboxId, userType }) {
			return axios.put(`/users/${id}/Permission/Grant`, { storeId, cashboxId, userType }).then(({ data }) => {
				return data
			})
		},

		cancelPermissionsByManger({ commit }, { id, storeId }) {
			return axios.put(`/users/${id}/Permission/Revoke`, null, { params: { storeId } }).then((data) => {
				return data
			})
		}
	},

	mutations: {
		[types.STORE_USERS](state, users) {
			state.users = users;
		},

		[types.STORE_NOTIFICATIONS](state, notifications) {
			state.notifications = notifications
		},

		[types.RESET] (state) {
			state.users = []
		} 
	},

	getters: {
		getUserById: state => id => state.users.find(c => c.id === id),
	}

}