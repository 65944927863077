export default {
	'user-create': 'إنشاء مستخدم',
	'user-edit': 'تعديل مستخدم',
	'edit-account-status': 'تعديل حالة حساب',
	'are-you-sure-to-deactivate-an-account': 'هل أنت متأكد من إيقاف حساب ',
	'are-you-sure-to-activate-an-account': 'هل أنت متأكد من تشغيل حساب ',
	'user-power': 'صلاحيات المستخدم',
	'store-name': 'اسم المتجر',
	'power-type': 'نوع الصلاحية',
	casher: 'كاشير',
	'executive-director': 'مدير متجر',
	optional: 'اختياري',
	'change-password': 'تغير كلمة المرور',
	'password-has-been-modified-successfully': 'تم تعديل كلمة المرور بنجاح',
	active: 'نشط',
	stopped: 'متوقف',
	'user-info': 'بيانات المستخدم',
	'the-user-has-been-added-successfully': 'تم إضافة المستخدم بنجاح',
	'user-data-has-been-modified-successfully': 'تم تعديل بيانات المستخدم بنجاح',
	viewer: 'مدير (إطلاع)'
}