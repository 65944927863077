<template>
	<v-tooltip
		:top="top"
		:left="left"
		:bottom="bottom"
		:right="right"
		:disabled="disabled"
		color="black"
	>
		<template v-slot:activator="{ on:tooltip, attrs }">
			<span v-bind="attrs" v-on="{...tooltip}">
				<slot></slot>
			</span>
		</template>
		<span class="text-caption"><slot name="text"></slot>{{text}}</span>
	</v-tooltip>
</template>

<script>
export default {
	props: {
		text: { type: String },
		top: { type: Boolean },
		left: { type: Boolean },
		bottom: { type: Boolean },
		right: { type: Boolean },
		disabled: { type: Boolean }
	}
}
</script>
