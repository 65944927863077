export default {
	tabs: {
		all: 'All',
		sales: 'Sales',
		purchases: 'Purchases',
		'sales-refund': 'Sales refund'
	},
	sale: 'sale',
	purchases: 'purchase',
	'sales-returns': 'Sales returns',
	ascending: 'from the oldest',
	descending: 'Of the newest',
	'view-the-associated-invoice': 'View the associated invoice',
	'view-associated-journal': 'View associated journal',
	print: 'Print',
	'sort-by-status': 'Sort by status',
	'posted-first': 'Posted first',
	'not-posted-first': 'Not posted first',
	no: 'no'
}