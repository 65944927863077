export default {
	casher: 'كاشير',
	manager: 'مدير متجر',
	name: 'الاسم',
	balance: 'المزانية',
	specify: 'تحديد',
	notDefined: 'غير معرف',
	operation: 'عملية',
	trade: 'متجارة',
	gainsAndLosses: 'أرباح و خسائر',
	budget: 'ميزانية',
	'types-of-final-accounts': {
		notDefined: 'غير معرف',
		operation: 'تشغيل',
		trade: 'متاجرة',
		'gains-and-losses': 'أرباح و خسائر ',
		budget: 'ميزانية',
		account: 'الحساب'
	},
	'default-account-names': {
		'sales-account': ' المبيعات',
		'purchases-account': ' المشتريات',
		'sales-return-account': 'حساب مرتجع المبيعات',
		'purchases-return-account': 'حساب مرتجع المشتريات',
		'the-discount-account-granted': 'الحسم الممنوح',
		'earned-discount-account': 'الحسم المكتسب',
		'tax-account': 'حساب الضريبة',
		'cash-customer': 'الزبون النقدي',
		'default-resource': 'المورد الافتراضي',
		'bank-account': 'حساب البنك الرئيسي',
		'bank-fee-account': ' العمولة البنكية',
		'main-cashBoxId': 'الصندوق الرئيسي',
		'vat-percent': 'نسبة الضريبة',
		'bank-fee-percent': 'نسبة العمولة البنكية',
		'account-cashboxes-reconciliation': 'حساب تسوية الصناديق النقدية',
		'vat-number': ' الرقم الضريبي'
	},
	'store-archive': 'أرشفة متجر',
	'store-default-settings': 'الإعدادت الافتراضية للمتجر',
	reappointment: ' تعيين',
	reset: 'إلغاء ',
	titles: {
		cashboxes: 'الصناديق',
		tax: 'الضريبة',
		'intermediary-accountsId': 'الحسابات الوسيطة',
		sales: 'المبيعات',
		purchase: 'المشتريات',
		bank: 'البنك'
	},
	currency: 'العملة',
	'attach-logo': 'إرفاق شعار',
	'import-store-materials': 'استيراد مواد متجر',
	address: 'العنوان',
	phone: 'رقم الهاتف',
	'please-log-in-to-the-store-context-to-add-settings': '(الرجاء الدخول إلى سياق المتجر لإضافة الإعدادات)',
	'export-item-tree': 'تصدير مواد شجرة',
	'import-item-tree': 'استيراد مواد شجرة',
	'confirm-import-of-material-tree': 'تأكيد استيراد شجرة المواد',
	'are-you-sure-you-want-to-import-the-material-tree-for-the-current-store?': 'هل أنت متأكد أنك تريد استيراد شجرة المواد للمتجر الحالي ؟',
	'the-material-tree-import-process-is-irreversible-and-may-take-some-time': 'إن عملية استيراد شجرة المواد لا يمكن التراجع عنها .. و قد يستغرق الأمر بعض الوقت',
	tracking: 'متابعة',
	'default-language': 'اللغة الافتراضية',
	importing: 'استيراد',
	exporting: 'تصدير',
	'export-import-tree-item': 'تصدير/استيراد شجرة مواد:',
	language: {
		arabic: 'العربية',
		english: 'الإنكليزية',
		spanish: 'الإسبانية'
	},
	'the-import-process-was-successful': 'تمت عملية الاستيراد بنجاح',
	'the-store-is-materials-file-has-been-successfully-exported-to-the-Downloads-folder': 'تم تصدير ملف المواد الموجودة في المتجر إلى مجلد التنزيلات بنجاح',
	'initial-number-of-sales-invoices': 'الرقم الأولي لفواتير البيع',
	'your-tax-report-results-will-be-affected': 'ستتأثر نتائج تقرير الضرائب',
	alert: 'تنبيه',
	'store-name-in-reports-(English)': 'اسم المتجر في التقرير (إنكليزي)',
	'store-name-in-reports-(Arabic)': 'اسم المتجر في التقرير (عربي) ',

	timezone: 'المنطقة الزمينة',
	'printed-invoice-title': 'عنوان الفاتورة المطبوعة',
}