import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		itemsInventory: {
			inventoryRows: []
		},
		accountStatementSummary: {
			sumDifference: {

			}
		},
		daySummary: {
			dayStatementReportRows: [
			
			],
			summary: {
				sumCredits: {
					amount: null
				},
				sumDebits: {
					amount: null
				},
				sumDifference: {
					amount: null
				}
			}
		},
		accountStatement: {
			accountStatementRows: [
			],
			summary: { sumCredits: {}, sumDebits: {}, sumDifference: {} }
		}
	},

	actions: {
		// item inventory
		fetchItemsInventory({ commit }, { materialId, withParents, itemsWithBalancesOnly }) {
			return axios.get('/Accounting/ItemsInventory', {
				params: { 
					materialId,
					withParents,
					itemsWithBalancesOnly
				} 
			}).then(({ data }) => {
				commit(types.STORE_ITEMS_INVENTORY, data);
				return data.inventoryRows
			})
		},
		// item statement 
		fetchItemStatement({ commit }, { itemId, fromDate, toDate }) {
			return axios.get('Accounting/ItemStatement', {
				params: {
					itemId,
					fromDate,
					toDate
				}
			}).then(({ data }) => {
				commit(types.STORE_ITEMS_INVENTORY, data);
				return data
			})
		},
		//  sell statement
		salesStatement({ commit }, { itemId, fromDate, toDate }) {
			return axios.get('Accounting/SalesStatement', {
				params: {
					itemId,
					fromDate,
					toDate
				}
			}).then(({ data }) => {
				commit(types.STORE_ITEMS_INVENTORY, data);
				return data
			})
		},

		fetchAccountStatement({ commit }, { accountId }) {
			return axios.get(`/Accounting/AccountStatement/${accountId}/Summary`).then(({ data }) => {
				commit(types.STORE_ACCOUNT_STATEMENT_SUMMARY, data);
				return data
			})
		},

		// day summary
		getDaySummary({ commit }, { forDate, cashboxId, compoundType }) {
			return axios.get('/Accounting/DayStatementReport', { 
				params: {
					forDate, cashboxId, compoundType 
				} 
			}).then(({ data }) => {
				commit(types.STORE_DAY_SUMMARY, data)
				return data
			})
		},

		// account statement
		getAccountStatement({ commit }, { accountId, fromDate, toDate }) {
			return axios.get('/Accounting/AccountStatement', { 
				params: { accountId, fromDate, toDate } 
			}).then(({ data }) => {
				commit(types.STORE_ACCOUNT_STATEMENT, data)
				return data
			})
		},

		fetchMatchingInventory({ commit }, { entries }) {
			return axios.put('/Accounting/ItemsInventory/Reconciliation', entries).then(({ data }) => {
				return data
			})
		},

		reset({ commit }) {
			return commit(types.RESET)
		}
	},

	mutations: {
		[types.STORE_ITEMS_INVENTORY](state, itemsInventory) {
			state.itemsInventory = itemsInventory;
		},

		[types.STORE_ACCOUNT_STATEMENT_SUMMARY](state, accountStatementSummary) {
			state.accountStatementSummary = accountStatementSummary
		},

		[types.STORE_DAY_SUMMARY](state, daySummary) {
			state.daySummary = daySummary
		},

		[types.STORE_ACCOUNT_STATEMENT] (state, accountStatement) {
			state.accountStatement = accountStatement
		},

		[types.RESET](state) {
			state.itemsInventory = {
				inventoryRows: []
			};
			state.accountStatementSummary = {
				sumDifference: {}
			};
			state.daySummary =	{
				dayStatementReportRows: [],
				summary: {
					sumCredits: {
						amount: null
					},
					sumDebits: {
						amount: null
					},
					sumDifference: {
						amount: null
					}
				}
			};
			state.accountStatement = {
				accountStatementRows: [
				],
				summary: { sumCredits: {}, sumDebits: {}, sumDifference: {} }
			}
		}
	},

	getters: {
	}
}