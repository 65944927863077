<template>
    <div class="bgColor rounded-t-xl">
        <v-row ma-0 class="px-3">
			<v-col align-self="center" cols="6" lg="3" md="3" sm="6" class="py-0">
				<v-autocomplete
					v-model="materialId"
					:items="items"
					:item-text="item => `${item.no} - ${item.name}`"
					item-value="id"
					dense
					hide-details
					outlined
					:placeholder=" $t('item-id-or-name') "
					:disabled="loading"
					clearable
					@keypress.enter="fetchItem(checkboxShowMainItem, checkboxItemWithQuantity)"
				></v-autocomplete>
			</v-col>
			
			<v-col cols="auto" class="py-0">
				<v-checkbox
					v-model="checkboxShowMainItem"
					:label="$t('ItemInventory.show-main-item')"
					class="d-inline mx-2"
					hide-details
					:disabled="loading"
					@change="noSelectedItem = false"
				></v-checkbox>
			</v-col>
        
			<v-col cols="auto" class="py-0">
				<v-checkbox
					v-model="checkboxItemWithQuantity"
					:label="$t('ItemInventory.items-with-quantities-only')"
					class="d-inline mx-2"
					hide-details
					:disabled="loading"
					@change="noSelectedItem = false"
				></v-checkbox>
			</v-col>
			<!-- search btn -->
			<v-col cols="auto" class="px-0">
				<v-btn 
					class="mx-2"
					fab
					x-small
					color="of-white"
					@click ="fetchItem(checkboxShowMainItem, checkboxItemWithQuantity)"
					:loading="loading"
					:disabled="loading || noSelectedItem"
				>
					<v-icon color="blue-dark">mdi-magnify</v-icon>
				</v-btn>
			</v-col>

			<!-- close btn -->
			<v-col cols="auto" class="px-0">
				<v-btn
					v-if="hideClose"
					class="mx-2"
					fab
					x-small
					color="of-white"
					@click="resetAndFetchAll"
					:disabled="loading || noSelectedItem"
				>
					<v-icon color="alert-color">mdi-close</v-icon>
				</v-btn>
			</v-col>

			<v-spacer />
	
			<v-col class="d-flex justify-end align-center">
				<v-btn 
					color="blue-dark white--text" 
					:disabled="loading || permissionsForUser.userType === userType.dataReader"
					:to="{ 
						name: 'inventory-matching',
						query: {
							preview: 1,
							material: materialId !== null ? materialId : null,
							withParents: checkboxShowMainItem, 
							itemsWithBalancesOnly: checkboxItemWithQuantity
						}
					}"	
				>
					{{$t('ItemInventory.inventory-matching')}}
				</v-btn>
			</v-col>
        </v-row>
		<v-col>
			<bee-handy-table 
				:headers="headers"
				:items="itemsInventory.inventoryRows"
				:zebra="!checkboxShowMainItem"
				:loading="loading"
				pagination-on-scroll
				:fixed-header="itemsInventory.inventoryRows.length !== 0"
				:data-table-props="{
					disableSort: true
				}"
				:shown-rows-text="$t('shown-rows')"
				dense
				:height="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.height - 190 : $vuetify.breakpoint.height - 200"
			></bee-handy-table>
		</v-col>

		<!-- footer -->
		<v-bottom-navigation v-if="0" class="mx-3 rounded-0"  fixed hide-on-scroll light scroll-threshold style="box-shadow: none" key="footer" >
			<v-container class="mx-0 w-100 ">

				<v-row justify="center" align="center">
					<v-col cols="3"> {{$t('footer.total-amount-entered')}}  </v-col>
					<v-col cols="3"> {{$t('footer.total-quantity-out')}} </v-col>
					<v-spacer v-if="$vuetify.breakpoint.lgAndUp"/>
					<v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
					<v-spacer v-if="$vuetify.breakpoint.lgAndUp" />
					<v-col cols="3"> {{$t('footer.the-final-balance')}} </v-col>
				</v-row>
				
			</v-container>
		</v-bottom-navigation >
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { userType } from '@/helpers/enums'
export default {
	name: 'ItemInventory',

	data() {
		return {
			materialId: null,
			checkboxShowMainItem: false,
			checkboxItemWithQuantity: true,

			hideClose: false,
			noSelectedItem: true,
			
			loading: false,
			userType
		}
	},
	watch: {
		materialId () {
			if (this.materialId !== null) {
				this.noSelectedItem = false;
			}
		}
	},
	computed: {
		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'no',
					value: 'index',
					setValueExpr: (val, index) => index + 1,
					noInput: true,
				},
				{
					text: this.$t('headers.item-name'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.items.find(c => c.id === val)?.name ?? val,
					noInput: true
				},
				{
					text: this.$t('headers.entered-quantity'),
					name: 'sumPurchasedQuantity',
					value: 'sumPurchasedQuantity.quantity',
					noNotAvailableText: true,
				},
				{
					text: this.$t('headers.outgoing-quantity'),
					name: 'sumSoldQuantity.quantity',
					value: 'sumSoldQuantity.quantity',
					noNotAvailableText: true,
				},
				{
					text: this.$t('headers.remaining-quantity'),
					name: 'quantity.quantity',
					value: 'quantity.quantity',
					noNotAvailableText: true,
					width: 200
				}
			]
			return headers
		},

		...mapState({
			items: state => state.items.items,
			itemsInventory: state => state.statisticsReports.itemsInventory,
			permissionsForUser: state => state.auth.permissionsForUser
		})
	},
	methods: { 
		resetAndFetchAll() {
			this.materialId = null
			this.checkboxShowMainItem = false;
			this.fetchItem(this.checkboxShowMainItem, this.checkboxItemWithQuantity)
		},
		fetchItem(withParents, itemsWithBalancesOnly) {
			this.loading = true;
			this.$store.dispatch('statisticsReports/fetchItemsInventory', {
				materialId: this.materialId, 
				withParents: withParents,
				itemsWithBalancesOnly: itemsWithBalancesOnly 
			}).then(() => {
				this.loading = false
				this.hideClose = true
			})
		}
	},

	created () {
		// this.$store.dispatch('journals/ar')
		if (this.itemsInventory !== null && this.itemsInventory.inventoryRows.length === 0) {
			this.fetchItem(this.checkboxShowMainItem, this.checkboxItemWithQuantity)
		} else {
			this.materialId = this.$route.query.materialId
			this.checkboxShowMainItem = this.$route.query.withParents
			this.checkboxItemWithQuantity = this.$route.query.itemsWithBalancesOnly
		}
	},
	metaInfo() {
		return {
			title: this.$t('routes.item-inventory'),
			titleTemplate: '%s | POS'
		}
	}

}
</script>
