<template>
	<v-text-field
		v-model="localValue"
		:label="label"
		:append-outer-icon="$i18n.locale === 'ar' ? 'mdi-chevron-left' : 'mdi-chevron-right'"
		:prepend-icon="$i18n.locale === 'ar' ? 'mdi-chevron-right' : 'mdi-chevron-left'"
		dense
		hide-details
		@click:prepend="
			index > 0
			? (() => {
				index--;
				$router.push({
					name: $route.name,
					params: { id: getItemByNo(itemsNumbers[index]).id }
				})
			})()
			: null;
		"
		@click:append-outer="
			index < itemsNumbers.length - 1
				? (() => {
					index++;
					$router.push({
						name: $route.name,
						params: { id: getItemByNo(itemsNumbers[index]).id }
					})
				})()
				: null
		"
		:disabled="disabled"
		@keypress.enter="goToSelectedItem()"
	></v-text-field>
</template>

<script>
export default {
	name: 'ItemsNavigator',

	props: {
		value: { type: [Number, String] },
		label: { type: String },
		disabled: { type: Boolean, default: false },
		items: { type: Array, default: () => [] },
		itemNo: { type: String, default: 'no' },
	},

	data() {
		return {
			index: 0,
			storedIndex: 0
		}
	},

	watch: {
		watchData() {
			if (this.value) {
				const newIndex = this.itemsNumbers.indexOf((this.value).toString());
				
				if (newIndex !== -1) {
					this.index = newIndex;
				} else {
					this.index = this.storedIndex;
				}
			}
		}
	},

	computed: {
		localValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal)
			}
		},

		itemsNumbers() {
			return this.items.map(c => (c[this.itemNo]).toString());
		},

		watchData() {
			return `${this.value} | ${this.items.length}`
		}
	},

	methods: {
		getItemByNo(no) {
			return this.items.find(c => (c[this.itemNo]).toString() === no);
		},

		goToSelectedItem() {
			this.storedIndex = this.index;
			const item = this.getItemByNo(this.itemsNumbers[this.index]);

			if (item && item.id !== this.$route.params.id) {
				this.$router.push({
					name: this.$route.name,
					params: {
						id: item.id
					}
				})
			}
		},
	},

	created() {
		if (this.value) {
			this.index = this.itemsNumbers.indexOf((this.value).toString());
			this.storedIndex = this.index;
		}
	}
}
</script>

<style>

</style>