import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		currencies: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/currencies').then(({ data }) => {
				commit(types.STORE_CURRENCIES, data);
				return data
			})
		},
		reset({ commit }) {
			return commit(types.RESET)
		}
	},

	mutations: {
		[types.STORE_CURRENCIES] (state, currencies) {
			state.currencies = currencies
		},

		[types.RESET] (state) {
			state.currencies = []
		}
	}
}