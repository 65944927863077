export default {
	opened: 'مفتوح',
	closed: 'مغلق',
	store: 'المتجر',
	worked: 'يعمل',
	stopped: 'متوقف',
	'power-type': ' نوع الصلاحية',
	'archive-store': 'أرشفة متجر',
	'unarchive-a-store': 'إلغاء أرشفة متجر',
	'are-you-sure-you-want-to-archive-this-store': 'هل متأكد من أنك تريد أرشفة هذا المتجر ؟',
	'are-you-sure-you-want-to-unarchive-this-store': 'هل متأكد من أنك تريد إلغاء أرشفة هذا المتجر ؟',
	'the-store-has-been-successfully-archived': 'تم أرشفة المتجر بنجاح',
	'the-store-has-been-successfully-unarchived': 'تم إلغاء أرشفة المتجر بنجاح'
}