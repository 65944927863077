<template>
	<div>
		<v-row>
			<v-col lg="3" sm="4" cols="12" class="tree-col rounded-lg bgColor">
				<v-row class="align-center mt-0 mb-2">
					<!-- search autocomplete -->
					<v-col md="8" cols="12" class="px-2 pt-0 pb-md-0 pb-1">
						<v-autocomplete
							v-model="searchInTree"
							:items="items"
							:label="$t('item-id-or-name')"
							item-value="id"
							:item-text="item => `${item.no} - ${item.name}`"
							outlined
							dense
							hide-no-data
							hide-details
							auto-select-first
							@change="selectedAccountIsUpdated"
							:disabled="loading"
						></v-autocomplete>
					</v-col>

					<!-- add button -->
					<v-col md="4" cols="12" class="px-2 pt-0 pb-md-0 pb-1">
						<v-btn
							:to="
								activeNodes[0]
								? { name: 'item', params: { id: activeNodes[0] }, query: { addSub: 1 } }
								: { name: 'item' }
							"
							elevation="1"
							color="primary"
							class="px-2"
							:disabled="loading || disableAddBtn || currentItemIsSub ||  permissionsForUser.userType === userType.dataReader"
						>
							<v-icon class="me-1">mdi-seed-plus-outline</v-icon>
							{{$t('add')}}
						</v-btn>
					</v-col>
				</v-row>

				<!-- tree -->
				<v-progress-linear v-if="loading" indeterminate class="rounded"/>
				<bee-treeview
					:items="itemsTree"
					:itemOpen.sync="itemOpen"
					:active.sync="activeNodes"
					:open.sync="openNodes"
					hide-expand-icon
					active-class="deep-orange--text text--darken-1"
					@update:clicked-item="previewItem($event.id); openNodes.push($event.id)"
					:disabled="disableAddBtn"
					:itemClass="(item, itemDate) =>
						item.category === itemCategoryType.manufactured
							? 'blue-light--text'
							: item.type === type.main
								? 'font-weight-medium primary--text'
								: ''
					"
				>
					<template v-slot:prepend-label="{ item }">
						{{item.no + '-'}}
					</template>

					<template v-slot:prepend="{ item, open, leaf }">
						<v-icon
							v-if="!leaf"
							class="me-0"
							@click="
								open && !disableAddBtn
								? openNodes.splice(openNodes.indexOf(item.id), 1)
								: openNodes.push(item.id)"
						>
							{{open ? 'mdi-minus' : 'mdi-plus'}}
						</v-icon>
					</template>
				</bee-treeview>
			</v-col>
			<v-col lg="9" sm="8" cols="12">
				<!-- child item -->
				<transition name="fade" mode="out-in">
					<div
						v-if="$route.name === 'items-tree'"
						class="d-flex flex-column justify-center align-center"
						style="height: 85vh;"
					>
						<div>
							<v-img
								src="@/assets/empty-space-imgs/no-data.png"
								contain
								width="350"
								height="250"
								transition="scale-transition"
								lazy-src="@/assets/placeholder-image.png"
							/>
							<p class="primary--text text-h6 text-center mb-0">
								{{$t('Item.select-item')}}
							</p>
						</div>
					</div>
					<router-view
						@update-tree-items="updateTreeAccounts(true)"
						@disable-add="disableAddBtn = true"
						@enable-add="disableAddBtn = false"
						v-else
					></router-view>
				</transition>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { itemCategoryType, type, userType } from '@/helpers/enums'
export default {
	name: 'ItemsTree',

	data() {
		return {
			// helpers
			disableAddBtn: false,

			loading: false,
			searchInTree: null,
			itemOpen: null,
			activeNodes: [],
			openNodes: [],
			
			type,
			itemCategoryType,

			itemss: [
				{ 
					id: 3,
					name: 'test-test',
					children: [
						{ id: 22, name: 'test-test-test2', children: [{ id: 122, name: 'test-test-test2' }] }
					]
				},
				{ 
					id: 1,
					name: 'test-test-test',
					children: [
						{ 
							id: 33,
							name: 'test-test-test2',
							children: [
								{ 
									id: 133,
									name: 'test-test-test2',
									children: [
										{ 
											id: 335,
											name: 'test-test-test2',
											children: [
												{ id: 1533, name: 'test-test-test2' }
											]
										}
									]
								}
							]
						}
					]
				}
			],
			itemsAuto: [
				{ id: 3, name: 'test-test-test' },
				{ id: 22, name: 'test-test-test2' },
				{ id: 122, name: 'test-test-test2' },
				{ id: 1, name: 'test-test-test' },
				{ id: 33, name: 'test-test-test2' },
				{ id: 133, name: 'test-test-test2' },
				{ id: 335, name: 'test-test-test2' },
				{ id: 1533, name: 'test-test-test2' }
			],
			userType
		}
	},

	watch: {
		watchRoute(val) {
			const routeDataArray = val.split(' | ');
			const routData = { name: routeDataArray[0], id: routeDataArray[1], preview: routeDataArray[2] }
			if (routData.name === 'items-tree' || !routData.id) {
				this.activeNodes = [];
			}
		}
	},

	computed: {
		watchRoute() {
			return `${this.$route.name} | ${this.$route.params.id} | ${this.$route.query.preview}`
		},

		...mapState({
			items: state => state.items.items,
			itemsTree: state => state.items.itemsTree,
			permissionsForUser: state => state.auth.permissionsForUser
		}),

		...mapGetters({
			getItemById: 'items/getItemById'
		}),

		currentItemIsSub() {
			if (this.$route.params.id) {
				return this.getItemById(this.$route.params.id).type === type.sub;
			} else {
				return false
			}
		}
	},

	methods: {
		selectedAccountIsUpdated() {
			this.itemOpen = this.searchInTree;
			this.previewItem(this.searchInTree);
			this.updateTree();
		},

		previewItem(id) {
			if (this.$route.fullPath !== `/items-tree/item/${id}?preview=1`) {
				this.$router.push({ name: 'item', params: { id: id }, query: { preview: 1 } });
			}
		},

		updateTree() {
			if (this.$route.name === 'item' && this.$route.params.id) {
				this.itemOpen = this.$route.params.id;
				this.activeNodes = [this.$route.params.id];
			}
		},

		updateTreeAccounts(isForceUpdate) {
			this.loading = true;
			Promise.all([
				isForceUpdate || this.items.length === 0
					? this.$store.dispatch('items/fetchAll')
					: null,
				isForceUpdate || this.itemsTree.length === 0
					? this.$store.dispatch('items/fetchAllAsTree', { sellableOnly: false })
					: null
			]).finally(() => {
				this.openPathToCurrentItem();
				this.updateTree();
				this.loading = false;
			})
		},

		openPathToCurrentItem() {
			if (this.$route.params.id) {
				const getPathByItemName = (items, path, id) => {
					items.some(item => {
						if (item.id === this.$route.params.id) { 
							this.openNodes.push(...path);
							return true;
						} else {
							getPathByItemName(item.children || [], [...path, item.id], id); 
							return false;
						}
					});
				}

				getPathByItemName(this.itemsTree, [], this.$route.params.id);
			}
		}
	},

	created() {
		this.updateTreeAccounts();
	},

	mounted() {
		this.updateTree();
	},

	metaInfo() {
		return {
			title: this.$t('routes.items-tree'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>

<style lang="scss" scoped>
.tree-col {
	@media screen and (min-width: 600px) {
		height: 92vh;
	}
}
</style>