<template>
    <div>
        <v-dialog
            v-model="localValue"
            transition="dialog-transition"
            width="400"
            ref="dialog"
            content-class="rounded-medium"
        >
            <template v-slot:default="dialog">
                <v-card class="rounded-medium">
                    <v-card-title class="py-1">
                        <v-spacer/>
                        <span class="alert-color--text ms-8">{{$t('confirm-delete')}}</span>
                        <v-spacer/>
                        <v-btn @click="localValue = false" icon dark>
							<v-icon color="alert-color">mdi-close</v-icon>
						</v-btn>
                    </v-card-title>
                    <v-divider class="mx-4" />
                    <v-card-text class="pa-0 pl-2">
                        <!-- dialog content -->
                        <v-container>
                            <!-- default slot -->
                            <slot></slot>
                        </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn
                            height="34"
                            :loading="loading"
                            @click="deleteData"
                            color="red lighten-1"
                            dark
                            class="rounded-small"
                            elevation="1"
                        >
                            {{$t('delete')}}
                        </v-btn>
                        <v-btn
                            height="34"
                            :disabled="loading"
                            @click="dialog.value = false"
                            elevation="1"
                            class="rounded-small"
                        >
                            {{$t('cancel')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            transition="slide-y-reverse-transition"
            color="red lighten-1 white--text"
            class="text-body-2"
        >
            {{ message }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
export default {
	name: 'BeeDeleteDialog',
	props: {
		value: { type: Boolean, default: false },
		moduleType: { type: String, required: true },
		ids: { type: [Number, String, Object] },
		actionName: { type: String, default: 'delete' }
	},

	data() {
		return {
			snackbar: false,
			message: null,

			loading: false,
		}
	},

	methods: {
		deleteData() {
			this.loading = true;

			let actionData = {};
			
			if (typeof this.ids === 'number' || typeof this.ids === 'string') {
				actionData = { id: this.ids };
			} else if (this.ids && Object.keys(this.ids).length > 0) {
				actionData = this.ids;
			}

			this.$store.dispatch(`${this.moduleType}/${this.actionName}`, actionData)
				.then(() => {
					this.message = this.$t('messages.deleted-successfully');
					this.$emit('on-delete');
					this.$refs.dialog.isActive = false;
				})
				.catch((e) => {
					// this.message = e.response.data.message;
				})
				.finally(() => {
					this.snackbar = true;
					this.loading = false;
				});
		},
	},

	computed: {
		localValue: {
			get: function () {
				return this.value;
			},
			set: function (newValue) {
				this.$emit('input', newValue);
			},
		}
	}
}
</script>

<style>

</style>