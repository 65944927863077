<template>
	<div class="bgColor rounded-t-xl" style="height: 90vh">
		<!-- delete dialog -->
		<bee-delete-dialog
			v-model="deleteDialog"
			moduleType="users"
			:ids="userIdDelete"
			@on-delete="fetchAll"
		>
			{{$t('do-you-want-to')}} 
			<span class="text-uppercase error--text">{{$t('delete') +'  '+ getUserById(userIdDelete)?.name + '؟'}}</span>
		</bee-delete-dialog>

		<!-- dialog -->
		<v-dialog v-model="dialog" width="400px">
			<v-card>
				<v-form @submit.prevent="accountStatusChange()">
					<v-card-title class="py-1">
						<v-spacer/>
						<span
							:class="userActivatedId !== null ?  
								getUserById(userActivatedId).isActive ? 
								'alert-color--text' : 
							'primary--text' :
							'' "
						> 
							{{ $t('Users.edit-account-status')}}
						</span>
						<v-spacer/>
						<v-btn icon dark @click="dialog = false">
							<v-icon small :color="userActivatedId !== null ?  getUserById(userActivatedId).isActive ? 'alert-color' : 'primary' : '' ">
								mdi-close
							</v-icon>
						</v-btn>
					</v-card-title>
					<v-divider class="mx-4" />
					<v-card-text class="pt-3">
						{{ 
							userActivatedId !== null ? 
							getUserById(userActivatedId).isActive ? 
								$t('Users.are-you-sure-to-deactivate-an-account') 
								: $t('Users.are-you-sure-to-activate-an-account') 
							: '' 
						}} 
						{{
							userActivatedId !== null ? 
							getUserById(userActivatedId).name
							: '' 
						}}
						؟ 
					</v-card-text>

					<v-card-actions class="justify-end">
						<v-btn
							type="submit"
							:loading="loading"
							:disabled="loading"
							:color="userActivatedId !== null ? 
								getUserById(userActivatedId).isActive ? 
								'alert-color white--text'
								:'primary' 
							: '' "
							>
							{{$t('ok')}}
						</v-btn>
						<v-btn text @click="dialog = false" :disabled="loading"> {{$t('cancel')}} </v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
		<!-- dialog power-->
		<v-dialog v-model="dialogPower" width="700px">
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span class="primary--text"> 
						{{ userId !== null ? $t('Users.user-edit') : $t('Users.user-create') }}
					</span>
					<v-spacer/>
					<v-btn
						icon
						dark
						@click="dialogPower = false"
						class="d-flex justify-end"
					>	
						<v-icon small color="primary" @click="userId = null">
							mdi-close
						</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider class="mx-4" />
				<!-- info user -->
				<v-form ref="form">
					<v-card-text class="pt-3">
						<v-row>
							<v-col cols="12" class="pb-0"> 
								<p class="mb-0">
									{{$t('Users.user-info')}}	
								</p>
							</v-col>
							<v-col>
								<v-text-field
									v-model="submitData.name"
									:label="$t('forms.name')"
									outlined
									dense
									hide-details="auto"
									:rules="rules.required"
								/>	
							</v-col>

							<v-col>
								<v-text-field
									v-model="submitData.username"
									:label="$t('forms.user-name')"
									outlined
									dense
									hide-details="auto"
								/>
							</v-col>
						</v-row>

						<!-- password -->
						<v-row>
							<v-col>
								<v-text-field
									v-model="submitData.password"
									:type="showPassword ? 'text' : 'password'"
									:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showPassword = !showPassword"
									:label="$t('forms.password')"
									:placeholder="userId ? $t('Users.optional') : ''"
									outlined
									dense
									:rules="userId ? [] : rules.password"
									hide-details="auto"
								/>					
							</v-col>
							<v-col>
								<!-- :rules="userId ? [] : rules.password" -->
								<v-text-field
									v-model="submitData.confirmPassword"
									:type="showConfirmPassword ? 'text' : 'password'"
									:append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
									@click:append="showConfirmPassword = !showConfirmPassword"
									:label="$t('forms.confirmPassword')"
									:placeholder="userId ? $t('Users.optional') : ''"
									outlined
									dense
									:rules="submitData.password && userId === null ? [val => val === submitData.password || $t('messages.not-matched-password')] : []"
									hide-details="auto"
								/>
							</v-col>
						</v-row>

						<v-divider class="mt-4 mb-2"/>

						<!-- power (permations) -->
						<p>{{$t('Users.user-power')}}</p>
						<bee-handy-smart-table
							v-model="tableInput"
							ref="table"
							:headers="headersSmart"
							:items="entries"
							:items-per-page="-1"
							show-add-row
							stay-in-input-fields
							:loading="loadingPermissions"
							:data-table-props="{
								disableSort: true,
								noDataText: $t('entery-value-and-prass-enter')
							}"
							dense
							:editable-rows="!loadingPermissions"
							:disable-input-fields="loadingPermissions"
							@edit-cancelled="isEditItem = false"
							@reached-last-input="!isEditItem ? addItem() : editItem()"
							@keypress.enter="!isEditItem ? addItem() : editItem()"
							hide-default-footer
							@context-menu-edit="setRowIndex"
							class="mb-3"
							:validation-messages.sync="validationMessages"
						>	
							<template v-slot:item.actions="{ index }">
								<div class="d-flex">
									<!-- edit -->
									<v-btn
										text 
										small
										color="edit-color"
										:disabled="loadingPermissions"
										@click="$refs.table.activeEditForRow(index); setRowIndex({ index })"
									>
										<v-icon>mdi-pencil</v-icon>
									</v-btn>

									<!-- delete -->
									<v-btn	
										text
										small
										color="alert-color"
										:disabled="loadingPermissions"
										@click="deleteItem({ index })"
									>
										<v-icon>mdi-delete</v-icon>
									</v-btn>
								</div>
							</template>					
							<!-- storeId -->
							<template v-slot:input.storeId="{ on, attr }">
								<v-autocomplete
									:items="stores"
									item-value="id"
									item-text="name"
									auto-select-first
									v-on="on"
									v-bind="attr"
									hide-details
								></v-autocomplete>
							</template>

							<template v-slot:input.userType="{ on, attr }">
								<v-autocomplete
									:items="powersArray"
									item-text="name"
									item-value="id"
									dense
									v-on="on"
									v-bind="attr"
									hide-details
								/>
							</template>

							<template v-slot:input.cashboxId="{ on, attr }">
								<v-autocomplete
									:items="cashboxSystem.filter(c => c.storeId === tableInput.storeId)"
									item-text="name"
									item-value="id"
									dense
									hide-details="auto"
									v-on="on"
									v-bind="attr"
								/>
							</template>
						</bee-handy-smart-table>
					</v-card-text>

					<v-card-actions >
						<v-spacer />	
						<v-btn
							color="primary"
							:loading="loading"
							:disabled="loading"
							@click="submit"
						> 
							{{$t('save')}}
						</v-btn>
						<v-btn
							color="alert-color white--text"
							@click="dialogPower = false"
							:disabled="loading"
						> 
							{{$t('cancel')}} 
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>

		<!-- button -->
		<v-row justify="start" class="px-3">
			<v-col class="d-flex justify-end">
				<v-btn color="primary" @click="dialogPower = true">
					<v-icon class="me-1">mdi-file-document-plus-outline</v-icon>
					{{$t('Users.user-create')}}
				</v-btn>
			</v-col>
		</v-row>

		<!-- table -->
        <bee-handy-table
           :headers="headers"
           :items="users"
			class="my-3 px-3"
			zebra
			:loading="loading"
			fixed-header
			pagination-on-scroll
			:height="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.height - 180 : $vuetify.breakpoint.height - 180 "
			:shown-rows-text="$t('shown-rows')"
        >
			<!-- status account -->
			<template v-slot:item.isActive="{ item }">
				<v-chip :color="item.isActive ? 'success' : 'alert-color white--text' " small>
					{{ item.isActive ? $t('Users.active') : $t('Users.stopped') }}
				</v-chip>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<v-btn
					text
					small
					:disabled="item.isAdmin"
					color="primary"
					@click="dialog = true; userActivatedId = item.id"
				>
					<v-icon> mdi-account-reactivate</v-icon>
				</v-btn>

				<v-btn
					text
					small
					:disabled="item.isAdmin"
					color="edit-color"
					@click="userId = item.id; dialogPower = true; permissionsUser(userId)"
				>
					<v-icon>mdi-pencil</v-icon>
				</v-btn>

				<v-btn
					text
					small
					:disabled="item.isAdmin"
					color="alert-color"
					@click="userIdDelete = item.id; deleteDialog = true"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</template>
		</bee-handy-table>
    </div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapState, mapGetters } from 'vuex'
import { userType } from '@/helpers/enums'
export default {
	data() {
		return {
			tableInput: {},
			showPassword: false,
			showConfirmPassword: false,
			deleteDialog: false,
			dialog: false,
			dialogPower: false,
			rules,
			userId: null,
			userIdDelete: null,
			userActivatedId: null,
			submitData: {
				name: null,
				username: null,
				password: null,
				confirmPassword: null,
			},
			loading: false,
			loadingPermissions: false,
			show: false,
			userPermissions: [],
			entries: [],
			isEditItem: false,
			rowEditIndex: null,
			userType,
			validationMessages: []
		}
	},
	watch: {
		dialogPower(val) {
			if (val === true) {
				if (this.userId) {
					this.submitData.name = this.getUserById(this.userId).name
					this.submitData.username = this.getUserById(this.userId).username
					this.submitData.password = this.getUserById(this.userId).password
				} else {
					this.submitData.name = null
					this.submitData.username = null
					this.submitData.password = null
					this.submitData.confirmPassword = null
				}
			} else {
				this.userId = null
				this.$refs.form.resetValidation()
				this.entries = []
				this.$refs.form.reset()
			}
		},
	},
	computed: {	
		...mapState({
			stores: state => state.stores.stores,
			cashboxes: state => state.cashboxes.cashboxes,
			users: state => state.users.users,
			cashboxSystem: state => state.cashboxes.cashboxSystem
		}),
		...mapGetters({
			getUserById: 'users/getUserById',
			getStoreById: 'stores/getStoreById',
			getCashboxById: 'cashboxes/getCashboxSystemById'
		}),
		headers() {
			const headers = [
				{
					text: 'no',
					name: '#',
					value: 'id',
					setValueExpr: (val, index) => index + 1
				},
				{
					text: this.$t('headers.name'),
					name: 'name',
					value: 'name'
				},
				{
					text: this.$t('headers.user-name'),
					name: 'username',
					value: 'username'
				},
				{
					text: this.$t('headers.status-account'),
					name: 'isActive',
					value: 'isActive'
				}, 
				{
					text: '',
					name: 'actions',
					value: 'actions',
				}
			]
			return headers
		},
		headersSmart() {
			const headersSmart = [
				{
					text: this.$t('Users.store-name'),
					name: 'storeId',
					value: 'storeId',
					setValueExpr: val => this.getStoreById(val)?.name,
					class: 'text-caption'
				},
				{
					text: this.$t('Users.power-type'),
					name: 'userType',
					value: 'userType',
					setValueExpr: val => val === userType.casher ? this.$t('Users.casher') : this.$t('Users.executive-director'),
					inputRules: this.tableInput.storeId && this.tableInput.userType === null ? rules.required : [],
					class: 'text-caption'
				},
				{
					text: this.$t('forms.cashbox'),
					name: 'cashboxId',
					value: 'cashboxId',
					setValueExpr: val => this.getCashboxById(val)?.name,
					class: 'text-caption'
				},
				{
					text: '',
					name: 'actions',
					value: 'actions',
					noInput: true,
				}
			]
			return headersSmart
		},
		powersArray() {
			const powersArray = [
				{
					name: this.$t('Users.casher'),
					id: userType.casher
				}, 
				{
					name: this.$t('Users.executive-director'),
					id: userType.manager
				},
				{
					name: this.$t('Users.viewer'),
					id: userType.dataReader
				}
			]
			return powersArray
		}
	},
	methods: {
		fetchAll () {
			this.loading = true
			this.$store.dispatch('users/fetchAll').finally(() => {
				this.loading = false
			})
		},
		submit() {
			const actionName = this.userId !== null ? 'update' : 'create';
			if (this.$refs.form.validate()) {
				this.loading = true
				Promise.all([
					this.$store.dispatch(`users/${actionName}`, {
						id: this.userId ? this.userId : null, 
						name: this.submitData.name,
						username: this.submitData.username, 
						password: this.submitData.password, 
						confirmPassword: this.submitData.confirmPassword
					}).then((data) => {
						this.$eventBus.$emit('show-snackbar', false, actionName === 'create' ? this.$t('Users.the-user-has-been-added-successfully') : this.$t('Users.user-data-has-been-modified-successfully'));
						// if (this.entries.length !== 0) {
						if (actionName === 'create') {
							this.addPermission(data)
						} else {
							this.addPermission(this.userId)
						}
						// }
						this.fetchAll()
						this.dialogPower = false
					}).catch((e) => {
						this.message = e.response.data.message;
						this.$eventBus.$emit('show-snackbar', true, this.message);
					}).finally(() => {
						this.loading = false
					}),
				])	
			}
		}, 
		accountStatusChange() {
			this.loading = true
			const actionName = this.getUserById(this.userActivatedId).isActive === true ? 'deactivate' : 'activate'
			this.$store.dispatch(`users/${actionName}`, { id: this.userActivatedId }).finally(() => {
				this.fetchAll()
				this.loading = false
				this.dialog = false
			})
		},

		addItem() {
			if (this.$refs.table.validate()) {
				this.entries.push({
					...this.tableInput
				})
				this.$nextTick(() => {
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus();
				})
			}
		},

		editItem() {
			if (this.$refs.table.validate()) {
				this.entries[this.rowEditIndex].storeId = this.tableInput.storeId
				this.entries[this.rowEditIndex].userType = this.tableInput.userType
				this.entries[this.rowEditIndex].cashboxId = this.tableInput.cashboxId;
				this.$refs.table.resetEdit();
				this.isEditItem = false;
				
				this.$nextTick(() => {
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus();
				})
			}
		},

		resetTableInputs() {
			this.$refs.table.resetInputs({
				storeId: null,
				userType: '1',
				cashboxId: null
			})
		},

		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus();
			})
		},

		deleteItem({ index }) {
			this.$refs.table.resetEdit();
			this.isEditItem = false;
			this.entries.splice(index, 1);
			
			this.$nextTick(() => {
				this.resetTableInputs();
				this.$refs.table.resetInputsFocus();
			})
		},

		addPermission(id) {
			this.$store.dispatch('users/addPermissions', { 
				id: id,
				entryPermissions: this.entries
			}).then(() => {
				this.fetchAll()
			}).catch((e) => {
				this.message = e.response.data.message;
				this.$eventBus.$emit('show-snackbar', true, this.message);
			})
		},
		permissionsUser(userId) {
			this.loadingPermissions = true;
			this.entries = []
			this.$store.dispatch('stores/fetchAll', {})
			this.$store.dispatch('users/permissionsUser', { 
				id: userId
			}).then((data) => {
				for (let i = 0; i < data.length; i++) {
					this.entries.push({
						storeId: data[i].storeId,
						cashboxId: data[i].cashboxId,
						userType: data[i].userType
					})
				}
			}).finally(() => {
				this.loadingPermissions = false
			})
		}
	},
	created() {
		this.fetchAll()
		this.$store.dispatch('cashboxes/fetchCashboxSystem')
		if (this.stores.length === 0) {
			this.$store.dispatch('stores/fetchAll')
		}
	},
	metaInfo() {
		return {
			title: this.$t('routes.users'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>
