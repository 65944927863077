<template>
    <div>
		<!-- tabs -->
        <v-tabs v-model="tab">
            <v-tab
                v-for="(report, index) in reports"
                :key="index"
                class="text-capitalize"
				@change="resetFilter(); isReportGenerated = null; $refs.form.reset()"
            >
                {{ report.name }}
            </v-tab>
            <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-divider class="mx-1"/>
       
		<!-- filter -->
		<v-form ref="form" @submit.prevent="$refs.form.validate() ? getReport() : null">
			<v-row class="mt-1">
				<!-- from date -->
				<v-col cols="6" lg="2" md="3" sm="4">
					<bee-date-input
						v-model="fromDate"
						:label="$t('forms.from-date')"
						input-format-order="DD-MM-YYYY"
						prepend-icon="mdi-calendar"
						dense
						hide-details
						user-menu-picker
						:disabled="loading"
						:rules="[2, 4].includes(tab) ? rules.required : []"
					></bee-date-input>
				</v-col>
			
				<!-- to date -->
				<v-col cols="6" lg="2" md="3" sm="4">
					<bee-date-input
						v-model="toDate"
						:label="$t('forms.to-date')"
						input-format-order="DD-MM-YYYY"
						prepend-icon="mdi-calendar"
						dense
						hide-details
						user-menu-picker
						:disabled="loading"
						:rules="[2, 4].includes(tab) ? rules.required : []"
					></bee-date-input>
				</v-col>
				
				<!-- item -->
				<v-col v-if="tab === 0" cols="6" lg="2" md="3" sm="4">
					<v-autocomplete		
						v-model="itemId"
						:items="items"
						item-text="name"
						item-value="id"
						outlined
						dense
						:label="$t('Reports.item')"
						hide-details
						:rules="rules.required"
					/>
				</v-col>
			
				<!-- accounts -->
				<v-col v-if="tab === 1 || tab === 3" cols="6" lg="2" md="3" sm="4">
					<v-autocomplete
						v-model="accountId"
						:items="accounts"
						item-text="name"
						item-value="id"
						outlined
						dense
						:label="$t('Reports.account')"
						hide-details
						:rules="rules.required"
					/>
				</v-col>
			
				<v-col>
					<v-btn
						:loading="loading"
						:disabled="excelLoading || saveLoading"
						color="blue-dark white--text"
						type="submit"
					>
						<v-icon class="me-1"> mdi-eye </v-icon>
						{{$t('show')}}
					</v-btn>

					<v-btn 
						v-if="tab === 4"
						@click="getReportExcel()"
						color="primary white--text"
						class="mx-1"
						:loading="excelLoading"
						:disabled="loading || saveLoading"
					>
						{{$t('Reports.download-in-excel-format')}}
					</v-btn>
				</v-col>
			
				<template v-if="isReportGenerated">
					<v-spacer/>
					<v-col cols="auto">
						<v-btn
							:loading="downloadLoading"
							color="blue-dark white--text"
							@click="$refs.form.validate() ? getReport(true) : null"
						>
							<v-icon class="me-1"> mdi-tray-arrow-down </v-icon>
							{{$t('save')}}
						</v-btn>
					</v-col>
				</template>
			</v-row>
		</v-form>
		
		<!-- preview area -->
		<iframe
			v-show="isReportGenerated"
			ref="iframe"
			width="100%"
			:height="$vuetify.breakpoint.height - 190"
			class="preview-area rounded-medium mt-3"
		/>
    </div>
</template>
<script>
import { downloadBlob } from '@/helpers/functions';
import { mapState } from 'vuex'
import rules from '@/helpers/validation rules';

export default {
	data() {
		return {
			tab: 0,
			rules,
			excelLoading: false,

			reports: [
				{
					id: 1,
					name: this.$t('Reports.tabs-title.detailed-sales-statement-for-an-item')
				},
				{
					id: 2,
					name: this.$t('Reports.tabs-title.total-sales-to-a-customer')
				},
				{
					id: 3,
					name: this.$t('Reports.tabs-title.total-sales')
				},
				{
					id: 4,
					name: this.$t('Reports.tabs-title.detailed-account-statement')
				},
				{
					id: 5,
					name: this.$t('Reports.tabs-title.vat-statement')
				}
			],
			isReportGenerated: false,
			downloadLoading: false,
			
			fromDate: null,
			toDate: null,
			loading: false,
			itemId: null,
			accountId: null,
			saveLoading: false
		}
	},

	computed: {
		...mapState({
			items: state => state.items.items,
			accounts: state => state.accounts.accounts
		})
	},

	methods: {
		getReport(isDownload = false, isExcel = false) {
			this.loading = !isDownload;
			this.downloadLoading = isDownload;
			this.saveLoading = true

			let moduleName;
			switch (this.tab) {
			case 0: moduleName = 'totalMaterialsSales'; break;
			case 1: moduleName = 'ItemsSalesForAccount'; break;
			case 2: moduleName = 'accountsSales'; break;
			case 3: moduleName = 'detailedAccountStatement'; break;
			case 4: moduleName = 'vatStatement'; break;
			}
			this.$store.dispatch(`reports/${moduleName}`, {
				storeId: this.$localStorage.storeId, 
				itemId: this.itemId,
				fromDate: this.fromDate, 
				toDate: this.toDate,
				accountId: this.accountId,
				isDownload,
				isExcel,
			}).then(data => {
				if (!isDownload) {
					let iframe = this.$refs.iframe;
					iframe = iframe.contentWindow || (iframe.contentDocument.document || iframe.contentDocument);
					iframe.document.open();
					iframe.document.write(data);
					iframe.document.close();
					this.isReportGenerated = true;
				} else {
					let fileName;
					switch (this.tab) {
					case 0: fileName = 'Detailed material sales statement'; break;
					case 1: fileName = 'Sales for an account'; break;
					case 2: fileName = 'Total material sales'; break;
					case 3: fileName = 'Account statement'; break;
					case 4: fileName = 'VAT statement'; break;
					}
					downloadBlob(data, fileName);
				}
			}).finally(() => {
				this.downloadLoading = false;
				this.loading = false;
				this.saveLoading = false
			})
		},

		getReportExcel() {
			this.excelLoading = true
			this.downloadLoading = false;
			this.loading = false;
			this.saveLoading = false
			this.$store.dispatch('reports/vatStatement', {
				storeId: this.$localStorage.storeId, 
				itemId: this.itemId,
				fromDate: this.fromDate, 
				toDate: this.toDate,
				accountId: this.accountId,
				isExcel: true,
				isDownload: true,
			}).then((data) => {
				const fileName = 'VAT statement'
				downloadBlob(data, fileName)
			}).finally(() => {
				this.excelLoading = false
			})
		},

		resetFilter() {
			this.itemId = null
			this.fromDate = null 
			this.toDate = null
			this.accountId = null
		}
	},

	metaInfo() {
		return {
			title: this.$t('routes.reports'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>

<style>
.preview-area {
	border: 1px solid #ccc;
}
</style>