<template>
	<div class="d-flex flex-column justify-center log" >
		<div >
			<v-form ref="form" @submit.prevent="login" class="width mg-auto">
				<div class="d-flex flex-column justify-center align-center">
					<v-img 
						src="@/assets/logo.png"
						max-width="150px"
						max-height="200px"
						contain
						class="my-4"
					/>
					<p class="text-h4 primary--text mb-3">Emerald POS</p>
				</div>
				
				<v-row>
					<v-col cols="12" class="">
						<v-text-field
							v-model="username"
							:label="$t('forms.user-name')"
							outlined
							dense
							hide-details="auto"
							:rules="rules.required"
						/>
					</v-col>

					<v-col cols="12" class="">
						<v-text-field
							v-model="password"
							:label="$t('forms.password')"
							:type="showPassword ? 'text' : 'password'"
							:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
							@click:append="showPassword = !showPassword"
							outlined
							dense
							hide-details="auto"
							:rules="rules.required"
						/>
					</v-col>

					<v-col class="text-center ">
						<v-btn
							color="primary white--text"
							width="100%"
							type="submit"
							:loading="loading"
							:disabled="loading"
						>
							{{ $t('Login.login') }}
						</v-btn>
					</v-col>
				</v-row> 
			</v-form>
		</div>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
export default {
	data() {
		return {
			showPassword: false,
			username: null,
			password: null,
			rules,
			loading: false,
		}
	},
	methods: {
		login() {
			if (this.$refs.form.validate()) {
				this.loading = true
				this.$store.dispatch('auth/login', {
					username: this.username,
					password: this.password
				}).then(() => {
					this.$router.replace({
						name: 'choose-store'
					})
				}).finally(() => {
					this.loading = false
				})
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.log {
	width: 100%;
	height: 90vh;
}
.width {
	width: 30%;
	@media only screen and (min-width: 600px) and (max-width: 960px){
		width: 50%;
	}
	@media only screen and (max-width: 600px) {
		width: 90%;
	}
}
.mg-auto {
	margin: 0 auto;
}
</style>