<template>
	<div>
		<v-navigation-drawer
			v-click-outside="closeDrawer"
			id="navigation-drawer"
			app
			v-model="localeValue"
			class="d-flex flex-column justify-space-between"
			width="230"
			:mobile-breakpoint="$vuetify.breakpoint.thresholds.lg"
			permanent
			dark
			:temporary="!localeValue"
			:right="$vuetify.rtl"
			:mini-variant="localeValue"
			mini-variant-width="45"
			color="primary"
		>
			<!-- pos logo -->
			<v-list nav dense class="py-1">
				<v-list-item 
					dense 
					exact-path
					active-class="white--text"
					class="px-0"
				>
					<v-list-item-avatar class="my-0 ml-0">
						<v-img
							src="../assets/logo-white.png"
							max-width="28"
							width="28"
							min-width="20"
							contain 
							@click="$router.push({ name: 'home' })"
							
						/>
					</v-list-item-avatar>
					<v-list-item-title>
						<span class="mx-1">POS</span>
					</v-list-item-title>
					<v-list-item-action v-if="!localeValue" class="mx-0" >
						<v-icon @click="localeValue  = !localeValue"  color="primary lighten-5">mdi-menu</v-icon>
					</v-list-item-action>
				</v-list-item>
			</v-list>

			<!-- toggle button for close -->
			<template v-if="localeValue">
				<v-divider/>
				<v-list nav dense class="py-0">
					<v-list-item dense class="d-flex justify-center mb-0">
						<v-list-item-icon class="mx-0">
							<v-icon @click="localeValue = !localeValue" color="primary lighten-5">mdi-menu</v-icon>
						</v-list-item-icon>
					</v-list-item>
				</v-list>
				<v-divider/>
			</template>

			<v-divider/>
			<v-list nav dense>
				<div v-for="(group, index) in groups" :key="index">
					<v-list-group
						v-model="opened[index]"
						v-if="group.show"
						active-class="white--text"
						class="mt-1"
					>
						<template v-slot:activator>
							<v-list-item-avatar class="my-0 ml-0 d-flex align-end" expanded>
								<tooltip left :text="group.tooltipText" :disabled="!localeValue"  >
									<v-img
										:src="group.img"
										max-width="23"
										width="23"
										min-width="20"
										contain 							
									/>
								</tooltip>
							</v-list-item-avatar>
							<v-list-item-title class="primary--text text--lighten-5">
								{{group.title}}
							</v-list-item-title>
						</template>
						<template v-for="(page, index) in group.pages.filter(page => page.show)">
							<v-list-item
								:key="'item-' + index"
								:to=" { name: page.target }"
								:disabled="page.disabled"
								exact-path
								@click="localeValue = true;
								page.target === 'all-invoices' ? 
								$router.push({ 
									query: {
										type: null,
										typeIndex: 0
									} 
								}) : null"
								class="mb-0"
								active-class="white--text"
							>

								<v-list-item-avatar class="my-0 ml-0 d-flex align-end">
									<tooltip left :text="page.title" :disabled="!localeValue" >
										<v-icon size="20" style="height: auto" v-if=" page.target === 'choose-store' " >
											mdi-file-replace
										</v-icon>
										<v-img
											v-else
											:src="page.img"
											max-width="20"
											min-width= "20"
											width="20"
											contain 
										/>
									</tooltip>
								</v-list-item-avatar>

								<v-list-item-title class="text-capitalize">
									{{page.title}}
								</v-list-item-title>
							</v-list-item>
							<v-divider v-if="page.divider" :key="'divider-' + index" class="my-1"/>
						</template>
					</v-list-group>
				</div>
			</v-list>
			<template v-slot:append>
				<div v-if="!localeValue" class="d-flex align-center justify-center width-100">
					<p class="white--text text-caption mb-0 text-center width-100">
						v 4.5.0 | Powered By BEETRONIX
					</p>
				</div>
			</template>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Tooltip from './Tooltip.vue'
import { userType } from '@/helpers/enums'
import { VERSION as version } from '@/helpers/constants'
export default {
	components: { Tooltip },
	name: 'NavigationDrawer',
	props: {
		value: { type: Boolean, default: false }
	},

	data () {
		return {
			userType,
			version,
			opened: [true, false, false]
		}
	},
	computed: {
		groups() {
			return [
				{
					show: this.$inStoreContext,
					tooltipText: this.$t('shortcuts'),
					title: this.$t('shortcuts'),
					img: '/sidebar-icons/3.png',
					nameGroup: 'shortcuts',
					pages: [
						{
							title: this.$t('NavigationDrawer.sale-invoice'),
							target: 'sale-invoice',
							img: '/sidebar-icons/2-2.png',
							disabled: false,
							show: this.$inStoreContext,
						},
						{
							title: this.$t('NavigationDrawer.sales-invoice-returned'),
							target: 'sales-invoice-returned',
							img: '/sidebar-icons/2-4.png',
							disabled: false,
							show: this.$inStoreContext,
						},
						{
							title: this.$t('NavigationDrawer.cashbox-journal'),
							target: 'cashbox-journal',
							img: '/sidebar-icons/1-3.png',
							disabled: false,
							show: this.$inStoreContext,
							divider: false
						},
						{
							title: this.$t('NavigationDrawer.choose-store'),
							target: 'choose-store',
							img: 'mdi-file-replace ',
							disabled: false,
							show: this.$inStoreContext,
						}
					]
				},
				{
					show: this.$inStoreContext && this.permissionsForUser.userType !== userType.casher,
					tooltipText: this.$t('accounting'),
					title: this.$t('accounting'),
					img: '/sidebar-icons/1.png',
					nameGroup: 'accounting',
					pages: [
						{
							title: this.$t('NavigationDrawer.accounts-tree'),
							target: 'accounts-tree',
							img: '/sidebar-icons/1-1.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: false
						},
						{
							title: this.$t('NavigationDrawer.account-statement'),
							target: 'account-statement',
							img: '/sidebar-icons/1-2.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: true
						},
						{
							title: this.$t('NavigationDrawer.cashbox-journal'),
							target: 'cashbox-journal',
							img: '/sidebar-icons/1-3.png',
							disabled: false,
							show: this.$inStoreContext,
							divider: false
						},
						{
							title: this.$t('NavigationDrawer.all-journals'),
							target: 'journals',
							img: '/sidebar-icons/1-4.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: true
						},
						{
							title: this.$t('NavigationDrawer.daily-journal'),
							target: 'daily-journal',
							img: '/sidebar-icons/bill.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: false
						},
						{
							title: this.$t('NavigationDrawer.funds-journal-table'),
							target: 'funds-journal-table',
							img: '/sidebar-icons/check-list.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: true
						}
					]
				},
				{
					show: this.$inStoreContext && this.permissionsForUser.userType !== userType.casher,
					tooltipText: this.$t('items-and-invoices'),
					title: this.$t('items-and-invoices'),
					img: '/sidebar-icons/2.png',
					nameGroup: 'items-and-invoices',
					pages: [
						{
							title: this.$t('NavigationDrawer.items-tree'),
							target: 'items-tree',
							img: '/sidebar-icons/2-1.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: true
						},
						{
							title: this.$t('NavigationDrawer.sale-invoice'),
							target: 'sale-invoice',
							img: '/sidebar-icons/2-2.png',
							disabled: false,
							show: this.$inStoreContext,
						},
						{
							title: this.$t('NavigationDrawer.pruchase-invoice'),
							target: 'pruchase-invoice',
							img: '/sidebar-icons/2-3.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
						},
						{
							title: this.$t('NavigationDrawer.sales-invoice-returned'),
							target: 'sales-invoice-returned',
							img: '/sidebar-icons/2-4.png',
							disabled: false,
							show: this.$inStoreContext,
						},
						{
							title: this.$t('NavigationDrawer.all-invoices'),
							target: 'all-invoices',
							img: '/sidebar-icons/2-5.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: true
						},
						// ////////////////////////////////
						{
							title: this.$t('NavigationDrawer.journal-item'),
							target: 'journal-item',
							img: '/sidebar-icons/2-6.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher)
						},
						// {
						// 	title: this.$t('NavigationDrawer.items-journal-table'),
						// 	target: 'items-journal-table',
						// 	img: '/sidebar-icons/2-7.png',
						// 	disabled: false,
						// 	show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher)
						// },
						{
							title: this.$t('NavigationDrawer.all-journals-items'),
							target: 'all-journals',
							img: '/sidebar-icons/2-8.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: true
						},
						// ///////////////////////
						{
							title: this.$t('NavigationDrawer.statment'),
							target: 'statement-item',
							img: '/sidebar-icons/2-9.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
						},
						{
							title: this.$t('NavigationDrawer.item-inventory'),
							target: 'item-inventory',
							img: '/sidebar-icons/2-10.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
							divider: true
						},
						{
							title: this.$t('NavigationDrawer.reports'),
							target: 'reports',
							img: '/sidebar-icons/invoiceReport.png',
							disabled: false,
							show: this.$inStoreContext && (this.permissionsForUser.userType !== userType.casher),
						}

					]

				}
			]
		},
		...mapState({
			cashboxes: state => state.cashboxes.cashboxes,
			permissionsForUser: state => state.auth.permissionsForUser,
			auth: state => state.auth.self,
		}),
		localeValue: {
			get() {
				return this.value;
			},
			set(newVal) {
				this.$emit('input', newVal)
			}
		}
	},
	methods: {
		closeDrawer() {
			if (!this.localeValue) {
				this.localeValue = true;
			}
		}
	}
}
</script>

<style lang="scss">
#navigation-drawer {
	*::-webkit-scrollbar {
		width: 5px;
	}
}

</style>