export default {
	opened: 'Opened',
	closed: 'Closed',
	store: 'Store',
	worked: 'Worked',
	stopped: 'Stopped',
	'power-type': 'Power type',
	'archive-store': 'Archive store',
	'unarchive-a-store': 'Unarchive a store',
	'are-you-sure-you-want-to-archive-this-store': 'Are you sure you want to archive this store?',
	'are-you-sure-you-want-to-unarchive-this-store': 'Are you sure you want to unarchive this store?',
	'the-store-has-been-successfully-archived': 'The store has been successfully archived',	
	'the-store-has-been-successfully-unarchived': 'The store has been successfully unarchived',
	'your-tax-report-results-will-be-affected': 'Your tax report results will be affected',
	alert: 'alert'
}