export default {
	'fiscal-account': 'Fiscal account',
	users: 'Users',

	'tax-percent': 'Tax percent',
	cashbox: 'Cashbox',
	cashboxes: 'Cashboxes',
	delete: 'delete ',
	active: 'Active',
	inactive: 'inactive',
}