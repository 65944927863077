<template>
	<div>
		<div class="bgColor rounded-t-xl px-2" >
			<v-form ref="form" @submit.prevent="filter()">
				<v-row class="mb-1">
					<v-col cols="12" lg="3" md="3" sm="4">
						<v-autocomplete 
							dense 
							outlined 
							v-model="id" 
							:label="$t('item-id-or-name')" 
							hide-details 
							:items="items" 
							:item-text="item => `${item.no} - ${item.name}`" 
							item-value="id"
							:disabled="loading"
						></v-autocomplete>
					</v-col>

					<v-col cols="5" lg="3" md="3" >
						<bee-date-input
							v-model="fromDate"
							:label="$t('forms.from-date')"
							input-format-order="DD-MM-YYYY"
							prepend-icon="mdi-calendar"
							dense
							hide-details
							:disabled="loading"
						></bee-date-input>
					</v-col>

					<v-col cols="5" lg="3" md="3" >
						<bee-date-input
							v-model="toDate"
							:label="$t('forms.to-date')"
							input-format-order="DD-MM-YYYY"
							prepend-icon="mdi-calendar"
							dense
							hide-details
							:disabled="loading"
						></bee-date-input>
					</v-col>

					<!-- search btn -->
					<v-col cols="auto" class="px-0">
						<v-btn 
							class="mx-2"
							type="submit"
							fab
							x-small
							color="of-white"
							:loading="loading"
							:disabled="loading || noSelectedItem"
						>
							<v-icon color="blue-dark">mdi-magnify</v-icon>
						</v-btn>
					</v-col>

					<!-- close btn -->
					<v-col cols="auto" class="px-0">
						<v-btn 
							class="mx-2"
							:class="hideClose ? 'd-none' : 'd-block' "
							fab
							x-small
							color="of-white"
							:disabled="loading || noSelectedItem"
							@click="resetAll"
							
						>
							<v-icon color="alert-color">mdi-close</v-icon>
						</v-btn>
					</v-col>
				</v-row>	
			</v-form>
			<bee-handy-table
				ref="table"
				:headers="headers"
				:items="itemsInventory.itemReportRows"
				:loading="loading"
				pagination-on-scroll
				:fixed-header="itemsInventory.itemReportRows.length !== 0"
				zebra
				dense
				:data-table-props="{
					disableSort: true
				}"
				:shown-rows-text="$t('shown-rows')"
				:height="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.height - 230 : $vuetify.breakpoint.height - 220"
			>
			</bee-handy-table>
		</div>

		<v-footer  color="white" class="py-4 elevation-0 mt-2">
			<v-row>
				<v-col cols="auto">
					{{
						$t('footer.total-amount-entered') + ' :  ' 						
					}}
					{{
						itemsInventory.itemReportRows.length !== 0 ?  itemsInventory.itemReportSummary.sumInputQuantity.quantity : 0
					}}
				</v-col>
				<v-col cols="auto">
					{{$t('footer.total-quantity-out') + ' :  '}}
					{{
						itemsInventory.itemReportRows.length !== 0 ?  itemsInventory.itemReportSummary.sumOutputQuantity.quantity : 0
					}}
				</v-col>

				<v-spacer />

				<v-col cols="auto">
					{{ $t('footer.the-final-balance') }}
					{{
						itemsInventory.itemReportRows.length !== 0 ?  itemsInventory.itemReportSummary.balance.quantity : 0
					}}
				</v-col>
			</v-row>
		</v-footer >
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { types } from '@/helpers/enums'
import moment from 'moment'
export default {
	name: 'StatementItem',

	data () {
		return {
			id: null,
			fromDate: null,
			toDate: null,
			loading: false,
			noSelectedItem: true,
			hideClose: true,
		}
	},
	watch: {
		id () {
			if (this.id !== null) {
				this.noSelectedItem = false;
			}
		}
	},
	computed: {
		...mapState({
			items: state => state.items.items,
			itemsInventory: state => state.statisticsReports.itemsInventory,
			invoices: state => state.invoices.invoices
		}),
		...mapGetters({
			getInvoiceById: 'invoices/getInvoiceById',
		}),
		headers() {
			const headers = [
				{
					text: this.$t('headers.quantity'),
					name: 'quantity',
					value: 'quantity.quantity',
					align: 'center',
				},
				{
					text: this.$t('headers.balance'),
					name: 'balance',
					value: 'balance',
					align: 'center',
				},
				{
					text: this.$t('headers.price'),
					name: 'price',
					value: 'price.amount',
					align: 'center'
				},
				{
					text: this.$t('headers.type'),
					name: 'type',
					value: 'type',
					setValueExpr: val => val === 1 ? this.itemMovement.purchases : this.itemMovement.sell,
					align: 'center'
				},
				{
					text: this.$t('headers.notes'),
					name: 'notes',
					value: 'notes',
					align: 'center'
				},
				{
					text: this.$t('headers.invoice-number'),
					name: 'invoiceId',
					value: 'invoiceId',
					setValueExpr: val => this.getInvoiceById(val)?.no
				},
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					align: 'center',
					setValueExpr: val => this.moment(val).format('YYYY-MM-DD')
				}
			]
			return headers
		},
		types() { return types() },
		itemMovement() {
			const itemMovement = {
				purchases: this.$t('ReportItem.purchase'),
				sell: this.$t('ReportItem.sell')
			}
			return itemMovement
		}
	},
	methods: {
		filter() {
			this.loading = true;
			this.$store.dispatch('statisticsReports/fetchItemStatement', {
				itemId: this.id,
				fromDate: this.fromDate,
				toDate: this.toDate
			}).then(() => {
				this.hideClose = false
				if (this.invoices.length === 0) {
					this.$store.dispatch('invoices/fetchAll', {}).finally(() => {
						this.loading = false
					})
				} else {
					this.loading = false
				}
			})
		},
		resetAll () {
			this.id = null;
			this.toDate = null;
			this.fromDate = null;
			this.itemsInventory.itemReportRows = []
		},
		moment
	},

	created () {
		this.itemsInventory.itemReportRows = []
	},
	metaInfo() {
		return {
			title: this.$t('routes.statement-item'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>