export default {
	'user-create': 'User create',
	'user-edit': 'User edit',
	'edit-account-status': 'Edit account status',
	'are-you-sure-to-deactivate-an-account': 'Are you sure to deactivate an account',
	'are-you-sure-to-activate-an-account': 'Are you sure to activate an account',
	'user-power': 'User power',
	'store-name': 'Store name',
	'power-type': 'Power type',
	casher: 'Casher',
	'executive-director': 'Store Director',
	optional: 'Optional',
	'change-password': 'Change password',
	'password-has-been-modified-successfully': 'Password has been modified successfully',
	active: 'Active',
	stopped: 'Stopped',
	'user-info': 'User data',
	'the-user-has-been-added-successfully': 'The user has been added successfully',
	'user-data-has-been-modified-successfully': 'User data has been modified successfully',
	viewer: 'Viewer'
}