<template>
	<div>
		<p v-if="insideTable && readonly" style="margin: 0">
			{{
				localValue
					? (itemText
							? items.find((c) => c.id == localValue)[itemText]
							: items.find((c) => c.id == localValue).no +
								"-" +
								items.find((c) => c.id == localValue).name) || emptyState
					: emptyState
			}}
		</p>
		<v-autocomplete
			v-else
			v-model="localValue"
			:items="items"
			:item-text="itemText"
			item-value="id"
			:label="label"
			:readonly="readonly"
			:disabled="disabled"
			:insideTable="insideTable"
			:clearable="clearable"
			outlined
			dense
			:filter="customFilter"
			:hide-details="hideDetails"
			:rules="rules"
			@change="$emit('change', $event)"
			@blur="$emit('blur')"
			@keydown="$emit('keydown', $event)"
		>
			<template v-slot:selection="data">
				{{
					itemText ? data.item[itemText] : data.item.no + "-" + data.item.name
				}}
			</template>
			<template v-slot:item="data">
				{{
					itemText ? data.item[itemText] : data.item.no + "-" + data.item.name
				}}
				<v-spacer v-if="!!withBalance"></v-spacer>
				<bee-material-amount-label
					:with-color="true"
					v-if="!!withBalance"
					:value="getBalance(data)"
					:show-zeros-values="true"
				></bee-material-amount-label>
			</template>
		</v-autocomplete>
	</div>
</template>
<script>
export default {
	props: {
		rules: { type: Array },
		value: {},
		items: { type: Array },
		label: { type: String },
		itemText: { type: String },
		insideTable: { type: Boolean },
		readonly: { type: Boolean },
		emptyState: { type: String },
		disabled: { type: Boolean },
		clearable: { type: Boolean },
		withBalance: { type: Boolean },
		hideDetails: { type: Boolean },
	},
	name: 'BeeMaterialAutocomplete',
	computed: {
		localValue: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				this.$emit('input', value);
			},
		},
	},
	methods: {
		customFilter(item, queryText, itemText) {
			const filter = itemText || item.no + '-' + item.name;
			const searchText = queryText.toLocaleLowerCase();
			return filter.toLocaleLowerCase().indexOf(searchText) > -1;
		},
		getBalance(data) {
			return data.item?.balance;
		},
	},
};
</script>
<style>
</style>