<template>
	<div id="daily-journal" class="bgColor rounded-t-xl px-3" style="height: calc(100vh - 70px)">
		<v-row align="center" class="px-3">
			<!-- date -->
            <v-col sm="auto" md="3" cols="4" class="d-flex align-center">
				<span class="me-2">
					{{ moment(date).locale($i18n.locale).format('dddd') }}
				</span> 
				<bee-date-input
					v-model="date"
					:label="$t('forms.date')"
					append-icon="mdi-menu-left"
					prepend-icon="mdi-menu-right"
					input-format-order="DD-MM-YYYY"
					dense
					hide-details
					:disabled="loading"
					@keydown.enter="filter()"
					@click:append="date = moment(date).add(1, 'd').format('YYYY-MM-DD')"
					@click:prepend="date = moment(date).subtract(1, 'd').format('YYYY-MM-DD')"
				>
				</bee-date-input>
			</v-col>

			<!-- save btn -->
			<v-col cols="auto">
				<v-btn
					color="blue-dark white--text"
					:loading="loading"
					:disabled="loading"
					@click="filter()"
				>
					{{$t('search')}}
				</v-btn>
			</v-col>

			<v-spacer/>

			<v-col cols="auto">
				{{$t('DailyJournal.daily-journal')}}:
				<span
					class="ms-1 font-weight-bold"
					:class="!dailyTotal.moneyType ? 'primary--text' : 'alert-color--text'"
				>
					{{(dailyTotal.sum || 0) | numberFormat}}
				</span>
			</v-col>
		</v-row>

		<v-row dense>
			<!-- sales-refunds -->
			<v-col lg="4" sm="6" cols="12">
				<v-card class="rounded-large">
					<v-card-text class="pa-2">
						<p class="font-weight-bold ms-2 mb-2">{{$t('DailyJournal.sales-refunds')}}</p>

						<bee-handy-table
							:headers="salesHeaders"
							:items="loading ? [] : salesItems"
							:items-per-page="20"
							:loading="loading"
							pagination-on-scroll
							fixed-header
							:height="$vuetify.breakpoint.height - 220"
							:data-table-props="{ disableSort: true, showExpand: true, singleExpand: true }"
							dense
							zebra
							elevation="1"
							:shown-rows-text="$t('shown-rows')"
							table-class="mb-2"
						>
							<template v-slot:item.actions="{ item }">
								<v-btn
									:to="{ name: getRouteName(item.invoiceType), params: { id: item.invoiceId } }"
									icon
									small
									color="edit-color"
								>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</template>

							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<v-chip
										v-for="(data, index) in getInvoiceDetails(item)"
										:key="index"
										x-small
										color="primary"
										class="details-chip me-1"
									>
										({{data.quantity}}×) {{data.name}}
									</v-chip>
								</td>
							</template>
						</bee-handy-table>

						{{$t('DailyJournal.total')}}:
						<span
							class="font-weight-bold"
							:class="!salesTotal.moneyType ? 'primary--text' : 'alert-color--text'"
						>
							{{(salesTotal.sum || 0) | numberFormat}}
						</span>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- purchase-refunds -->
			<v-col lg="4" sm="6" cols="12">
				<v-card class="rounded-large">
					<v-card-text class="pa-2">
						<p class="font-weight-bold ms-2 mb-2">{{$t('DailyJournal.purchase-refunds')}}</p>

						<bee-handy-table
							:headers="purchaseHeaders"
							:items="loading ? [] : purchaseItems"
							:items-per-page="20"
							:loading="loading"
							pagination-on-scroll
							fixed-header
							:height="$vuetify.breakpoint.height - 220"
							:data-table-props="{ disableSort: true, showExpand: true, singleExpand: true }"
							dense
							zebra
							elevation="1"
							:shown-rows-text="$t('shown-rows')"
							table-class="mb-2"
						>
							<template v-slot:item.actions="{ item }">
								<v-btn
									:to="{ name: getRouteName(item.invoiceType), params: { id: item.invoiceId } }"
									icon
									small
									color="edit-color"
								>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</template>

							<template v-slot:expanded-item="{ headers, item }">
								<td :colspan="headers.length">
									<v-chip
										v-for="(data, index) in getInvoiceDetails(item)"
										:key="index"
										x-small
										color="primary"
										class="details-chip me-1"
									>
										({{data.quantity}}×) {{data.name}}
									</v-chip>
								</td>
							</template>
						</bee-handy-table>

						{{$t('DailyJournal.total')}}:
						<span
							class="font-weight-bold"
							:class="!purchaseTotal.moneyType ? 'primary--text' : 'alert-color--text'"
						>
							{{(purchaseTotal.sum || 0) | numberFormat}}
						</span>
					</v-card-text>
				</v-card>
			</v-col>

			<!-- in-out-cashbox -->
			<v-col md="4" cols="12">
				<v-card class="rounded-large">
					<v-card-text class="pa-2">
						<p class="font-weight-bold ms-2 mb-2">{{$t('DailyJournal.in-out-cashbox')}}</p>

						<bee-handy-table
							:headers="inOutHeaders"
							:items="loading ? [] : cashboxTransactionsItems"
							:items-per-page="20"
							:loading="loading"
							pagination-on-scroll
							fixed-header
							:height="$vuetify.breakpoint.height - 220"
							:data-table-props="{ disableSort: true }"
							dense
							zebra
							elevation="1"
							:shown-rows-text="$t('shown-rows')"
							table-class="mb-2"
						>
							<template v-slot:item.actions="{ item }">
								<v-btn
									:to="{ name: 'cashbox-journal', params: { id: item.accountingJournalId }, query: { preview: 1 } }"
									icon
									small
									color="edit-color"
								>
									<v-icon>mdi-eye</v-icon>
								</v-btn>
							</template>
						</bee-handy-table>

						{{$t('DailyJournal.total')}}:
						<span
							class="font-weight-bold"
							:class="!cashboxTransactionsTotal.moneyType ? 'primary--text' : 'alert-color--text'"
						>
							{{(cashboxTransactionsTotal.sum || 0) | numberFormat}}
						</span>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import moment from 'moment'
import { compoundType, invoiceTypes } from '@/helpers/enums';
import { mapGetters } from 'vuex';
export default {
	name: 'DailyJournal',

	data() {
		return {
			date: moment().format('YYYY-MM-DD'),
			loading: false,

			salesItems: [],
			purchaseItems: [],
			cashboxTransactionsItems: [],

			salesTotal: {},
			purchaseTotal: {},
			cashboxTransactionsTotal: {},
			dailyTotal: {},
		}
	},

	computed: {
		...mapGetters({
			getAccountById: 'accounts/getAccountById',
			getInvoiceById: 'invoices/getInvoiceById',
			getItemById: 'items/getItemById',
			getCashJournalById: 'cashJournals/getCashJournalById'
		}),

		// headers
		salesHeaders() {
			const headers = [
				{
					text: this.$t('headers.no'),
					name: 'invoiceId',
					value: 'invoiceId',
					setValueExpr: val => this.getInvoiceById(val).no
				},
				{
					text: this.$t('headers.receipt'),
					name: 'credit',
					value: 'credit',
					noNotAvailableText: true,
					setValueExpr: val => val ? this.$options.filters.numberFormat(val.amount) : '-'
				},
				{
					text: this.$t('headers.payment'),
					name: 'debit',
					value: 'debit',
					noNotAvailableText: true,
					setValueExpr: val => val ? this.$options.filters.numberFormat(val.amount) : '-',
				},
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					setValueExpr: val => {
						let date = moment(val).format('hh:mm a')
						if (this.$i18n.locale === 'ar') {
							date = date.replace(/am|pm/, matched => ({ am: 'ص', pm: 'م' })[matched]);
						}
						return date;
					}
				},
				{ text: '', name: 'actions', value: 'actions', width: 40 },
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-1 text-body-2' : 'px-1 text-body-2';
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-1' : 'px-1';
			}

			return headers;
		},
		purchaseHeaders() {
			const headers = [
				{
					text: this.$t('headers.no'),
					name: 'invoiceId',
					value: 'invoiceId',
					setValueExpr: val => this.getInvoiceById(val).no
				},
				{
					text: this.$t('headers.receipt'),
					name: 'credit',
					value: 'credit',
					noNotAvailableText: true,
					setValueExpr: val => val ? this.$options.filters.numberFormat(val.amount) : '-'
				},
				{
					text: this.$t('headers.payment'),
					name: 'debit',
					value: 'debit',
					noNotAvailableText: true,
					setValueExpr: val => val ? this.$options.filters.numberFormat(val.amount) : '-',
				},
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					setValueExpr: val => {
						let date = moment(val).format('hh:mm a')
						if (this.$i18n.locale === 'ar') {
							date = date.replace(/am|pm/, matched => ({ am: 'ص', pm: 'م' })[matched]);
						}
						return date;
					}
				},
				{ text: '', name: 'actions', value: 'actions', width: 40 },
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-1 text-body-2' : 'px-1 text-body-2';
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-1' : 'px-1';
			}

			return headers;
		},
		inOutHeaders() {
			const headers = [
				{
					text: this.$t('headers.no'),
					name: 'accountingJournalId',
					value: 'accountingJournalId',
					setValueExpr: val => this.getCashJournalById(val).cashJournalNo
				},
				{
					text: this.$t('headers.account'),
					name: 'accountId',
					value: 'accountId',
					noNotAvailableText: true,
					setValueExpr: val => {
						const account = this.getAccountById(val);
						return account ? account.name : '-'
					},
				},
				{
					text: this.$t('headers.receipt'),
					name: 'credit',
					value: 'credit',
					noNotAvailableText: true,
					setValueExpr: val => val ? this.$options.filters.numberFormat(val.amount) : '-'
				},
				{
					text: this.$t('headers.payment'),
					name: 'debit',
					value: 'debit',
					noNotAvailableText: true,
					setValueExpr: val => val ? this.$options.filters.numberFormat(val.amount) : '-',
				},
				{
					text: this.$t('headers.notes'),
					name: 'notes',
					value: 'notes',
				},
				{ text: '', name: 'actions', value: 'actions', width: 40 },
			];

			for (let i = 0; i < headers.length; i++) {
				headers[i].class = headers[i].class ? headers[i].class + ' px-1 text-body-2' : 'px-1 text-body-2';
				headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-1' : 'px-1';
			}

			return headers;
		},
	},

	methods: {
		moment,

		filter(isFirstLoad = false) {
			this.loading = true;
			return Promise.all([
				this.$store.dispatch('invoices/fetchAll', {
					fromDate: this.date,
					toDate: this.date,
				}),
				this.$store.dispatch('accounting/fetchDetailedDayStatement', {
					forDate: this.date,
					compoundType: compoundType.notCompounded
				}).then((data) => {
					this.salesItems =
						data.salesRows.map((c, i) => ({ id: i, ...c }));
					this.purchaseItems =
						data.purchasesRows.map((c, i) => ({ id: i, ...c }));
					this.cashboxTransactionsItems =
						data.cashboxTransactionsRows.map((c, i) => ({ id: i, ...c }));

					this.salesTotal = {
						sum: data.sumSales.sumDifference.amount,
						moneyType: data.sumSales.sumDifference.moneyType
					};
					this.purchaseTotal = {
						sum: data.sumPurchases.sumDifference.amount,
						moneyType: data.sumPurchases.sumDifference.moneyType
					};
					this.cashboxTransactionsTotal = {
						sum: data.sumCashboxTransactions.sumDifference.amount,
						moneyType: data.sumCashboxTransactions.sumDifference.moneyType
					};
					this.dailyTotal = {
						sum: data.summary.sumDifference.amount,
						moneyType: data.summary.sumDifference.moneyType
					};
				})
			]).finally(() => {
				if (!isFirstLoad) this.loading = false;
			})
		},

		getRouteName(type) {
			switch (type) {
			case invoiceTypes.sales: return 'sale-invoice';
			case invoiceTypes.salesRefund: return 'pruchase-invoice';
			case invoiceTypes.purchases: return 'sales-invoice-returned';
			}
		},

		getInvoiceDetails(item) {
			return item.details.map(c => ({
				name: this.getItemById(c.itemId).name,
				quantity: c.quantity.quantity
			}))
		}
	},

	created() {
		this.loading = true;
		Promise.all([
			this.filter(true),
			this.$store.dispatch('cashJournals/fetchAll', { context: 2 })
		]).finally(() => {
			this.loading = false;
		})
	},

	metaInfo() {
		return {
			title: this.$t('routes.daily-journal'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>

<style lang="scss">
#daily-journal {
	.bee-handy-table td:first-of-type {
		padding: 0;
	}
	.details-chip .v-chip__content{
		font-size: 12px;
	}
}
</style>