<template>
    <div>
		<!-- Dialog alert -->
		<v-dialog v-model="alertDialog" width="350px">
			<v-card>
				<v-card-title class="orange--text py-0">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('alerts.alert')}}
					</span>
					<v-spacer/>

					<v-btn @click="alertDialog = false" icon dark>
						<v-icon color="orange">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pb-0 pt-1">
					{{ $t('alerts.check-date.message') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="orange white--text"
						large
						height="34"
						@click="submitData.date = moment().format('YYYY-MM-DD'); checkout()"
					>
						{{$t('alerts.check-date.today-date')}}
					</v-btn>
					<v-btn
						large
						height="34"
						@click="alertDialog = false; checkout()"
					>
						{{$t('alerts.check-date.invoice-date')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- confirm discard dialog -->
		<v-dialog v-model="confirmDiscardDialog" width="400" persistent>
			<v-card>
				<v-card-title class="alert-color--text py-0">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('delete')}}
					</span>
					<v-spacer/>

					<v-btn @click="confirmDiscardDialog = false" icon dark>
						<v-icon color="alert-color">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pt-4 pb-2">
					{{ $t('messages.are-you-sure-to-delete-invoice-draft') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>

					<v-btn
						color="alert-color white--text"
						large
						height="34"
						@click="deleteInvoice()"
						:loading="deleteLoading"
					>
						{{$t('confirm')}}
					</v-btn>
					<v-btn
						large
						height="34"
						@click="confirmDiscardDialog = false"
						:disabled="deleteLoading"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- dialog -->
		<v-dialog v-model="dialog" width="300px">
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span class="primary--text"> 
						{{$t('add-invoice')}}
					</span>
					<v-spacer/>
					<v-btn icon dark @click="dialog = false; dialogData.refundedFromInvoices = null">
						<v-icon small color="primary">
							mdi-close
						</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider class="mx-2" />

				<v-form @submit.prevent="createInvoice()">
					<v-card-text>
						<v-row>
							<v-col cols="12" align-self="center" >
								<v-autocomplete
									v-model="dialogData.refundedFromInvoices"
									:label="$t('forms.return-from-invoice-no')"
									:items="invoicesTypeSales"
									item-value="id"
									item-text="no"
									outlined
									dense
									hide-details
									multiple
									small-chips
									clearable
								/>
							</v-col>

						</v-row>
					</v-card-text>
					
					<v-card-actions>
						<v-spacer />

						<v-btn type="submit" color="primary" :loading="loading">
							{{$t('save')}}
						</v-btn>

						<v-btn @click="dialog= false; dialogData.refundedFromInvoices = null" color="transparent black--text" :disabled="loading">
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>
			</v-card>
		</v-dialog>
        <v-row>
			<!-- fav items -->
			<v-col lg="2" md="3" cols="12">
				<!-- tabs -->
				<v-btn-toggle
					v-model="activeItems"
					mandatory
					borderless
					active-class="primary white--text"
					class="d-flex elevation-2 mb-2"
				>
					<v-btn
						height="40"
						class="flex-grow-1"
						:class="$i18n.locale === 'ar' ? 'rounded-l-0' : 'rounded-r-0'"
					>
						{{$t('SaleInvoice.all')}}
					</v-btn>
					<v-btn
						width="40%"
						height="40"
						class="rounded-0"
					>
						{{$t('SaleInvoice.favorite')}}
					</v-btn>
					<v-btn
						height="40"
						class="flex-grow-1"
						:class="$i18n.locale === 'ar' ? 'rounded-r-0' : 'rounded-l-0'"
					>
						{{$t('search')}}
					</v-btn>
				</v-btn-toggle>
				
				<!-- items -->
				<v-slide-y-transition mode="out-in">
					<!-- favorite items & search -->
					<v-layout v-if="[1, 2].includes(activeItems)" wrap justify-space-between>
						<!-- barcode -->
						<v-expand-transition>
							<v-flex v-if="activeItems === 2" xs12>
								<v-text-field
									v-model="searchItem"
									:label="isSearchByBarcode ? $t('forms.barcode') : $t('search')"
									dense
									hide-details
									outlined
									:append-icon="isSearchByBarcode ? 'mdi-barcode' : 'mdi-barcode-off'"
									@click:append="isSearchByBarcode = !isSearchByBarcode"
									@keypress.enter="isSearchByBarcode ? addByBarcode() : null"
									:disabled="permissionsForUser.userType === userType.dataReader"
								></v-text-field>
							</v-flex>
						</v-expand-transition>

						<!-- items -->
						<v-flex
							v-for="item in filteredItems"
							:key="item.id"
							md6 xs3
							class="pa-1"
						>
							<button
								v-ripple
								class="d-flex justify-center align-center text-body-2 text-center text-capitalize grey lighten-3 rounded-medium px-2 elevation-2 font-weight-bold"
								:class="{ 'elevation-0 grey--text': submitLoading || loading || isCurrentInvoicePosted }"
								:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
								:style="{
									width: '100%',
									height: $vuetify.breakpoint.lgAndUp ? '55px' : '40px'
								}"
								@click="addFavItem(item)"
							>
								{{item.name}}
							</button>
						</v-flex>
					</v-layout>

					<!-- all items -->
					<v-expansion-panels
						v-else
						accordion
						id="all-items-panels"
						active-class="py-0"
					>
						<v-expansion-panel
							v-for="(rootItem,i) in saleableItemsTree"
							:key="i"
						>
							<v-expansion-panel-header>
								{{rootItem.name}}
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-layout wrap justify-space-between class="pb-2">
									<v-flex
										v-for="item in rootItem.children"
										:key="item.id"
										md6 xs3
										class="pa-1"
									>
										<button
											v-ripple
											class="d-flex justify-center align-center text-body-2 text-center text-capitalize grey lighten-3 rounded-medium px-2 elevation-1 font-weight-bold"
											:class="{ 'elevation-0 grey--text': submitLoading || loading || isCurrentInvoicePosted }"
											:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
											:style="{
												width: '100%',
												height: $vuetify.breakpoint.lgAndUp ? '55px' : '40px'
											}"
											@click="addFavItem(getItemById(item.id))"
										>
											{{item.name}}
										</button>
									</v-flex>
								</v-layout>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</v-slide-y-transition>

				<template v-if="0">
					<v-divider class="my-3"/>
					
					<!-- Edit fav list -->
					<v-btn
						:width="$vuetify.breakpoint.mdAndUp ? '100%': '50%'"
						height="35"
						color="edit-color white--text mb-md-2"
						class="text-capitalize"
					>
						{{$t('SaleInvoice.edit-fav-list')}}
					</v-btn>
				</template>
			</v-col>
			<!-- table -->
			<v-col lg="8" md="9" cols="12" class="table-col rounded-t-xl bgColor pt-lg-8 pt-6 px-lg-5 px-3 " >
				<!-- top row in md and Down size add + barcode -->
				<v-row class="mb-2"> 
					<v-col md="2" sm="4" cols="6" class="pa-1">
						<items-navigator
							v-model="invoiceNo"
							:label="$t('invoice-no')"
							:disabled="loading"
							:items="
								invoices.filter(
									c => permissionsForUser.userType === userType.casher
									? c.cashboxId === permissionsForUser.cashboxId
									: true
								)
							"
						></items-navigator>
					</v-col>

					<v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>

					<v-divider v-if="$vuetify.breakpoint.smAndDown" vertical class="my-2 mx-2" />

					<v-col cols="auto" class="pa-1">
						<v-btn 
							elevation="0" 
							color="primary" 
							width="100%" 
							height="35" 
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							@click="dialog = true"
						>
							{{$t('add-invoice')}}
						</v-btn>
					</v-col>

					<v-col cols="auto" class="pa-1 pe-md-4">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									dark
									v-bind="attrs"
									v-on="on"
									elevation="0" 
									color="blue-dark white--text" 
									width="100%" 
									height="35" 
									:disabled="submitLoading || loading "
								>
									{{$t('show')}}
									<v-icon size="20">mdi-chevron-down</v-icon>
								</v-btn>
							</template>
							<v-list dense>
								<v-list-item 
									:to="{
										name: 'all-invoices', 
										query: { type: invoiceTypes.salesRefund }
									}"
								>
									<v-list-item-title> 
										{{$t('all-invoice')}}
									</v-list-item-title>
								</v-list-item>

								<v-list-item 
									:to="{
										name: 'journal-item',
										params: {
											id: getInvoiceById($route.params.id)?.itemsJournalId
										}
									}"
									:disabled="!getInvoiceById($route.params.id)?.isPosted"
								>
									<v-list-item-title> 
										{{$t('view-invoice-details.related-items-journal')}}
									</v-list-item-title>
								</v-list-item>

								<v-list-item 
									:to="{
										name: 'cashbox-journal',
										params: {
											id: getInvoiceById($route.params.id)?.cashJournalId
										}
									}"
									:disabled="!getInvoiceById($route.params.id)?.isPosted"
								>
									<v-list-item-title> 
										{{$t('view-invoice-details.related-cashbox-journal')}}
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
				<bee-handy-smart-table
					v-model="tableInputs"
					ref="table"
					:headers="headers"
					:items="entries"
					:items-per-page="-1"
					:loading="loading"
					:data-table-props="{
						disableSort: true,
						noDataText: $t('entery-value-and-prass-enter')
					}"
					fixed-header
					hide-default-footer
					dense
					:show-add-row="permissionsForUser.userType !== userType.dataReader"
					:editable-rows="!submitLoading && !loading && !isCurrentInvoicePosted"
					:height="
						$vuetify.breakpoint.lgAndUp
						? '73.5vh'
						: submitData.paymentMethodId === 0 ? '70vh': '65vh'"
					:context-menu-items="contextMenuItems"
					stay-in-input-fields
					:validation-messages.sync="validationMessages"
					hide-inputs-details
					disable-mobile-context-menu
					@edit-cancelled="isEditItem = false"
					@change-itemId="isPriceChangedByUser = false"
					@input-price="isPriceChangedByUser = true"
					@keypress.enter="!isEditItem ? addItem() : editItem()"
					@reached-last-input="!isEditItem ? addItem() : editItem()"
					zebra
					hot-inputs
					table-class="py-2 px-3"
					:disable-context-menu="submitLoading || loading || isCurrentInvoicePosted"
					:disable-input-fields="submitLoading || loading || isCurrentInvoicePosted"
				>
					<!-- actions -->
					<template v-slot:item.actions="{ index }">
						<div class="d-flex">
							<!-- edit -->
							<!-- @click="$refs.table.activeEditForRow(index); setRowIndex({ index })" -->
							<v-btn
								width="30"
								min-width="30"
								height="25"
								color="edit-color"
								class="me-2 pa-0"
								outlined
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								@click="$refs.table.activeEditForRow(index); setRowIndex({ index })"
							>
								<v-icon size="18">mdi-pencil</v-icon>
							</v-btn>

							<!-- delete -->
							<v-btn
								width="30"
								min-width="30"
								height="25"
								color="alert-color"
								class="pa-0"
								outlined
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								@click="deleteItem({ index })"
							>
								<v-icon size="18">mdi-delete</v-icon>
							</v-btn>
						</div>
					</template>

					<!-- itemId -->
					<template v-slot:input.itemId="{ on, attr }">
						<!-- FIXME use ENUM for c.category && CHECK value (c.category === 6) -->
						<v-autocomplete
							:items="items.filter(c => c.type === type.sub && c.category !== 6 )"
							item-value="id"
							:item-text="item => `${item.no} - ${item.name}`"
							auto-select-first
							v-on="on"
							v-bind="attr"
						></v-autocomplete>
					</template>

					<!-- quantity -->
					<template v-slot:input.quantity="{ on, attr }">
						<quantity-input
							v-on="on"
							v-bind="attr"
						></quantity-input>
					</template>

					<!-- percentDiscount -->
					<template v-slot:input.percentDiscount="{ on, attr}">
						<v-text-field
							prefix="%"
							v-on="on"
							v-bind="attr"
						></v-text-field>
					</template>
				</bee-handy-smart-table>
			</v-col>
			<!-- table -->
			<!-- info in mdAndUp size -->
			<v-col v-if="$vuetify.breakpoint.lgAndUp" md="2" cols="12" class="pt-6">
				<v-row>
					<v-col cols="12" md="12" sm="3" class="pt-0">
						<!-- cashbox -->
						<v-autocomplete 
							v-model="submitData.cashbox" 
							:items="cashboxes" 
							:label="$t('forms.cashbox')"
							item-value="id"
							item-text="name" 
							dense
							outlined
							append-icon
							:disabled="permissionsForUser.userType === userType.dataReader"
						></v-autocomplete>

						<!-- account -->
						<v-autocomplete
							v-model="submitData.account"
							:label="$t('forms.account')"
							:items="accounts.filter(c => c.type === type.sub && !c.isHidden)"
							item-value="id"
							:item-text="account => `${account.accountingNo} - ${account.name}`"
							outlined
							dense
							hide-details
							:rules="rules.required"
							:readonly="isCurrentInvoicePosted"
							:disabled="permissionsForUser.userType === userType.dataReader"
						></v-autocomplete>
					</v-col>
					<v-col>
						<!-- account -->
						<v-autocomplete
							v-model="submitData.refundedFromInvoices"
							:label="$t('forms.return-from-invoice-no')"
							:items="invoicesTypeSales"
							item-value="id"
							item-text="no"
							outlined
							dense
							hide-details
							multiple
							:readonly="isCurrentInvoicePosted"
							small-chips
							clearable
							:disabled="permissionsForUser.userType === userType.dataReader || isCurrentInvoicePosted"
						></v-autocomplete>
					</v-col>

					<!-- date -->
					<v-col cols="12" md="12" sm="2" class="py-0">
						<bee-date-input
							v-model="submitData.date"
							:label="$t('forms.date')"
							input-format-order="DD-MM-YYYY"
							prepend-icon="mdi-calendar"
							dense
							hide-details
							class="my-4"
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
							iso-date
						></bee-date-input>
					</v-col>

					<v-col class="py-0" cols="12" md="12" sm="2">
						<!-- tax -->
						<v-text-field
							:label="$t('forms.tax')"
							dense
							hide-details
							outlined
							readonly
							class="mb-2"
							:value="numberFormat(vatAmount)"
							:disabled="permissionsForUser.userType === userType.dataReader"
						></v-text-field>
					</v-col>
					
					<v-col class="py-0" cols="12" md="12" sm="2">
						<!-- total -->
						<v-text-field
							:label="$t('SaleInvoice.total-without-vat')"
							dense
							hide-details
							class="mb-2"
							outlined
							readonly
							:value="numberFormat(entriesTotal)"
							:disabled="permissionsForUser.userType === userType.dataReader"
						></v-text-field>
					</v-col>

					<!-- total net -->
					<v-col cols="12" md="12" sm="2" class="py-0">
						<v-divider class="my-2"   />
						<div class="d-flex justify-space-between">
							<span class="text-subtitle-2 font-weight-medium">
								{{$t('PurchaseInvoice.total-net')}}
							</span>
							<span class="text-subtitle-1 font-weight-bold">
								{{numberFormat(totalNet)}}
							</span>
						</div>
						<v-divider class="my-2"   />
					</v-col>

					<v-divider vertical class="my-3"/>
					
					<!-- notes -->
					<v-col cols="12" md="12" sm="2" class="py-0">
						<v-textarea
							v-model="submitData.notes"
							:label="$t('forms.notes')"
							dense
							outlined
							rows="2"
							full-width
							class="my-2"
							hide-details
							no-resize
							auto-grow
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
						></v-textarea>
					</v-col>

					<!-- buttons -->
					<v-col cols="12" md="12" sm="2" class="pt-0">
						<div class="text-center" style="width:100%" >
							<v-btn
								height="40"
								width="100%"
								color="blue-dark white--text"
								class="text-capitalize "
								:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
								:loading="submitLoading || loading"	
								@click="checkDate()"
							>
								{{ $t('save') }}
							</v-btn>
							<v-btn
								v-if="!isCurrentInvoicePosted"
								height="34"
								width="100%"
								color="alert-color white--text"
								class="text-capitalize my-1"
								@click="confirmDiscardDialog = true"
								:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
							>
								{{$t('delete')}}
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-col>

			<!-- info in smAndDown size -->
			<v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12">
				<v-row>
					<v-col cols="4">
						<!-- cashbox -->
						<v-autocomplete 
							v-model="submitData.cashbox" 
							:items="cashboxes" 
							:label="$t('forms.cashbox')"
							item-value="id"
							item-text="name" 
							readonly
							dense 
							outlined
							append-icon
							:disabled="permissionsForUser.userType === userType.dataReader"
						></v-autocomplete>

						<!-- account -->
						<v-autocomplete
							v-model="submitData.account"
							:label="$t('forms.account')"
							:items="accounts.filter(c => c.type === type.sub && !c.isHidden)"
							item-value="id"
							:item-text="account => `${account.accountingNo} - ${account.name}`"
							outlined
							dense
							hide-details
							:readonly="isCurrentInvoicePosted"
							:rules="rules.required"
							:disabled="permissionsForUser.userType === userType.dataReader"
						></v-autocomplete>
								
						<!-- date in md and down size -->
						<bee-date-input
							v-if="$vuetify.breakpoint.mdAndDown"
							v-model="submitData.date"
							:label="$t('forms.date')"
							input-format-order="DD-MM-YYYY"
							prepend-icon="mdi-calendar"
							dense
							hide-details
							class="my-4"
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
							iso-date
						></bee-date-input>
					</v-col>

					<v-divider class="my-2" vertical />
					<v-col cols="4">
						<!-- refundedFromInvoices -->
						<v-autocomplete
							v-model="submitData.refundedFromInvoices"
							:label="$t('forms.return-from-invoice-no')"
							:items="invoicesTypeSales"
							item-value="id"
							item-text="no"
							outlined
							dense
							hide-details
							multiple
							:readonly="isCurrentInvoicePosted"
							:disabled="permissionsForUser.userType === userType.dataReader || isCurrentInvoicePosted"
						></v-autocomplete>
						
						<!-- tax -->
						<div class="pa-0">
							<div class="d-flex justify-space-between align-center ">
								<span class="text-subtitle-2 font-weight-medium">
									{{$t('forms.tax')}}
								</span>
								<span class="text-subtitle-1 font-weight-bold">
									{{numberFormat(vatAmount)}}
								</span>
							</div>
						</div>

						<!-- totalNet -->
						<div class="d-flex justify-space-between">
							<span class="text-subtitle-2 font-weight-medium">{{$t('SaleInvoice.total-without-vat')}}</span>
								<span class="text-subtitle-1 font-weight-bold">
									{{numberFormat(entriesTotal)}}
								</span>
						</div>

						<div class="pa-0">
							<div class="d-flex justify-space-between align-center">
								<span class="text-subtitle-2 font-weight-medium">
									{{$t('SaleInvoice.total-net')}}
								</span>
								<span class="text-subtitle-1 font-weight-bold">
									{{numberFormat(totalNet)}}
								</span>
							</div>
						</div>
					
						<v-spacer />
					</v-col>
				
					<v-divider class="my-2" vertical />

					<v-col>
						<!-- notes -->
						<v-textarea
							v-model="submitData.notes"
							:label="$t('forms.notes')"
							dense
							outlined
							rows="1"
							full-width
							class="my-2"
							hide-details
							no-resize
							auto-grow
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
						></v-textarea>

						<div class="text-center" style="width:100%" >
							<v-btn
								height="34"
								width="100%"
								color="blue-dark white--text"
								class="text-capitalize my-1"
								:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
								:loading="submitLoading || loading"	
								@click="checkDate()"
							>
								{{ $t('save') }}
							</v-btn>
							<v-btn
								v-if="!isCurrentInvoicePosted"
								height="34"
								width="100%"
								color="alert-color white--text"
								class="text-capitalize"
								@click="confirmDiscardDialog = true"
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							>
								{{$t('delete')}}
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import ItemsNavigator from '@/components/ItemsNavigator.vue';
import QuantityInput from '@/components/QuantityInput.vue';
import { fixNumber } from '@/helpers/functions';
import rules from '@/helpers/validation rules';
import { type, invoiceTypes, userType, paymentMethod } from '@/helpers/enums';
import moment from 'moment'

export default {
	data () {
		return {
			alertDialog: false,
			tableInputs: {},
			searchItem: null,
			manualQuantity: false,
			entries: [],
			submitLoading: false,
			isCurrentInvoicePosted: false,
			loading: false,
			validationMessages: [],
			invoiceNo: 0,
			// data
			submitData: {
				account: null,
				cashbox: null,
				date: null,
				paymentMethodId: 0,

				paid: null,
				// discount: null,
				notes: null,
				invoice: null
			},
			type,
			rules,
			paidFormatted: '',
			discountFormatted: '',
			invoiceTypes,
			invoicesTypeSales: [],
			isEditItem: false,
			rowEditIndex: null,
			savedTableInputs: {},
			userType,
			dialog: false,
			dialogData: {
				refundedFromInvoices: null
			},
			confirmDiscardDialog: false,
			activeItems: 1,
			isSearchByBarcode: true,
			deleteLoading: false
		}
	},
	components: { ItemsNavigator, QuantityInput },
	watch: {
		'$route.params.id'(val) {
			if (val) {
				this.resetTableInputs();
				this.fetchInvoice().then(() => {
					if (this.createInvoiceByTabsHandler) {
						this.createInvoiceByTabsHandler({
							id: this.$route.params.id,
							name: this.invoiceNo
						});
						this.createInvoiceByTabsHandler = false;
					}
				});
			}
		},

		watchInputs() {
			if (!this.isEditItem && this.tableInputs.itemId) {
				this.savedTableInputs = this.tableInputs;
			}
		},
		
		isEditItem(val) {
			if (!val && !this.entries.find(c => c.itemId === this.savedTableInputs.itemId)) {
				this.$nextTick(() => {
					this.$refs.table.resetInputs(this.savedTableInputs)
				})
			}
		},

		dialog (val) {
			if (val === false) {
				this.dialogData.refundedFromInvoices = null
			}
		}
	},
	computed: {
		...mapState({
			items: state => state.items.items,
			cashboxes: state => state.cashboxes.cashboxes,
			saleableItemsTree: state => state.items.saleableItemsTree,
			accounts: state => state.accounts.accounts,
			invoices: state => state.invoices.invoices,
			configs: state => state.configs.configs,
			permissionsForUser: state => state.auth.permissionsForUser
		}),
		...mapGetters({
			getItemById: 'items/getItemById',
			getInvoiceById: 'invoices/getInvoiceById',
			getItemByBarcode: 'items/getItemByBarcode',
		}),
		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'no',
					value: 'no',
					setValueExpr: (val, index) => index + 1,
					noInput: true,
				},
				{
					text: this.$t('headers.barcode'),
					name: 'barcode',
					value: 'barcode',
					divider: true,
					noInput: true,
				},
				{
					text: this.$t('headers.item'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.items.find(c => c.id === val)?.name ?? val,
					inputRules: rules.required,
					width: 140,
					disableInput: this.isEditItem,
				},
				{
					text: this.$t('headers.quantity'),
					name: 'quantity',
					value: 'quantity',
					width: 85,
					inputRules: rules.number,
					writable: !this.isCurrentInvoicePosted,
					onWriteChange: (item, activeInput, index) => {
						this.entries[index].amount = item.quantity * item.price;
						this.entries[index].totalNet = item.amount;
					}		
				},
				{
					text: this.$t('headers.price'),
					name: 'price',
					value: 'price',
					// inputRules: [val => !isNaN(val) && Number(val) > 0],
					inputRules: rules.required,
					setInputExpr: inputs => {
						return this.getItemById(inputs.itemId)?.salePrice?.amount || 0
					},
					type: 'number-format'
				},
				{
					text: this.$t('headers.amount'),
					name: 'amount',
					value: 'amount',
					disableInput: false,
					setInputExpr: inputs => inputs.price * inputs.quantity,
					type: 'number-format'
				},
				{
					text: this.$t('headers.total-net'),
					name: 'totalNet',
					value: 'totalNet',
					disableInput: true,
					setInputExpr: inputs => fixNumber(inputs.amount),
					type: 'number-format'
				},
			];
			if (!this.isCurrentInvoicePosted) {
				headers.splice(
					headers.length, 0,
					{
						text: '',
						name: 'actions',
						value: 'actions',
						align: 'center',
						noInput: true,
						sortable: false,
					}
				)
			}
			for (let i = 0; i < headers.length; i++) {
				headers[i].columnClass = headers[i].columnClass ? headers[i].columnClass + ' px-2' : 'px-2'
			}
			return headers
		},

		filteredItems() {
			return this.items.filter(c =>
				c.type === type.sub &&
				!c.isUnsellable &&
				(this.activeItems === 1 ? c.isFavourite : true) &&
				(
					this.searchItem
						? this.isSearchByBarcode
							? c.barcode.includes(this.searchItem)
							: c.name.includes(this.searchItem)
						: true
				)
			)
		},

		// computed invoice values
		entriesTotal() {
			return fixNumber(
				(this.sumEntires) *
				(1 / (1 + (this.submitData.vatPercent / 100)))
			);
		},
		sumEntires() {
			return this.entries.map(c => Number(c.totalNet)).reduce((a, b) => a + b, 0)
		},

		vatAmount() {
			return fixNumber(this.totalNet - this.entriesTotal);
		},
		totalNet() {
			return fixNumber(this.sumEntires)
		},
		// computed helpers
		contextMenuItems() {
			return [
				{ title: this.$t('edit'), icon: 'mdi-pencil', iconColor: 'edit-color', event: 'edit', class: 'edit-color--text' },
				{ title: this.$t('delete'), icon: 'mdi-delete', iconColor: 'alert-color', event: 'delete', class: 'alert-color--text' },
			]
		},
	},
	methods: {
		moment,

		// helpers
		numberFormat(value) {
			return this.$options.filters.numberFormat(value);
		},

		resetTableInputs() {
			this.$refs.table.resetInputs({
				itemId: null,
				quantity: '1',
				price: null,
				amount: null,
				percentDiscount: null,
				// discount: null,
				totalNet: null,
			})
		},

		addFavItem(item) {
			const itemInTable = this.entries.find(c => c.itemId === item.id);
			if (!itemInTable) {
				const itemToAdd = {
					id: item.id,
					itemId: item.id,
					quantity: 1,
					price: item.salePrice?.amount ?? 0,
					amount: item.salePrice?.amount ?? 0,
					barcode: item.barcode,
					percentDiscount: null,
					discount: null,
					totalNet: item.salePrice?.amount ?? 0,
				}
				this.entries.push(itemToAdd)
			} else {
				itemInTable.quantity = Number(itemInTable.quantity) + 1;
				itemInTable.amount = itemInTable.price * itemInTable.quantity;
				itemInTable.discount = itemInTable.amount * ((itemInTable.percentDiscount || 0) / 100);
				itemInTable.totalNet = itemInTable.amount - itemInTable.discount;
			}
		},

		addItem() {
			if (this.$refs.table.validate()) {
				const itemInTable = this.entries.find(c => c.itemId === this.tableInputs.itemId);
				if (!itemInTable) {
					this.entries.push({
						barcode: this.getItemById(this.tableInputs.itemId)?.barcode,
						...this.tableInputs
					});
				} else {
					itemInTable.quantity = Number(itemInTable.quantity) + Number(this.tableInputs.quantity);
					itemInTable.amount = itemInTable.quantity * itemInTable.price;
					// itemInTable.discount = itemInTable.amount * ((itemInTable.percentDiscount || 0) / 100);
					// itemInTable.totalNet = itemInTable.amount - itemInTable.discount;
					itemInTable.totalNet = itemInTable.amount;
				}

				this.$nextTick(() => {
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus()
				})
			}
		},

		editItem() {
			if (this.$refs.table.validate()) {
				this.entries[this.rowEditIndex].itemId = this.tableInputs.itemId
				this.entries[this.rowEditIndex].quantity = this.tableInputs.quantity
				this.entries[this.rowEditIndex].price = this.tableInputs.price;
				this.entries[this.rowEditIndex].amount = this.tableInputs.amount;
				this.entries[this.rowEditIndex].percentDiscount = this.tableInputs.percentDiscount;
				// this.entries[this.rowEditIndex].discount = this.tableInputs.discount;
				this.entries[this.rowEditIndex].totalNet = this.tableInputs.totalNet;
				this.$refs.table.resetEdit()
				this.isEditItem = false;
				
				this.$nextTick(() => {
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus()
				})
			}
		},

		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus()
			})
		},
	
		deleteItem({ index }) {
			this.$refs.table.resetEdit()
			this.isEditItem = false;
			this.entries.splice(index, 1);
			
			this.$nextTick(() => {
				this.resetTableInputs();
				this.$refs.table.resetInputsFocus()
			})
		},
		
		createInvoice(isFirstInvoice) {
			this.loading = true
			this.$store.dispatch('invoices/create', {
				type: invoiceTypes.salesRefund, 
				cashboxId: this.submitData.cashbox,
				intermediaryAccountId: this.configs.defaultCashCustomerId,
				date: new Date().toISOString(),
				notes: null,
				refDoc: null,
				// refundedFromInvoices: this.dialog ? this.dialogData.refundedFromInvoices : this.submitData.refundedFromInvoices,
				refundedFromInvoices: this.dialogData.refundedFromInvoices ? this.dialogData.refundedFromInvoices : null,
				paymentWay: paymentMethod.cash
			}).then((id) => {
				this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.salesRefund });
				this.$router.push({ name: 'sales-invoice-returned', params: { id } });
				this.submitData.date = new Date().toISOString();
				
				if (isFirstInvoice) { this.invoiceNo = 1 };
			}).finally(() => {
				this.dialog = false
				this.loading = false
				this.dialogData.refundedFromInvoices = null
			})
		},

		addByBarcode() {
			const item = this.getItemByBarcode(this.searchItem);
			if (item) {
				this.addFavItem(item);
				this.searchItem = null;
			}
		},

		fetchInvoice() {
			this.loading = true;
			return this.$store.dispatch('invoices/fetchById', { id: this.$route.params.id })
				.then((data) => {
					this.isCurrentInvoicePosted = data.isPosted;

					this.invoiceNo = data.no;
					this.submitData.date = data.date;
					this.submitData.paymentMethodId = data.paymentWay;
					this.submitData.cashbox = data.cashboxId;
					this.submitData.account = data.intermediaryAccountId;
					this.submitData.refundedFromInvoices = data.refundedFromInvoices
					this.submitData.paid = data.paidAmount?.amount;
					this.paidFormatted = this.numberFormat(data.paidAmount?.amount);
					// this.submitData.discount = data.discountTotal?.amount;
					this.discountFormatted = this.numberFormat(data.discountTotal?.amount);
					this.submitData.vatPercent = data.vatPercent;
					this.submitData.notes = data.notes;
					this.entries = [];
					data.entries.forEach((entry) => {
						this.entries.push({
							barcode: this.getItemById(entry.itemId)?.barcode,
							itemId: entry.itemId,
							quantity: entry.quantity.quantity,
							price: entry.price.amount,
							amount: entry.quantity.quantity * entry.price.amount,
							percentDiscount: entry.discountPercentage,
							// discount: entry.discountAmount.amount,
							totalNet: entry.totalAmount.amount,
						})
					});
					this.submitData.refundedFromInvoices = data.refundedFromInvoices
				})
				.finally(() => {
					this.loading = false;
				})
		},

		deleteInvoice() {
			this.deleteLoading = true
			this.$store.dispatch('invoices/delete', { id: this.$route.params.id }).then(() => {
				this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.salesRefund }).then((data) => {
					if (data.length === 0) {
						this.createInvoice(true)
					} else {
						this.$router.push({
							params: {
								id: data[data.length - 1].id
							}
						})
					}
					this.confirmDiscardDialog = false
				}).finally(() => {
					this.deleteLoading = false
				})
			})
		},

		checkDate() {
			if (this.moment(this.submitData.date).isBefore(this.moment(), 'day')) {
				this.alertDialog = true
			} else {
				this.checkout()
			}
		},

		checkout() {
			this.submitLoading = true;

			const currentInvoice = this.getInvoiceById(this.$route.params.id);
			const finalEntries = [];

			this.entries.forEach((entry) => {
				const item = this.getItemById(entry.itemId);
				finalEntries.push({
					invoiceId: currentInvoice.id,
					itemId: item.id,
					quantity: {
						quantity: Number(entry.quantity),
						unitSizeId: item.unitId,
					},
					price: {
						amount: Number(entry.price),
						currencyId: this.$localStorage.currencyId
					},
					amount: {
						amount: Number(entry.totalNet),
						currencyId: this.$localStorage.currencyId
					},
					notes: null,
					// FIXME is should to send discountPercentage to API?
					// discountPercentage: Number(entry.percentDiscount),
				})
			})
			this.$store.dispatch('invoices/updateEntries', {
				invoiceType: invoiceTypes.salesRefund,
				invoiceId: currentInvoice.id,
				entries: finalEntries,
			})
				.then(() => {
					this.$eventBus.$emit('show-snackbar', false, this.$t('messages.saving-entries-done-wait-checkout'))
					this.$store.dispatch('invoices/updateInvoiceWithoutEntries', {
						id: this.$route.params.id,
						type: invoiceTypes.salesRefund,
						cashboxId: this.submitData.cashbox,
						intermediaryAccountId: this.submitData.account,
						date: this.submitData.date,
						notes: this.submitData.notes,
						refDoc: this.submitData.refDoc,
						paymentWay: 1,
						paidAmount: {
							amount: this.submitData.paid,
							currencyId: this.$localStorage.currencyId
						},
						totalNet: {
							// amount: data.totalNet.amount,
							amount: this.entriesTotal,
							currencyId: this.$localStorage.currencyId
						},
						refundedFromInvoices: this.submitData.refundedFromInvoices
					})
						.then(() => {
							this.$store.dispatch('invoices/checkout', { id: currentInvoice.id })
								.then(() => {
									this.$eventBus.$emit('show-snackbar', false, this.$t('messages.checkout-done'))
									this.submitLoading = false;
									this.isCurrentInvoicePosted = true;

									const latestInvoice = this.invoices[this.invoices.length - 1];
									if (latestInvoice.id === this.$route.params.id) {
										this.createInvoice(false);
									}
								})
						})
						.catch(() => {
							this.submitLoading = false;
						})
				})
				.catch(() => {
					this.submitLoading = false;
				})
				.finally(() => {
					this.resetTableInputs();
					this.refDoc = null
					this.submitLoading = false;
					this.alertDialog = false
				})
		},
	},
	created() {
		this.submitData.cashbox = this.permissionsForUser.userType === userType.casher ? this.$localStorage.cashboxId : this.configs.mainCashBoxId

		this.dialogData.account = this.configs.defaultCashCustomerId

		this.loading = true 
		this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.sales }).then((data) => {
			this.invoicesTypeSales = data
		}).then(() => {
			this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.salesRefund }).then((invoices) => {
				if (!this.$route.params.id) {
					if (invoices.length === 0) {
						this.createInvoice(true);
					} else {
						// casher allowed to see his cashbox invoices
						const availableInvoices =
							invoices.filter(c =>
								this.permissionsForUser.userType === userType.casher
									? c.cashboxId === this.permissionsForUser.cashboxId
									: true
							);
						const latestInvoice = availableInvoices[availableInvoices.length - 1];
						
						if (latestInvoice.isPosted) {
							this.createInvoice(false)
						} else {
							this.$router.push({ name: 'sales-invoice-returned', params: { id: latestInvoice.id } });
							this.submitData.date = latestInvoice.date;
							this.invoiceNo = latestInvoice.no;
							this.loading = false;
						}
					}
				} else {
					this.fetchInvoice();
				}
			}).catch(() => {
				this.loading = false
			})
		})
	},

	mounted() {
		this.tableInputs.quantity = '1';
	},

	metaInfo() {
		return {
			title: this.$t('routes.sales-invoice-returned'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>
<style lang="scss" scoped>
.round {
	border-radius: 18px;
}
@media screen and (min-width: 960px) {
	.table-col {
		min-height: 91vh;
	}
	.side-col {
		height: 91vh;
		overflow: auto;
	}
}
</style>