<template>
	<v-row>
		<v-col></v-col>
		<v-col>
			<div class="d-flex flex-column align-center justify-center" style="height: 85vh">
				<svg				
					version="1.0"
					xmlns="http://www.w3.org/2000/svg"
					width="140pt"
					height="140pt"
					viewBox="0 0 430 393"
					preserveAspectRatio="xMidYMid meet"
				>
					<g transform="translate(0,393) scale(0.100000,-0.100000)" stroke="none">
						<path class="part-1" d="M1263 3893 c2 -5 113 -198 247 -430 l243 -423 401 0 401 0 220 382 c121 210 232 403 248 430 l27 48 -895 0 c-493 0 -894 -3 -892 -7z"/>
						<path class="part-2" d="M3354 3683 c-15 -26 -34 -59 -42 -73 -9 -14 -30 -50 -47 -80 -148
						-258 -310 -539 -349 -604 l-47 -79 79 -136 c43 -75 134 -234 203 -353 l124
						-218 513 0 c281 0 512 3 512 6 0 5 -158 282 -190 334 -21 34 -370 638 -398
						690 -14 25 -35 61 -47 80 -24 38 -149 256 -175 305 -9 17 -30 53 -48 80 -17
						28 -32 55 -32 61 0 6 -6 17 -14 23 -12 10 -19 3 -42 -36z"/>
						<path class="part-3" d="M3675 1772 l-390 -2 -192 -333 c-106 -183 -195 -340 -198 -349 -4 -9 85 -174 235 -434 133 -231 245 -422 248 -426 4 -4 163 264 354 595 191 331
						392 679 447 774 l99 172 -106 3 c-59 2 -282 2 -497 0z"/>
						<path class="part-4" d="M1754 876 c-8 -6 -123 -198 -254 -426 l-240 -415 451 -3 c248 -1 650
						-1 893 0 l443 3 -246 426 -245 426 -394 0 c-270 0 -397 -4 -408 -11z"/>
						<path class="part-5" d="M116 1623 c127 -219 223 -384 352 -608 63 -110 140 -243 170 -295 30 -52 71 -122 90 -155 19 -33 54 -94 77 -135 23 -41 60 -105 81 -142 l39 -67 16
						27 c8 15 76 133 151 262 163 283 278 482 307 530 l21 36 -136 234 c-131 227
						-161 278 -234 403 l-34 57 -493 0 -492 0 85 -147z"/>
						<path class="part-6" d="M478 2937 c-245 -425 -445 -773 -443 -775 4 -4 985 -5 988 -1 2 2 92
						158 201 347 l198 342 -238 413 c-131 226 -243 420 -249 429 -9 14 -83 -107
						-457 -755z"/>
						<path class="part-7" d="M1554 2318 c-108 -189 -197 -347 -197 -353 1 -8 63 -117 361 -632
						l36 -63 401 0 401 0 194 337 c107 185 196 342 198 349 2 7 -68 136 -154 286
						-87 150 -176 306 -198 345 l-41 73 -401 0 -402 0 -198 -342z"/>
					</g>
				</svg>
				<p class="text-h4 primary--text mb-1">Emerald POS</p>
				<span class="text-subtitle-2 overline">UI - v{{version}}</span>
				<span> 
					{{ $t('welcome-in') + '  '}} 
					<span class="text-h6 font-weight-bold">
						{{ auth.name }}
					</span>
				</span>
			</div>
		</v-col>

		<v-col class="d-flex flex-column">	
			<v-col>
				<a href="/journals">
					<div
						v-if="permissionsForUser?.userType !== 3 && accountStatementSummary.sumDifference.amount"				
						class="position position-absolute w-100"
					>
						<div
							class="d-flex justify-end white--text w-100"
						>
							<!-- style="width: 100%" -->
							<v-alert
								color="alert-color white--text"
								dense
								small
								shaped
							>	
								<v-icon color="white" class="me-1"> mdi-alert-circle-outline </v-icon>
								<span class="body-3">{{ $t('messages.alert-message-part-one') }}</span>
								<br>
								<span class="body-3">{{ $t('messages.alert-message-part-two') }}</span>
							</v-alert>
						</div>
					</div>
				</a>
			</v-col>
		
			<v-row  
				v-if="permissionsForUser.userType !== userType.casher"
				:class="{ 
					'mt-95': $vuetify.breakpoint.mdAndDown, 
					'mt-120': $vuetify.breakpoint.smAndDown, 
					'mt-16': $vuetify.breakpoint.lgAndUp 
				}"
			>
				<v-col
					v-for="(cashbox, k) in statistics.cashboxBalances"
					:key="k"
					class="d-flex"
				>
					<v-card width="190" height="180" class="rounded-xl">
						<v-card-title class="pb-1">
							<h3 class=""> {{ $t('home.cashbox-name') }} </h3>
							<h3 class="dark-blue-primary--text"> {{cashbox.cashbox.name}} </h3>
						</v-card-title>
						<v-card-text> 
							<div class="pb-1">
								<h6> {{ $t('home.name-of-the-financial-account') }} </h6>
								<p class="mb-1 text-subtitle-2"> {{cashbox.accountBalance.name}} </p>
							</div>
							<div class="pb-1">
								<h3
									:class="{ 
										'primary--text': cashbox.accountBalance.balance.moneyType === 0, 
										'alert-color--text': cashbox.accountBalance.balance.moneyType === 1 
									}"
									class="mb-0 font-22"
								> 
									<!-- {{ }}  -->
									{{Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(cashbox.accountBalance.balance.amount ,)}}
								</h3>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col 
					v-for="(account, index) in statistics.accountConfigBalances" 
					:key="index"
				>
					<v-card width="190" height="180" class="rounded-xl">
						<v-card-title class="pb-1">
							<h3 class="dark-blue-primary--text"> {{ $t('home.sales') }} </h3>
						</v-card-title>
						<v-card-text> 
							<div class="pb-1">
								<h6> {{ $t('home.name-of-the-financial-account') }} </h6>
								<p class="mb-1 text-subtitle-2"> {{account.accountBalance.name }} </p>
								<h3 
									class="mb-0 black--text font-22" 
									:class="{ 
										'primary--text': account.accountBalance.balance.moneyType === 0, 
										'alert-color--text': account.accountBalance.balance.moneyType === 1 
									}" 
								>
									<!-- {{account.accountBalance.balance.amount }}  -->

									{{
										Intl.NumberFormat("en-IN", { maximumSignificantDigits: 3 }).format(account.accountBalance.balance.amount ,)
									}}
								</h3>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script>
import { VERSION as version } from '@/helpers/constants'
import { mapState } from 'vuex';
import { moneyType, userType } from '@/helpers/enums'
import { rules } from '@/helpers/validation rules'
export default {
	name: 'Home',

	data() {
		return {
			version,
			moneyType,
			rules,
			userType
		}	
	},

	metaInfo: {
		title: 'POS'
	},
	computed: {
		...mapState({
			permissionsForUser: state => state.auth.permissionsForUser,
			auth: state => state.auth.self,
			accountStatementSummary: state => state.statisticsReports.accountStatementSummary,
			notifications: state => state.users.notifications,
			statistics: state => state.accounting.statistics,
			cashboxBalances: state => state.accounting.statistics?.cashboxBalances
		}),
	},
	methods: {
		numberFormat(value) {
			return this.$options.filters.numberFormat(value);
		},
	},
	created() {
		this.$store.dispatch('users/fetchNotifications', {
			storeId: this.$localStorage.storeId,
			isSeen: false
		})
		this.$store.dispatch('accounting/fetchStatistics')
	}
};
</script>

<style lang="scss" scoped>

$list: 1 5, 3 1, 4 2, 5 3, 6 4;

@each $item, $order in $list {
	.part-#{$item} {
		opacity: 0;
		animation: fadeIn .8s cubic-bezier(.46,.45,.39,.95) #{$order * 150}ms 1 forwards;
	}
	@keyframes fadeIn {
		0% {
			opacity: .4;
			transform: scale(1.1);
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}
}
.part-2 {
	opacity: 0;
	animation: scale 1.15s cubic-bezier(.46,.45,.39,.95) #{7 * 200}ms 1 forwards;
	@keyframes scale {
		0% {
			opacity: .4;
			transform: scale(3);
		}
		50% {
			opacity: 1;
			transform: scale(1);
		}
		75% {
			opacity: .8;
		}
		100% {
			opacity: 1;
			transform: scale(1);
		}
		// 0% {
		// 	opacity: .6;
		// }
		// 50% {
		// 	opacity: 1;
		// }
		// 100% {
		// 	opacity: .6;
		// }
	}
}
.position {
	width: 30%;
	left: 3%; 
	top:30px
}
.position-absolute {
	position: absolute;
}
.body-3 {
	font-size: 13px;
}
.font-22 {
	font-size: 19px;
} 
.mt-95 {
	margin-top: 95px;
}
.mt-120 {
	margin-top: 120px;
}
</style>
