import * as types from '../mutation-types'

export default {
	namespaced: true,
	state: {
		journals: [],
		journal: {
			entries: []
		}
	},
	actions: {
		fetchAll({ commit }, { status, cashboxId, fromDate, toDate, context, dateSortMode }) {
			return axios.get('/FundsFlow/cashJournals', {
				params: {
					status,
					cashboxId,
					fromDate,
					toDate,
					context,
					dateSortMode
				}
			}).then(({ data }) => {
				commit(types.STORE_JOURNALS, data);
				return data;
			})
		},

		create({ commit }, { date, cashboxId, isCash }) {
			return axios.post('/FundsFlow/cashJournals', {
				date, 
				cashboxId,
				isCash
			}).then(({ data }) => data)
		},

		fetchById({ commit }, { id }) {
			return axios.get(`/FundsFlow/cashJournals/${id}`).then(({ data }) => {
				commit(types.STORE_JOURNAL, data);
				return data
			})
		},

		saveCashJournals({ commit }, { id }) {
			return axios.put(`/FundsFlow/cashJournals/${id}/post`).then(({ data }) => {
				return data;
			})
		},
		delete({ commit }, { id }) {
			return axios.delete(`/FundsFlow/cashJournals/${id}`)
		},
		reset({ commit }) {
			return commit(types.RESET) 
		}
	},
	mutations: {
		[types.STORE_JOURNALS](state, journals) {
			state.journals = journals
		},
		[types.STORE_JOURNAL](state, journal) {
			state.journal = journal
		},
		[types.RESET](state) {
			state.journals = [];
			state.journal = []
		}
	},

	getters: {
		getCashJournalById: state => id => state.journals.find(c => c.id === id)
	}
}