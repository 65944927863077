<template>
	<div class="bgColor rounded-t-xl" style="height: 90vh">
		<v-row class="mx-2">
			<!-- from data -->
            <v-col cols="6" sm="4" md="3" lg="2">
				<bee-date-input
					v-model="fromDate"
					:label="$t('forms.from-date')"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					dense
					hide-details="auto"
					:loading="loading"
					:disabled="loading"
				></bee-date-input>
			</v-col>

			<!-- to date -->
            <v-col cols="6" sm="4" md="3" lg="2">
				<bee-date-input
					v-model="toDate"
					:label="$t('forms.to-date')"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					dense
					hide-details="auto"
					:loading="loading"
					:disabled="loading"
				></bee-date-input>
			</v-col>
		</v-row>

		<!-- table -->
		<bee-handy-table
			ref="table"
			:headers="headers"
			:items="entries"
			hide-default-footer
			dense
			class="my-2 px-2 mx-2"
			:loading="loading"
		>
			<!-- actions -->
			<template v-slot:item.actions="{ }">
			</template>
		</bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { type } from '@/helpers/enums'
import moment from 'moment'
export default {
	name: 'ItemsJournalTable',

	data() {
		return {
			loading: false,
			submitLoading: false,
			tableInputs: {},
			entries: [],
			type,
			fromDate: null,
			toDate: null,
			rowEditIndex: null,
			isEditItem: false,
			rowId: null,
			deleteId: null
		}
	},
	computed: {
		...mapState({
			items: state => state.items.items,
			accounts: state => state.accounts.accounts
		}),

		...mapGetters({
			getItemById: 'items/getItemById'
		}),

		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'type',
					value: 'type',
					// setValueExpr: (val, index) => index + 1
				},
				{
					text: this.$t('headers.entered-quantity'),
					name: 'enteredQuantity',
					value: 'quantity.quantity',
					setValueExpr: (val, index) => this.headers[index + 1].type === 1 ? val.quantity : null
				},
				{
					text: this.$t('headers.outgoing-quantity'),
					name: 'outgoingQuantity',
					value: 'quantity.quantity',
					setValueExpr: (val, index) => this.headers[index].type === 2 ? val.quantity : null
				},
				{
					text: this.$t('headers.item'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.getItemById(val)?.name,
					width: 140
				},
				{
					text: this.$t('headers.account'),
					name: 'accountId',
					value: 'accountId',
					setValueExpr: val => this.accounts.find(c => c.id === val)?.name ?? val,
					width: 140		
				},
				{
					text: this.$t('headers.the-number'),
					name: 'count',
					value: 'count'
				},
				{
					text: this.$t('headers.price'),
					name: 'price',
					value: 'price.amount'
				}, 
				{
					text: this.$t('headers.explanation'),
					name: 'notes',
					value: 'notes'
				},
				{
					text: this.$t('headers.the-document'),
					name: 'theDocument',
					value: 'theDocument'
				},
				{
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					setValueExpr: val => moment(val).format('YYYY-MM-DD')
				},
				{
					text: '',
					name: 'actions',
					value: 'actions',
					noInput: true,
					width: '100px'
				}
			]
			return headers
		}
	},

	methods: {
		moment, 		
	},

	created() {
	},

	metaInfo() {
		return {
			title: this.$t('routes.items-journal-table'),
			titleTemplate: '%s | POS'	
		}
	}
}
</script>
