export default {
	'fiscal-account': 'الحساب المالي',
	users: 'المسخدمين',

	'tax-percent': 'نسبة الضريبة',
	cashbox: 'صندوق',
	cashboxes: 'الصناديق',
	delete: 'حذف ',
	active: 'فعال',
	inactive: 'غير فعال'
}