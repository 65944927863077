export default {
	'payment-restrictions-only': 'قيود المدفوعات فقط',
	'the-total-number': 'العدد الكلي:',
	'non-posted-bonds-count': 'عدد السندات غير المرحلة:',
	type: 'النوع',
	all: 'الكل',
	posted: 'مرحلة',
	'non-posted': 'غير مرحلة',
	tabs: {
		all: 'الكل',
		'cashboxes-journals': 'يوميات الصناديق',
		'journals-linking-invoices': 'اليوميات المرتبطة بالفواتير',
		'journals-associated-with-the-closing-of-funds': 'اليوميات المرتبطة بإغلاق الصناديق'
	}
}
