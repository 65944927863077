var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-treeview',{ref:"tree",class:[{ 'bee-treeview--hide-expand-icon': _vm.hideExpandIcon }],style:({
		width: _vm.treeWidth ? _vm.treeWidth + 'px' : '100%',
		minWidth: _vm.minTreeWidth + 'px'
	}),attrs:{"id":"tree-view","dense":"","open":_vm.openItems,"active":_vm.active,"disabled":_vm.disabled,"hoverable":"","open-on-click":_vm.openOnClick,"activatable":"","items":_vm.items,"load-children":_vm.loadChildren,"expand-icon":_vm.expandIcon,"selection-type":"independent"},on:{"update:open":[function($event){_vm.openItems=$event},function($event){_vm.$emit('update:open', $event); _vm.findTreeWidth()}],"update:active":function($event){return _vm.$emit('update:active', $event);}},scopedSlots:_vm._u([{key:"label",fn:function(data){return [_c('div',{staticClass:"d-flex align-center flex-grow-1",class:[
				!_vm.disabled
					? typeof _vm.itemClass === 'function'
						? _vm.itemClass(data.item, { leaf: data.leaf, active: data.active })
						: _vm.itemClass
					: 'grey--text',
				!data.leaf && !_vm.disabled ? _vm.rootClass : null,
				data.active ? _vm.activeClass : null,
			],on:{"click":function($event){!_vm.disabled ? _vm.$emit('update:clicked-item', data.item) : null}}},[(_vm.$slots['prepend-label'] || _vm.$scopedSlots['prepend-label'])?_vm._t("prepend-label",null,null,{...data}):_vm._e(),_vm._v(" "+_vm._s(data.item.name)+" ")],2)]}},(_vm.$slots['append'] || _vm.$scopedSlots['append'])?{key:"append",fn:function(data){return [_vm._t("append",null,null,{...data})]}}:null,(_vm.$slots['prepend'] || _vm.$scopedSlots['prepend'])?{key:"prepend",fn:function(data){return [_vm._t("prepend",null,null,{...data})]}}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }