<template>
	<div id="invoice" class="invoice">	
		<!-- dialog -->
		<!-- Dialog alert -->
		<v-dialog v-model="alertDialog" width="350px">
			<v-card>
				<v-card-title class="orange--text py-0">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('alerts.alert')}}
					</span>
					<v-spacer/>

					<v-btn @click="alertDialog = false" icon dark>
						<v-icon color="orange">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pb-0 pt-1">
					{{ $t('alerts.check-date.message') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>
					<v-btn
						color="orange white--text"
						large
						height="34"
						@click="submitData.date = moment(); checkout()"
					>
						{{$t('alerts.check-date.today-date')}}
					</v-btn>
					<v-btn
						large
						height="34"
						@click="alertDialog = false; checkout()"
					>
						{{$t('alerts.check-date.invoice-date')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="confirmDiscardDialog" width="400" persistent>
			<v-card>
				<v-card-title class="alert-color--text py-0">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('delete')}}
					</span>
					<v-spacer/>

					<v-btn @click="confirmDiscardDialog = false" icon dark>
						<v-icon color="alert-color">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pt-4 pb-2">
					{{ $t('messages.are-you-sure-to-delete-invoice-draft') }}
				</v-card-text>
				<v-card-actions>
					<v-spacer/>

					<v-btn
						color="alert-color white--text"
						large
						height="34"
						@click ="deleteInvoice()" 
						:loading="deleteLoading"
					>
						{{$t('confirm')}}
					</v-btn>
					<v-btn
						large
						height="34"
						@click="confirmDiscardDialog = false"
						:disabled="deleteLoading"
					>
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- quantity dialog -->
		<v-dialog v-model="dialogQuantity" width="300">
			<v-card>
				<v-card-title class="primary--text text-center py-1">
					<v-spacer />
					
					<span>
						{{$t('PurchaseInvoice.addition-item')}}
					</span>

					<v-spacer/>

					<v-btn @click="resetQuantityDialogData()" icon dark>
						<v-icon color="primary">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider />

				<v-form @submit.prevent="addByBarcode" ref="form">
					<v-card-text class="pb-0 pt-1">
						<span class="mb-2">
							{{dialogQuantity ? searchItem ? items.find(c => c.barcode === searchItem).name : getItemById(itemFavId)?.name : null}}
						</span>

						<v-row>
							<v-col cols="12">
								<v-text-field
									v-model.number="quantityEntryDialog"
									ref="quantityDialogInput"
									:label="$t('forms.quantity')"
									autofocus
									outlined
									dense
									class="pt-3"
									hide-details
									:rules="rules.requiredAsNumber"
								></v-text-field>
							</v-col>
							<v-col cols="12">
								<v-text-field
									v-model="priceEntryDialog"
									:label="$t('forms.price')"
									hide-details
									@focus="fetchSuggestedPurchasePrice()"
									:rules="[val => val !== null]"
									:disabled="loadingRequestPrice"
									:loading="dialogLoading"
									outlined
									dense
								></v-text-field>
							</v-col>
						</v-row>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn
							text
							color="primary white--text"
							type="submit"
							:disabled="dialogLoading"
						>
							{{$t('ok')}}
						</v-btn>

						<v-btn
							text
							color="alert-color white--text"
							@click="resetQuantityDialogData()"
							:disabled="dialogLoading"
						>
							{{$t('cancel')}}
						</v-btn>
					</v-card-actions>
				</v-form>

			</v-card>
		</v-dialog>

		<!--  -->
		<v-dialog v-model="dialogPaymentMethod" width="400">
			<v-card>
				<v-card-title class="primary--text text-center py-1">
					<v-spacer/>
					<span class="ms-8 text-uppercase">
						{{$t('payment-method.label')}}
					</span>
					<v-spacer />
					<v-btn @click="dialogPaymentMethod = false" icon dark>
						<v-icon color="primary">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-card-text class="pb-0 pt-1">
					{{ $t('PurchaseInvoice.the-net-invoice-must-be-zero-and-this-requires-zeroing-all-financial-fields') }}
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn @click="resetPrice()" text color="success">
						{{$t('ok')}}
					</v-btn>

					<v-btn @click="dialogPaymentMethod = false" text color="alert-color">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-row>
			<!-- fav items -->
			<v-col lg="2" md="3" cols="12" class="side-col">
				<!-- barcode -->
				<v-text-field
					v-model="searchItem"
					:label="$t('PurchaseInvoice.enter-item-by-barcode')"
					dense
					hide-details
					outlined
					append-icon="mdi-barcode"
					@keypress.enter="checkBarcode()"
					:disabled="isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
				></v-text-field>
				
				<!-- items -->
				<v-expansion-panels
					accordion
					id="all-items-panels"
					active-class="py-0"
				>
					<v-expansion-panel
						v-for="(rootItem, i) in oneLevelItemsTree"
						:key="i"
					>
						<v-expansion-panel-header>
							{{rootItem.name}}
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-layout wrap justify-space-between class="pb-2">
								<v-flex
									v-for="item in rootItem.children"
									:key="item.id"
									md6 xs3
									class="pa-1"
								>
									<button
										v-ripple
										class="d-flex justify-center align-center text-center text-capitalize grey lighten-3 rounded-medium px-2 elevation-1 font-weight-bold"
										:class="[{ 'elevation-0 grey--text': submitLoading || loading || isCurrentInvoicePosted }, item.name.length > 16 ? 'text-small' : 'text-body-2']"
										:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
										:style="{
											width: '100%',
											height: $vuetify.breakpoint.lgAndUp ? '55px' : '40px'
										}"
										@click="itemFavId = item.id; searchItem = null; dialogQuantity = true"
									>
										{{item.name}}
									</button>
								</v-flex>
							</v-layout>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>

			</v-col>
			<!-- table -->
			<v-col lg="8" md="9" sm="12" class="table-col rounded-t-xl bgColor pt-lg-8 pt-6 px-lg-5 px-3">
				<!-- top row in md and Down size add + barcode -->
				<v-row class="mb-2"> 
					<v-col md="2" sm="3" cols="6" class="pa-1">
						<items-navigator
							v-model="invoiceNo"
							:label="$t('invoice-no')"
							:disabled="loading"
							:items="invoices"
						></items-navigator>
					</v-col>

					<v-col md="2" sm="3" cols="6" class="pa-1">
						<v-text-field 
							v-model="submitData.refDoc"
							:label="$t('PurchaseInvoice.paper-document')"
							class="py-0"
							hide-details
							dense
							:disabled="isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
						/>
					</v-col>

					<v-spacer/>

					<v-divider v-if="$vuetify.breakpoint.sm" vertical class="my-2 mx-2" />

					<v-col cols="auto" class="pa-1">
						<v-btn 
							elevation="0" 
							color="primary" 
							width="100%" 
							height="35" 
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							@click="createNewInvoice"
						>
							{{$t('add-invoice')}}
						</v-btn>
					</v-col>

					<v-col cols="auto" class="pa-1 pe-md-4">
						<v-menu offset-y>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									dark
									v-bind="attrs"
									v-on="on"
									elevation="0" 
									color="blue-dark white--text" 
									width="100%" 
									height="35" 
									:disabled="submitLoading || loading "
								>
									{{$t('show')}}
									<v-icon size="20">mdi-chevron-down</v-icon>
								</v-btn>
							</template>
							<v-list dense>
								<v-list-item 
									:to="{
										name: 'all-invoices',
										query: { type: invoiceTypes.purchases }
									}"
								>
									<v-list-item-title> 
										{{$t('all-invoice')}}
									</v-list-item-title>
								</v-list-item>

								<v-list-item 
									:to="{
										name: 'journal-item',
										params: {
											id: getInvoiceById($route.params.id)?.itemsJournalId
										}
									}"
									:disabled="!getInvoiceById($route.params.id)?.isPosted"
								>
									<v-list-item-title> 
										{{$t('view-invoice-details.related-items-journal')}}
									</v-list-item-title>
								</v-list-item>

								<v-list-item 
									:to="{
										name: 'cashbox-journal',
										params: {
											id: getInvoiceById($route.params.id)?.cashJournalId
										}
									}"
									:disabled="!getInvoiceById($route.params.id)?.isPosted"
								>
									<v-list-item-title> 
										{{$t('view-invoice-details.related-cashbox-journal')}}
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
		
				<!-- table -->
				<bee-handy-smart-table
					v-model="tableInputs"
					ref="table"
					:headers="headers"
					:items="entries"
					:items-per-page="-1"
					:loading="loading"
					:data-table-props="{
						disableSort: true,
						noDataText: ''
					}"
					fixed-header
					hide-default-footer
					dense
					:show-add-row="permissionsForUser.userType !== userType.dataReader"
					:editable-rows="!submitLoading && !loading && !isCurrentInvoicePosted"
					:height="$vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.height - 275 : $vuetify.breakpoint.height - 145"
					:context-menu-items="contextMenuItems"
					stay-in-input-fields
					:validation-messages.sync="validationMessages"
					hide-inputs-details
					disable-mobile-context-menu
					@edit-cancelled="isEditItem = false"
					@context-menu-edit="setRowIndex"
					@context-menu-delete="deleteItem"
					@keypress.enter="!isEditItem ? addItem() : editItem()"
					@reached-last-input="!isEditItem ? addItem() : editItem()"
					@change-itemId="isPriceChangedByUser = false; reset()"
					@input-price="isPriceChangedByUser = true"
					zebra
					hot-inputs
					table-class="py-2 px-3"
					:disable-context-menu="submitLoading || loading || isCurrentInvoicePosted"
					:disable-input-fields="submitLoading || loading || isCurrentInvoicePosted"
				>
					<!-- actions -->
					<template v-slot:item.actions="{ index }">
						<div class="d-flex">
							<!-- edit -->
							<v-btn
								@click="$refs.table.activeEditForRow(index); setRowIndex({ index })"
								width="30"
								min-width="30"
								height="25"
								color="edit-color"
								class="me-2 pa-0"
								outlined
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							>
								<v-icon size="18">mdi-pencil</v-icon>
							</v-btn>

							<!-- delete -->
							<v-btn
								width="30"
								min-width="30"
								height="25"
								color="alert-color"
								class="pa-0"
								outlined
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								@click="deleteItem({ index })"
							>
								<v-icon size="18">mdi-delete</v-icon>
							</v-btn>
						</div>
					</template>

					<!-- itemId -->
					<template v-slot:input.itemId="{ on, attr }">
						<v-autocomplete
							:items="items.filter(c => c.type === type.sub)"
							item-value="id"
							:item-text="item => `${item.no} - ${item.name}`"
							auto-select-first
							v-on="on"
							v-bind="attr"
						></v-autocomplete>
					</template>
					<!-- quantity -->
					<template v-slot:input.quantity="{ on, attr }">
						<quantity-input
							v-on="on"
							v-bind="attr"
						></quantity-input>
					</template>

					<!-- price -->
					<template v-slot:input.price="{ on, attr }">
						<v-text-field
							:loading="loadingRequestPrice"
							v-on="on"
							v-bind="attr"
							@focus="fetchSuggestedPurchasePrice"
						></v-text-field>
					</template>

					<!-- percentDiscount -->
					<template v-slot:input.percentDiscount="{ on, attr}">
						<v-text-field
							prefix="%"
							v-on="on"
							v-bind="attr"
							hide-spin-buttons
						></v-text-field>
					</template>
				</bee-handy-smart-table>

				<!-- info in smAndDown size -->
				<v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12">
					<v-row>
						<v-col cols="4">
							<!-- cashbox -->
							<v-autocomplete 
								v-model="submitData.cashbox" 
								:items="cashboxes" 
								:label="$t('forms.cashbox')"
								item-value="id"
								item-text="name" 
								dense
								outlined
								:disabled="permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
								hide-details
							></v-autocomplete>

							<!-- account -->
							<v-autocomplete
								v-model="submitData.account"
								:label="$t('forms.account')"
								:items="accounts.filter(c => c.type === type.sub && !c.isHidden)"
								item-value="id"
								:item-text="account => `${account.accountingNo} - ${account.name}`"
								outlined
								dense
								hide-details
								:readonly="isCurrentInvoicePosted"
								:rules="rules.required"
								:disabled="permissionsForUser.userType === userType.dataReader"
							></v-autocomplete>
									
							<!-- date in md and down size -->
							<bee-date-input
								v-if="$vuetify.breakpoint.mdAndDown"
								v-model="submitData.date"
								:label="$t('forms.date')"
								input-format-order="DD-MM-YYYY"
								prepend-icon="mdi-calendar"
								dense
								hide-details
								class="my-4"
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
								:loading="loading"
								iso-date
							></bee-date-input>
						</v-col>

						<v-divider class="my-2" vertical />
						<v-col cols="4">
							<!-- payment method -->
							<div class="d-flex justify-space-between align-center flex-wrap mb-1">
								<v-autocomplete
									v-model="submitData.paymentMethodId"
									:items="paymentMethodArray"
									item-text="name"
									item-value="id"
									:label="$t('payment-method.label')"
									outlined
									dense
									hide-details="auto"
									@change="submitData.paymentMethodId === paymentMethod.internal ? dialogPaymentMethod = true : null"
									:disabled="permissionsForUser.userType === userType.dataReader"
									:readonly="isCurrentInvoicePosted"
								/>
							</div>

							<v-spacer />
							
							<!-- paid -->
							<v-text-field
								v-model="paidFormatted"
								v-number-format.number="[paidFormatted, submitData.paid]"
								:label="$t('forms.paid')"
								dense
								hide-details
								class="mb-2"
								outlined
								:disabled="submitLoading || loading || [paymentMethod.cash, paymentMethod.creditCard, paymentMethod.internal].includes(submitData.paymentMethodId) || permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
								:loading="loading"
							></v-text-field>

							<!-- notes -->
							<!-- <v-textarea
								v-model="submitData.notes"
								:label="$t('forms.notes')"
								dense
								outlined
								rows="1"
								full-width
								class="my-2"
								hide-details
								no-resize
								auto-grow
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
								:loading="loading"
							></v-textarea> -->

							<!-- unpaid -->
							<v-col v-if="submitData.paymentMethodId === paymentMethod.debt" class="pa-0">
								<div class="d-flex justify-space-between align-center edit-color--text my-1">
									<span class="text-subtitle-2 font-weight-medium">
										{{$t('forms.unpaid')}}
									</span>
									<span class="text-subtitle-1 font-weight-bold">
										{{unpaidAmount}}
									</span>
								</div>
							</v-col>

							<!-- total -->
							<v-text-field
								:label="$t('forms.total')"
								dense
								hide-details
								class="mb-2"
								outlined
								readonly
								:value="numberFormat(entriesTotal)"
								:disabled="permissionsForUser.userType === userType.dataReader"
							></v-text-field>
						</v-col>
					
						<v-divider class="my-2" vertical />

						<v-col>
							<!-- discount -->
							<v-text-field
								v-model="discountFormatted"
								v-number-format.number="[discountFormatted, submitData.discount]"
								:label="$t('forms.discount')"
								dense
								hide-details
								outlined
								class="mb-2"
								:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
								:loading="loading"
							></v-text-field>

							<!-- totalNet -->
							<div class="d-flex justify-space-between">
								<span class="text-subtitle-2 font-weight-medium">
									{{$t('PurchaseInvoice.total-net')}}
								</span>
								<span class="text-subtitle-1 font-weight-bold">
									{{numberFormat(totalNet)}}
								</span>
							</div>
							<div class="d-flex  text-center" style="width:100%" >
								<v-btn
									height="34"
									width="75%"
									color="blue-dark white--text"
									class="text-capitalize"
									:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
									:loading="submitLoading || loading"	
									@click="checkDate()"
								>
									{{ $t('save') }}
								</v-btn>
								<v-btn
									v-if="!isCurrentInvoicePosted"
									height="34"
									width="25%"
									color="alert-color white--text"
									class="text-capitalize mx-1"
									@click="confirmDiscardDialog = true"
									:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
								>
									{{$t('delete')}}
								</v-btn>
							</div>

						</v-col>
					</v-row>
				</v-col>
			</v-col>

				<!-- info in mdAndUp size -->
			<v-col v-if="$vuetify.breakpoint.lgAndUp" md="3" lg="2" sm="12" class="pt-6">
				<v-row>
					<v-col cols="12" md="12" sm="3" class="py-0">
						<!-- cashbox -->
						<v-autocomplete 
							v-model="submitData.cashbox" 
							:items="cashboxes" 
							:label="$t('forms.cashbox')"
							item-value="id"
							item-text="name" 
							dense
							outlined
							:disabled="permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
						></v-autocomplete>

						<!-- account -->
						<v-autocomplete
							v-model="submitData.account"
							:label="$t('forms.account')"
							:items="accounts.filter(c => c.type === type.sub && !c.isHidden)"
							item-value="id"
							:item-text="account => `${account.accountingNo} - ${account.name}`"
							outlined
							dense
							class="mb-2"
							hide-details
							:rules="rules.required"
							:readonly="isCurrentInvoicePosted"
							:disabled="permissionsForUser.userType === userType.dataReader"
						></v-autocomplete>
					</v-col>

					<!-- date -->
					<v-col cols="12" md="12" sm="2" class="py-0">
						<bee-date-input
							v-model="submitData.date"
							:label="$t('forms.date')"
							input-format-order="DD-MM-YYYY"
							prepend-icon="mdi-calendar"
							dense
							hide-details
							class="mb-2"
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
							iso-date
						></bee-date-input>
					</v-col>

					<v-col class="py-0" cols="12">
						<v-divider class="my-2" />
						<!-- payment method -->
						<div class="d-flex justify-space-between align-center flex-wrap mb-1">	
							<v-autocomplete
								v-model="submitData.paymentMethodId"
								:items="paymentMethodArray"
								item-text="name"
								item-value="id"
								:label="$t('payment-method.label')"
								outlined
								dense
								hide-details="auto"
								@change="submitData.paymentMethodId === paymentMethod.internal ? dialogPaymentMethod = true : null"
								:disabled="permissionsForUser.userType === userType.dataReader"
								:readonly="isCurrentInvoicePosted"
							/>
						</div>
					</v-col>

					<v-col class="py-0" cols="12">
						<!-- paid -->
						<v-text-field
							v-model="paidFormatted"
							v-number-format.number="[paidFormatted, submitData.paid]"
							:label="$t('forms.paid')"
							dense
							hide-details
							class="mb-2"
							outlined
							:disabled="submitLoading || loading || [paymentMethod.cash, paymentMethod.creditCard, paymentMethod.internal].includes(submitData.paymentMethodId) || permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
						></v-text-field>
					</v-col>

					<v-col class="py-0" cols="12" md="12" sm="2">
						<!-- total -->
						<v-text-field
							:label="$t('forms.total')"
							dense
							hide-details
							class="mb-2"
							outlined
							readonly
							:value="numberFormat(entriesTotal)"
							:disabled="permissionsForUser.userType === userType.dataReader"
						></v-text-field>
					</v-col>

					<v-col class="py-0" cols="12" md="12" sm="2">
						<!-- discount -->
						<v-text-field
							v-model="discountFormatted"
							v-number-format.number="[discountFormatted, submitData.discount]"
							:label="$t('forms.discount')"
							dense
							hide-details
							outlined
							class="mb-2"
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
						></v-text-field>
					</v-col>

					<!-- total net -->
					<v-col cols="12" md="12" sm="2" class="py-0">
						<v-divider class="my-2"   />
						<div class="d-flex justify-space-between">
							<span class="text-subtitle-2 font-weight-medium">
								{{$t('PurchaseInvoice.total-net')}}
							</span>
							<span class="text-subtitle-1 font-weight-bold">
								{{numberFormat(totalNet)}}
							</span>
						</div>
						<v-divider class="my-2"   />
					</v-col>

					<v-divider vertical class="my-3"/>

					<!-- unpaid -->
					<v-col v-if="submitData.paymentMethodId === paymentMethod.debt">
						<div class="d-flex justify-space-between align-center edit-color--text my-1">
							<span class="text-subtitle-2 font-weight-medium">
								{{$t('forms.unpaid')}}
							</span>
							<span class="text-subtitle-1 font-weight-bold">
								{{unpaidAmount}}
							</span>
						</div>
						<v-divider/>
					</v-col>

					<v-divider vertical class="my-3" />
						
					<!-- notes -->
					<v-col cols="12" md="12" sm="2" class="py-0">
						<v-textarea
							v-model="submitData.notes"
							:label="$t('forms.notes')"
							dense
							outlined
							rows="2"
							full-width
							class="my-2"
							hide-details
							no-resize
							auto-grow
							:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
							:readonly="isCurrentInvoicePosted"
							:loading="loading"
						></v-textarea>
					</v-col>

					<!-- buttons -->
					<v-col cols="12" md="12" sm="2" class="pt-0">
						<div class="text-center" style="width:100%" >
							<v-btn
								height="40"
								width="100%"
								color="blue-dark white--text"
								class="text-capitalize "
								:disabled="permissionsForUser.userType === userType.dataReader || submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
								:loading="submitLoading || loading"	
								@click="checkDate()"
							>
								{{ $t('save') }}
							</v-btn>
							<v-btn
								v-if="!isCurrentInvoicePosted"
								height="34"
								width="100%"
								color="alert-color white--text"
								class="text-capitalize my-1"
								@click="confirmDiscardDialog = true"
								:disabled="submitLoading || loading || isCurrentInvoicePosted || permissionsForUser.userType === userType.dataReader"
							>
								{{$t('delete')}}
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { mapGetters, mapState } from 'vuex';
import { invoiceTypes, type, itemCategories, itemCategoryType, paymentMethod, paymentMethodArray, userType } from '@/helpers/enums';
import ItemsNavigator from '@/components/ItemsNavigator.vue';
import QuantityInput from '@/components/QuantityInput.vue';
import { fixNumber } from '@/helpers/functions';
import moment from 'moment'

export default {
	name: 'PurchaseInvoice',
	components: { ItemsNavigator, QuantityInput },

	data() {
		return {
			alertDialog: false,
			cashbox: '',
			itemFavId: null,
			tableInputs: {},
			isEditItem: false,
			rowEditIndex: null,
			validationMessages: [],
			itemCategoryType,

			confirmDiscardDialog: false,
			invoiceNo: 0,
			entries: [],

			searchItem: null,

			loading: false,
			submitLoading: false,

			paidFormatted: '',
			discountFormatted: '',

			isCurrentInvoicePosted: false,

			// enum
			invoiceTypes,
			paymentMethod,
			type,
			itemCategories,

			dialogQuantity: false,

			// data
			submitData: {
				account: null,
				cashbox: null,
				date: null,
				paymentMethodId: 1,

				paid: null,
				discount: null,
				notes: null,
				refDoc: null
			},
			rules,
			deleteLoading: false,

			loadingRequestPrice: false,
			priceEntryDialog: null,
			quantityEntryDialog: null,
			named: null,
			dialogLoading: false,
			dialogPaymentMethod: false,
			userType
		}
	},

	watch: {
		'$route.params.id'(val) {
			if (val) {
				this.fetchInvoice();
				this.resetTableInputs();
			}
		},
		'submitData.paymentMethodId'(val) {
			if (!this.isCurrentInvoicePosted) {
				if (val === paymentMethod.debt) {
					this.submitData.account = null
				} else if ([paymentMethod.cash, paymentMethod.creditCard].includes(val)) {
					this.submitData.account = this.configs.defaultImporterId;
				}
			}
		},

		watchInputs() {
			if (!this.isEditItem && this.tableInputs.itemId) {
				this.savedTableInputs = this.tableInputs;
			}
		},
		
		isEditItem(val) {
			if (!val && !this.entries.find(c => c.itemId === this.savedTableInputs.itemId)) {
				this.$nextTick(() => {
					this.$refs.table.resetInputs(this.savedTableInputs)
				})
			}
		},

		dialogQuantity(newVal) {
			if (newVal) {
				this.quantityEntryDialog = 1
				this.priceEntryDialog = null;
				this.$nextTick(() => {
					this.$refs.quantityDialogInput.focus();
					this.$refs.quantityDialogInput.$el.querySelector('input').select();
				})
			}
		}
	},

	computed: {
		...mapState({
			configs: state => state.configs.configs,
			cashboxes: state => state.cashboxes.cashboxes,
			oneLevelItemsTree: state => state.items.oneLevelItemsTree,
			accounts: state => state.accounts.accounts,
			permissionsForUser: state => state.auth.permissionsForUser,
			invoices: state => state.invoices.invoices,
			items: state => state.items.items
		}),
		...mapGetters({
			getInvoiceById: 'invoices/getInvoiceById',
			getInvoiceByNo: 'invoices/getInvoiceByNo',
			getItemById: 'items/getItemById',
			// getUnitById: 'units/getUnitById',
			getItemByBarcode: 'items/getItemByBarcode',
		}),

		// computed helpers
		contextMenuItems() {
			return [
				{ title: this.$t('edit'), icon: 'mdi-pencil', iconColor: 'edit-color', event: 'edit', class: 'edit-color--text' },
				{ title: this.$t('delete'), icon: 'mdi-delete', iconColor: 'alert-color', event: 'delete', class: 'alert-color--text' },
			]
		},

		headers() {
			const headers = [
				{
					text: this.$t('headers.serial'),
					name: 'no',
					value: 'no',
					setValueExpr: (val, index) => index + 1,
					noInput: true,
				},
				{
					text: this.$t('headers.barcode'),
					name: 'barcode',
					value: 'barcode',
					divider: true,
					noInput: true,
				},
				{
					text: this.$t('headers.item'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.items.find(c => c.id === val)?.name ?? val,
					inputRules: rules.required,
					width: 140,
					disableInput: this.isEditItem,
				},
				{
					text: this.$t('headers.quantity'),
					name: 'quantity',
					value: 'quantity',
					width: 85,
					inputRules: rules.number,
					writable: !this.isCurrentInvoicePosted,
					onWriteChange: (item, activeInput, index) => {
						this.entries[index].amount = item.quantity * item.price;
						this.entries[index].discount =
							fixNumber(item.amount * (item.percentDiscount / 100));
						this.entries[index].totalNet = item.amount - this.entries[index].discount;
					}		
				},
				{
					text: this.$t('headers.price'),
					name: 'price',
					value: 'price',
					inputRules: [val => !isNaN(val) && Number(val) >= 0 && val !== null],
					// inputRules: rules.required,
				},
				{
					text: this.$t('headers.amount'),
					name: 'amount',
					value: 'amount',
					disableInput: false,
					setInputExpr: inputs => inputs.price * inputs.quantity,
					type: 'number-format'
				},
				{
					text: this.$t('headers.percent-discount'),
					name: 'percentDiscount',
					value: 'percentDiscount',
					setInputExpr: (inputs, activeInput) => {
						if (activeInput !== 'percentDiscount') {
							let value = (inputs.discount * 100) / (inputs.amount || 1);
							const str = value.toString();
							if (str.includes('.') && str.split('.')[1].length > 2) {
								value = fixNumber(value);
							}
							return value || null
						} else {
							return inputs.percentDiscount
						}
					},
					type: 'number'
				},
				{
					text: this.$t('headers.discount'),
					name: 'discount',
					value: 'discount',
					disableInput: false,
					setInputExpr: (inputs, activeInput) => {
						if (activeInput !== 'discount') {
							let value = inputs.amount * (inputs.percentDiscount / 100);
							const str = value.toString();
							if (str.includes('.') && str.split('.')[1].length > 2) {
								value = fixNumber(value);
							}
							return value || null
						} else {
							return inputs.discount
						}
					},
					type: 'number-format'
				},
				{
					text: this.$t('headers.total-net'),
					name: 'totalNet',
					value: 'totalNet',
					disableInput: true,
					setInputExpr: inputs => fixNumber(inputs.amount - inputs.discount),
					type: 'number'
				},
			];
			if (!this.isCurrentInvoicePosted) {
				headers.splice(
					headers.length, 0,
					{
						text: '',
						name: 'actions',
						value: 'actions',
						align: 'center',
						noInput: true,
						sortable: false,
					}
				)
			}
			for (let i = 0; i < headers.length; i++) {
				headers[i].columnClass = headers[i].columnClass ? headers[i].columnClass + ' px-2' : 'px-2'
			}
			return headers
		},
		paymentMethods() {
			return [
				{ id: 0, name: this.$t('payment-method.cash') },
				{ id: 1, name: this.$t('payment-method.debt') },
			]
		},

		watchInputs() {
			return `${this.tableInputs.itemId} | ${this.tableInputs.quantity} | ${this.tableInputs.price} | ${this.tableInputs.discount} | ${this.tableInputs.percentDiscount}`
		},
		// computed invoice values
		entriesTotal() {
			// const totalArray = this.entries.map(c => c.totalNet);
			return this.entries.map(c => c.totalNet).reduce((a, b) => a + b, 0)
			// if (totalArray.length !== 0) {
			// 	return totalArray.reduce((a, b) => a + b)
			// } else {
			// 	return 0
			// }
		},

		totalNet() {
			return fixNumber(this.entriesTotal - this.submitData.discount)
		},

		unpaidAmount() {
			return this.numberFormat(fixNumber((this.totalNet - this.submitData.paid), 3));
		},

		paymentMethodArray() { return paymentMethodArray() },
	},

	methods: {
		moment,
		resetTableInputs() {
			this.$refs.table.resetInputs({
				itemId: null,
				quantity: 1,
				price: null,
				amount: null,
				percentDiscount: null,
				discount: null,
				totalNet: null,
			})
		},

		addItem() {
			if (this.$refs.table.validate()) {
				const itemInTable = this.entries.find(c => c.itemId === this.tableInputs.itemId);
				if (!itemInTable) {
					this.entries.push({
						barcode: this.getItemById(this.tableInputs.itemId)?.barcode,
						...this.tableInputs
					});
				} else {
					itemInTable.quantity = Number(itemInTable.quantity) + Number(this.tableInputs.quantity);
					itemInTable.amount = itemInTable.quantity * itemInTable.price;
					itemInTable.discount = itemInTable.amount * ((itemInTable.percentDiscount || 0) / 100);
					itemInTable.totalNet = itemInTable.amount - itemInTable.discount;
				}

				this.$nextTick(() => {
					this.resetTableInputs();
					this.$refs.table.resetInputsFocus()
				})
			}
		},

		editItem() {
			if (this.$refs.table.validate()) {
				this.entries[this.rowEditIndex].itemId = this.tableInputs.itemId
				this.entries[this.rowEditIndex].quantity = this.tableInputs.quantity
				this.entries[this.rowEditIndex].price = this.tableInputs.price;
				this.entries[this.rowEditIndex].amount = this.tableInputs.amount;
				this.entries[this.rowEditIndex].percentDiscount = this.tableInputs.percentDiscount;
				this.entries[this.rowEditIndex].discount = this.tableInputs.discount;
				this.entries[this.rowEditIndex].totalNet = this.tableInputs.totalNet;
				this.$refs.table.resetEdit()
				this.resetTableInputs();
				this.isEditItem = false;
				this.$refs.table.resetInputsFocus()
			}
		},

		deleteItem({ index }) {
			this.$refs.table.resetEdit()
			this.isEditItem = false;
			this.entries.splice(index, 1);
		},

		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus()
			})
		},
		checkDate() {
			if (this.moment(this.submitData.date).isBefore(this.moment(), 'day')) {
				this.alertDialog = true
			} else {
				this.checkout()
			}
		},
		checkout() {
			this.submitLoading = true;

			const currentInvoice = this.getInvoiceById(this.$route.params.id);
			const finalEntries = [];

			this.entries.forEach((entry) => {
				const item = this.getItemById(entry.itemId);
				finalEntries.push({
					invoiceId: currentInvoice.id,
					itemId: item.id,
					quantity: {
						quantity: Number(entry.quantity),
						unitSizeId: item.unitId,
					},
					price: {
						amount: Number(entry.price),
						currencyId: this.$localStorage.currencyId
					},
					amount: {
						amount: Number(entry.totalNet),
						currencyId: this.$localStorage.currencyId
					},
					notes: null,
					// FIXME is should to send discountPercentage to API?
					// discountPercentage: Number(entry.percentDiscount),
					discountAmount: {
						amount: Number(entry.discount),
						currencyId: this.$localStorage.currencyId
					}
				})
			})
			
			this.$store.dispatch('invoices/update', {
				id: this.$route.params.id,
				type: invoiceTypes.purchases,
				cashboxId: this.submitData.cashbox,
				intermediaryAccountId: this.submitData.account,
				date: this.submitData.date,
				notes: this.submitData.notes,
				refDoc: this.submitData.refDoc,
				paymentWay: this.submitData.paymentMethodId,
				paidAmount: {
					amount: this.submitData.paid,
					currencyId: this.$localStorage.currencyId
				},
				discountTotal: {
					amount: this.submitData.discount,
					currencyId: this.$localStorage.currencyId
				},
				totalNet: {
					amount: this.totalNet,
					currencyId: this.$localStorage.currencyId
				},
				entries: finalEntries
			})
				.then(() => {
					this.$store.dispatch('invoices/checkout', { id: currentInvoice.id })
						.then(() => {
							this.$eventBus.$emit('show-snackbar', false, this.$t('messages.checkout-done'))
							this.submitLoading = false;
							this.isCurrentInvoicePosted = true;

							const latestInvoice = this.invoices[this.invoices.length - 1];
							if (latestInvoice.id === this.$route.params.id) {
								this.createNewInvoice(false);
							}
						})
				})
				.finally(() => {
					this.submitLoading = false
					this.resetTableInputs();
					this.refDoc = null
					this.alertDialog = false
				})
		},
		checkBarcode () {
			if (this.getItemByBarcode(this.searchItem)) {
				this.dialogQuantity = true
			} else {
				this.$eventBus.$emit(
					'show-snackbar',
					true,
					this.$t('messages.please-check-barcode')
				)
			}
		},
		addByBarcode() {
			const item = this.searchItem ? this.getItemByBarcode(this.searchItem) : this.getItemById(this.itemFavId)
			if (this.$refs.form.validate()) {
				if (item) {
					const itemInTable = this.entries.find(c => c.itemId === item.id);
					if (!itemInTable) {
						const itemToAdd = {
							id: item.id,
							no: item.no * Math.round(Math.random() * 1000),
							itemId: item.id,
							quantity: this.quantityEntryDialog,
							price: this.priceEntryDialog,
							amount: this.priceEntryDialog * this.quantityEntryDialog,
							barcode: item.barcode,
							percentDiscount: null,
							discount: null,
							totalNet: this.priceEntryDialog * this.quantityEntryDialog,
						}
						this.entries.push(itemToAdd)
					} else {
						// itemInTable.quantity = this.dialogQuantity ? this.quantityEntryDialog : 1;
						itemInTable.price = this.priceEntryDialog
						itemInTable.quantity = this.quantityEntryDialog
						itemInTable.amount = this.priceEntryDialog * this.quantityEntryDialog;
						itemInTable.discount = itemInTable.amount * ((itemInTable.percentDiscount || 0) / 100);
						itemInTable.totalNet = itemInTable.amount - itemInTable.discount;
					}
					this.resetQuantityDialogData()
					this.searchItem = null;
					this.$refs.table.resetInputs();
					this.tableInputs.quantity = 1
				} else {
					this.$eventBus.$emit(
						'show-snackbar',
						true,
						this.$t('messages.please-check-barcode')
					)
				}
			}
		},
		
		// helpers
		numberFormat(value) {
			return this.$options.filters.numberFormat(value);
		},
		createNewInvoice(isFirstInvoice) {
			this.loading = true;
			this.submitLoading = true;
			this.$store.dispatch('invoices/create', {
				type: invoiceTypes.purchases, 
				cashboxId: this.submitData.cashbox,
				intermediaryAccountId: this.configs.defaultImporterId,
				date: new Date().toISOString(),
				notes: null,
				refDoc: null,
				paymentWay: this.submitData.paymentMethodId
			}).then((id) => {
				this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.purchases });
				this.$router.push({ name: 'pruchase-invoice', params: { id } });
				this.submitData.date = new Date().toISOString();
				
				if (isFirstInvoice) { this.invoiceNo = 1; this.submitData.refDoc = null };
			}).finally(() => {
				this.submitData.account = this.configs.defaultImporterId;
				this.loading = false;
				this.submitLoading = false;
			})
		},

		fetchInvoice() {
			this.loading = true;
			return this.$store.dispatch('invoices/fetchById', { id: this.$route.params.id })
				.then((data) => {
					this.isCurrentInvoicePosted = data.isPosted;

					this.invoiceNo = data.no;
					this.submitData.date = data.date;
					this.submitData.paymentMethodId = data.paymentWay;
					this.submitData.cashbox = data.cashboxId;
					this.submitData.account = data.intermediaryAccountId;
					this.submitData.paid = data.paidAmount?.amount;
					this.paidFormatted = this.numberFormat(data.paidAmount?.amount);
					this.submitData.discount = data.discountTotal?.amount;
					this.discountFormatted = this.numberFormat(data.discountTotal?.amount);
					this.submitData.refDoc = data.refDoc;
					this.submitData.notes = data.notes;
					
					this.entries = [];
					data.entries.forEach((entry) => {
						this.entries.push({
							barcode: this.getItemById(entry.itemId).barcode,
							itemId: entry.itemId,
							quantity: entry.quantity.quantity,
							price: entry.price.amount,
							amount: entry.quantity.quantity * entry.price.amount,
							percentDiscount: entry.discountPercentage,
							discount: entry.discountAmount.amount,
							totalNet: entry.totalAmount.amount,
						})
					})
				})
				.finally(() => {
					this.loading = false;
					// this.submitData.account = this.configs.defaultImporterId
				})
		},
		reset() {
			this.paidFormatted = null
			this.tableInputs.price = null
			this.tableInputs.amount = null
			this.tableInputs.discount = null
			this.tableInputs.percentDiscount = null
			this.tableInputs.totalNet = null
		},

		deleteInvoice() {
			this.deleteLoading = true
			this.$store.dispatch('invoices/delete', { id: this.$route.params.id }).then(() => {
				this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.purchases }).then((data) => {
					if (data.length === 0) {
						this.createNewInvoice(true)
					} else {
						this.$router.push({
							params: {
								id: data[data.length - 1].id
							}
						})
					}
				}).then(() => {
					this.confirmDiscardDialog = false
				}).finally(() => {
					this.deleteLoading = false
				})
			})
		},

		fetchSuggestedPurchasePrice() {
			if (this.dialogQuantity) {
				this.dialogLoading = true
			} else {
				this.loadingRequestPrice = true 
			}
			this.$store.dispatch('invoices/fetchSuggestedPurchasePrice', { 
				itemId: this.tableInputs.itemId ? this.tableInputs.itemId : this.itemFavId ? this.itemFavId : this.getItemByBarcode(this.searchItem).id,
				accountId: null
			}).then((data) => {
				// if (this.dialogQuantity) {
				// 	this.priceEntryDialog = data;
				// } else {
				// 	this.tableInputs.price = data;
				// }
				if (this.dialogQuantity) {
					if (this.submitData.paymentMethodId === this.paymentMethod.internal) {
						this.priceEntryDialog = 0
					} else {
						this.priceEntryDialog = data.price;
					}
				} else {
					if (this.submitData.paymentMethodId === this.paymentMethod.internal) {
						this.tableInputs.price = 0
					} else {
						this.tableInputs.price = data.price;
					}
				}
			}).finally(() => {
				this.loadingRequestPrice = false
				this.dialogLoading = false
			})
		},

		resetQuantityDialogData() {
			this.dialogQuantity = false; 
			this.priceEntryDialog = null; 
			this.$refs.form.reset();
			this.searchItem = null
		},

		resetPrice() {
			if (this.submitData.paymentMethodId === 4) {
				for (let i = 0; i < this.entries.length; i++) {
					this.entries[i].price = 0
					this.entries[i].amount = 0
					this.entries[i].totalNet = 0
					this.entries[i].percentDiscount = 0
					this.entries[i].discount = 0
					if (this.entries[i].price !== 0) {
						this.entries[i].price = 0
						this.entries[i].amount = 0
						this.entries[i].totalNet = 0
						this.entries[i].percentDiscount = 0
						this.entries[i].discount = 0
					}
				}
				this.discountFormatted = ''
				this.submitData.discount = 0
				this.paidFormatted = 0
				this.discountTotal = 0
				this.dialogPaymentMethod = false
				return this.entries
			}
		}
	},
	created() {
		this.submitData.cashbox = this.permissionsForUser.userType === userType.casher ? this.$localStorage.cashboxId : this.configs.mainCashBoxId;
		this.submitData.account = this.configs.defaultImporterId;

		// fetch last invoice / create new one
		this.$store.dispatch('items/fetchAllAsTree', { oneNestedLevel: true });
		this.loading = true;
		if (!this.$route.params.id) {
			this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.purchases })
				.then((invoices) => {
					if (invoices.length === 0) {
						this.createNewInvoice(true);
					} else {
						const latestInvoice = invoices[invoices.length - 1];

						if (latestInvoice.isPosted) {
							this.createNewInvoice(false);
						} else {
							this.$router.push({ name: 'pruchase-invoice', params: { id: latestInvoice.id } });
							this.submitData.date = latestInvoice.date;
							this.invoiceNo = latestInvoice.no;
							this.loading = false;
						}
					}
				})
				.catch(() => {
					this.loading = false;
				}).finally(() => {
					// this.submitData.account = this.configs.defaultImporterId
				})
		} else {
			this.$store.dispatch('invoices/fetchAll', { type: invoiceTypes.purchases });
			this.fetchInvoice();
		}
	},

	mounted() {
		this.tableInputs.quantity = 1;
	},

	metaInfo() {
		return {
			title: this.$t('routes.pruchase-invoice'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>

<style lang="scss" scoped>
.round {
	border-radius: 18px;
}
@media screen and (min-width: 960px) and (max-width: 1025px) {
	.table-col {
		min-height: 65vh;
	}
	.side-col {
		height: 88vh;
		overflow: auto;
	}
}
.w-100 {
	width: 100%
}
</style>

<style lang="scss">
#invoice { 
	.v-input {
		margin-top: 1px;
	}
	.text-undefined {
		padding: 0 8px;
	}
	#all-items-panels {
		.v-expansion-panel--active {
			> .v-expansion-panel-header  {
				min-height: 33px;
			}
		}
		.v-expansion-panel-header {
			min-height: 40px;
			padding: 0 16px;
		}
		.v-expansion-panel-content__wrap {
			padding: 0 12px;
		}
	}
	.calculated-number {
		line-height: 1.4rem;
	}
}
</style>