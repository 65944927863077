export default {
	'add-journal-cashbox': 'إضافة يومية الصندوق',
	'adding-journal-cashbox': 'أضف يومية الصندوق',
	'delete-journal': 'حذف اليومية',
	restriction: 'القيد',
	'total-received': 'مجموع المدين ',
	'paid-total': 'مجموع الدائن ',
	'new-journal': 'يومية جديدة',
	'the-difference': 'الفرق',
	'the-journal-has-been-migrated-successfully': 'تم ترحيل اليومية بنجاح',
	'journal-saved-successfully': 'تم حفظ اليومية بنجاح',
	journal: 'البومية',
	'alert-message-for-date-matching': 'تاريخ اليومية غير مطابقة لتاريخ اليوم'
}