<template>
    <div>
		<!-- tabs -->
		<v-tabs
			v-model="tab"
			grow
			height="36"
			background-color="bgColor"
			active-class="primary white--text"
			slider-color="success"
			class="rounded-lg mb-2"
			style="overflow: hidden"
			show-arrows
		>
			<v-tab
				v-for="(tab) in tabs"
				:key="tab.value"
				:disabled="loading"
				@click="
					journalType !== tab.value
						? (() => { 
							journalType = tab.value; 
							$router.push({
								name: 'all-journals',
								query: {
									type: journalType
								}
							}); 
							filter(journalType) 
						})()
						: null"
			>
				{{tab.name}}
			</v-tab> 
		</v-tabs>

		<v-form @submit.prevent="filter($route.query.type)">
			<v-row class="mx-2">
				<!-- number journal -->
				<v-col cols="6" sm="2" md="2" lg="1" class="">
					<v-text-field
						v-model="noJournal"
						:label="$t('forms.no')"
						dense
						outlined
						hide-details="auto"
						:loading="loading"
						:disabled="loading"
					/>
				</v-col>

				<!-- status -->
				<v-col cols="6" sm="3" md="2" lg="2">
					<v-autocomplete
						v-model="status"
						:items="TheStatus"
						item-text="name"
						item-value="id"
						:label="$t('forms.status')"
						dense
						outlined
						hide-details="auto"
						:loading="loading"
						:disabled="loading"
					/>
				</v-col>

				<!-- from data -->
				<v-col cols="6" sm="4" md="3" lg="2">
					<bee-date-input
						v-model="fromDate"
						:label="$t('forms.from-date')"
						input-format-order="DD-MM-YYYY"
						prepend-icon="mdi-calendar"
						dense
						hide-details="auto"
						:loading="loading"
						:disabled="loading"
					></bee-date-input>
				</v-col>

				<!-- to date -->
				<v-col cols="6" sm="4" md="3" lg="2">
					<bee-date-input
						v-model="toDate"
						:label="$t('forms.to-date')"
						input-format-order="DD-MM-YYYY"
						prepend-icon="mdi-calendar"
						dense
						hide-details="auto"
						:loading="loading"
						:disabled="loading"
					></bee-date-input>
				</v-col>

				<!-- search btn -->
				<v-col cols="auto" class="px-0">
					<v-btn 
						type="submit"
						class="mx-2"
						fab
						x-small
						color="of-white"
						:loading="loading"
						:disabled="loading"
					>
						<v-icon color="blue-dark">mdi-magnify</v-icon>
					</v-btn>
				</v-col>

				<!-- close btn -->
				<v-col cols="auto" class="px-0">
					<v-btn 
						@click="resetFilter(); filter()"
						class="mx-2"
						fab
						x-small
						color="of-white"
						:disabled="loading"					
					>
						<v-icon color="alert-color">mdi-close</v-icon>
					</v-btn>
				</v-col>	
				
				<!-- close btn -->
				<v-col cols="auto" class="px-0">
					<v-btn 
						v-if="0"
						class="mx-2"
						fab
						x-small
						color="of-white"
						:disabled="loading"					
					>
						<v-icon color="alert-color">mdi-close</v-icon>
					</v-btn>
				</v-col>
			</v-row>
		</v-form>

		<!-- table -->
		<bee-handy-table
			ref="table"
			:items="journals"
			:headers="headers"
			hide-default-footer
			class="mx-3 my-3"
			dense
			:loading="loading"
			zebra
			:items-per-page="20"
			pagination-on-scroll
			:height="$vuetify.breakpoint.height - 200"
			:data-table-props="{
				disableSort: false,
			}"
			:shown-rows-text="$t('shown-rows')"
			fixed-header
		>
			<template v-slot:item.status="{ value }">
				<v-chip :color="value === true ? 'success': 'alert-color white--text' " small>
					{{ value === true ? TheStatus[0].name : TheStatus[1].name }}
				</v-chip>
			</template>

			<template v-slot:item.actions="{ item }">
				<tooltip right :text="$t('show')">
					<v-btn 
						:to="{
							name: 'journal-item',
							params: {
								id: item.id
							}
						}"
						text
						small
					>
						<v-icon color="success"> mdi-eye </v-icon>
					</v-btn>
				</tooltip>
			</template>
		</bee-handy-table>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { contextItems, dateSortMode } from '@/helpers/enums' 
export default {
	name: 'AllJournals',

	data() {
		return {
			// table
			entries: [],
			loading: false,
			// v-model filter
			noJournal: null,
			fromDate: null,
			toDate: null,
			status: null,
			// pagination
			scrollOptions: {},
			serverItemsLength: 0,
			needToResetItems: false,
			tab: 0,
			journalType: null,
			dateSortMode
		}
	},
	computed: {
		...mapState({
			journals: state => state.itemJournals.journals.reverse(),
			invoices: state => state.invoices.invoices
		}),

		...mapGetters({
			getInvoiceById: 'invoices/getInvoiceById'
		}),

		headers() {
			const headers = [
				{	
					text: this.$t('headers.journal-no'),
					name: 'no',
					value: 'no'
				},
				{	
					text: this.$t('headers.date'),
					name: 'date',
					value: 'date',
					setValueExpr: val => this.moment(val).format('YYYY-MM-DD')
				},
				{
					text: this.$t('headers.status'),
					name: 'status',
					value: 'isPosted'
				},
				{
					text: '',
					name: 'actions',
					value: 'actions'
				}
				// {
				// 	text: this.$t('headers.cashbox'),
				// 	name: 'status',
				// 	value: 'status'
				// }
			]
			if (this.journalType === contextItems.invoice) {
				headers.push(
					{
						text: this.$t('headers.invoice-no'),
						name: 'no-invoice',
						value: 'invoiceId',
						setValueExpr: val => this.getInvoiceById(val)?.no
					}
				)
			}
			return headers
		},
		TheStatus () {
			const status = [
				{
					id: 1,
					name: this.$t('status.ending')
				},
				{
					id: 2,
					name: this.$t('status.not-complete')
				},
			]
			return status
		},

		tabs() {
			return [
				{ value: contextItems.all, name: this.$t('AllJournals.tabs.all') },
				{ value: contextItems.journal, name: this.$t('AllJournals.tabs.journal') },
				{ value: contextItems.invoice, name: this.$t('AllJournals.tabs.invoice') },
				{ value: contextItems.reconciliation, name: this.$t('AllJournals.tabs.reconciliation') }				
			]
		},
	},	

	methods: {
		moment,
		filter(journalType) {
			this.loading = true
			this.$store.dispatch('itemJournals/fetchAll', { 
				JournalNo: this.noJournal,
				Status: this.status,
				fromDate: this.fromDate,
				toDate: this.toDate,
				status: this.status,
				// JournalId:,
				dateSortMode: dateSortMode.descending,
				context: journalType,
			}).finally(() => {
				this.loading = false
			})
		},

		resetFilter() {
			this.noJournal = null;
			this.status = null;
			this.fromDate = null;
			this.toDate = null
		},
		// fetchData(journalType) {
		// 	this.loading = true
		// 	this.$store.dispatch('itemJournals/fetchAll', {
		// 		fromDate: this.fromDate,
		// 		toDate: this.toDate,
		// 		JournalNo: this.noJournal,
		// 		status: this.status,
		// 		// JournalId:,
		// 		dateSortMode: dateSortMode.descending,
		// 		context: journalType,
		// 	}).finally(() => {
		// 		this.loading = false
		// 	})
		// }
	},

	mounted() {
		this.loading = true
		Promise.all([
			!this.invoices.length ? this.$store.dispatch('invoices/fetchAll', {}) : null,
			// this.fetchJournals(),
			this.$store.dispatch('itemJournals/fetchAll', {})
		]).finally(() => {
			this.loading = false
		})
	},
	metaInfo() {
		return {
			title: this.$t('routes.all-journals'),
			titleTemplate: '%s | POS'	
		}
	}
}
</script>
