<template>
    <div>

		<v-row>
			<v-col cols="5" lg="2" md="3" >
				<bee-date-input
					v-model="fromDate"
					:label="$t('forms.from-date')"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					dense
					hide-details
					:disabled="loading"
				></bee-date-input>
			</v-col>

			<v-col cols="5" lg="2" md="3" >
				<bee-date-input
					v-model="toDate"
					:label="$t('forms.to-date')"
					input-format-order="DD-MM-YYYY"
					prepend-icon="mdi-calendar"
					dense
					hide-details
					:disabled="loading"
				></bee-date-input>
			</v-col>

			<v-col cols="2">
				<v-autocomplete  :items="[]" :label="$t('forms.item-classification')" outlined dense/>
			</v-col>

			<v-col cols="2">
				<v-checkbox :label="$t('SalesReport.hide-zero-lines')" outlined dense/>
			</v-col>

			<v-spacer />
			<!-- search btn -->
			<v-col cols="auto" class="px-0">
				<v-btn 
					class="mx-2"
					fab
					x-small
					color="of-white"
				>
					<v-icon color="blue-dark">mdi-magnify</v-icon>
				</v-btn>
			</v-col>

			<!-- close btn -->
			<v-col cols="auto" class="ps-0">
				<v-btn
					class="ms-2 me-0"
					fab
					x-small
					color="of-white"
				>
					<v-icon color="alert-color">mdi-close</v-icon>
				</v-btn>
			</v-col>

			<v-divider vertical class="my-4" /> 
			<v-col cols="auto">
				<v-btn color="blue-dark white--text">
					<v-icon> mdi-printer-outline </v-icon>
					{{$t('check-out')}}
				</v-btn>
			</v-col>
		</v-row>
		<bee-handy-table
			:headers="headers"
			:items="[]"
			zebra
			:loading="loading"
			pagination-on-scroll
			:data-table-props="{
				disableSort: true
			}"
			:shown-rows-text="$t('shown-rows')"
			dense
			:height="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.height - 190 : $vuetify.breakpoint.height - 200"
		></bee-handy-table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			loading: false,
			fromDate: null,
			toDate: null
		}
	},

	computed: {
		...mapGetters({
			getItemById: 'items/getItemById'
		}),

		headers() {
			return [
				{
					text: this.$t('headers.item-name'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.getItemById(val).name,
					noInput: true,
				},
				{
					text: this.$t('headers.the-quantity-sold'),
					name: 'quantity',
					value: 'quantity',
					noInput: true,
				},
				{
					text: this.$t('headers.middle-price'),
					name: 'price',
					value: 'price',
					noInput: true,
				},
				{
					text: this.$t('headers.total-sales'),
					name: 'total',
					value: 'total',
					noInput: true
				}
			]
		}
	}
}
</script>
