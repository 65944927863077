<template>
	<div class="bgColor rounded-t-xl" style="height: 87vh">
		<!-- Archive Dialog -->
		<v-dialog v-model="archiveDialog" width="450px">
			<v-card>
				<v-card-title class="py-1">
					<v-spacer/>
					<span 
						class="ms-8" 
						:class="{ 'primary--text': isArchived,
							'alert-color--text': !isArchived
						}"
					>
						{{ isArchived ?  $t('Stores.unarchive-a-store') : $t('Stores.archive-store')}}
					</span>
					<v-spacer/>
					<v-btn @click="archiveDialog = false" icon dark>
						<v-icon :color="isArchived? 'primary' : 'alert-color'">mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-divider class="mx-2" />
				<v-card-text class="py-1" >
					{{ 
						isArchived ? $t('Stores.are-you-sure-you-want-to-unarchive-this-store') : $t('Stores.are-you-sure-you-want-to-archive-this-store')
					}}
				</v-card-text>

				<v-card-actions>
					<v-spacer />
					<v-btn text :color="isArchived? 'primary' : 'alert-color'" @click="toggleState()" :loading="loading">
						{{$t('ok')}}
					</v-btn>
					<v-btn text @click="archiveDialog = false" :disabled="loading">
						{{$t('cancel')}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- add button and filter section -->
		<div class="d-flex mx-3">
			<div class="d-flex align-center">
				<v-text-field
					v-model="country"
					:label=" $t('forms.country')"
					outlined
					filled
					dense
					hide-details
					style="width: 250px"
				/>
			</div>

			<div cols="auto" class="px-0 d-flex align-center mx-3">
				<v-btn 
					class="mx-2"
					fab
					x-small
					color="of-white"
					:loading="loading"
					:disabled="loading"
					@click="fetchAll()"
				>
					<v-icon color="blue-dark">mdi-magnify</v-icon>
				</v-btn>

				<v-btn 
					class="mx-2"
					:class="hideClose ? 'd-none' : 'd-block' "
					fab
					x-small
					color="of-white"
					:disabled="loading"
					@click="reset()"
				>
					<v-icon color="alert-color">mdi-close</v-icon>
				</v-btn>
			</div>

			<v-spacer/>

			<v-divider vertical class="mx-2 my-1"/>

			<v-btn
				height="35"
				color="primary"
				:to=" { 
					name: 'store-info',
					query: {
						addSub: 1
					}
				} "
				class="px-4 my-2"
			>
				<v-icon size="23" class="me-1">mdi-store-plus</v-icon>
				{{$t('add')}}
			</v-btn>
		</div>
		<!-- table -->
		<bee-handy-table
			:headers="headers"
			:items="stores"
			:items-per-page="10"
			zebra
			fixed-header
			pagination-on-scroll
			:row-highlight-expretion="(val) => stores.find(c => c.name === 'Oulabi')"
			:height="$vuetify.breakpoint.lgAndUp ? $vuetify.breakpoint.height - 180 : $vuetify.breakpoint.height - 180 "
			class="mx-3"
			:shown-rows-text="$t('shown-rows')"
			:loading="loading"
		>
			<!-- status -->
			<template v-slot:item.status="{ value }">
				<v-chip :color="value ? 'success' : 'alert-color white--text'" small>
					{{value === statusStore.activated ? $t('Stores.worked') : $t('Stores.stopped')}}
				</v-chip>
			</template>

			<!-- logo -->
			<template v-slot:item.logo="{ value }">
				<div style="width: 100%" class=" " v-if="value">
					<v-img  
						:src="value" 
						max-width="100px"
						max-height="50px"
						contain
						class="my-2"
						:class="{
							'ms-14': $vuetify.breakpoint.lgAndUp
						}"
					/>
					<!-- <v-spacer />
					<v-spacer /> -->
				</div>
				<v-icon v-else> mdi-store </v-icon>
			</template>

			<!-- actions -->
			<template v-slot:item.actions="{ item }">
				<div class="d-flex">
					<!-- priview -->
					<tooltip top :text="$t('details')" >
						<v-btn
							small
							text
							color="edit-color"
							@click="
								storeId = item.id; 
								$router.push({
									name: 'store-info',
									params: { id: item.id },
									query: {
										preview: 1
									}
								})
							"
						>
							<v-icon>mdi-eye</v-icon>
						</v-btn>
					</tooltip>

					<!-- archive -->
					<tooltip
						top
						:text="item.isArchived ? $t('Stores.unarchive-a-store') : $t('Stores.archive-store')"
					>
						<v-btn
							small
							text
							:color="!item.isArchived ? 'alert-color' : 'grey' "
							@click="isArchived = item.isArchived; storeId = item.id; archiveDialog = true"
						>
							<v-icon> {{ item.isArchived ? 'mdi-archive-off' : 'mdi-package-down ' }} </v-icon>
						</v-btn>
					</tooltip>
				</div>
			</template>

		</bee-handy-table>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { statusStore } from '@/helpers/enums'
export default {
	name: 'Stores',

	data() {
		return {
			store: null,
			// helpers
			loading: false,
			submitLoading: false,
			
			storeId: null,

			// data
			dialogData: {
				no: null,
				name: null,
				country: null,
				status: true,
				notes: null,
			},
			// enum
			statusStore,

			// archive Dialog
			archiveDialog: false,
			isArchived: false,
			country: null,
			hideClose: true
		}
	},

	watch: {
	},

	computed: {
		...mapState({
			stores: state => state.stores.stores,
		}),
		...mapGetters({
			getStoreById: 'stores/getStoreById'
		}),

		headers() {
			return [
				{
					text: this.$t('headers.serial'),
					name: 'storeNo',
					value: 'storeNo',
					setValueExpr: val => this.stores.map(c => c.storeNo).indexOf(val) + 1,
					class: 'edit--text',
				},
				{
					text: this.$t('headers.no'),
					name: 'no',
					value: 'storeNo'
				},
				{
					text: this.$t('headers.name'),
					name: 'name',
					value: 'name',
				},
				{
					text: this.$t('headers.country'),
					name: 'country',
					value: 'country'
				},
				// {
				// 	text: this.$t('headers.executive-director'),
				// 	name: 'executiveDirector',
				// 	value: 'executiveDirector'
				// },
				{
					text: this.$t('headers.status'),
					name: 'status',
					value: 'status'
				},
				{	
					text: this.$t('headers.logo'),
					name: 'logo',
					value: 'logo',
					align: 'center'
				},
				{
					text: '',
					name: 'actions',
					value: 'actions',
					align: 'center',
					width: 120,
					sortable: false
				},
			]
		},
		powersArray() {
			const powersArray = [
				this.$t('Users.casher'),
				this.$t('Users.executive-director')
			]
			return powersArray
		},
	},
	methods: {
		fetchAll() {
			this.loading = true;
			this.submitLoading = true;
			this.$store.dispatch('stores/fetchAll', {
				country: this.country !== null ? this.country : null
			}).then(() => {
				this.loading = false;
				this.submitLoading = false;
				this.country !== null ? this.hideClose = false : this.hideClose = true
			})
		},

		reset() {
			this.country = null
			this.fetchAll()
		},

		toggleState() {
			this.loading = true;
			if (!this.isArchived) {
				this.$store.dispatch('stores/archive', {
					id: this.storeId
				}).then(() => {
					this.$store.dispatch('stores/fetchAll', {}).then(() => {
						this.$eventBus.$emit('show-snackbar', false, this.$i18n.t('Stores.the-store-has-been-successfully-archived'));
						this.archiveDialog = false
					})
				}).finally(() => {
					this.loading = false;
				})
			} else {
				this.$store.dispatch('stores/retrieve', {
					id: this.storeId
				}).then(() => {
					this.$store.dispatch('stores/fetchAll', {}).then(() => {
						this.$eventBus.$emit('show-snackbar', false, this.$i18n.t('Stores.the-store-has-been-successfully-unarchived'));
						this.archiveDialog = false
					})
				}).finally(() => {
					this.loading = false;
				})
			}
		}
	},
	created() {
		this.loading = true;
		this.fetchAll()
	},
	metaInfo() {
		return {
			title: this.$t('routes.stores'),
			titleTemplate: '%s | POS'
		}
	}
}
</script>