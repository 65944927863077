import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		cashboxJournal: [],
		allRestrictions: []
	},

	actions: {
		create({ commit }, { cashJournalId, accountId, debitAmount, creditAmount, note, date, refDoc }) {
			return axios.post(`/FundsFlow/CashJournals/${cashJournalId}/Entries`, {
				cashJournalId, accountId, debitAmount, creditAmount, note, date, refDoc
			}).then(({ data }) => data)
		},

		fetchById({ commit }, { cashJournalId, id }) {
			return axios.get(`/FundsFlow/CashJournals/${cashJournalId}/Entries/${id}`).then(({ data }) => 
			{
				commit(types.STORE_JOURNAL, data);
				return data;
			})
		},

		update({ commit }, { cashJournalId, id, accountId, debitAmount, creditAmount, note, date, refDoc }) {
			return axios.put(`/FundsFlow/CashJournals/${cashJournalId}/Entries/${id}`, {
				id, accountId, debitAmount, creditAmount, note, date, refDoc
			})
		},

		delete({ commit }, { cashJournalId, id }) {
			return axios.delete(`/FundsFlow/CashJournals/${cashJournalId}/Entries/${id}`)
		},

		deleteJournal({ commit }, { id }) {
			return axios.delete(`/FundsFlow/CashJournals/${id}`)
		},
		
		closeDay({ commit }, { id, cashboxId, accountId, amount, remainingAmount }) {
			return axios.put(`/FundsFlow/CashJournals/${id}/Close`, { cashboxId, accountId, amount, remainingAmount })
		},

		post({ commit }, { id }) {
			return axios.put(`/FundsFlow/CashJournals/${id}/Post`)
		},

		unPosted({ commit }, { id }) {
			return axios.put(`/FundsFlow/CashJournals/${id}/UnPost`)
		},

		reset({ commit }) {
			return commit(types.RESET)
		}
	},

	mutations: {
		[types.STORE_CASHBOX_JOURNAL](state, cashboxJournal) {
			state.cashboxJournal = cashboxJournal;
		},
		[types.STORE_ALL_RESTRICTIONS](state, allRestrictions) {
			state.allRestrictions = allRestrictions;
		},
		
		[types.RESET](state) {
			state.cashboxJournal = [];
			state.allRestrictions = []
		}
	},

	getters: {

	}
}