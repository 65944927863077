<template>
	<div class="d-flex align-center">
		<v-icon
			@click="
				$vuetify.rtl
					? localValue = Number(localValue) - 1
					: localValue = Number(localValue) + 1;
			"
			:disabled="disabled || $vuetify.rtl && Number(localValue) === 1 ? true : false"
			size="20"
			class="mt-2"
			tabindex="-1"
		>
			{{$vuetify.rtl ? 'mdi-minus' : 'mdi-plus' }}
		</v-icon>

		<v-text-field
			v-model.number="localValue"
			ref="input"
			type="number"
			min="1"
			hide-spin-buttons
			:disabled="disabled"
			:dense="dense"
			:hide-details="hideDetails"
			:rules="rules"
			@keypress="(e) => $emit('keypress', e)"
			@keydown="(e) => $emit('keydown', e)"
			@change="(e) => $emit('change', e)"
		></v-text-field>

		<v-icon
			@click="
				!$vuetify.rtl
					? localValue = Number(localValue) - 1
					: localValue = Number(localValue) + 1;
			"
			:disabled="disabled || !$vuetify.rtl && Number(localValue) === 1 ? true : false"
			size="20"
			class="mt-2"
			tabindex="-1"
		>
			{{!$vuetify.rtl ? 'mdi-minus' : 'mdi-plus' }}
		</v-icon>
	</div>
</template>

<script>
export default {
	name: 'QuantityInput',
	props: {
		value: { type: [Number, String], default: 1 },
		dense: { type: Boolean, default: false },
		disabled: { type: Boolean, default: false },
		'hide-details': { type: Boolean, default: false },
		rules: { type: Array }
	},
	computed: {
		localValue: {
			get() {
				return this.value
			},
			set(newVal) {
				this.$emit('input', newVal)
			}
		},
		hasFocused: {
			get () {
				return this.$refs.input.hasFocused
			},
			set(newVal) {
				this.$refs.input.hasFocused = newVal;
			}
		},
		valid() {
			return this.$refs.input.valid;
		}
	},

	methods: {
		validate() {
			return this.$refs.input.validate();
		},
		resetValidation() {
			this.$refs.input.resetValidation();
		},
		focus() {
			this.$refs.input.focus();
		}
	}
}
</script>

<style>

</style>