<template>
   <div class="d-flex flex-column align-center justify-center">
		<v-img 
			src="@/assets/logo.png"
			max-width="100px"
			max-height="150px"
			contain
			class="my-4"
		/>
		<p class="text-h5 primary--text mb-0">Emerald POS</p>
		<div class="container px-7" style="width: 100%">
			<v-chip-group
				v-model="filters"
				mandatory
				active-class="primary"
				class="w-100"
			>
				<v-chip
					active-class="primary"
					:value="statusStoreWithName.activated.id"
					@click="filter(0)"
				>
					{{ statusStoreWithName.activated.name }}
					
				</v-chip>
				<v-chip
					active-class="primary"
					:value="statusStoreWithName.suspended.id"	
					@click="filter(1)"
				>
					{{ statusStoreWithName.suspended.name }}
				</v-chip>
			</v-chip-group>
		</div>
		<div v-if="loading">
			<p> {{$t('ChooseStore.stores-are-being-brought-in')}} </p>
		</div>
		<div v-else class="d-flex justify-center flex-wrap">
			<v-card
				v-for="(store, index) in stores.filter(c => c.isArchived === Boolean(filters))"
				:key="index"
				class="card mx-2 my-2 rounded-lg"
				style="width: 250px; height: 20%"
				outlined
				flat
				@click="updateState(store.id)"
			>
				<v-card-text class="justify-center">
					<div class=" d-flex justify-center">
						<v-icon size="40" class="icon"> mdi-store </v-icon>
					</div>
					<p class="text-center"> {{store.name}}</p>
				</v-card-text>
			</v-card>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { statusStoreWithName } from '../helpers/enums'
export default {
	name: 'ChooseStore',

	data() {
		return {
			isActive: false,
			filterStore: false,
			statusStoreWithName,
			filters: 0,
			storeId: null,
			show: false,
			loading: false,
		}
	},
	watch: {
	},
	computed: {
		...mapState({
			stores: state => state.stores.stores,
			permissionsForUser: state => state.auth.permissionsForUser
		}),
		...mapGetters({
			getStoreById: 'users/getStoreById'
		}),
	}, 
	methods: {
		filter(status) {
			const stores = this.stores.filter(c => c.isArchived === Boolean(status))
			return stores
		},
		updateState(storeId) {
			this.$localStorage.storeId = storeId;
			window.axios.defaults.headers.common['x-store'] = storeId;
			this.$router.push({ name: 'home' });
			this.$emit('update-main-data');
		}
	},

	created() {
		if (!this.stores.length) {
			this.loading = true
			this.$store.dispatch('stores/fetchAll', {}).then((data) => {
				if (data.length === 0) {
					this.show = true
					this.$eventBus.$emit('show-snackbar', true, this.$i18n.t('there-are-no-stores-you-can-access'));
					localStorage.removeItem('auth')
					localStorage.removeItem('userType')
					localStorage.removeItem('isAdmin')
					// this.$store.dispatch('users/logout')
					this.$router.replace({
						name: 'login'
					})
				}
			}).finally(() => {
				this.loading = false
			})
		}
	},

	metaInfo() {
		return {
			title: this.$t('routes.choose-store'),
			titleTemplate: '%s | POS'	
		}
	}

}
</script>
<style lang="scss" scoped>
.icon {
	color: #001d63;
}
.card {
	cursor: pointer;
	transition: 0.3s all ease;
	&:hover {
		background-color: #02855b;
	}
	&:hover .icon {
		color: white
	}
	&:hover p {
		color: white
	}
}
</style>