import * as types from '../mutation-types'

export default {
	namespaced: true,

	state: {
		cashboxes: [],
		cashboxSystem: []
	},

	actions: {
		fetchAll({ commit }) {
			return axios.get('/cashboxes').then(({ data }) => {
				commit(types.STORE_CASHBOXES, data);
				return data;
			})
		},
		create({ commit }, { name, no, accountId, notes }) {
			return axios.post('/cashboxes', { name, no, accountId, notes }).then(({ data }) => {
				return data;
			})
		},
		update({ commit }, { id, name, no, accountId, notes }) {
			return axios.put(`/cashboxes/${id}`, {
				id, name, no, accountId, notes
			}).then(({ data }) => {
				return data;
			})
		},
		delete({ commit }, { id }) {
			return axios.delete(`/cashboxes/${id}`)
		},

		fetchCashboxSystem({ commit }) {
			return axios.get('/System/Cashboxes').then(({ data }) => {
				commit(types.CASHBOX_SYSTEM, data);
				return data
			})
		},

		cashboxesPostBalance({ commit }, { entries }) {
			return axios.put('/cashboxes/PostBalance', entries)
		},

		reset({ commit }) {
			return commit(types.RESET)
		}
	},

	mutations: {
		[types.STORE_CASHBOXES](state, cashboxes) {
			state.cashboxes = cashboxes;
		},

		[types.CASHBOX_SYSTEM](state, cashboxSystem) {
			state.cashboxSystem = cashboxSystem;
		},

		[types.RESET](state) {
			state.cashboxes = [];
			state.cashboxSystem = []
		}
	},

	getters: {
		getCashboxById: state => id => state.cashboxes.find(c => c.id === id),
		getCashboxSystemById: state => id => state.cashboxSystem.find(c => c.id === id),

	}
}