import i18n from '@/i18n/i18n';

export function printHtml(htmlData, usePopup = false) {
	const htmlContent = htmlData;
	const printWindow = window.open('', '_blank', usePopup ? { popup: true } : null);
	if (!printWindow) {
		alert(i18n.t('messages.no-permission-to-print'));
	} else {
		printWindow.document.write(htmlContent);
		printWindow.document.close();
		printWindow.onafterprint = () => printWindow.setTimeout(printWindow.close);
		printWindow.print();
	}
}

export function downloadBlob(blob, fileName) {
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName || true;
	a.click();
	a.remove();
}

export function fixNumber(number, digits = 4) {
	return Number((number).toFixed(digits));
}