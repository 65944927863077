export default {
	tabs: {
		all: 'الكل',
		sales: 'المبيعات',
		purchases: 'المشتريات',
		'sales-refund': 'مرتجع المبيعات'
	},
	sale: 'بيع',
	purchases: 'شراء',
	'sales-returns': 'مرتجع فاتورة بيع',
	ascending: 'من الأقدم',
	descending: 'من الأحدث',
	'view-the-associated-invoice': 'عرض الفاتورة المرتبطة',
	'view-associated-journal': 'عرض اليومية المرتبطة',
	print: 'طباعة',
	'sort-by-status': 'ترتيب بحسب الحالة',
	'posted-first': ' المنتهية أولا',
	'not-posted-first': 'المسودات أولا',
	// no: 'يدون تحديد'
}