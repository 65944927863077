export default {
	'tabs-title': {
		'detailed-sales-statement-for-an-item': 'Item sales',
		'total-sales-to-a-customer': 'Total sales to a customer',
		'total-sales': 'Total sales',
		'detailed-account-statement': 'Detailed Account statement',
		'vat-statement': 'Vat statement'
	},
	item: 'Item',
	account: 'Account',
	'download-in-excel-format': 'Download in excel format'
}