<template>
	<div id="invoice-tabs">
		<!-- chips -->
		<v-chip-group
			v-if="draftInvoices.length"
			v-model="activeInvoice"
			center-active
			show-arrows
			active-class="primary elevation-0 white--text"
			:style="{ maxWidth: maxWidth }"
		>
			<v-chip
				v-for="(item, index) in draftInvoices"
				:key="index"
				class="rounded-t-0 rounded-b-lg elevation-1 ma-0 mb-1 me-1 px-1"
				:class="{ 'grey lighten-3': item.id !== activeInvoice }"
				:style="{ minWidth: '20px', overflow: 'hidden' }"
				:value="item.id"
				@click="
					item.id !== $route.params.id
						? $router.push({ name: $route.name, params: { id: item.id } })
						: null
				"
			>
				{{item.name}}
			</v-chip>
		</v-chip-group>

		<!-- add button -->
		<tooltip bottom :text="$t('add-invoice')">
			<v-btn
				min-width="0"
				elevation="1"
				width="38"
				height="32"
				:loading="loading"
				:disabled="loading || disable"
				class="rounded-t-0 rounded-b-lg align-self-start me-1"
				@click="
					loading = true;
					eventHub.$emit('create-invoice', data => {
						loading = false;
					}
				)"
			>
				<v-icon size="22">mdi-plus</v-icon>
			</v-btn>
		</tooltip>
	</div>
</template>

<script>
import Tooltip from './Tooltip.vue';
export default {
	name: 'InvoicesTabs',

	props: {
		eventHub: { type: Object },
		disable: { type: Boolean }
	},

	components: {
		Tooltip
	},

	data() {
		return {
			loading: false,
			activeInvoice: null,
			draftInvoices: []
		}
	},

	watch: {
		'$route.params.id'(val) {
			if (this.$route.name === 'sale-invoice' && val) {
				this.activeInvoice = val;
			}
		}
	},

	computed: {
		maxWidth() {
			return `${this.$vuetify.breakpoint.width - (this.$vuetify.breakpoint.lgAndUp ? 600 : 450)}px`
		}
	},

	created() {
		this.eventHub.$on('update-invoices', invoices => {
			this.draftInvoices = invoices
		})
		if (this.$route.name === 'sale-invoice' && this.$route.params.id) {
			this.activeInvoice = this.$route.params.id;
		}
	},

	beforeDestroy() {
		this.eventHub.$off('update-invoices');
	}
}
</script>

<style lang="scss">
#invoice-tabs {
	display: flex;
	margin-top: -8px;

	.v-slide-group__content {
		padding: 0;
	}
}
</style>