<template>
	<span
		:style="color ? { color: color } : {}"
		:class="[content ? { 'font-weight-bold': bold } : 'title']"
		>{{ localValue }}</span
	>
</template>
<script>
const match = /\D{1,3}(,\D{3})*(\.\D+)?$/g; // Discard commas and decimal point
const ls = false;
export default {
	props: ['content', 'color', 'bold', 'emptyState', 'showOnlyNumbersPatch'],
	name: 'BeeLabel',
	computed: {
		localValue: {
			get: function () {
				return this.content
					? !ls.getVisibleSymbolMoney() && this.showOnlyNumbersPatch
						? this.content.toString().replace(match, '')
						: this.content
					: this.emptyState;
			},
		},
	},
};
</script>