import Vue from 'vue'

import BeeTreeview from '@/components/BeeTreeview.vue'
import BeeLabel from '@/components/legacy/BeeLabel.vue'
import BeeMaterialAutocomplete from '@/components/legacy/BeeMaterialAutocomplete.vue'
import BeeMaterialAmountLabel from '@/components/legacy/BeeMaterialAmountLabel.vue'
import BeeAccountAutocomplete from '@/components/legacy/BeeAccountAutocomplete.vue'
import BeeAdvancedMoneyLabel from '@/components/legacy/BeeAdvancedMoneyLabel.vue'
import BeeDeleteDialog from '@/components/BeeDeleteDialog.vue'
import Tooltip from '@/components/Tooltip.vue'
export const $eventBus = new Vue();
export const $localStorage = {
	// cashboxId
	get cashboxId() { return this.getFromStorage('cashbox-id') },
	set cashboxId(newVal) {
		if (newVal) this.setInStorage('cashbox-id', newVal)
		else this.removeFromStorage('cashbox-id')
	},

	// storeId
	get storeId() { return this.getFromStorage('store-id') }, 
	set storeId(newVal) {
		if (newVal) this.setInStorage('store-id', newVal)
		else this.removeFromStorage('store-id')
	},

	// currencyId
	get currencyId() { return this.getFromStorage('currency-id') },
	set currencyId(newVal) {
		if (newVal) this.setInStorage('currency-id', newVal)
		else this.removeFromStorage('currency-id')
	},

	// methods
	getFromStorage(key) {
		return JSON.parse(localStorage.getItem(key));
	},
	setInStorage(key, value) { localStorage.setItem(key, JSON.stringify(value)) },
	removeFromStorage(key) {
		return localStorage.removeItem(key);
	},
}
export const pos = {
	install: (Vue, options) => {
		// global variable
		Vue.prototype.$eventBus = $eventBus;
		Vue.prototype.$localStorage = Vue.observable($localStorage);

		Vue.mixin({
			computed: {
				$inStoreContext() {
					return Boolean(this.$localStorage.storeId);
				}
			}	
		})

		Vue.component('BeeTreeview', BeeTreeview);
		Vue.component('BeeDeleteDialog', BeeDeleteDialog);
		Vue.component('Tooltip', Tooltip);
		
		// legacy
		Vue.component('BeeLabel', BeeLabel);
		Vue.component('BeeMaterialAutocomplete', BeeMaterialAutocomplete);
		Vue.component('BeeMaterialAmountLabel', BeeMaterialAmountLabel);
		Vue.component('BeeAccountAutocomplete', BeeAccountAutocomplete);
		Vue.component('BeeAdvancedMoneyLabel', BeeAdvancedMoneyLabel);
	}
}

Vue.use(pos);

export default {}