<template>
	<div>
		<v-form ref="form" @submit.prevent="saveAccount">
			<!-- {{items}} -->
			<!-- delete dialog -->
			<bee-delete-dialog
				v-model="deleteDialog"
				moduleType="accounts"
				:ids="$route.params.id"
				@on-delete="$emit('update-tree-accounts'); $router.push({ name: 'accounts-tree' });"
			>
				{{$t('do-you-want-to')}} 
				<span class="text-uppercase error--text">{{$t('delete')}}</span> 
				{{$t('Account.the-account')}}
			</bee-delete-dialog>

			<!-- actions -->
			<div class="mb-2">
				<!-- edit -->
				<v-btn
					v-if="$route.query.preview"
					:to="{ name: 'account', params: { id: $route.params.id } }"
					elevation="1"
					color="edit-color white--text"
					height="35px"
					class="px-4"
					:loading="submitLoading"
					:disabled="submitLoading || loading || permissionsForUser.userType === userType.dataReader"
					key="edit-button"
				>
					<v-icon size="22" class="me-1">mdi-pencil</v-icon>
					{{$t('edit')}}
				</v-btn>

				<!-- delete -->
				<v-btn
					v-if="$route.query.preview"
					elevation="1"
					color="alert-color white--text"
					height="35px"
					class="ms-2 px-4"
					:disabled="submitLoading || permissionsForUser.userType === userType.dataReader"
					@click="deleteDialog = true"
				>
					<v-icon size="22" class="me-1">mdi-delete</v-icon>
					{{$t('delete')}}
				</v-btn>

				<!-- save -->
				<v-btn
					v-if="!$route.query.preview"
					elevation="1"
					color="primary"
					height="35px"
					class="px-4"
					type="submit"
					:loading="submitLoading"
					:disabled="submitLoading"
					key="save-button"
				>
					<v-icon size="22" class="me-1">mdi-content-save</v-icon>
					{{$t('save')}}
				</v-btn>

				<!-- cancel -->
				<v-btn
					v-if="!$route.query.preview"
					:to="{ name: 'accounts-tree' }"
					elevation="1"
					color="alert-color white--text"
					height="35px"
					class="ms-2 px-4"
					:disabled="submitLoading"
				>
					{{$t('cancel')}}
				</v-btn>
			</div>
			
			<!-- item data -->
			<v-card elevation="2">
				<v-card-text class="pt-0">
					<!-- main info -->
					<v-card-title class="pt-2 pb-4 px-0 main--text">
						{{$t('Account.main-info')}}
					</v-card-title>

					<v-row class="flex-wrap align-center">
						<!-- account name -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="name"
								:label="$t('Account.account-name')"
								outlined
								dense
								:loading="loading"
								:rules="rules.required"
								:disabled="disableFieldsToPreview || submitLoading"
							></v-text-field>
						</v-col>
						
						<!-- main account -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-autocomplete
								v-model="mainAccountId"
								:items="accounts.filter(c => c.type === type.main)"
								item-value="id"
								item-text="name"
								:loading="loading"
								clearable
								outlined
								dense
								:label="$t('Account.main-account')"
								:disabled="disableFieldsToPreview || submitLoading"
								@change="
									fetchSuggestedChildNo($event);
									noPrefix = getAccountById($event)?.accountingNo ?? null
								"
							></v-autocomplete>
						</v-col>

						<!-- Account no -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model.number="accountNo"
								type="number"
								min="1"
								:label="$t('Account.account-no-automatic-or-manual')"
								hide-spin-buttons
								outlined
								dense
								:prefix="!$vuetify.rtl ? noPrefix : null"
								:loading="loading"
								:rules="rules.requiredAsNumber"
								dir="ltr"
								:disabled="disableFieldsToPreview || submitLoading"
							>
								<template v-if="$vuetify.rtl" v-slot:append>
									<div class="d-flex align-center mt-1" :class="{ 'primary--text': !disableFieldsToPreview }">
										{{noPrefix}}
									</div>
								</template>
							</v-text-field>
						</v-col>

						<!-- account type -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-autocomplete
								v-model="accountType"
								:items="accountTypes"
								item-value="id"
								item-text="name"
								:label="$t('Account.account-type')"
								outlined
								clearable
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								:rules="rules.requiredAsNumber"
							></v-autocomplete>
						</v-col>

						<!-- final account -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-autocomplete
								v-model="finalAccount"
								:items="finalAccounts"
								item-value="id"
								item-text="name"
								:label="$t('Account.final-account')"
								outlined
								clearable
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
								:rules="rules.requiredAsNumber"
							></v-autocomplete>
						</v-col>

						<!-- is hidden -->
						<v-col lg="4" md="4" sm="6" cols="12" class="py-1">
							<v-checkbox
								v-model="isHidden"
								:label="$t('Account.is-hidden')"
								class="ma-0"
								:disabled="disableFieldsToPreview || submitLoading"
							></v-checkbox>
						</v-col>

						<!-- notes -->
						<v-col lg="12" md="12" sm="6" cols="12" class="py-1">
							<v-text-field
								v-model="notes"
								:label="$t('Account.notes')"
								outlined
								dense
								:disabled="disableFieldsToPreview || submitLoading"
								:loading="loading"
							></v-text-field>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-form>

		<!-- tabs users -->
		<v-card elevation="2" class="mt-4">
			<v-tabs
				v-model="tab"
				class="ms-5"
				slider-color="blue-dark"
			>
				<v-tab
					v-for="item in itemsTabs"
					:key="item"
				>
					{{ item }}
				</v-tab>
			</v-tabs>

			<v-tabs-items v-model="tab">
				<v-tab-item>
					<v-card flat>
						<v-card-text>
							<v-form>
								<v-row>
									<v-col cols="6" sm="6" md="3">
										<v-text-field
											v-model="contactInfo.mobileNumber"
											:label="$t('Account.info-account.mobile-number')"
											outlined
											dense
											hide-details
											:disabled="disableFieldsToPreview || submitLoading"
										/>
									</v-col>

									<v-col cols="6" sm="6" md="3">
										<v-text-field
											v-model="contactInfo.phoneNumber"
											:label="$t('Account.info-account.phone-number')"
											outlined
											dense
											hide-details
											:disabled="disableFieldsToPreview || submitLoading"
										/>
									</v-col>

									<v-col cols="6" sm="6" md="3">
										<v-text-field
											v-model="contactInfo.email"
											:label="$t('Account.info-account.email')"
											outlined
											dense
											hide-details
											:disabled="disableFieldsToPreview || submitLoading"
										/>
									</v-col>

									<v-col cols="12" sm="12" md="3">
										<v-text-field
											v-model="contactInfo.address"
											:label="$t('Account.info-account.address')"
											outlined
											dense
											hide-details
											:disabled="disableFieldsToPreview || submitLoading"
										/>
									</v-col>

									<v-col cols="6" sm="6" md="3">
										<v-text-field
											v-model="contactInfo.trn"
											:label="$t('Account.info-account.tax-number')"
											outlined
											dense
											hide-details
											:disabled="disableFieldsToPreview || submitLoading"
										/>
									</v-col>

								</v-row>
							</v-form>
						</v-card-text>
					</v-card>
				</v-tab-item>
				
				<v-tab-item
					:eager="true"
				>
					<bee-handy-smart-table
						v-model="tableInputs"
						ref="table"
						:headers="headers"
						:items="entries"
						:items-per-page="-1"
						:loading="loading"
						:editable-rows="!loading"
						:data-table-props="{
							disableSort: true,
							noDataText: '',
						}"
						hide-default-footer
						dense
						show-add-row
						@input-price="isPriceChangedByUser = true"
						@keypress.enter="!isEditItem ? addItem() : editItem()"
						@edit-cancelled="isEditItem = false"
						hot-inputs
						zebra
						table-class="py-2 px-3"
					>
						<!-- actions -->
						<template v-slot:item.actions="{ index }">
							<div class="d-flex">
								<!-- edit -->
								<v-btn
									width="30"
									min-width="30"
									height="25"
									color="edit-color"
									class="me-2 pa-0"
									:disabled="submitLoading || loading || disableFieldsToPreview"
									@click="$refs.table.activeEditForRow(index); setRowIndex({ index })"
									icon
								>
									<v-icon>mdi-pencil</v-icon>
								</v-btn>
								<!-- delete -->
								<v-btn
									width="30"
									min-width="30"
									height="25"
									color="alert-color"
									class="pa-0"
									:disabled="loading || disableFieldsToPreview"
									@click="deleteItem({ index })"
									icon
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</div>
						</template>

						<template v-slot:input.itemId="{ on, attr }">
							<v-autocomplete
								:items="items.filter(c => c.type === type.sub)"
								:item-text="(item) => `${item.no} - ${item.name}`"
								item-value="id"
								v-on="on"
								v-bind="attr"
								hide-details
								:disabled="disableFieldsToPreview || submitLoading"
								@change="getPrice()"
							/>
						</template>

						<template v-slot:input.customPrice="{ on, attr }">
							<v-text-field
								v-on="on"
								v-bind="attr"
								hide-details
								:disabled="disableFieldsToPreview || submitLoading"
							/>
						</template>

						<!-- <template v-slot:no-input.actions>
							<v-btn
								small
								text
								:color="!isEditItem ? 'success': null"
								@click="!isEditItem ? addItem() : editItem()"
							>
								<v-icon>mdi-{{!isEditItem ? 'playlist-plus' : 'check'}}</v-icon>
							</v-btn>
						</template> -->
					</bee-handy-smart-table>
				</v-tab-item>
			</v-tabs-items>
		</v-card>

	</div>
</template>

<script>
import rules from '@/helpers/validation rules'
import { finalAccounts, types, type, userType } from '@/helpers/enums'
import { mapGetters, mapState } from 'vuex'
export default {
	name: 'Account',
	
	data() {
		return {
			tab: null,
			
			text: ['lorem', 'text'],
			// helpers
			submitLoading: false,
			loading: false,
			
			isInSameItem: false,

			deleteDialog: false,

			noPrefix: '',
			disableFieldsToPreview: false,
			type,
			rules,

			// data
			name: null,
			mainAccountId: null,
			accountNo: null,
			accountType: null,
			finalAccount: null,
			isHidden: false,
			notes: null,
			entries: [],
			isPriceChangedByUser: false,
			tableInputs: {},
			isEditItem: false,
			savedTableInputs: {},
			rowEditIndex: null,
			userType,
			contactInfo: {
				mobileNumber: null,
				phoneNumber: null,
				address: null,
				email: null,
				trn: null
			}, 
			customPrices: this.entries
		}
	},

	watch: {
		watchRoute() {
			this.updatePageState();

			if (!this.isInSameItem) {
				this.fetchAccountData();
			}
		},

		isEditItem(val) {
			if (!val && !this.entries.find(c => c.itemId === this.savedTableInputs.itemId)) {
				this.$nextTick(() => {
					this.$refs.table.resetInputs(this.savedTableInputs)
				})
			}
		},
	},

	computed: {
		watchRoute() {
			return `${this.$route.name} | ${this.$route.params.id} | ${this.$route.query.preview}`
		},

		...mapState({
			accounts: state => state.accounts.accounts,
			items: state => state.items.items,
			permissionsForUser: state => state.auth.permissionsForUser
		}),

		...mapGetters({
			getAccountById: 'accounts/getAccountById',
			getItemById: 'items/getItemById'
		}),

		accountTypes() { return types() },
		finalAccounts() { return finalAccounts() },

		itemsTabs() {
			return [
				this.$t('Account.tabs.info'),
				this.$t('Account.tabs.custom-prices')
			]
		},

		headers() {
			return [
				{
					text: this.$t('headers.item'),
					name: 'itemId',
					value: 'itemId',
					setValueExpr: val => this.getItemById(val)?.name,
					// setValueExpr: val => this.items.find(c => c.id === val)?.name,
					// inputRules: rules.required,
					width: 170,
				},
				{
					text: this.$t('headers.price'),
					name: 'price',
					value: 'itemPrice',
					// setValueExpr: val => this.getItemById(val)?.salePrice?.amount,
					// setInputExpr: inputs => {
					// 	return this.getItemById(inputs.itemId)?.salePrice?.amount || 0
					// },
					disableInput: true,
					width: 80
				}, 

				{
					text: this.$t('headers.custom-price'),
					name: 'customPrice',
					value: 'customPrice',
					// setValueExpr: val => this.getItemById(val)?.name,
					// inputRules: rules.required,
					width: 100,
					type: 'number'
				},
				{
					text: '',
					name: 'actions',
					value: 'actions',
					width: 50,
					disableInput: true
				}
			]
		}
	},

	methods: {
		updatePageState() {
			if (this.$route.query.preview) {
				this.disableFieldsToPreview = true;
				this.$refs.form?.resetValidation();
			} else {
				this.disableFieldsToPreview = false;
			}
		},

		fetchSuggestedChildNo(accountId) {
			if (accountId) {
				this.$store.dispatch('accounts/suggestNewChildNo', { id: accountId })
					.then((data) => { this.accountNo = data })
			} else {
				this.accountNo = null;
			}
		},

		fetchAccountData() {
			if (this.$route.params.id) {
				this.loading = true;
				this.$emit('update-add-state', false);
				Promise.all([
					this.$store.dispatch('accounts/fetchById', { id: this.$route.params.id })
						.then((data) => {
							this.name = data.name;
							this.mainAccountId = data.parentAccountId;
							this.noPrefix = data.parentAccountingNo;
							this.accountNo = data.innerAccountingNo
							this.accountType = data.type;
							this.finalAccount = data.finalType;
							this.isHidden = data.isHidden;
							this.notes = data.notes;
							this.contactInfo.mobileNumber = data.contactInfo.mobileNumber
							this.contactInfo.phoneNumber = data.contactInfo.phoneNumber
							this.contactInfo.address = data.contactInfo.address
							this.contactInfo.email = data.contactInfo.email
							this.contactInfo.trn = data.contactInfo.trn
							this.entries = data.customPrices.map(c => ({ ...c, customPrice: c.price.amount, itemPrice: this.getItemById(c.itemId)?.salePrice?.amount }))
						}),
					this.$route.query.addSub
						? this.fetchSuggestedChildNo(this.$route.params.id)
						: null
				]).finally(() => {
					this.loading = false;
					this.$emit('update-add-state', true);
				})
			}
		},

		saveAccount() {
			if (this.$refs.form.validate()) {
				this.submitLoading = true;
				const actionName = this.$route.params.id && !this.$route.query.addSub ? 'update' : 'create';
				this.$store.dispatch(`accounts/${actionName}`, {
					id: this.$route.params.id,
					name: this.name,
					innerAccountingNo: this.accountNo,
					type: this.accountType,
					finalType: this.finalAccount,
					isHidden: this.isHidden,
					notes: this.notes,
					parentAccountId: this.mainAccountId,
					contactInfo: {
						mobileNumber: this.contactInfo.mobileNumber,
						phoneNumber: this.contactInfo.phoneNumber,
						address: this.contactInfo.address,
						email: this.contactInfo.email,
						trn: this.contactInfo.trn
					}, 
					customPrices: this.entries.map(c => ({
						itemId: c.itemId,
						price: {
							amount: c.customPrice,
							currencyId: this.$localStorage.currencyId
						}
					}))
				}).then((data) => {
					this.$emit('update-tree-accounts');
					this.$router.push({
						name: 'account',
						params: { id: actionName === 'update' ? this.$route.params.id : data },
						query: { preview: 1 }
					});
				}).finally(() => {
					this.submitLoading = false;
				})
			}
		},

		resetTableInputs() {
			this.$refs.table.resetInputs({
				itemId: null,
				customPrice: null,
				price: null,
				
			})
		},

		addItem() {
			if (this.$refs.table.validate()) {
				this.entries.push({
					barcode: this.getItemById(this.tableInputs.itemId)?.barcode,
					...this.tableInputs,
					// price:
					itemPrice: this.tableInputs.price
				});
				this.$nextTick(() => {
					this.resetTableInputs()
					this.$refs.table.resetValidation()
				})
			}
		},

		editItem() {
			// if (this.$refs.table.validate()) {
			this.entries[this.rowEditIndex].itemId = this.tableInputs.itemId;
			this.entries[this.rowEditIndex].price = this.getItemById(this.tableInputs.price)?.salePrice?.amount;
			this.entries[this.rowEditIndex].customPrice = this.tableInputs.customPrice;
			this.$refs.table.resetEdit()
			this.resetTableInputs();
			this.isEditItem = false;
			// this.$refs.table.resetInputsFocus()
			// }
		},

		setRowIndex(rowData) {
			this.rowEditIndex = rowData.index;
			this.isEditItem = true;
			this.$nextTick(() => {
				this.$refs.table.resetInputsFocus()
			})
		},

		deleteItem({ index }) {
			this.$refs.table.resetEdit()
			this.isEditItem = false;
			this.entries.splice(index, 1);
		},

		getPrice() {
			this.tableInputs.price = this.getItemById(this.tableInputs.itemId)?.salePrice?.amount
		}

	},

	created() {
		this.updatePageState();

		if (!this.$route.params.id || !this.$route.query.addSub) {
			this.fetchAccountData();
		}
		if (this.$route.query.addSub) {
			this.mainAccountId = this.$route.params.id;
			this.noPrefix = String(this.getAccountById(this.$route.params.id).accountingNo);
			this.fetchSuggestedChildNo(this.$route.params.id)
		}
	},

	// route helper
	beforeRouteUpdate(to, from, next) {
		if (to.params.id === from.params.id) {
			this.isInSameItem = true;
			if (to.query.preview) {
				this.isInSameItem = false;
			}
		} else {
			this.isInSameItem = false;
		}
		if (!to.params.id || to.query.addSub) {
			this.$refs.form.reset();
			this.isHidden = false;
			this.noPrefix = null;
		}
		if (to.query.addSub) {
			this.mainAccountId = to.params.id;
			this.noPrefix = String(this.getAccountById(to.params.id).accountingNo);
			this.fetchSuggestedChildNo(to.params.id)
		}
		next();
	},

	// meta info
	metaInfo() {
		let title;
		if (this.$route.query.preview) {
			title = this.$t('Navbar.info') + ' ';
		} else if (this.$route.query.addSub) {
			title = this.$t('add-sub') + (this.$i18n.locale === 'ar' ? '' : ' ');
		} else if (this.$route.params.id) {
			title = this.$t('edit') + ' ';
		} else {
			title = this.$t('add') + ' ';
		}
		return {
			title: title + this.$t('routes.account'),
			titleTemplate: `%s | ${this.$t('routes.accounts-tree')} | POS`
		}
	}
}
</script>