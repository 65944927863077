import * as types from '../mutation-types'
export default {
	namespaced: true,
	state: {
		statistics: [
			{
				cashboxBalances: {
					cashbox: {
						name: null
					},
					accountBalance: {
						name: null,
						balance: {
							amount: null
						}
					}
					
				},
				accountConfigBalances: []
			}
		]
	},
	
	actions: {
		fetchDetailedDayStatement({ commit }, { forDate, cashboxId, compoundType }) {
			return axios.get('/accounting/detailedDayStatementReport', {
				params: { forDate, cashboxId, compoundType }
			}).then(({ data }) => data)
		},

		fetchStatistics({ commit }) {
			return axios.get('/accounting/Statistics').then(({ data }) => {
				commit(types.STORE_STATICS, data)
				return data
			})
		}
	},

	mutations: {
		[types.STORE_STATICS] (state, statistics) {
			state.statistics = statistics
		}
	}
}