var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"invoice-tabs"}},[(_vm.draftInvoices.length)?_c('v-chip-group',{style:({ maxWidth: _vm.maxWidth }),attrs:{"center-active":"","show-arrows":"","active-class":"primary elevation-0 white--text"},model:{value:(_vm.activeInvoice),callback:function ($$v) {_vm.activeInvoice=$$v},expression:"activeInvoice"}},_vm._l((_vm.draftInvoices),function(item,index){return _c('v-chip',{key:index,staticClass:"rounded-t-0 rounded-b-lg elevation-1 ma-0 mb-1 me-1 px-1",class:{ 'grey lighten-3': item.id !== _vm.activeInvoice },style:({ minWidth: '20px', overflow: 'hidden' }),attrs:{"value":item.id},on:{"click":function($event){item.id !== _vm.$route.params.id
					? _vm.$router.push({ name: _vm.$route.name, params: { id: item.id } })
					: null}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1):_vm._e(),_c('tooltip',{attrs:{"bottom":"","text":_vm.$t('add-invoice')}},[_c('v-btn',{staticClass:"rounded-t-0 rounded-b-lg align-self-start me-1",attrs:{"min-width":"0","elevation":"1","width":"38","height":"32","loading":_vm.loading,"disabled":_vm.loading || _vm.disable},on:{"click":function($event){_vm.loading = true;
				_vm.eventHub.$emit('create-invoice', data => {
					_vm.loading = false;
				}
			)}}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-plus")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }